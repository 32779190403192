<template>
  <div class="main-content sys-user-manage">
    <div class="btn-area">
      <div class="right">
        <el-button-group>
          <el-button type="default" size="small" @click="refreshClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-shuaxin" />
            </svg>
            <span>刷新</span>
          </el-button>
          <el-button type="default" size="small" @click="addClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-xinzeng" />
            </svg>
            <span>新增</span>
          </el-button>
          <el-button type="default" size="small" @click="editClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-edit" />
            </svg>
            <span>编辑</span>
          </el-button>
          <el-button type="default" size="small" @click="delClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-trash" />
            </svg>
            <span>删除</span>
          </el-button>
        </el-button-group>
      </div>
    </div>
    <div class="table-area">
      <el-table
        size="small"
        :data="answerList"
        style="width: 100%; margin-bottom: 20px"
        row-key="userId"
        border
        ref="answerListTable"
        height="100%"
        tooltip-effect="dark"
        @selection-change="handleSelectionChange"
        @row-click="answerRowClick"
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="question"
          label="问题"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="periodId"
          label="学段"
          align="center"
          :formatter="answerPeriodId"
        ></el-table-column>
      </el-table>
    </div>
    <div class="page-area">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="answerParams.pageNumber"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="answerParams.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background
      ></el-pagination>
    </div>
    <el-dialog
      :title="answerDialog.title"
      :visible.sync="answerSync"
      width="700px"
      center
    >
      <div class="save-dialog-content">
        <el-form
          :model="answerRuleForm"
          status-icon
          ref="answerRuleForm"
          label-width="100px"
          size="small"
          :rules="answerRules"
        >
          <el-row :gutter="20">
            <el-col :span="20">
              <el-form-item label="问题" prop="question">
                <el-input
                  type="textarea"
                  :rows="2"
                  v-model="answerRuleForm.question"
                  placeholder="请输入问题"
                ></el-input>
              </el-form-item>
              <el-form-item label="学段" prop="periodId">
                <el-select
                  v-model="answerRuleForm.periodId"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="option in phaseList"
                    :key="option.macroId"
                    :label="option.name"
                    :value="option.macroId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="answerSync = false" size="small">取 消</el-button>
        <el-button
          type="primary"
          @click="confirmSave('answerRuleForm')"
          size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  getSelectList,
  sectionCode,
  subjectCode,
  teachTitle,
  fileUpload,
  phaseCode
} from "@/api/public";
import {
  //讲师接口
  teacherQuestionList,
  teacherQuestionSave,
  teacherQuestionUpdate,
  teacherQuestionRemove,
  teacherQuestionInfo
} from "@/api/teacher/teacherManage";
export default {
  data() {
    return {
      answerList: [],
      total: 0,
      answerId: [],
      answerParams: {
        pageNumber: 1,
        pageSize: 10
      },
      answerRuleForm: {
        question: "",
        periodId: ""
      },
      answerDialog: {
        title: "新增面试答题",
        type: "add"
      },
      answerSync: false,
      phaseList: [],
      answerRules:{
        question: [
          { required: true, message: "问题不能为空", trigger: "blur" }
        ],
        periodId: [
          { required: true, message: "学段不能为空", trigger: "change" }
        ],
      }
    };
  },
  created() {
    this.teacherQuestionList();
  },
  methods: {
    //列表数据
    teacherQuestionList() {
      teacherQuestionList(this.answerParams).then(res => {
        this.answerList = res.rows;
        this.total = res.total;
        // this.answerSync=false;
      });
    },
    refreshClick() {
      //刷新
      this.reload();
    },
    addClick() {
      this.answerRuleForm.question = "";
      this.answerRuleForm.periodId = "";
      this.answerSync = true;
      this.answerDialog = {
        title: "新增面试答题",
        type: "add"
      };
    },
    editClick() {
      if (this.answerId.length > 1 || this.answerId.length == 0) {
        this.msgWarn("请选择一条数据进行编辑操作！");
        return;
      }
      this.answerSync = true;
      this.answerDialog = {
        title: "编辑面试答题",
        type: "edit"
      };
      this.answerRuleForm.question = "";
      this.answerRuleForm.periodId = "";
      teacherQuestionInfo(this.answerId[0]).then(res => {
        if (res.code == 0) {
          this.answerRuleForm.question = res.rows.question;
          this.answerRuleForm.periodId = res.rows.periodId;
        }
      });
    },
    delClick() {
      if (this.answerId.length == 0) {
        this.msgWarn("请至少选择一条数据！");
        return;
      }
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          teacherQuestionRemove(this.answerId).then(res => {
            if (res.code == 0) {
              this.msgSuccess("操作成功！");
              this.teacherQuestionList();
            }
          });
        })
        .catch(() => {});
    },
    handleSelectionChange(val) {
      this.answerId = [];
      if (val.length > 0) {
        for (let i = 0, len = val.length; i < len; i++) {
          this.answerId.push(val[i].id);
        }
      }
    },
    answerRowClick(row) {
      this.$refs.answerListTable.toggleRowSelection(row);
    },
    handleSizeChange(pageSize) {
      this.answerParams.pageSize = pageSize;
      this.teacherQuestionList();
    },
    handleCurrentChange(currentPage) {
      this.answerParams.pageNumber = currentPage;
      this.teacherQuestionList();
    },
    confirmSave(formName) {
      // this.saveRuleForm.periodId=this.periodId.join();//学段
      // this.saveRuleForm.sectionId=this.sectionId.join();//年纪
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.answerDialog.type == "add") {
            teacherQuestionSave(this.answerRuleForm).then(res => {
              if (res.code == 0) {
                this.msgSuccess("操作成功！");
                this.answerList = res.rows;
                this.answerSync = false;
                this.teacherQuestionList();
              }
            });
          } else if (this.answerDialog.type == "edit") {
            this.answerRuleForm.id = this.answerId[0];
            teacherQuestionUpdate(this.answerRuleForm).then(res => {
              if (res.code == 0) {
                this.msgSuccess("操作成功！");
                this.answerSync = false;
                this.teacherQuestionList();
              }
            });
          }
        } else {
          this.msgWarn("必填字段不能为空！");
          return false;
        }
      });
    },
    answerPeriodId(row) {
      var str = "";
      var arr2 = new Array();
      if (row.periodId == 107) {
        str = "高中";
      } else if (row.periodId == 106) {
        str = "初中";
      } else if (row.periodId == 105) {
        str = "小学";
      } else if (row.periodId == 108) {
        str = "艺术培训";
      } else if (row.periodId == 109) {
        str = "小语种";
      }else if (row.periodId == 235) {
        str = "全学段";
      }
      return str;
    },
    getPhaseList() {
      getSelectList({
        parentCode: phaseCode
      }).then(res => {
        if (res.code == 0) {
          this.phaseList = res.rows;
          this.phaseList.unshift({
            macroId:235,
            name: "全学段",
          });
        }
      });
    }
  },
  mounted() {
    this.getPhaseList();
  }
};
</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */
.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .btn-area {
    height: 50px;
    display: flex;
    align-items: center;
    .left {
      width: 300px;
    }
    .right {
      flex: 1;
      display: flex;
      justify-content: flex-end;
    }
  }
  .table-area {
    flex: 1;
    overflow: hidden;
  }
  .page-area {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .save-dialog-content {
    padding-right: 20px;
  }
}
</style>
