<template>
  <div class="main-content sys-role-manage sys-menu-manage">
    <div class="btn-area">
      <div class="left"></div>
      <div class="right">
        <el-button-group>
           
          <el-button type="default" size="small" @click="refreshClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-shuaxin" />
            </svg>
            <span>刷新</span>
          </el-button>
          <el-button type="default" size="small" @click="addClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-xinzeng" />
            </svg>
            <span>新增</span>
          </el-button>
          <el-button type="default" size="small" @click="editClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-edit" />
            </svg>
            <span>编辑</span>
          </el-button>
          <el-button type="default" size="small" @click="delClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-trash" />
            </svg>
            <span>删除</span>
          </el-button>
        </el-button-group>
      </div>
    </div>
    <div class="table-area org-tree">
      <el-tree
        node-key="id"
        :props="props"
        :load="loadNode"
        lazy
        :highlight-current="treeOptions.highlightCurrent"
        :expand-on-click-node="treeOptions.expandOnClickNode"
        :default-expand-all="treeOptions.defaultExpandAll"
        :default-expanded-keys="treeOptions.defaultExpandedKeys"
        @node-click="handleNodeClick"
        :key="treeKey"
        style="height: 400px"
      ></el-tree>
    </div>
    <el-dialog :title="saveDialog.title" :visible.sync="saveDialogVisible" width="500px" center>
      <div>
        <el-form
          :model="saveRuleForm"
          status-icon
          :rules="saveRules"
          ref="saveRuleForm"
          label-width="100px"
          size="small"
        >
          <el-form-item label="名称" prop="name">
             <el-input v-model="saveRuleForm.name" :disabled=true @click.native="choiceName()"></el-input>
          </el-form-item>
          <el-form-item label="排序" prop="sort">
            <el-input v-model="saveRuleForm.sort"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="saveDialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="confirmSave('saveRuleForm')" size="small">2确 定</el-button>
      </span>
    </el-dialog>
     <el-dialog title="选择知识点" :visible.sync="choiceDialogVisible" width="350px" center>
      <div class="dialog-content">
         <el-tree
          node-key="id"
          :props="props"
          :load="knowledgeloadNode"
          lazy
          :highlight-current="knowledgeOptions.highlightCurrent"
          :expand-on-click-node="knowledgeOptions.expandOnClickNode"
          :default-expand-all="knowledgeOptions.defaultExpandAll"
          :default-expanded-keys="knowledgeOptions.defaultExpandedKeys"
          @node-click="knowledgeNodeClick"
          :key="treeKey"
          style="height: 400px"
        ></el-tree>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="choiceDialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="choiceSave()" size="small">确 定</el-button>
      </span>
    </el-dialog>
    
    <!-- <el-dialog
      title="导入知识点"
      :visible.sync="importDialogVisible"
      :close-on-click-modal="false"
      width="800px"
      center
    >
      <div class="dialog-content">
        <el-form
          :model="importRuleFormOrder"
          status-icon
          :rules="importRuleOrder"
          ref="importRuleFormOrder"
          label-width="100px"
          size="small"
        >
          <el-form-item
            label="辅导协议"
            prop="coachAgreement"
          >
            <el-row align="middle" type="flex">
              <el-col :span="12" class="mL20">
                <el-upload
                  :action="importFile"
                  :on-success="coachAgreementSuccess"
                  :on-error="coachAgreementError"
                  :show-file-list="false"
                >
                  <el-button
                    size="small"
                    type="primary"
                    >点击上传</el-button
                  >
                </el-upload>
              </el-col>
            </el-row>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog> -->
  </div>
</template>

<script>
import {
  systemCourseListSyncTree,
  systemCourseSave,
  systemCourseRemove,
  systemCourseUpdate,
  systemCourseInfo,

} from "@/api/system/systemManage";
import {
  catalog,
  knowledge,
  catalogCheckRemove,
  catalogRemove,
  catalogSave,
  catalogUpdate,
  catalogInfo,
  importFile
} from "@/api/teacher/teacherManage";
import { getSelectList, phaseCode, sectionCode, subjectCode,fileUpload, } from "@/api/public";
import { formatTree } from "@/utils/common";
export default {
  inject: ['reload'],
  data() {
    return {
      selectFile:[],
      fileUpload,
      importFile,
      importRuleFormOrder:{},
      importRuleOrder:{},
      importDialogVisible:false,
      saveDialogVisible: false,
      saveRuleForm: {
        businessMark:'public',
        dataType:5,
        dictId:'',
        id:0,
        name:'',
        parentId:'',
        parentIds:'',
        sort:''
        // id: 0,
        // name: "",
        // dictId: "",
        // parentId: "",
        // parentIds: "",
        // dataType: 1,
        // sort: "",
      },
      saveRules: {
        name: [
          { required: true, message: "名称不能为空", trigger: "blur" },
        ],
        sort: [{ required: true, message: "排序不能为空", trigger: "blur" }],
      },
      saveDialog: {
        title: "新增",
        type: "add",
      },
      defaultProps: {
        children: "children",
        label: "name",
      },
      treeData: [],
      treeOptions: {
        defaultExpandAll: false,
        expandOnClickNode: true,
        highlightCurrent: true,
        defaultExpandedKeys: [0],
        selectNode: {},
      },
      props: {
        label: "name",
      },
      parentId: 0,
      childList: [],
      // selectList: [],
      treeKey: new Date().getTime(),
      choiceDialogVisible:false,
      knowledgeOptions: {
        defaultExpandAll: false,
        expandOnClickNode: true,
        highlightCurrent: true,
        defaultExpandedKeys: [0],
        selectNode: {},
      },
      knowledgetreeData:[],
      knowledgechildList:[],
      knowledgeparentId:0,
      


    };
  },
  methods: {
    loadNode(node, resolve) {
      if (node.level === 0) {
        return catalog({
          parentId: this.parentId,
        }).then((res) => {
          if (res.code == 0) {
            this.treeData = formatTree(res.rows, {
              id: "id",
              parentId: "parentId",
              originId: -1,
            });
            if (this.treeData[0].children.length > 0) {
              this.childList = JSON.parse(
                JSON.stringify(this.treeData[0].children)
              );
            }
            resolve(this.treeData);
          }
        });
      }
      if (node.level === 1) {
        return resolve(this.childList);
      }
      if (node.level > 1) {
        this.parentId = node.data.id;
        return catalog({
          parentId: this.parentId,
        }).then((res) => {
          if (res.code == 0) {
            resolve(res.rows);
          }
        });
      }
    },
    handleNodeClick(selectNode) {
      this.treeOptions.selectNode = selectNode;
      console.log(selectNode)
    },
    confirmSave(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // for (let i = 0, len = this.selectList.length; i < len; i++) {
          //   if (this.saveRuleForm.dictId == this.selectList[i].macroId) {
          //     this.saveRuleForm.name = this.selectList[i].name;
          //     break;
          //   }
          // }
          let data = {
            id: this.saveRuleForm.id,
            name: this.saveRuleForm.name,
            dictId: this.saveRuleForm.dictId,
            parentId: this.saveRuleForm.parentId,
            parentIds: this.saveRuleForm.parentIds,
            dataType: this.saveRuleForm.dataType,
            sort: this.saveRuleForm.sort,
            businessMark:'public'
          };
          if (this.saveDialog.type == "add") {
            catalogSave(data).then((res) => {
              if (res.code == 0) {
                this.msgSuccess("操作成功！");
                this.saveDialogVisible = false;
                this.parentId = 0;
                this.treeKey = new Date().getTime()
              }
            });
          } else if (this.saveDialog.type == "edit") {
            catalogUpdate(data).then((res) => {
              if (res.code == 0) {
                this.msgSuccess("操作成功！");
                this.saveDialogVisible = false;
                this.parentId = 0;
                this.treeKey = new Date().getTime()
              }
            });
          }
        } else {
          this.msgWarn("必填字段不能为空！");
          return false;
        }
      });
    },
    editClick() {
      if (this.treeOptions.selectNode.id === undefined) {
        this.msgWarn("请选择一个条目进行编辑！");
        return;
      }
       if (this.treeOptions.selectNode.dataType < 5) {
        this.msgWarn("请选择知识点修改!");
        return;
      }
      this.saveDialog.title = "编辑";
      this.saveDialog.type = "edit";
      this.saveRuleForm = {
        id: this.treeOptions.selectNode.id,
        name: "",
        dictId: "",
        parentId: "",
        parentIds: "",
        dataType: 1,
        sort: "",
      };
      this.saveDialogVisible = true;
      console.log(this.saveRuleForm.id,'this.saveRuleForm.id....')
      catalogInfo(this.saveRuleForm.id).then((res) => {
        
        if (res.code == 0) {
          this.saveRuleForm = {
            id: res.rows.id,
            name: res.rows.name,
            dictId: res.rows.dictId,
            parentId: res.rows.parentId,
            parentIds: res.rows.parentIds,
            dataType: res.rows.dataType,
            sort: res.rows.sort,
          };
          let parentCode;
          if (this.saveRuleForm.dataType == 1) {
            parentCode = phaseCode;
          } else if (this.saveRuleForm.dataType == 2) {
            parentCode = sectionCode;
          } else if (this.saveRuleForm.dataType == 3) {
            parentCode = subjectCode;
          }
        }
      });
    },
    addClick() {
      let parentCode;
      if (this.treeOptions.selectNode.id === undefined) {
        this.msgWarn("请选择一个目录添加下级！");
        return;
      }
      if (this.treeOptions.selectNode.dataType < 4) {
        this.msgWarn("请在章节目录上创建知识点!");
        return;
      }
      this.saveRuleForm.parentIds=this.treeOptions.selectNode.parentIds+','+this.treeOptions.selectNode.id;
      this.saveRuleForm.parentId=this.treeOptions.selectNode.id;
      this.saveDialog.title = "新增";
      this.saveDialog.type = "add";
      this.saveRuleForm = {
        id: 0,
        name: "",
        dictId: "",
        parentId: this.treeOptions.selectNode.id,
        parentIds: "",
        dataType: Number(this.treeOptions.selectNode.dataType) + 1,
        sort: "",
      };
      if (this.treeOptions.selectNode.id == 0) {
        this.saveRuleForm.parentIds = "0";
      } else {
        this.saveRuleForm.parentIds =
          this.treeOptions.selectNode.parentIds +
          "," +
          this.treeOptions.selectNode.id;
      }
      if (this.saveRuleForm.dataType == 1) {
        parentCode = "sys.teach.phase";
      } else if (this.saveRuleForm.dataType == 2) {
        parentCode = "sys.teach.section";
      } else if (this.saveRuleForm.dataType == 3) {
        parentCode = "sys.teach.subject";
      }
      // getSelectList({
      //   parentCode,
      // }).then((res) => {
      //   if (res.code == 0) {
      //     this.selectList = res.rows;
      //   }
      // });
      this.saveDialogVisible = true;
    },
   
    
    
    coachAgreementSuccess(){

    },
    coachAgreementError(){

    },
    refreshClick() {
      this.reload()
    },
    delClick() {
      if (this.treeOptions.selectNode.id === undefined) {
        this.msgWarn("请选择一个条目进行操作！");
        return;
      }
      if(this.treeOptions.selectNode.dataType!=5){
        this.msgWarn("请选择知识点删除！");
        return;
      }
       catalogCheckRemove(this.treeOptions.selectNode.id).then((res) => {
            if (res.rows=='OK') {
              this.$confirm("确定要删除吗?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              })
                .then(() => {
                  catalogRemove([this.treeOptions.selectNode.id]).then((res) => {
                    if (res.code == 0) {
                      this.msgSuccess("操作成功！");
                      this.parentId = 0;
                      this.treeKey = new Date().getTime()
                    }
                  });
                })
                .catch(() => {});
            }
          });
    },
    choiceName(){
        this.choiceDialogVisible=true;
    },
    knowledgeNodeClick(selectNode){
      
        if(selectNode.dataType>=3){
            this.knowledgeOptions.selectNode=selectNode;
        }else{
          this.msgWarn("请选择一个知识点目录!");
          return;
        }
        console.log(selectNode,'点添加选择的数据。。。')
        
    },
    knowledgeloadNode(node, resolve) {
      if (node.level === 0) {
        return knowledge({
          parentId: this.knowledgeparentId,
        }).then((res) => {
          if (res.code == 0) {
            this.knowledgetreeData = formatTree(res.rows, {
              id: "id",
              parentId: "parentId",
              originId: -1,
            });
            console.log(this.knowledgetreeData,'knowledgetreeData....')
            if (this.knowledgetreeData[0].children.length > 0) {
              this.knowledgechildList = JSON.parse(
                JSON.stringify(this.knowledgetreeData[0].children)
              );
            }
            resolve(this.knowledgetreeData);
          }
        });
      }
      if (node.level === 1) {
        return resolve(this.knowledgechildList);
      }
      if (node.level > 1) {
        this.parentId = node.data.id;
        return knowledge({
          parentId: this.parentId,
        }).then((res) => {
          if (res.code == 0) {
            resolve(res.rows);
          }
        });
      }
    },
    
    choiceSave(){
      // if(this.knowledgeOptions.selectNode=={}){
      //     this.msgWarn("请选择一个知识点目录!");
      //     return;
      // }else{
      // }
      // console.log(this.knowledgeOptions.selectNode)
      this.saveRuleForm.name=this.knowledgeOptions.selectNode.name;
      this.saveRuleForm.dictId=this.knowledgeOptions.selectNode.id;
      this.choiceDialogVisible=false;
    }
  },
  created() {},
  mounted() {},
};
</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */
.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .btn-area {
    height: 50px;
    display: flex;
    align-items: center;
    .left {
      width: 300px;
    }
    .right {
      flex: 1;
      display: flex;
      justify-content: flex-end;
    }
  }
  .table-area {
    flex: 1;
    padding: 20px 0;
    overflow: auto;
    border: 1px solid #f1f1f1;
  }
   .dialog-content {
    min-height: 260px;
    max-height: 60vh;
    overflow-y: auto;
    padding-left: 10px;
    padding-right: 10px;
    margin-top:30px;
    .item_select {
      display: flex;
      align-items: center;
      span {
        width: 120px;
      }
    }
    .record {
      margin-top: 230px;
    }
  }
}
</style>