<template>
  <div class="main-content sys-role-manage">
    <div class="btn-area select-condition">
      <div class="left">
        <div class="select-condition-item">
          <span class="item-lable">关键字：</span>
          <el-input
            v-model="listParams.name"
            placeholder="请输入姓名/联系方式/昵称"
            size="small"
            style="min-width:120px"
          ></el-input>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">年级：</span>
          <el-select
            v-model="listParams.sectionId"
            placeholder="请选择"
            size="small"
            style="width:120px"
          >
            <el-option
              v-for="item in sectionList"
              :key="item.macroId"
              :label="item.name"
              :value="item.macroId"
            ></el-option>
          </el-select>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">学科：</span>
          <el-select
            v-model="listParams.subjectId"
            placeholder="请选择"
            size="small"
            style="width:120px"
          >
            <el-option
              v-for="item in subjectList"
              :key="item.macroId"
              :label="item.name"
              :value="item.macroId"
            ></el-option>
          </el-select>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">可面授地点：</span>
          <el-select
                  v-model="listParams.currentProvinceIds"
                  placeholder="请选择"
                  size="small"
                  clearable
                  @clear="clearFn()"
                  @change="listProvinceChange"
                  style="width:150px"
                >
                  <el-option
                    v-for="item in listProvinceData"
                    :key="item.areaId"
                    :label="item.name"
                    :value="item.areaId"
                  ></el-option>
                </el-select>
                <el-select
                  v-model="listParams.currentCityIds"
                  placeholder="请选择"
                  size="small"
                   clearable
                   @clear="clearFn()"
                  style="width:150px;margin-left:10px;"
                  @change="listCityChange"
                >
                  <el-option
                    v-for="item in listCityData"
                    :key="item.areaId"
                    :label="item.name"
                    :value="item.areaId"
                  ></el-option>
                </el-select>
                <el-select
                  v-model="listParams.currentCountyIds"
                  placeholder="请选择"
                  size="small"
                   clearable
                   @clear="clearFn()"
                  style="width:150px;margin-left:10px;"
                >
                  <el-option
                    v-for="item in listCountyData"
                    :key="item.areaId"
                    :label="item.name"
                    :value="item.areaId"
                  ></el-option>
                </el-select>
        </div>  
        <div class="select-condition-item">
          <el-button
            type="primary"
            size="small"
            icon="el-icon-search"
            @click="()=>(listParams.pageNumber=1,getteacherManageList())"
            >搜索</el-button
          >
        </div>
      </div>
    </div>
    <div class="btn-area select-condition">
      <div class="left">
          <div class="select-condition-item">
          <span class="item-lable">讲师职称：</span>
          <el-select
            v-model="listParams.teachTitleId"
            placeholder="请选择"
            size="small"
            style="width:120px"
          >
            <el-option
              v-for="item in teachTitleIdListSearch"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">教龄：</span>
          <el-select
            v-model="listParams.teachingAgeId"
            placeholder="请选择"
            size="small"
            style="width:120px"
          >
            <el-option
              v-for="item in teachingAgeIdListSearch"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">讲师身份：</span>
          <el-select
            v-model="listParams.identityType"
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="item in identityTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div class="btn-area">
      <div class="right">
        <el-button-group>
          <el-button type="default" size="small" @click="refreshClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-shuaxin" />
            </svg>
            <span>刷新</span>
          </el-button>
          <el-button type="default" size="small" @click="dealAdd">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-xinzeng" />
            </svg>
            <span>添加讲师</span>
          </el-button>
          <el-button type="default" size="small" @click="dealEdit">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-edit" />
            </svg>
            <span>编辑讲师</span>
          </el-button>
           <el-button type="default" size="small" @click="dealSeeTit">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-edit" />
            </svg>
            <span>查看讲师</span>
          </el-button>
          <el-button type="default" size="small" @click="dealReset">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-mima2" />
            </svg>
            <span>重置密码</span>
          </el-button>
        </el-button-group>
      </div>
    </div>
    <div class="table-area">
      <el-table
        size="small"
        :data="listArray"
        style="width: 100%; margin-bottom: 20px"
        row-key="id"
        border
        height="100%"
        tooltip-effect="dark"
        ref="productListTable"
        @selection-change="handleSelectionChange"
        @row-click="studentItemClick"
        :row-class-name="tableRowClassName"
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="no"
          label="编号"
          align="center"
        ></el-table-column>
        
        <el-table-column
          prop="name"
          label="昵称"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="realname"
          label="姓名"
          align="center"
        >
        <template slot-scope="scope">
          <div style="text-decoration:underline;cursor:pointer;" @click.stop="dealSee(scope.row)">{{ scope.row.realname }}</div>
        </template>
        </el-table-column>
        <el-table-column
          prop="account"
          label="账号"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="sexId"
          :formatter="dealSex"
          label="性别"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="sectionId"
          label="年级"
          :formatter="dealSectionId"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="subjectId"
          label="科目"
          :formatter="dealSubjectIdd"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="identityType"
          :formatter="dealIdentityType"
          label="教师身份"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="teachingAgeId"
          label="教龄"
          :formatter="dealTeachingAgeId"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="teachTitleId"
          :formatter="dealTeachTitleId"
          label="教师职称"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="no"
          label="状态"
          align="center"
        >
          <template slot-scope="scope">
            <p style="color:red;" v-show="scope.row.isBasicdata==2">审核通过</p>
            <p style="color:red;" v-show="scope.row.isBasicdata==3">审核失败</p>
           <p style="color:red;" v-show="scope.row.isBasicdata!=3&&scope.row.isBasicdata!=2
           " >审核中</p>
          </template>
        </el-table-column>
        <el-table-column prop="" label="更多" align="center" type="expand">
          <template slot-scope="props">
            <el-form label-position="left" inline class="demo-table-expand">
             <el-form-item label="基本信息">
                <span style="color:red" v-show="props.row.isBasicdata==0"
                 @click.stop="dealRowSee(props.row.id,1)"
                  >(未认证)</span
                >
                <span style="color:red" v-show="props.row.isBasicdata==1"
                   @click.stop="dealRowSee(props.row.id,1)"
                  >(审核中)</span
                >
                 <span style="color:#2fbd4a" v-show="props.row.isBasicdata==2"
                   @click.stop="dealRowSee(props.row.id,1)"
                  >(认证成功)</span
                >
                <span style="color:red" v-show="props.row.isBasicdata==3"
                   @click.stop="dealRowSee(props.row.id,1)"
                  >(已驳回)<span  @click.stop="seeReason(props.row.basicdataRejectReason)">原因</span></span
                >
              </el-form-item>
             <el-form-item label="资质认证">
                <span
                  @click.stop="dealRowSee(props.row.id,2)"
                  style="color:red"
                  v-show="
                    props.row.idcardAuthStatus == 0 ||
                      props.row.capesAuthStatus == 0
                  "
                  >(未认证)</span
                >
                <span
                @click.stop="dealRowSee(props.row.id,2)"
                  style="color:red"
                  v-show="
                    props.row.idcardAuthStatus == 1 ||
                      props.row.capesAuthStatus == 1
                  "
                  >(审核中)</span
                >
                <span
                  @click.stop="dealRowSee(props.row.id,2)"
                  style="color:#2fbd4a"
                  v-show="
                    props.row.idcardAuthStatus == 2 ||
                      props.row.capesAuthStatus == 2
                  "
                  >(认证成功)</span
                >
                <span
                  @click.stop="dealRowSee(props.row.id,2)"
                  style="color:red"
                  v-show="
                    props.row.idcardAuthStatus == 3 ||
                      props.row.capesAuthStatus == 3
                  "
                  >(已驳回)<span  @click.stop="seeReason(props.row.rejectQualificationReason)">原因</span></span
                >
              </el-form-item>
              <el-form-item label="面试答题">
                <span style="color:red" v-show="props.row.isInterview == 0"
                  @click.stop="dealRowSee(props.row.id,3)"
                  >(未填写)</span
                >
                <span style="color:#2fbd4a" v-show="props.row.isInterview == 1"
                  @click.stop="dealRowSee(props.row.id,3)"
                  >(已填写)</span
                >
              </el-form-item>
              <el-form-item label="试讲视频">
                <span
                 @click="dealRowSee(props.row.id,4)"
                  style="color:red"
                  v-show="
                    props.row.videoAuthStatus == 0
                  "
                  >(未填写)</span
                >
                <span
                  @click="dealRowSee(props.row.id,4)"
                  style="color:red"
                  v-show="
                    props.row.videoAuthStatus == 1
                  "
                  >(已填写)</span
                >
              </el-form-item>
             <el-form-item label="面试评分">
                <span style="color:red" v-show="props.row.isFacemark == 0"
                  @click.stop="dealRowSee(props.row.id,6)"
                  >(未填写)</span
                >
                <span style="color:#2fbd4a" v-show="props.row.isFacemark == 1"
                   @click.stop="dealRowSee(props.row.id,6)"
                  >(已填写)</span
                >
              </el-form-item>
              <el-form-item label="">
                 <i class="el-icon-video-camera-solid" style="font-size:30px;" @click="updateEquipment(props.row)" :style="{'color': (props.row.isEquipment==1 ? '#1890ff':'#BBBBBB')}"></i>
              </el-form-item>
            </el-form>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page-area">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="listParams.pageNumber"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="listParams.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background
      ></el-pagination>
    </div>
    <el-dialog
      :title="saveDialog.title"
      :visible.sync="saveDialogVisible"
      width="1220px"
      center
      :close-on-click-modal="false"
      custom-class="product-dialog"
      @close="closeSaveDialog"
    >
     <el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane label="基本信息" name="info1" >
      <div>
        <div class="dialog-content">
          <el-form
            :model="saveRuleForm"
            status-icon
            :rules="saveRules"
            ref="saveRuleForm"
            label-width="120px"
            size="small"
          >
            <el-row :gutter="20">
              <el-col :span="20" >
                  <el-row :gutter="20">
                      <el-col :span="8">
                        <el-form-item label="真实姓名" prop="name">
                          <el-input v-model="saveRuleForm.realname" placeholder="输入内容"></el-input>
                        </el-form-item>
                      </el-col>
                       <el-col :span="8">
                        <el-form-item label="联系方式" prop="contact">
                          <el-input
                            v-model="saveRuleForm.contact"
                            oninput="if(value.length>11)value=value.slice(0,11)"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                     <el-col :span="8" v-show="saveDialog.type == 'edit'">
                        <el-form-item label="账号" prop="contact">
                          {{teacherInfoAccount}}
                        </el-form-item>
                      </el-col>
                  </el-row>
                  <el-row :gutter="20" >
                    <el-col :span="8">
                <el-form-item label="出生日期" prop="birthday">
                  <el-date-picker
                    v-model="saveRuleForm.birthday"
                    type="date"
                    :default-value="new Date('1986-01-01')"
                    :picker-options="expireTimeOption"
                    placeholder="选择日期"
                    value-format="yyyy-MM-dd"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="身份证号" prop="idcard">
                    <el-input v-model="saveRuleForm.idcard" placeholder="输入内容"></el-input>
                </el-form-item>
              </el-col>
               <el-col :span="8">
                  <el-form-item label="性别" prop="sexId">
                    <el-radio-group v-model="saveRuleForm.sexId">
                      <el-radio :label="1">男</el-radio>
                      <el-radio :label="2">女</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
                  </el-row>
              </el-col>
            </el-row>
            <el-row :gutter="20">
               <el-col :span="7">
                <el-form-item label="籍贯" prop="">
                  <el-select
                  v-model="saveRuleForm.provinceId"
                  placeholder="请选择"
                  size="small"
                  @change="provinceChangeList"
                >
                  <el-option
                    v-for="item in provinceLessonList"
                    :key="item.areaId"
                    :label="item.name"
                    :value="item.areaId"
                  ></el-option>
                </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6" class="mL20">
                  <el-select
                  v-model="saveRuleForm.cityId"
                  placeholder="请选择"
                  size="small"
                  @change="cityChangeList"
                >
                  <el-option
                    v-for="item in cityList"
                    :key="item.areaId"
                    :label="item.name"
                    :value="item.areaId"
                  ></el-option>
                </el-select>
              </el-col>
              <el-col :span="6" class="mL20">
                <el-select
                  v-model="saveRuleForm.countyId"
                  placeholder="请选择"
                  size="small"
                >
                  <el-option
                    v-for="item in countyList"
                    :key="item.areaId"
                    :label="item.name"
                    :value="item.areaId"
                  ></el-option>
                </el-select>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="10">
                <el-form-item label="教学方向" prop="teachingDirection">
                  <el-radio-group v-model="saveRuleForm.teachingDirection" @change="directionChange()">
                    <el-radio :label="1">学科类</el-radio>
                    <el-radio :label="2">素质教育类</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item label="选择身份" prop="identityType">
                  <el-radio-group v-model="saveRuleForm.identityType">
                    <el-radio :label="`${saveRuleForm.teachingDirection==1?'1':'3'}`">老师</el-radio>
                    <el-radio :label="`${saveRuleForm.teachingDirection==1?'2':'4'}`">大学生-在读</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20" v-show="saveRuleForm.identityType==2||saveRuleForm.identityType==4">
              <el-col :span="6">
                <el-form-item label="在读阶段" prop="readingStage">
                  <el-select
                    v-model="saveRuleForm.readingStage"
                    placeholder="请选择"
                    size="small"
                  >
                    <el-option
                      v-for="item in stageList"
                      :key="item.macroId"
                      :label="item.name"
                      :value="item.macroId"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="7">
                <el-form-item label="在读学校" prop="identity">
                  <el-input v-model="saveRuleForm.schoolName"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="20">
                <el-form-item label="教授学段" prop="periodId">
                  <el-checkbox-group v-model="saveRuleForm.periodId" @change="changes()">
                    <el-checkbox v-show="saveRuleForm.teachingDirection==1" :label="107">高中</el-checkbox>
                    <el-checkbox v-show="saveRuleForm.teachingDirection==1" :label="106">初中</el-checkbox>
                    <el-checkbox v-show="saveRuleForm.teachingDirection==1" :label="105">小学</el-checkbox>
                    <el-checkbox v-show="saveRuleForm.teachingDirection==2" :label="108">艺术培训</el-checkbox>
                    <el-checkbox v-show="saveRuleForm.teachingDirection==2" :label="109">小语种</el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20" v-show="teacherGradeList.length > 0">
              <el-col :span="20">
                <el-form-item label="教授年级" prop="sectionId">
                  <el-checkbox-group v-model="saveRuleForm.sectionId" >
                    <el-checkbox v-for="(item, index) in teacherGradeList"  :label="item.macroId" :key="index" >{{item.name}}</el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20" v-show="teacherSubjectList.length > 0&&saveRuleForm.teachingDirection==1&&saveRuleForm.identityType==1">
              <el-col :span="18">
                <el-form-item label="教授科目" prop="subjectId"  class="subjectId_ul">
                  <el-radio-group v-model="saveRuleForm.subjectId">
                    <el-radio v-for="(item, index) in teacherSubjectList" :label="item.macroId" :key="index">{{item.name}}</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20" v-show="teacherSubjectListCheck.length > 0&&(saveRuleForm.teachingDirection!=1||saveRuleForm.identityType!=1)">
              <el-col :span="18">
                <el-form-item label="教授科目" prop="subjectIdCheck"  class="subjectId_ul">
                  <el-checkbox-group v-model="saveRuleForm.subjectIdCheck" >
                    <el-checkbox v-for="(item, index) in teacherSubjectListCheck"  :label="item.macroId" :key="index" >{{item.name}}</el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20" v-show="saveRuleForm.identityType==1||saveRuleForm.identityType==3">
              <el-col :span="6">
                  <el-form-item label="教龄" prop="teachingAgeId">
                  <el-select
                    v-model="saveRuleForm.teachingAgeId"
                    placeholder="请选择"
                    size="small"
                  >
                    <el-option
                      v-for="item in teachingAgeIdList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="学历" prop="educationId">
                  <el-select
                    v-model="saveRuleForm.educationId"
                    placeholder="请选择"
                    size="small"
                  >
                    <el-option
                      v-for="item in educationIdList"
                      :key="item.macroId"
                      :label="item.name"
                      :value="item.macroId"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="毕业院校" prop="graduationSchool">
                  <el-input v-model="saveRuleForm.graduationSchool"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20" v-show="saveRuleForm.identityType=='1'&&saveRuleForm.teachingDirection==1">
              <el-col :span="6">
                <el-form-item label="任职学校" prop="schoolName">
                  <el-input v-model="saveRuleForm.schoolName"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="教师职称" prop="teachTitleId">
                  <el-select
                    v-model="saveRuleForm.teachTitleId"
                    placeholder="请选择"
                    size="small"
                  >
                    <el-option
                      v-for="item in teachTitleIdList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="10">
                <el-form-item label="个人身份认证" prop="idjust">
                  <el-upload
                    class="avatar-uploader"
                    :action="fileUpload"
                    :show-file-list="false"
                    :on-success="idCardUpload"
                    :before-upload="beforeAvatarUpload"
                    accept="image/jpeg, image/png/, image/gif"
                    :disabled="detaildStatus ? true : false"
                  >
                    <img
                      v-if="uploadImg.idcard"
                      :src="uploadImg.idcard"
                      class="avatar"
                    />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item :label="saveRuleForm.identityType==1||saveRuleForm.identityType==3?'学历证明':'学生证'" prop="idjust">
                  <el-upload
                    class="avatar-uploader"
                    :action="fileUpload"
                    :show-file-list="false"
                    :on-success="xueliUpload"
                    :before-upload="beforeAvatarUpload"
                    accept="image/jpeg, image/png/, image/gif"
                    :disabled="detaildStatus ? true : false"
                  >
                    <img
                      v-if="uploadImg.xueli"
                      :src="uploadImg.xueli"
                      class="avatar"
                    />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="10">
                <el-form-item label="教师资格认证" prop="qualifications" v-show="saveRuleForm.teachingDirection==1">
                  <el-upload
                    class="avatar-uploader"
                    :action="fileUpload"
                    :show-file-list="false"
                    :on-success="teaQualifications"
                    :before-upload="beforeAvatarUpload"
                    accept="image/jpeg, image/png/, image/gif"
                    :disabled="detaildStatus ? true : false"
                    @clearFiles="clearFilesQualifications"
                  >
                    <img
                      v-if="uploadImg.qualifications"
                      :src="uploadImg.qualifications"
                      class="avatar"
                    />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                    <el-checkbox v-model="teacherQualificationCertificate" :disabled="qualDisaled">暂未取得教师资格证</el-checkbox>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item :label="saveRuleForm.teachingDirection==2&&saveRuleForm.identityType==3?'教师荣誉':'专业资质'" prop="qualifications">
                  <div class="img_list">
                <div
                  class="img_item img_item_true"
                  v-for="(item, index) in testPapers"
                  :key="index"
                >
                  <div class="img_hover">
                    <span
                      class="iconfont icon-yulan1 icon_preview"
                      title="预览"
                      @click="imgItemSeeClick(item, index)"
                    ></span>
                    <span
                      @click="imgItemDelClick(index)"
                      class="iconfont icon-shanchu4 icon_del"
                      title="删除"
                     
                    ></span>
                  </div>
                  <img :src="item.url" alt="" />
                </div>
                <div class="img_item" @click="imgSelectClick">
                  <span class="iconfont icon-danyehuaban icon_new"></span>
                  <input
                    type="file"
                    ref="uploadImg"
                    @change="imgSelectChange"
                    style="display: none"
                    accept="image/png, image/jpeg, image/gif, image/jpg"
                  />
                </div>
              </div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="saveDialogVisible = false" size="small"
            >取 消</el-button
          >
          <el-button
            type="primary"
            :disabled="isDisable"
            @click="confirmSave('saveRuleForm')"
            size="small"
            >确 定</el-button
          >
        </span>
      </div>
    </el-tab-pane>
    <el-tab-pane label="展示资料" name="info2" :disabled='info2Flag'>
      <div>
        <div class="dialog-content">
         <el-form
            :model="dataRuleForm"
            status-icon
            label-width="120px"
            size="small"
          >
            <el-row :gutter="20">
              <el-col :span="5">
                <el-form-item label="本人头像" prop="photo">
                  <el-upload
                    class="avatar-uploader"
                    :action="fileUpload"
                    :show-file-list="false"
                    :on-success="handleAvatarSuccess"
                    :before-upload="beforeAvatarUpload"
                    accept="image/jpeg, image/png/"
                  >
                    <img
                      v-if="dataRuleForm.photo"
                      :src="dataRuleForm.photo"
                      class="avatar"
                    />
                    <i v-else   class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </el-form-item>
              </el-col>
              <el-col :span="17" >
                <el-row :gutter="20">
                  <el-col :span="8">
                    <el-form-item label="昵称" prop="name">
                      <el-input v-model="dataRuleForm.name" placeholder="某某科老师"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="15">
                  <el-form-item label="是否接收试听" prop="ifAcceptAudition">
                      <el-radio-group v-model="dataRuleForm.ifAcceptAudition">
                        <el-radio :label="1">可接受45分钟~1小时试听</el-radio>
                        <el-radio :label="0">不接受</el-radio>
                      </el-radio-group>
                    </el-form-item>
                </el-col>
                </el-row>
              </el-col>
              <el-col :span="17" >
                <el-row :gutter="20">
                  <el-col :span="20">
                    <el-form-item label="授课方式" prop="classForm">
                    <el-radio-group v-model="dataRuleForm.classForm">
                      <el-radio :label="0">均可</el-radio>
                      <el-radio :label="1">线上</el-radio>
                      <el-radio :label="2">线下</el-radio>
                    </el-radio-group>
                  </el-form-item>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
            <el-row :gutter="20" v-show="dataRuleForm.classForm=='0'||dataRuleForm.classForm=='2'">
               <el-col :span="7">
                <el-form-item label="可面授地点" prop="">
                  <el-select
                  v-model="dataRuleForm.currentProvinceIds"
                  placeholder="请选择"
                  size="small"
                  @change="currentProvinceChange"
                >
                  <el-option
                    v-for="item in currentProvinceData"
                    :key="item.areaId"
                    :label="item.name"
                    :value="item.areaId"
                  ></el-option>
                </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6" class="mL20">
                  <el-select
                  v-model="dataRuleForm.currentCityIds"
                  placeholder="请选择"
                  size="small"
                  @change="currentCityChange"
                >
                  <el-option
                    v-for="item in currentCityData"
                    :key="item.areaId"
                    :label="item.name"
                    :value="item.areaId"
                  ></el-option>
                </el-select>
              </el-col>
              <el-col :span="6" class="mL20">
                <el-select
                  v-model="dataRuleForm.currentCountyIds"
                  placeholder="请选择"
                  size="small"
                  multiple
                  @change="bindChange"
                >
                  <el-option
                    v-for="item in currentCountyData"
                    :key="item.areaId"
                    :label="item.name"
                    :value="item.areaId"
                  ></el-option>
                </el-select>
              </el-col>
            </el-row>
            <el-row :gutter="20" v-show="saveRuleForm.teachingDirection==1">
              <el-col :span="20">
                <el-form-item label="擅教学生">
                  <span style="color:#ccc;"
                    >一对一教学，讲究个性化教学，根据以往经历，请您选择您更擅长带哪类学生？</span
                  >
                  <el-checkbox-group v-model="skilfulTeachArray" @change="groupChange">
                      <el-checkbox v-for="(item,index) in skilfulTeachDate" :key="index" :label="item.macroId" :value="item.macroId"
                      >{{item.name}}</el-checkbox
                    >
                  </el-checkbox-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20" v-show="saveRuleForm.teachingDirection==1">
              <el-col :span="20">
                <el-form-item label="讲课风格">
                  <el-checkbox-group v-model="skilfulArray" @change="skilfulArrayChange">
                      <el-checkbox v-for="(item,index) in skilfulDate" :key="index" :label="item.macroId" :value="item.macroId"
                      >{{item.name}}</el-checkbox
                    >
                  </el-checkbox-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20" v-show="saveRuleForm.teachingDirection==1">
              <el-col :span="20">
                <el-form-item label="教学特点" prop="teachTrait">
                  <el-input
                    type="textarea"
                    :rows="2"
                    maxlength="500"
                    show-word-limit
                    v-model="dataRuleForm.teachTrait"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12"> </el-col>
            </el-row>
            <el-row :gutter="20" v-show="saveRuleForm.teachingDirection==1">
            <el-col :span="20">
              <el-form-item label="成功案例">
                <el-button type="primary" @click="addCase()">添加</el-button>
                  <div class="caseDiv" v-for="(item, index) in caseList" :key="index">
                    <div class="caseLeft">
                        <p>
                          <span>{{item.studentName}}</span>
                          <span>{{studentSectionIdFn(item.studentSectionId)}}</span>
                          <span>{{item.classTimeBegin}} 至 {{item.classTimeEnd}}</span>
                          <span>{{studentAchievementFn(item.studentAchievement)}}</span>
                        </p>
                        <p>{{item.detailContent}}</p>
                        <p>
                          <el-image   style="cursor:pointer;width:100px;height:100px;margin-right:10px;margin-top:15px;" v-for='(items,indexs) in item.photoList'  :preview-src-list="item.photoList" :src="items"  :key='indexs' alt=""></el-image>
                        </p>
                    </div>
                    <div class="caseRight">
                        <el-button type="primary" @click="caseEdit(item,index)">编辑</el-button>
                        <el-button type="primary" @click="caseRemove(index)">删除</el-button>
                    </div>
                  </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20" v-show="saveRuleForm.teachingDirection==2">
            <el-col :span="20">
              <el-form-item label="代课经历">
                <el-button type="primary" @click="addExperience()">添加</el-button>
                  <div class="caseDiv" v-for="(item, index) in daikeList" :key="index">
                    <div class="caseLeft">
                        <p>
                          <span>{{item.studentName}}</span>
                          <span>{{item.classTimeBegin}} 至 {{item.classTimeEnd}}</span>
                          <span>{{experienceFormListFn(item.studentAchievement)}}</span>
                        </p>
                        <p>{{item.detailContent}}</p>
                        <p>
                          <el-image   style="cursor:pointer;width:100px;height:100px;margin-right:10px;margin-top:15px;" v-for='(items,indexs) in item.photoList'  :preview-src-list="item.photoList" :src="items"  :key='indexs' alt=""></el-image>
                        </p>
                    </div>
                    <div class="caseRight">
                        <el-button type="primary" @click="experienceEdit(item,index)">编辑</el-button>
                        <el-button type="primary" @click="experienceRemove(index)">删除</el-button>
                    </div>
                  </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20" v-show="saveRuleForm.teachingDirection==2">
            <el-col :span="20">
              <el-form-item label="作品展示">
                <div class="img_list">
                <div
                  class="img_item img_item_true"
                  v-for="(item, index) in exhibitionPhotos"
                  :key="index"
                >
                  <div class="img_hover">
                    <span
                      class="iconfont icon-yulan1 icon_preview"
                      title="预览"
                      @click="exhibitionPhotosSee(item, index)"
                    ></span>
                    <span
                      class="iconfont icon-shanchu4 icon_del"
                      title="删除"
                      @click="exhibitionPhotosDel(index)"
                    ></span>
                  </div>
                  <img :src="item" alt="" />
                </div>
                <div class="img_item" @click="zuopinSelectClick">
                  <span class="iconfont icon-danyehuaban icon_new"></span>
                  <input
                    type="file"
                    ref="zuopinuploadImg"
                    @change="zuopinSelectChange"
                    style="display: none"
                    accept="image/png, image/jpeg, image/gif, image/jpg"
                  />
                </div>
              </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item
            label="附件简历"
            prop="resume"
          >  
            <el-row align="middle" type="flex">
              <el-col :span="12">
                <div class="coachAgreement_tip">
                   <a target="view_window" :href="dataRuleForm.resume">{{dataRuleForm.resume}}</a>
                  <span v-show="!dataRuleForm.resume"
                    >还未上传附件简历</span
                  >
                </div>
              </el-col>
              <el-col :span="6" class="mL20">
                <el-upload
                  accept="image/jpeg, image/png/, image/gif"
                  :action="fileUpload"
                  :on-success="resumeSuccess"
                  :on-error="resumeError"
                  :show-file-list="false"
                >
                  <el-button
                    size="small"
                    type="primary"
                    >点击上传</el-button
                  >
                </el-upload>
              </el-col>
            </el-row>
          </el-form-item>
          <el-row :gutter="20">
              <el-col :span="20">
                <el-form-item label="期望课酬" prop="hopeMinFee">
                  <el-input
                    style="width:70px !important;"
                    v-model="dataRuleForm.hopeMinFee"
                    :maxlength="5"
                  ></el-input>
                  <span style="padding:0 13px;">至</span>
                  <el-input
                    style="width:70px !important;"
                    v-model="dataRuleForm.hopeMaxFee"
                    :maxlength="5"
                  ></el-input>元/课时
                  <span style="color:#999;">（1课时=1小时）</span>
                </el-form-item>
              </el-col>
            </el-row>
        </el-form>
        </div>
        <span
          slot="footer"
          class="dialog-footer"
          v-show="detaildStatus != true"
        >
          <el-button @click="saveDialogVisible = false" size="small"
            >取 消</el-button
          >
          <el-button
            type="primary"
            @click="confirmSaveup('saveRuleForm')"
            size="small"
            >确 定</el-button
          >
        </span>
      </div>
    </el-tab-pane>
    <el-tab-pane label="面试答题" name="info3" :disabled='info2Flag'>
      <div>
        <div class="dialog-content">
          <p>面试部分主要是在教学中遇到的常见问题，请依据老师实例情况填写</p>
          <el-button
            type="primary"
            size="small"
            style="margin-top:20px;"
            @click="choiceProblem"
            >选择问题</el-button
          >
          <div style="margin-top:30px;">
            <div
              v-for="(item, index) in takeAwayArray"
              :key="index"
              style="margin-top:15px;"
            >
              <p>
                问题{{ index + 1 }}：{{ item.questionTitle
                }}<i
                  class="el-icon-delete"
                  style="margin-left:20px;"
                  size="60"
                  @click="removeProblem(item.questionId)"
                ></i>
              </p>
              <el-input
                type="textarea"
                :rows="2"
                placeholder="请输入内容"
                style="margin-top:10px;"
                v-model="item.answer"
              >
              </el-input>
            </div>
          </div>
        </div>
        <span
          slot="footer"
          class="dialog-footer"
          v-show="detaildStatus != true"
        >
          <el-button @click="saveDialogVisible = false" size="small"
            >取 消</el-button
          >
          <el-button type="primary" @click="confirmAnswer()" size="small"
            >确 定</el-button
          >
        </span>
      </div>
    </el-tab-pane>
    <el-tab-pane label="上传视频" name="info4" :disabled='info2Flag'>
      <div>
        <div class="dialog-content">
          <p style="color:#000;">
            试讲视频：<b style="color:#666;"
              >（视频大小不超过150M，视频格式为rm，rmvb，mpeg1-4 mov mtv dat wmv
              avi 3gp amv dmv flv）</b
            >
          </p>
          <el-button type="primary" size="small" style="margin-top:20px;" @click="choiceTeacherProblem()"
            >选择问题</el-button
          >
          <p v-html="getResult"></p>
          <el-form
            :model="videoForm"
            status-icon
            ref="videoForm"
            label-width="120px"
            size="small"
          >
            <el-row :gutter="20">
              <el-col>
                <el-form-item label="上传视频" prop="" style="margin-top:30px;">
                  <upload-video
                    class="avatar-uploader"
                    @video_change="video_change"
                    :default_src="videoForm.videoUrl"
                  >
                  </upload-video>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col>
                <el-form-item label="教师风采" prop="photo">
                  <el-upload
                    accept="image/jpeg, image/png/, image/gif"
                    class="upload-demo"
                    list-type="picture-card"
                    :action="fileUpload"
                    :before-upload="photoBeforeAvatarUpload"
                    :on-preview="handlePreview"
                    :on-success="handVideoSuccess"
                    :on-remove="handleRemove"
                     multiple
                    :limit="10"
                    :on-exceed="handleExceed"
                    :file-list="fileList"
                  >
                    <i class="el-icon-plus"></i>
                    <div slot="tip" class="el-upload__tip">
                      照片大小不超过10M，格式JPG，最多不超过10张
                    </div>
                  </el-upload>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <span
          slot="footer"
          class="dialog-footer"
          v-show="detaildStatus != true"
        >
          <el-button @click="saveDialogVisible = false" size="small"
            >取 消</el-button
          >
          <el-button
            type="primary"
            @click="confirmSaveVideo('videoForm')"
            size="small"
            >确 定</el-button
          >
        </span>
      </div>
    </el-tab-pane>
  
    <el-tab-pane label="面试评分" name="info6" :disabled='info2Flag'>
      <div>
        <div class="dialog-content">
          <el-form
            :model="faceRuleForm"
            status-icon
            ref="faceRuleForm"
            :rules="faceRules"
            label-width="120px"
            size="small"
          >
            <el-row :gutter="20">
              <el-col>
                <el-form-item label="整体评分" prop="overallScore">
                  <el-radio-group v-model="faceRuleForm.overallScore">
                    <el-radio label="1">A+</el-radio>
                    <el-radio label="2">A</el-radio>
                    <el-radio label="3">B+</el-radio>
                    <el-radio label="4">B</el-radio>
                    <el-radio label="5">C</el-radio>
                    <el-radio label="6">D</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="1.形象气质" prop="imageTemperament">
                  <el-radio-group v-model="faceRuleForm.imageTemperament">
                    <el-radio label="1">负分</el-radio>
                    <el-radio label="2">正常</el-radio>
                    <el-radio label="3">加分</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="2.沟通表达" prop="communicationExpression">
                  <el-radio-group v-model="faceRuleForm.communicationExpression">
                    <el-radio label="1">负分</el-radio>
                    <el-radio label="2">正常</el-radio>
                    <el-radio label="3">加分</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="3.在京代课经验" prop="substituteLessonsBeijing">
                  <el-radio-group v-model="faceRuleForm.substituteLessonsBeijing">
                    <el-radio label="1">5年以下</el-radio>
                    <el-radio label="2">5-10年</el-radio>
                    <el-radio label="3">10年以上</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="4.教学经验" prop="teachingExperience">
                  <el-radio-group v-model="faceRuleForm.teachingExperience">
                    <el-radio label="1">5年以下</el-radio>
                    <el-radio label="2">5-10年</el-radio>
                    <el-radio label="3">10-15年</el-radio>
                    <el-radio label="4">15-20以上</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="5.主要代课区域" prop="provinceIdOne">
                  <el-row :gutter="20">
                    <el-col :span="6">
                      <el-select
                        v-model="faceRuleForm.provinceIdOne"
                        placeholder="请选择"
                        size="small"
                      >
                        <el-option
                          v-for="item in provinceList"
                          :key="item.areaId"
                          :label="item.name"
                          :value="item.areaId"
                        ></el-option>
                      </el-select>
                    </el-col>
                    <el-col :span="6">
                      <el-select
                        v-model="faceRuleForm.provinceIdTwo"
                        placeholder="请选择"
                        size="small"
                      >
                        <el-option
                          v-for="item in provinceList"
                          :key="item.areaId"
                          :label="item.name"
                          :value="item.areaId"
                        ></el-option>
                      </el-select>
                    </el-col>
            </el-row>
                </el-form-item>
                <el-form-item label="6.其他合作机构" prop="remark">
                  <el-row :gutter="20">
                    <el-col :span="6">
                       <el-input
                    type="text"
                    placeholder="机构一"
                    v-model="faceRuleForm.otherPartnersOne"
                  >
                  </el-input>
                    </el-col>
                     <el-col :span="6">
                      <el-input
                    type="text"
                    placeholder="机构二"
                    v-model="faceRuleForm.otherPartnersTwo"
                  >
                  </el-input>
                    </el-col>
                  <el-col :span="6">
                    <el-input
                    type="text"
                    placeholder="机构三"
                    v-model="faceRuleForm.otherPartnersThree"
                  >
                  </el-input>
                  </el-col>
                  </el-row>
                </el-form-item>
                <el-form-item label="7.教学特色" prop="teachingCharacteristics">
                  <el-radio-group v-model="faceRuleForm.teachingCharacteristics">
                    <el-radio label="1">负分</el-radio>
                    <el-radio label="2">正常</el-radio>
                    <el-radio label="3">加分</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="8.合作求职态度" prop="jobHuntingAttitude">
                  <el-radio-group v-model="faceRuleForm.jobHuntingAttitude">
                    <el-radio label="1">负分</el-radio>
                    <el-radio label="2">正常</el-radio>
                    <el-radio label="3">加分</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="整体评价" prop="overallEvaluation">
                  <el-input
                    type="textarea"
                    :rows="2"
                    :minRows="2"
                    :maxRows="6"
                    placeholder="请输入内容"
                    v-model="faceRuleForm.overallEvaluation"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <span
          slot="footer"
          class="dialog-footer"
          v-show="detaildStatus != true"
        >
          <el-button @click="saveDialogVisible = false" size="small"
            >取 消</el-button
          >
          <el-button
            type="primary"
            @click="confirmFace('faceRuleForm')"
            size="small"
            >确 定</el-button
          >
        </span>
      </div>
    </el-tab-pane>
  </el-tabs>
    </el-dialog>
     <el-dialog
      title="查看讲师"
      :visible.sync="seeDialogVisible"
      width="1200px"
      center
      :close-on-click-modal="false"
      custom-class="product-dialog"
      @close="closeDialog"
    > 
      <el-tabs v-model="seeActiveName" @tab-click="seeHandleClick">
          <el-tab-pane label="基本信息" name="see1" >
            <div>
              <p v-show="saveRuleForm.isBasicdata==2" style="color:red;font-size:16px;">此页面已审核</p>
              <div class="dialog-content">
                <el-form
                  :model="saveRuleForm"
                  status-icon
                
                  ref="saveRuleForm"
                  label-width="120px"
                  size="small"
                >
                  <el-row :gutter="20">
              <el-col :span="20" >
                  <el-row :gutter="20">
                      <el-col :span="8">
                        <el-form-item label="真实姓名：" prop="name">
                          <span>{{saveRuleForm.realname}}</span>
                        </el-form-item>
                      </el-col>
                       <el-col :span="8">
                        <el-form-item label="联系方式：" prop="contact">
                          <span>{{saveRuleForm.contact}}</span>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-form-item label="出生日期：" prop="birthday">
                          <span>{{saveRuleForm.birthday?saveRuleForm.birthday.split(' ')[0]:""}}</span>
                        </el-form-item>
                      </el-col>
                  </el-row>
                  <el-row :gutter="20" >
                    <el-col :span="8">
                      <el-form-item label="身份证号：" prop="idcard">
                          <span>{{saveRuleForm.idcard}}</span>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                          <el-form-item label="性别：" prop="sexId">
                            <span>{{saveRuleForm.sexId==1?'男':'女'}}</span>
                          </el-form-item>
                        </el-col>
                        <el-col :span="8">
                        <el-form-item label="籍贯：" prop="sexId">
                            <span>{{saveRuleForm.provinceName}}{{saveRuleForm.cityName}}{{saveRuleForm.countyName}}</span>
                          </el-form-item>
                      </el-col>
                        </el-row>
                      <el-row :gutter="20">
                    <el-col :span="8">
                      <el-form-item label="教学方向：" prop="teachingDirection">
                        <span>{{saveRuleForm.teachingDirection==1?'学科类':saveRuleForm.teachingDirection==2?'素质教育类':''}}</span>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="选择身份：" prop="teachingDirection">
                        <span>{{saveRuleForm.identityType==1||saveRuleForm.identityType==3?'老师':saveRuleForm.identityType==2||saveRuleForm.identityType==4?'大学生-在读':''}}</span>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="账号：">
                          <span>{{teacherInfoAccount}}</span>
                        </el-form-item>
                      </el-col>
                  </el-row>
                    <el-row :gutter="20" v-show="saveRuleForm.identityType==2||saveRuleForm.identityType==4">
                    <el-col :span="8">
                      <el-form-item label="在读阶段：" prop="teachingAgeId">
                        <span>{{dealStage(saveRuleForm)}}</span>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="在读院校：" prop="teachingAgeId">
                        <span>{{saveRuleForm.schoolName}}</span>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="20">
                      <el-form-item label="教授学段：" prop="periodId">
                        <el-checkbox-group v-model="saveRuleForm.periodId" @change="changes()">
                          <el-checkbox disabled  :label="107">高中</el-checkbox>
                          <el-checkbox disabled :label="106">初中</el-checkbox>
                          <el-checkbox disabled :label="105">小学</el-checkbox>
                          <el-checkbox disabled :label="108">艺术培训</el-checkbox>
                          <el-checkbox disabled :label="109">小语种</el-checkbox>
                        </el-checkbox-group>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="20">
                      <el-form-item label="教授年级：" prop="sectionId">
                        <el-checkbox-group v-model="saveRuleForm.sectionId" >
                          <el-checkbox  disabled :label="98" :key="98" >高三</el-checkbox>
                          <el-checkbox  disabled :label="97" :key="97" >高二</el-checkbox>
                          <el-checkbox  disabled :label="96" :key="96" >高一</el-checkbox>
                          <el-checkbox  disabled :label="20" :key="20" >初三</el-checkbox>
                          <el-checkbox  disabled :label="19" :key="19" >初二</el-checkbox>
                          <el-checkbox  disabled :label="18" :key="18" >初一</el-checkbox>
                          <el-checkbox  disabled :label="95" :key="95" >六年级</el-checkbox>
                          <el-checkbox  disabled :label="94" :key="94" >五年级</el-checkbox>
                          <el-checkbox  disabled :label="93" :key="93" >四年级</el-checkbox>
                          <el-checkbox  disabled :label="92" :key="92" >三年级</el-checkbox>
                          <el-checkbox  disabled :label="110" :key="110" >艺术培训</el-checkbox>
                          <el-checkbox  disabled :label="111" :key="111" >小语种</el-checkbox>
                        </el-checkbox-group>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20" v-show="saveRuleForm.teachingDirection==1&&saveRuleForm.identityType==1">
                    <el-col :span="18">
                      <el-form-item label="教授科目：" prop="subjectId"  class="subjectId_ul">
                        <el-radio-group v-model="saveRuleForm.subjectId">
                          <el-radio  v-for="(item, index) in teacherSubjectList"  :disabled="saveRuleForm.subjectId!=item.macroId" :label="item.macroId"  :key="index">{{item.name}}</el-radio>
                        </el-radio-group>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20" v-show="saveRuleForm.teachingDirection!=1||saveRuleForm.identityType!=1">
                    <el-col :span="18">
                      <el-form-item label="教授科目" prop="subjectIdCheck"  class="subjectId_ul">
                        <el-checkbox-group v-model="saveRuleForm.subjectIdCheck" >
                          <el-checkbox v-for="(item, index) in teacherSubjectListCheck"  :label="item.macroId" :disabled="true" :key="index" >{{item.name}}</el-checkbox>
                        </el-checkbox-group>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20" v-show="saveRuleForm.identityType==1||saveRuleForm.identityType==3">
                    <el-col :span="8">
                      <el-form-item label="教龄：" prop="teachingAgeId">
                        <span>{{dealTeachingAgeId(saveRuleForm)}}</span>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="学历：" prop="educationId">
                        <span>{{educationId(saveRuleForm.educationId)}}</span>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="毕业院校：" prop="graduationSchool">
                        <span>{{saveRuleForm.graduationSchool}}</span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20" v-show="saveRuleForm.identityType=='1'&&saveRuleForm.teachingDirection==1">
                    <el-col :span="8">
                      <el-form-item label="任职学校：" prop="schoolName">
                        <span>{{saveRuleForm.schoolName}}</span>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="教师职称：" prop="teachTitleId">
                        <span>{{dealTeachTitleId(saveRuleForm)}}</span>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="" >
                       
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="10" class="img-col">
                      <el-form-item label="个人身份认证" prop="idjust">
                        <el-image
                          v-if="uploadImg.idcard"
                          :src="uploadImg.idcard"
                          class="avatar"
                          style="width:146px;height:108px;border-radius:6px;"
                          >
                      </el-image>
                      <div class="imgDefault" v-else>
                        <el-image>
                          <div slot="error" class="image-slot">
                            <i class="el-icon-picture-outline" style="font-size:25px;display:block;text-align:center;"></i>
                            <p>暂未上传</p>
                          </div>
                        </el-image>
                      </div>
                      </el-form-item>
                    </el-col>
                    <el-col :span="10" class="img-col">
                      <el-form-item :label="saveRuleForm.identityType==1||saveRuleForm.identityType==3?'学历证明':'学生证'" prop="idjust" >
                        <el-image
                          v-if="uploadImg.xueli"
                          :src="uploadImg.xueli"
                          class="avatar"
                          style="width:146px;height:108px;border-radius:6px;"
                         >
                      </el-image>
                      <div class="imgDefault" v-else>
                        <el-image>
                          <div slot="error" class="image-slot">
                            <i class="el-icon-picture-outline" style="font-size:25px;display:block;text-align:center;"></i>
                            <p>暂未上传</p>
                          </div>
                        </el-image>
                        </div>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="10" v-show="saveRuleForm.teachingDirection==1" class="img-col">
                      <el-form-item label="教师资格认证" prop="qualifications" >
                        <el-image
                          v-if="uploadImg.qualifications"
                          :src="uploadImg.qualifications"
                          class="avatar"
                          style="width:146px;height:108px;border-radius:6px;"
                         >
                      </el-image>
                      <div class="imgDefault" v-else>
                        <el-image>
                          <div slot="error" class="image-slot">
                            <i class="el-icon-picture-outline" style="font-size:25px;display:block;text-align:center;"></i>
                            <p>暂未上传</p>
                          </div>
                        </el-image>
                      </div>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12" class="img-col">
                      <el-form-item :label="saveRuleForm.teachingDirection==2&&saveRuleForm.identityType==3?'教师荣誉':'专业资质'" prop="qualifications">
                        <el-image v-for="(ele,index) in testPapers" :key="index"
                                style="margin-right:10px;width:146px;height:108px;border-radius:6px;"
                                :src="ele.url"
                                :preview-src-list="testPapers"
                              >
                          </el-image>
                      </el-form-item>
                    </el-col>
                  </el-row>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="展示资料" name="see2" >
             <p v-show="saveRuleForm.capesAuthStatus==2" style="color:red;font-size:16px;">此页面已审核</p>
            <div class="dialog-content">
          <el-form
            :model="authenRuleForm"
            status-icon
            ref="authenRuleForm"
            label-width="170px"
            size="small"
          >
            <el-row :gutter="20">
                  <el-col :span="5">
                      <el-form-item label="本人头像：" prop="photo">
                        <img
                            v-if="dataRuleForm.photo"
                            :src="dataRuleForm.photo"
                            class="avatar"
                            style="width:100px;height:100px;"
                          />
                      </el-form-item>
                    </el-col>
                    <el-col :span="17" >
                <el-row :gutter="20">
                  <el-col :span="8">
                    <el-form-item label="昵称：" prop="name">
                      <span>{{dataRuleForm.name}}</span>
                    </el-form-item>
                  </el-col>
                  <el-col :span="15">
                  <el-form-item label="是否接收试听：" prop="ifAcceptAudition">
                      <span>{{dataRuleForm.ifAcceptAudition==1?'可接受45分钟~1小时试听':'不接受'}}</span>
                    </el-form-item>
                </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="7">
                      <el-form-item label="授课方式：" prop="classForm">
                      <span v-show="dataRuleForm.classForm==0">均可</span>
                      <span v-show="dataRuleForm.classForm==1">线上</span>
                      <span v-show="dataRuleForm.classForm==2">线下</span>
                    </el-form-item>
                    </el-col>
                    <el-col :span="14" v-show="dataRuleForm.classForm==0||dataRuleForm.classForm==2">
                      <el-form-item label="可面授地点：" prop="classForm">
                        <span>{{dataRuleForm.currentProvincesName}}{{dataRuleForm.currentCitysName}}{{getCurrentCountysName}}</span>
                      </el-form-item>
                    </el-col>
                </el-row>
              </el-col>
                </el-row>
                <el-row :gutter="20" v-show="dataRuleForm.teachingDirection==1">
                    <el-col :span="20">
                      <el-form-item label="擅教学生：">
                        <span style="color:#ccc;"
                          >一对一教学，讲究个性化教学，根据以往经历，请您选择您更擅长带哪类学生？</span
                        >
                        <el-checkbox-group v-model="skilfulTeachArray">
                            <el-checkbox disabled v-for="(item,index) in skilfulTeachDate" :key="index" :label="item.macroId" :value="item.macroId"
                            >{{item.name}}</el-checkbox
                          >
                        </el-checkbox-group>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20" v-show="dataRuleForm.teachingDirection==1">
                    <el-col :span="20">
                      <el-form-item label="讲课风格：">
                        <el-checkbox-group v-model="skilfulArray">
                            <el-checkbox disabled v-for="(item,index) in skilfulDate" :key="index" :label="item.macroId" :value="item.macroId"
                            >{{item.name}}</el-checkbox
                          >
                        </el-checkbox-group>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20" v-show="saveRuleForm.teachingDirection==1">
                    <el-col :span="20">
                      <el-form-item label="教学特点：" prop="teachTrait">
                        <span>{{dataRuleForm.teachTrait}}</span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20" v-show="saveRuleForm.teachingDirection==1">
                  <el-col :span="20">
                    <el-form-item label="成功案例：">
                          <div class="caseDiv" v-for="(item, index) in caseList" :key="index">
                    <div class="caseLeft">
                        <p>
                          <span>{{item.studentName}}</span>
                          <span>{{studentSectionIdFn(item.studentSectionId)}}/{{studentSubjectIdFn(item.studentSubjectId)}}</span>
                          <span>{{item.classTimeBegin}} 至 {{item.classTimeEnd}}</span>
                          <span>{{studentAchievementFn(item.studentAchievement)}}</span>
                        </p>
                        <p>{{item.detailContent}}</p>
                        <p>
                          <el-image  style="cursor:pointer;width:100px;height:100px;margin-right:10px;margin-top:15px;" v-for='(items,indexs) in item.photoList'  :preview-src-list="item.photoList" :src="items"  :key='indexs' alt=""></el-image>
                        </p>
                    </div>
                  </div>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20" v-show="saveRuleForm.teachingDirection==2">
                  <el-col :span="20">
                    <el-form-item label="代课经历：">
                          <div class="caseDiv" v-for="(item, index) in daikeList" :key="index">
                    <div class="caseLeft">
                        <p>
                          <span>{{item.studentName}}</span>
                          <span>{{item.classTimeBegin}} 至 {{item.classTimeEnd}}</span>
                          <span>{{experienceFormListFn(item.studentAchievement)}}</span>
                        </p>
                        <p>{{item.detailContent}}</p>
                        <p>
                          <el-image  style="cursor:pointer;width:100px;height:100px;margin-right:10px;margin-top:15px;" v-for='(items,indexs) in item.photoList'  :preview-src-list="item.photoList" :src="items"  :key='indexs' alt=""></el-image>
                        </p>
                    </div>
                  </div>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20" v-show="saveRuleForm.teachingDirection==2">
                  <el-form-item label="作品展示：">
                    <el-image  style="cursor:pointer;width:100px;height:100px;margin-right:10px;margin-top:15px;" v-for='(items,indexs) in exhibitionPhotos'   :src="items"  :key='indexs' alt=""></el-image>
                  </el-form-item>
                </el-row>
                <el-row :gutter="20" >
                  <el-col :span="20">
                    <el-form-item label="附件简历" prop="hopeMinFee">
                      <div class="coachAgreement_tip">
                        <a target="view_window" :href="dataRuleForm.resume">{{dataRuleForm.resume}}</a>
                        <span v-show="!dataRuleForm.resume"
                          >还未上传附件简历</span
                        >
                      </div>
                    </el-form-item>
                    
                  </el-col>
                </el-row>
                <el-row :gutter="20" >
                  <el-col :span="20">
                    <el-form-item label="期望课酬：" prop="hopeMinFee">
                        <span style="color:red;">{{dataRuleForm.hopeMinFee}}</span>
                        <span style="padding:0 13px;">至</span>
                        <span style="color:red;">{{dataRuleForm.hopeMaxFee}}元/课时</span>
                        <span style="color:#999;">（1课时=1小时）</span>
                      </el-form-item>
                  </el-col>
                </el-row>
          </el-form>
        </div>
          </el-tab-pane>
         <el-tab-pane label="面试答题" name="see3">
            <p v-show="saveRuleForm.isInterview==2" style="color:red;font-size:16px;padding-bottom:10px;">此页面已审核</p>
            <div class="dialog-content">
          <p>面试部分主要是在教学中遇到的常见问题，请依据老师实例情况填写</p>
          <div style="margin-top:30px;">
            <div
              v-for="(item, index) in takeAwayArray"
              :key="index"
              style="margin-top:15px;"
            >
              <p>
                问题{{ index + 1 }}：{{ item.questionTitle
                }}
              </p>
              <p style="margin-top:10px;">{{item.answer}}</p>
            </div>
          </div>
        </div>
          </el-tab-pane>
          <el-tab-pane label="上传视频" name="see4">
            <p v-show="saveRuleForm.videoAuthStatus==2" style="color:red;font-size:16px;">此页面已审核</p>
            <div class="dialog-content">
          <p style="color:#000;">
            试讲视频：<b style="color:#666;"
              >（视频大小不超过150M，视频格式为rm，rmvb，mpeg1-4 mov mtv dat wmv
              avi 3gp amv dmv flv）</b
            >
          </p>
          <p v-html="getResult"></p>
          <el-form
            :model="videoForm"
            status-icon
            ref="videoForm"
            label-width="120px"
            size="small"
          >
            <el-row :gutter="20">
              <el-col>
                <el-form-item label="上传视频" prop="" style="margin-top:30px;">
                  <div class="video_area">
                    <video :src="videoForm.videoUrl" controls v-show="videoForm.videoUrl"></video>
                    <div v-show="!videoForm.videoUrl">暂无</div>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col>
                <el-form-item label="教师风采" prop="photo">
                  <el-upload
                    accept="image/jpeg, image/png/, image/gif"
                    class="upload-demo"
                    list-type="picture-card"
                    :action="fileUpload"
                    :before-upload="photoBeforeAvatarUpload"
                    :on-preview="handlePreview"
                    :on-success="handVideoSuccess"
                    :on-remove="handleRemove"
                     multiple
                    :limit="10"
                    :on-exceed="handleExceed"
                    :file-list="fileList"
                  >
                    <i class="el-icon-plus"></i>
                    <div slot="tip" class="el-upload__tip">
                      照片大小不超过10M，格式JPG，最多不超过10张
                    </div>
                  </el-upload>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
          </el-tab-pane>
          <el-tab-pane label="面试评分" name="see6">
              <div class="dialog-content">
                <p style="font-size:18px;padding-bottom:30px;font-weight:600;">录入员评价</p>
          <el-form
            :model="faceRuleForm"
            status-icon
            ref="faceRuleForm"
            label-width="120px"
            size="small"
          >
            <el-row :gutter="20">
              <el-col>
                <el-form-item label="整体评分" prop="overallScore">
                  <el-radio-group v-model="faceRuleForm.overallScore">
                    <el-radio label="1">A+</el-radio>
                    <el-radio label="2">A</el-radio>
                    <el-radio label="3">B+</el-radio>
                    <el-radio label="4">B</el-radio>
                    <el-radio label="5">C</el-radio>
                    <el-radio label="6">D</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="1.形象气质" prop="imageTemperament">
                  <el-radio-group v-model="faceRuleForm.imageTemperament">
                    <el-radio label="1">负分</el-radio>
                    <el-radio label="2">正常</el-radio>
                    <el-radio label="3">加分</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="2.沟通表达" prop="communicationExpression">
                  <el-radio-group v-model="faceRuleForm.communicationExpression">
                    <el-radio label="1">负分</el-radio>
                    <el-radio label="2">正常</el-radio>
                    <el-radio label="3">加分</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="3.在京代课经验" prop="substituteLessonsBeijing">
                  <el-radio-group v-model="faceRuleForm.substituteLessonsBeijing">
                    <el-radio label="1">5年以下</el-radio>
                    <el-radio label="2">5-10年</el-radio>
                    <el-radio label="3">10年以上</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="4.教学经验" prop="teachingExperience">
                  <el-radio-group v-model="faceRuleForm.teachingExperience">
                    <el-radio label="1">5年以下</el-radio> 
                    <el-radio label="2">5-10年</el-radio>
                    <el-radio label="3">10-15年</el-radio>
                    <el-radio label="4">15-20以上</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="5.主要代课区域" prop="provinceIdOne">
                  <el-row :gutter="20">
                    <el-col :span="6">
                      <el-select
                        v-model="faceRuleForm.provinceIdOne"
                        placeholder="请选择"
                        size="small"
                        disabled 
                      >
                        <el-option
                          v-for="item in provinceList"
                          :key="item.areaId"
                          :label="item.name"
                          :value="item.areaId"
                        ></el-option>
                      </el-select>
                    </el-col>
                    <el-col :span="6">
                      <el-select
                        v-model="faceRuleForm.provinceIdTwo"
                        placeholder="请选择"
                        size="small"
                        disabled 
                      >
                        <el-option
                          v-for="item in provinceList"
                          :key="item.areaId"
                          :label="item.name"
                          :value="item.areaId"
                        ></el-option>
                      </el-select>
                    </el-col>
            </el-row>
                </el-form-item>
                <el-form-item label="6.其他合作机构" prop="remark">
                  <el-row :gutter="20">
                    <el-col :span="6">
                      <span style="margin-right:15px;">{{faceRuleForm.otherPartnersOne}}</span>
                      <span style="margin-right:15px;">{{faceRuleForm.otherPartnersTwo}}</span>
                      <span >{{faceRuleForm.otherPartnersThree}}</span>
                    </el-col>
                  </el-row>
                </el-form-item>
                <el-form-item label="7.教学特色" prop="teachingCharacteristics">
                  <el-radio-group v-model="faceRuleForm.teachingCharacteristics">
                    <el-radio label="1">负分</el-radio>
                    <el-radio label="2">正常</el-radio>
                    <el-radio label="3">加分</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="8.合作求职态度" prop="jobHuntingAttitude">
                  <el-radio-group v-model="faceRuleForm.jobHuntingAttitude">
                    <el-radio label="1">负分</el-radio>
                    <el-radio label="2">正常</el-radio>
                    <el-radio label="3">加分</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="整体评价" prop="overallEvaluation">
                  <span>{{faceRuleForm.overallEvaluation}}</span>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
           <p style="font-size:18px;margin-top:50px;padding-bottom:30px;font-weight:600;">审核员评价</p>
           <el-form
            :model="faceRuleFormAuditor"
            status-icon
            ref="faceRuleFormAuditor"
            label-width="120px"
            size="small"
          >
            <el-row :gutter="20">
              <el-col>
                <el-form-item label="整体评分" prop="overallScore">
                  <el-radio-group v-model="faceRuleFormAuditor.overallScore">
                    <el-radio label="1">A+</el-radio>
                    <el-radio label="2">A</el-radio>
                    <el-radio label="3">B+</el-radio>
                    <el-radio label="4">B</el-radio>
                    <el-radio label="5">C</el-radio>
                    <el-radio label="6">D</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="1.形象气质" prop="imageTemperament">
                  <el-radio-group v-model="faceRuleFormAuditor.imageTemperament">
                    <el-radio label="1">负分</el-radio>
                    <el-radio label="2">正常</el-radio>
                    <el-radio label="3">加分</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="2.沟通表达" prop="communicationExpression">
                  <el-radio-group v-model="faceRuleFormAuditor.communicationExpression">
                    <el-radio label="1">负分</el-radio>
                    <el-radio label="2">正常</el-radio>
                    <el-radio label="3">加分</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="3.在京代课经验" prop="substituteLessonsBeijing">
                  <el-radio-group v-model="faceRuleFormAuditor.substituteLessonsBeijing">
                    <el-radio label="1">5年以下</el-radio>
                    <el-radio label="2">5-10年</el-radio>
                    <el-radio label="3">10年以上</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="4.教学经验" prop="teachingExperience">
                  <el-radio-group v-model="faceRuleFormAuditor.teachingExperience">
                    <el-radio label="1">5年以下</el-radio>
                    <el-radio label="2">5-10年</el-radio>
                    <el-radio label="3">10-15年</el-radio>
                    <el-radio label="4">15-20以上</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="5.主要代课区域" prop="provinceIdOne">
                  <el-row :gutter="20">
                    <el-col :span="6">
                      <el-select
                        v-model="faceRuleFormAuditor.provinceIdOne"
                        placeholder="请选择"
                        size="small"
                        disabled 
                      >
                        <el-option
                          v-for="item in provinceList"
                          :key="item.areaId"
                          :label="item.name"
                          :value="item.areaId"
                        ></el-option>
                      </el-select>
                    </el-col>
                    <el-col :span="6">
                      <el-select
                        v-model="faceRuleFormAuditor.provinceIdTwo"
                        placeholder="请选择"
                        size="small"
                        disabled 
                      >
                        <el-option
                          v-for="item in provinceList"
                          :key="item.areaId"
                          :label="item.name"
                          :value="item.areaId"
                        ></el-option>
                      </el-select>
                    </el-col>
            </el-row>
                </el-form-item>
                <el-form-item label="6.其他合作机构" prop="remark">
                  <el-row :gutter="20">
                    <el-col :span="6">
                      <span style="margin-right:15px;">{{faceRuleFormAuditor.otherPartnersOne}}</span>
                      <span style="margin-right:15px;">{{faceRuleFormAuditor.otherPartnersTwo}}</span>
                      <span >{{faceRuleFormAuditor.otherPartnersThree}}</span>
                    </el-col>
                  </el-row>
                </el-form-item>
                <el-form-item label="7.教学特色" prop="teachingCharacteristics">
                  <el-radio-group v-model="faceRuleFormAuditor.teachingCharacteristics">
                    <el-radio label="1">负分</el-radio>
                    <el-radio label="2">正常</el-radio>
                    <el-radio label="3">加分</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="8.合作求职态度" prop="jobHuntingAttitude">
                  <el-radio-group v-model="faceRuleFormAuditor.jobHuntingAttitude">
                    <el-radio label="1">负分</el-radio>
                    <el-radio label="2">正常</el-radio>
                    <el-radio label="3">加分</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="整体评价" prop="overallEvaluation">
                  <span>{{faceRuleFormAuditor.overallEvaluation}}</span>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
          </el-tab-pane>
      </el-tabs>
     </el-dialog>
    <el-dialog
      title="选择试卷"
      :visible.sync="selectPaperPopup"
      width="1200px"
      center
      custom-class="product-dialog"
    >
      <div class="dialog-content">
        <div class="btn-area select-condition">
          <div class="left">
            <div class="select-condition-item">
              <span class="item-lable">关键字：</span>
              <el-input
                v-model="paperParams.name"
                placeholder="请输入试卷名称"
                size="small"
              ></el-input>
            </div>
            <div class="select-condition-item">
              <span class="item-lable">年级：</span>
              <el-select
                v-model="paperParams.gradeId"
                placeholder="请选择"
                size="small"
              >
                <el-option
                  v-for="item in sectionList"
                  :key="item.macroId"
                  :label="item.name"
                  :value="item.macroId"
                ></el-option>
              </el-select>
            </div>
            <div class="select-condition-item">
              <span class="item-lable">学科：</span>
              <el-select
                v-model="paperParams.subjectId"
                placeholder="请选择"
                size="small"
              >
                <el-option
                  v-for="item in subjectList"
                  :key="item.macroId"
                  :label="item.name"
                  :value="item.macroId"
                ></el-option>
              </el-select>
            </div>
            <div class="select-condition-item">
              <el-button
                type="primary"
                size="small"
                icon="el-icon-search"
                @click="getsearch"
                >搜索</el-button
              >
            </div>
          </div>
        </div>
        <div class="table-area mT10">
          <el-table
            size="small"
            :data="paperList"
            style="width: 100%;margin-bottom: 20px;"
            border
            :row-key="
              row => {
                return row.id;
              }
            "
            height="400px"
            tooltip-effect="dark"
            ref="paperListTable"
            @selection-change="handlePaperSelectionChange"
            @row-click="paperItemClick"
          >
            <el-table-column
              type="selection"
              width="55"
              :reserve-selection="true"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="title"
              label="试卷名称"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="gradeName"
              label="年级"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="subjectName"
              label="科目"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="limitTime"
              label="考试时长"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="totalScore"
              label="总分"
              align="center"
            ></el-table-column>
          </el-table>
        </div>
        <div class="page-area">
          <el-pagination
            @size-change="handlePaperSizeChange"
            @current-change="handlePaperCurrentChange"
            :current-page="paperParams.pageNumber"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="paperParams.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="paperTotal"
            background
          ></el-pagination>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="selectPaperPopup = false" size="small"
          >取 消</el-button
        >
        <el-button type="primary" @click="confirmPaperSelect()" size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      width="700px"
      center
      :title="answerDialog.title"
      :visible.sync="answerSync"
    >
      <div class="dialog-content">
        <el-form
          :model="answerRuleForm"
          status-icon
          :rules="answerRules"
          ref="answerRuleForm"
          label-width="100px"
          size="small"
        >
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="问题" prop="question">
                <el-input
                  v-model="answerRuleForm.question"
                  placeholder="请输入问题"
                ></el-input>
              </el-form-item>
              <el-form-item label="学段" prop="periodId">
                <el-select
                  v-model="answerRuleForm.periodId"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="option in phaseLists"
                    :key="option.macroId"
                    :label="option.name"
                    :value="option.macroId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="answerSync = false" size="small">取 消</el-button>
        <el-button
          type="primary"
          @click="answerConfirmSave('answerRuleForm')"
          size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog width="900px" center :visible.sync="problemPopup" custom-class="product-dialog" center> 
      <div class="dialog-content">
          <div class="btn-area select-condition">
            <div class="left">
               <div class="select-condition-item">
                <span class="item-lable">关键字：</span>
                <el-input
                  v-model="answerObject.question"
                  placeholder="关键字查询"
                  size="small"
                ></el-input>
              </div>
               <div class="select-condition-item">
                <span class="item-lable">学段：</span>
                <el-select
                  v-model="answerObject.periodId"
                  placeholder="请选择"
                  size="small"
                >
                  <el-option
                    v-for="item in phaseListss"
                    :key="item.macroId"
                    :label="item.name"
                    :value="item.macroId"
                  ></el-option>
                </el-select>
              </div>
              <div class="select-condition-item">
              <el-button type="primary" size="small" icon="el-icon-search"
              @click="searchAnswer"
                >搜索</el-button
              >
            </div>
            </div>
            <div class="right">
              <el-button-group>
                <el-button type="default" size="small" @click="answerAdd">
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#icon-xinzeng" />
                  </svg>
                  <span>添加</span>
                </el-button>
                <el-button type="default" size="small" @click="answerEdit">
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#icon-edit" />
                  </svg>
                  <span>编辑</span>
                </el-button>
                <el-button type="default" size="small" @click="answerDelete">
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#icon-trash" />
                  </svg>
                  <span>删除</span>
                </el-button>
              </el-button-group>
            </div>
          </div>
          <div class="table-area mT10">
            <el-table
              size="small"
              :data="answerArray"
              style="width: 100%; margin-bottom: 20px"
              row-key="id"
              border
               height="400px"
              tooltip-effect="dark"
              ref="answerListTable"
              @selection-change="answerSelectionChange"
            >
              <el-table-column
                type="selection"
                width="55"
                align="center"
                :reserve-selection="true"
                :selectable="checkSelectable"
              ></el-table-column>
              <el-table-column
                prop="question"
                label="问题"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="periodId"
                label="学段"
                :formatter="answerPeriodId"
                align="center"
              ></el-table-column>
            </el-table>
          </div>
          <div class="page-area">
            <el-pagination
              @size-change="handleSizeChange2"
              @current-change="handleCurrentChange2"
              :current-page="answerObject.pageNumber"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="answerObject.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total2"
              background
            ></el-pagination>
          </div>
      </div>
       <span slot="footer" class="dialog-footer">
          <el-button size="small" @click="problemPopup=false">取 消</el-button>
          <el-button type="primary" @click="takeAway()" size="small"
            >确 定</el-button
          >
        </span>
    </el-dialog>
    <el-dialog
      :title="followUpDialog.title"
      :visible.sync="followUpDialogVisible"
      :close-on-click-modal="false"
      width="900px"
      center
    >
      <div class="dialog-content">
        <el-form
          :model="saveRuleFormFollowUp"
          status-icon
          ref="saveRuleFormFollowUp"
          label-width="100px"
          size="small"
        >
          <el-row :gutter="20">
            <el-col :span="6">
              <el-form-item label="学生姓名" prop="studentName">
                <el-input
                  v-model="saveRuleForm.infoTitle"
                  placeholder="请输入学生姓名"
                  :maxlength="5"
                  size="small"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="学生年级" prop="sectionId">
                <el-select
                  v-model="saveRuleForm.sectionId"
                  placeholder="请选择"
                  size="small"
                >
                  <el-option
                    v-for="item in sectionList"
                    :key="item.macroId"
                    :label="item.name"
                    :value="item.macroId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="上课时间" prop="">
                <el-date-picker
                  size="small"
                  v-model="timePickerValue"
                  type="daterange"
                  align="right"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :picker-options="pickerOptions"
                  @change="datePickerChange"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :default-time="['00:00:00', '23:59:59']"
                ></el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-form-item label="详情介绍" prop="followContent">
              <editor
                :content_height="content_height"
                :set_editor_content="set_editor_content"
                @edit_content_change="edit_content_change"
              ></editor>
            </el-form-item>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="followUpDialogVisible = false" size="small"
          >取 消</el-button
        >
        <el-button
          type="primary"
          @click="confirmSaveFollowUp('saveRuleFormFollowUp')"
          size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
    <el-dialog
      title="选择问题"
      :visible.sync="choiceProblemPopup"
      :close-on-click-modal="false"
      width="1000px"
      center
    >
    <div class="btn-area select-condition">
      <div class="left">
        <div class="select-condition-item">
          <span class="item-lable">关键字：</span>
          <el-input
            v-model="subjectParams.title"
            placeholder="请输入查询关键字"
            size="small"
          ></el-input>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">请选择题型：</span>
          <el-select
            v-model="subjectParams.qtpye"
            placeholder="请选择"
            size="small"
          >
            <el-option
                v-for="item in qtpyeLists"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">请选择年级：</span>
          <el-select
            v-model="subjectParams.gradeId"
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="item in gradeidList"
              :key="item.macroId"
              :label="item.name"
              :value="item.macroId"
            ></el-option>
          </el-select>
        </div>
         <div class="select-condition-item">
          <span class="item-lable">请选择学科：</span>
          <el-select
            v-model="subjectParams.subjectId"
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="item in subjectList"
              :key="item.macroId"
              :label="item.name"
              :value="item.macroId"
            ></el-option>
          </el-select>
        </div>
        <div class="select-condition-item">
          <el-button
            type="primary"
            size="small"
            icon="el-icon-search"
            @click="questionsListFn()"
            >搜索</el-button
          >
        </div>
      </div>
    </div>
       <div class="dialog-content">
            <div class="table-area mT10">
            <el-table
        size="small"
        :data="choiceList"
        style="width: 100%; margin-bottom: 20px"
        row-key="id"
        border
        tooltip-effect="dark"
        ref="productListTable"
         @current-change="clickChange"
        @selection-change="choiceSelectionChange"
        @row-click="choiceItemClick"
      >
        <el-table-column label="选择" width="55">
          <template slot-scope="scope">
            <el-radio v-model="tableRadio" :label="scope.row"
              ><i></i
            ></el-radio>
          </template>
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="试题"
          align="center"
          width="355"
        >
          <template slot-scope="scope">
            <div v-html="testTitle(scope.row)"></div>
          </template>

        </el-table-column>
        <el-table-column
          prop="qtpye"
          label="试题题型"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="createTime"
          label="年级/学科"
          align="center"
          :formatter="dealSectionId"
        ></el-table-column>
         <el-table-column
          prop="knowledges"
          label="知识点"
          align="center"
        ></el-table-column>
         <el-table-column
          prop="diff"
          label="难度值(1~5)"
          align="center"
        ></el-table-column>
         <el-table-column
          prop="source"
          label="试题来源(试卷)"
          align="center"
        ></el-table-column>
      </el-table>
          </div>
           <div class="page-area">
            <el-pagination
              @size-change="choiceSizeChange"
              @current-change="choiceCurrentChange"
              :current-page="subjectParams.pageNumber"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="subjectParams.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="choiceTotal"
              background
            ></el-pagination>
          </div>
       </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="choiceProblemPopup = false" size="small"
            >取 消</el-button
          >
          <el-button type="primary" size="small"  @click="sureChoice()"
            >确 定</el-button
          >
        </span>
     </el-dialog>
     <el-dialog
      :title="caseUpDialog.title"
      :visible.sync="casePopup"
      :close-on-click-modal="false"
      width="1000px"
      center
    >
      <div class="dialog-content">
         <el-form  
          :model="caseRuleForm"
          status-icon
          :rules="scaseResultRules"
          ref="caseRuleForm"
          label-width="110px"
          size="small"
        >
           <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="机构/个人" prop="studentName">
                <el-input
                  v-model="caseRuleForm.studentName"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="年级" prop="studentSectionId">
                <el-select
                  v-model="caseRuleForm.studentSectionId"
                  placeholder="请选择"
                  size="small"
                >
                  <el-option
                    v-for="item in sectionList2"
                    :key="item.macroId"
                    :label="item.name"
                    :value="item.macroId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="学生类型" prop="studentAchievement">
                <el-select
                  v-model="caseRuleForm.studentAchievement"
                  placeholder="请选择"
                  size="small"
                >
                  <el-option
                    v-for="item in studentAchievementList"
                    :key="item.macroId"
                    :label="item.name"
                    :value="item.macroId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="开始上课时间" prop="classTimeEnd" >
                <el-date-picker
                  v-model="casePickerValue1"
                  type="date"
                   @change="casePickerChange1()"
                  :picker-options="startDatePicker"
                  placeholder="选择日期"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="结束上课时间" prop="classTimeEnd">
                <el-date-picker
                  v-model="casePickerValue2"
                  type="date"
                   @change="casePickerChange2()"
                  :picker-options="endDatePicker"
                  placeholder="选择日期"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
         
          <el-row :gutter="20">
           <el-col :span="20">
              <el-form-item label="详细介绍" prop="detailContent">
                <el-input
                  type="textarea"
                  v-model="caseRuleForm.detailContent"
                  :rows="2"
                  maxlength="500"
                  show-word-limit
                  placeholder="学生上课前的成绩、上课后的成绩、学生的性格等"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
           <el-col :span="20">
              <el-form-item label="上传图片" prop="photos">
                <el-upload  
                :action="fileUpload" 
                :file-list="caseRuleForm.photos" 
                list-type="picture-card" 
                :on-preview="caseHandlePicture" 
                multiple
                :on-remove="caseHandleRemove" 
                :limit="8" 
                accept="image/jpeg, image/png/, image/gif"
                :on-success="caseHandleAvatarSuccess"
                :on-change="handleLimit"
                :class="{hide:hideUpload}"
                ><i class="el-icon-plus"></i>
              </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
       <span slot="footer" class="dialog-footer">
        <el-button @click="casePopup = false" size="small"
          >取 消</el-button
        >
        <el-button
          type="primary"
          @click="confirmCase('caseRuleForm')"
          size="small"
          >添 加</el-button
        >
      </span>
    </el-dialog>
     <el-dialog :visible.sync="caseImgDialog">
  <img width="100%" :src="caseImageUrl" alt="">
</el-dialog>
 <el-dialog
      title="驳回原因"
      :visible.sync="seeReasonPoups"
      width="420px"
      center
      @close="closeSaveDialog"
      :close-on-click-modal="false"
      custom-class="product-dialog"
    >
      <p style="text-align:center;font-size:16px;margin-top:30px;padding-bottom:50px;">{{reasonVal}}</p>
    </el-dialog>
    <div class="imgDolg" v-show="imgPreview.show" @click.stop="imgPreview.show = false">
      <i class="el-icon-close" id="imgDolgClose" @click.stop="imgPreview.show = false"></i>
      <img @click.stop="imgPreview.show = true" :src="imgPreview.img" />
    </div>
    <el-dialog
      :title="experienceDialog.title"
      :visible.sync="experienceShow"
      :close-on-click-modal="false"
      width="1000px"
      center
    >
      <div class="dialog-content">
         <el-form
          :model="experienceForm"
          status-icon
          :rules="experienceRules"
          ref="experienceForm"
          label-width="120px"
          size="small"
        >
           <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="学生/机构名称" prop="studentName">
                <el-input
                  v-model="experienceForm.studentName"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="学生类型" prop="studentAchievement">
                <el-select
                  v-model="experienceForm.studentAchievement"
                  placeholder="请选择"
                  size="small"
                >
                  <el-option
                    v-for="item in experienceFormList"
                    :key="item.macroId"
                    :label="item.name"
                    :value="item.macroId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="开始上课时间" prop="classTimeEnd" >
                <el-date-picker
                  v-model="ecasePickerValue1"
                  type="date"
                   @change="ecasePickerChange1()"
                  :picker-options="estartDatePicker"
                  placeholder="选择日期"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="结束上课时间" prop="classTimeEnd">
                <el-date-picker
                  v-model="ecasePickerValue2"
                  type="date"
                   @change="ecasePickerChange2()"
                  :picker-options="eendDatePicker"
                  placeholder="选择日期"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
         
          <el-row :gutter="20">
           <el-col :span="20">
              <el-form-item label="详细介绍" prop="detailContent">
                <el-input
                  type="textarea"
                  v-model="experienceForm.detailContent"
                  :rows="2"
                  maxlength="500"
                  show-word-limit
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
           <el-col :span="20">
              <el-form-item label="案例展示" prop="photos">
                <div class="img_list">
                <div
                  class="img_item img_item_true"
                  v-for="(item, index) in daikePhotoList"
                  :key="index"
                >
                  <div class="img_hover">
                    <span
                      class="iconfont icon-yulan1 icon_preview"
                      title="预览"
                      @click="daikeItemSeeClick(item, index)"
                    ></span>
                    <span
                      class="iconfont icon-shanchu4 icon_del"
                      title="删除"
                      @click="daikeItemDelClick(index)"
                    ></span>
                  </div>
                  <img :src="item.url" alt="" />
                </div>
                <div class="img_item" @click="daikeSelectClick">
                  <span class="iconfont icon-danyehuaban icon_new"></span>
                  <input
                    type="file"
                    ref="diakeuploadImg"
                    @change="daikeSelectChange"
                    style="display: none"
                    accept="image/png, image/jpeg, image/gif, image/jpg"
                  />
                </div>
              </div>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
       <span slot="footer" class="dialog-footer">
        <el-button @click="experienceShow = false" size="small"
          >取 消</el-button
        >
        <el-button
          type="primary"
          @click="daikeSave('experienceForm')"
          size="small"
          >添 加</el-button
        >
      </span>
    </el-dialog>
    <dialog-file-view-private
      :dialogFileViewPrivateInfo="dialogFileViewPrivateInfo"
      @closeFileView="closeFileView"
    ></dialog-file-view-private>
  </div>
</template>

<script>
import {
  productAdd,
  productUpdate,
  productInfoDetail
} from "@/api/operates/productManage";
import {
  //讲师接口
  teacherManageList,
  teacherSave,
  teacherDetail,
  teacherResetpwd,
  teacherUpdate,
  teacherRemove,
  teacherUpdateAuthFilee,
  teacherFindAuthFile,
  teacherFaceMarkSave,
  teacherFaceMarkUpdate,
  teacherFaceMarkDetail,
  teacherQuestionList,
  teacherQuestionSave,
  teacherQuestionRemove,
  teacherQuestionInfo,
  teacherQuestionUpdate,
  saveXmtTeacherInterviewAnswer,
  teacherQuestionAnswer,
  removeByTeacher,
  startExam,
  getFinishExamDetail,
  paperList, //试卷列表接口
  answerQuestion,
  submitFinishPaper,
  findPaperQuestionsByTeacherId,
  getFinishStatusByTeacherId,
  findPaperQuestions,
  finishPaperList,
  questionsList,
  getTeacherInfoByPeriodId,
} from "@/api/teacher/teacherManage";
import {
  teacherUpdateEquipment,
  parentCodes,
  fileUploads
} from "@/api/administration/expertTeachBusiness/teachBusiness.js";
import { systemAreaList } from "@/api/system/systemManage";
import {
  getSelectList,
  sectionCode,
  subjectCode,
  teachTitle,
  fileUpload,
  phaseCode,
  achievement,//擅教学生
  teachStyle,//讲课风格
  readingStage,//在读阶段
  aisciplineProfessorSection,//学科类--教授学段
  disciplineProfessorGrade,//学科类--教授年级
  subjectTeachingSubject,//学科类--教学科目
  qualityClassProfessorSection,//素质类--教学学段
  qualityClassTeachingGrade,//素质类--教学年级
  qualityTeachingSubjects,
  qualityStudentsType,//学生类型

} from "@/api/public";
import dayjs from "dayjs";
import dialogFileViewPrivate from "../../teachBusiness/expertTeachBusiness/chat/dialog-file-view-private.vue";
let id = 0;
export default {
  inject: ["reload"],
  components: {
    dialogFileViewPrivate,
  },
  data() {
      const checkIDCard = (rule, value, callback) => {
      const IDCardReg = /^\d{6}(18|19|20)?\d{2}(0[1-9]|1[0-2])(([0-2][1-9])|10|20|30|31)\d{3}(\d|X|x)$/
      if (value) {
        if (IDCardReg.test(value)) {
          callback()
        } else {
          callback(new Error('身份证号格式不正确'))
        }
      } else {
        callback(new Error('请输入身份证号'))
      }
    }
    let idcard = (rule, value, callback) => {
      // 当数据为空时，不进行校验
      if (!value) {
        return callback()
      }
     const phoneReg = /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/
       setTimeout(() => {
        if (phoneReg.test(value)) {
          callback()
        } else {
          callback(new Error('请输入正确的身份证号码'))
        }
      }, 100)
    }
    return {
      daikeList:[],
      experienceDialog:{
        title:'添加代课经历',
        type:'save'
      },
      dialogFileViewPrivateInfo: {
        show: false,
        type: "img",
        src: "",
      },
      experienceForm:{
        studentName:"",
        studentAchievement:"",
        classTimeBegin:"",
        classTimeEnd:"",
        detailContent:"",
        photos:[]
      },
      experienceRules:{
        studentName: [
          { required: true, message: "学生/机构名称不能为空", trigger: "blur" },
        ],
      },
      experienceShow:false,
      imgPreview: {
        img: "",
        show: false,
      },
      paperTable:[],
      hideUpload: true,
      experienceImgUpload:true,
      srcList:[],
      srcList2:[],
      srcList3:[],
      photos:[],
      listProvinceData:[],
      listCityData:[],
      listCountyData:[],
      caseIndex:0,
      daikeIndex:0,
      subjectIdFlag:true,
      caseList:[],
      skilfulTeachArray: [],//擅教学生
      skilfulTeachDate:[],
      teacherInfo:{
        id:"",
        realname:"",
        idcard:"",
      },
      skilfulArray:[],
      skilfulDate:[],
      caseRuleForm:{
        studentName:"",
        detailContent:"",//详情介绍
        studentSectionId:"",//年级
        studentSubjectId:"",//科目
        classTimeEnd:null,
        classTimeBegin:null,
      },
      startDatePicker: null,
      endDatePicker: null,
      casePickerValue1:"",
      casePickerValue2:"",
      estartDatePicker: null,
      eendDatePicker: null,
      ecasePickerValue1:"",
      ecasePickerValue2:"",
      caseImgDialog:false,
      caseImageUrl:'',
      scaseResultRules:{
        studentName: [
          { required: true, message: "学生姓名不能为空", trigger: "blur" },
        ],
        studentSectionId: [
          { required: true, message: "年级不能为空", trigger: "blur" },
        ],
        studentSubjectId: [
          { required: true, message: "学科不能为空", trigger: "blur" },
        ],
      },
      daikePhotoList:[],
      casePopup:false,
      info2Flag:true,
      info3Flag:true,
      info4Flag:true,
      info5Flag:true,
      info6Flag:true,
      btnShow: 1,
      array: [],
      rules: {},
      ruleForm: {
        a: []
      },
      teacherSectionList:[],

      choiceProblemPopup:false,
      getFinishExamDesc: "",
      getFinishExamTitle: "",
      getFinishExam: [],
      finishPaperId: "",
      paperId: "",
      multipleTable: [],
      paperTotal: 0,
      paperParams: {
        pageNumber: 1,
        pageSize: 10,
        sortOrder: "asc",
        gradeId: "",
        subjectId: ""
      },
      paperList: [],
      selectPaperPopup: false,
      newarray: [],
      answerKey: "",
      takeAwayArray: [],
      takeAwayArray2: [],
      value: [],
      shareScopeEnd: [],
    
      answerId: [],
      answerArray: [],
      answerObject: {
        question:null,
        question: "",
        periodId:'',
        pageNumber: 1,
        pageSize: 10
      },
      phaseList: [],
      cityList:[],
      countyList:[],
      provinceLessonList:[],
      cityLessonList:[],
      countyLessonList:[],
      stageList:[],
      subjectIdCheck:[],
      phaseLists:[
        {
          macroId:107,
          name:"高中"
        },
        {
          macroId:106,
          name:"初中"
        },
        {
          macroId:105,
          name:"小学"
        },
        {
          macroId:108,
          name:"艺术培训"
        },
        {
          macroId:109,
          name:"小语种"
        },
        {
          macroId:235,
          name:"全学段"
        }
      ],
       phaseListss:[
         {
          macroId:"",
          name:"全部"
        },
        {
          macroId:107,
          name:"高中"
        },
        {
          macroId:106,
          name:"初中"
        },
        {
          macroId:105,
          name:"小学"
        },
        {
          macroId:108,
          name:"艺术培训"
        },
        {
          macroId:109,
          name:"小语种"
        },
        {
          macroId:235,
          name:"全学段"
        }
      ],

      answerSync: false,
      answerDialog: {
        title: "新增面试答题",
        type: "add"
      },
      answerRuleForm: {
        question: "",
        periodId: ""
      },
      problemPopup: false, //面试答题选择列表
      mackData: {
        peopleType: 1,
        teacherId: ""
      },
      mackData2: {
        peopleType: 2,
        teacherId: ""
      },
      answerId:[],
      getResult:'',
      tableRadio: "",
      gradeidList: [
        {
          macroId: "",
          name: "全部",
        },
      ],
      answerobj: {
        teacherId: ""
      },
      qualDisaled:false,
      faceRuleForm: {
        peopleType: 1,
        overallScore:'',
        imageTemperament:0,
        communicationExpression:0,
        substituteLessonsBeijing:0,
        teachingExperience:0,
        provinceIdOne:'',
        provinceIdTwo:"",
        otherPartnersOne:'',
        otherPartnersTwo:'',
        otherPartnersThree:'',
        // teacherType:0,
        teachingCharacteristics:0,
        jobHuntingAttitude:0,
        overallEvaluation:'',
      },
      saveRuleForm2:{
        periodId:"",
        sectionId:"",
      },
      saveperiodId:'',
      savesectionId:'',
      qtpyeLists: [
        {
            value: "",
            label: "全部",
        },
        {
            value: "单选题",
            label: "单选题",
        },
        {
            value: "多选题",
            label: "多选题",
        },
        {
            value: "判断题",
            label: "判断题",
        },
        {
            value: "论述题",
            label: "论述题",
        },
        {
            value: "填空题",
            label: "填空题",
        },
        {
            value: "测评题",
            label: "测评题",
        }
    ],
      isDisable:false,
      authFilesEdit:[],
      urlObj: {},
      urlArr: [],
      authenDialogVideo: false,
      authenDialogVideoTitle: {
        title: "上传视频",
        type: "add"
      },
      studentAchievementList:[
        {
          macroId:268,
          name:'拔高型'
        },
        {
          macroId:269,
          name:'中上等成绩'
        },
        {
          macroId:270,
          name:'中等类待提高'
        },
        {
          macroId:271,
          name:'基础型'
        }
      ],
      experienceFormList:[

      ],
      detaildStatus: false,
      authenDialogVisible: false, //资质弹窗是否显示
      authenDialogTitle: {
        title: "上传资质",
        type: "add"
      },
      videoForm: {
        videoUrl: ""
      },

      videoformArray: [],
      periodId: [],
      sectionId: [],
      set_editor_content: "",
      subjective_content_content: "",
      content_height: "160",
      timePickerValue: "", //上课时间
      currentCountyData:[],
      currentCityData:[],
      currentProvinceData:[],
      uploadDisabled:false,
      followUpDialog: {
        title: "添加案例",
        type: "save"
      },
      caseUpDialog: {
        title: "添加案例",
        type: "save"
      },
      faceRules:{
         overallScore: [
          { required: true, message: "整体评分不能为空", trigger: "change" }
        ],
      },
      followUpDialogVisible: false,
      currentCityList:[],
      fileUpload,
      listArray: [],
      getCurrentCountysName:"",
      listParams: {
        name: "", //关键字
        sectionId: "",
        subjectId: "",
        identityType: "0",
        teachingAgeId: "", //教龄
        teachTitleId: "", //职称
        currentProvinceIds:"",
        pageNumber: 1,
        pageSize: 10,

        currentCityIds:"",
        currentCountyIds:""
      },
      uploadImg:{
        idcard:"",
        qualifications:"",
        xueli:"",

      },
      identityTypeList: [
        {
          value: "0",
          label: "全部"
        },
        {
          value: "1",
          label: "学科类-老师"
        },
        {
          value: "2",
          label: "学科类-大学生"
        },
        {
          value: "3",
          label: "素质教育类-老师"
        },
        {
          value: "4",
          label: "素质教育类-大学生"
        }
      ],
      teachingAgeIdListSearch: [
        {
          value: '',
          label: "全部"
        },
        {
          value: 27,
          label: "5年以下"
        },
        {
          value: 28,
          label: "5-10"
        },
        {
          value: 29,
          label: "10-15"
        },
        {
          value: 227,
          label: "15-20以上"
        }
      ],
      teachingAgeIdList: [
        {
          value: 27,
          label: "5年以下"
        },
        {
          value: 28,
          label: "5-10"
        },
        {
          value: 29,
          label: "10-15"
        },
        {
          value: 227,
          label: "15-20以上"
        }
      ],
       teachTitleIdListSearch: [
         {
          value: '',
          label: "全部"
        },
        {
          value: 59,
          label: "初级教师"
        },
        {
          value: 60,
          label: "中级教师"
        },
        {
          value: 61,
          label: "高级教师"
        },
        {
          value: 62,
          label: "特级教师"
        }
      ],
      teachTitleIdList: [
        {
          value: 59,
          label: "初级教师"
        },
        {
          value: 60,
          label: "中级教师"
        },
        {
          value: 61,
          label: "高级教师"
        },
        {
          value: 62,
          label: "特级教师"
        }
      ],
      total: 0,
      reasonVal:'',
      seeReasonPoups:false,
      saveDialogVisible: false,
      seeDialogVisible:false,//查看讲师
      seeActiveName:"see1",
      faceRuleFormAuditor: {
        overallScore:'',
        imageTemperament:0,
        communicationExpression:0,
        substituteLessonsBeijing:0,
        teachingExperience:0,
        provinceIdOne:'',
        otherPartnersOne:'',
        otherPartnersTwo:'',
        otherPartnersThree:'',
        // teacherType:0,
        suitableForStudents:'',
        teachingCharacteristics:0,
        jobHuntingAttitude:0,
        overallEvaluation:'',
        peopleType: 1
      },
      sectionCheckbox:[
        {label:98,content:"高三",parentId:107},
        {label:97,content:"高二",parentId:107},
        {label:96,content:"高一",parentId:107},
        {label:20,content:"初三",parentId:106},
        {label:19,content:"初二",parentId:106},
        {label:18,content:"初一",parentId:106},
        {label:95,content:"六年级",parentId:105},
        {label:94,content:"五年级",parentId:105},
        {label:93,content:"四年级",parentId:105},
        {label:92,content:"三年级",parentId:105},
        {label:110,content:"艺术培训",parentId:108},
        {label:111,content:"小语种",parentId:109},
      ],
      dataRuleForm:{
          photo:"",
          name:"",
          ifAcceptAudition:"",
          classForm:"",
          currentProvinceIds:"",
          currentCityIds:"",
          currentCountyIds:[],
          xmtTeacherSuccessCaseEntityList:[]
      },
      teacherQualificationCertificate:false,
      saveRuleForm: {
        teacherQualificationCertificate:0,
        subjectIdCheck:[],
        teachingDirection:1,
        identityType:'1',
        photo: "", //图片
        name: "", //昵称
        contact: "", //联系方式
        sexId: "", //性别
        realname: "", //真实姓名
        idcard: "", //身份证号
        currentAddress: "", //籍贯
        birthday: "", //出生日期
        educationId: "", //学历
        graduationSchool: "", //毕业院校
        teachingAgeId: "", //教龄
        schoolName: "", //任教学校
        teachTitleId: "", //老师职称
        skilful: "", //讲课风格
        skilfulTeach:"",//擅教学生
        teachTrait: "", //教学特点
        periodId: [], //教授学段
        sectionId: [], //教授年纪
        subjectId: "", //教授科目
        hopeMinFee: "", //课酬最小值
        hopeMaxFee: "" ,//课酬最大值
        xmtTeacherSuccessCaseEntityList:[],
        authFiles:[]
      },
      exhibitionPhotos:[],
      teachTitleList: [],
      takeFlag:false,
      dialogImageUrl:'',
      dialogVisible:false,
      saveRules: {
        contact: [
          {
            validator: (rule, value, callback) => {
              let mobile = /^1[2|3|4|5|6|7|8|9]\d{9}$/;
              let phone = /^0\d{2,3}-?\d{7,8}$/;
              if (!value) {
                callback();
              } else if (!mobile.test(value) && !phone.test(value)) {
                callback(new Error("请输入正确手机号"));
              } else {
                callback();
              }
            },
            trigger: "blur"
          }
        ],
        periodId: [
          { required: true, message: "教授学段不能为空", trigger: "change" }
        ],
        // subjectId: [{ required: true, message: "教授科目不能为空", trigger: "change" }],
        sectionId: [{ required: true, message: "教授年级不能为空", trigger: "change" }],
        idcard : [
          {
             validator: idcard, message: '请输入正确的身份证号码'
          }
        ],
     },
      provinceList:[],
      parentId: "0",
      authenRuleForm: {
        idjust: "",
        idback: "",
        teacherStudent: "", //资格认证、学生证
        education: "", //学历
        qualifications: "" //资质
      },
      answerRules:{
          question: [{ required: true, message: "问题不能为空", trigger: "blur" }],
          periodId: [{ required: true, message: "学段不能为空", trigger: "change" }]
      },
      authenRuleJson: {
        idjust: {
          fileType: 1,
          fileUrl: null,
          teacherId: 0
        },
        idback: {
          fileType: 1,
          fileUrl: null,
          teacherId: 0
        },
        teacherStudent: {
          fileType: 2,
          fileUrl: null,
          teacherId: 0
        },
        education: {
          fileType: 3,
          fileUrl: null,
          teacherId: 0
        },
        qualifications: {
          fileType: 4,
          fileUrl: null,
          teacherId: 0
        }
      },
      authenRules: {
        idjust: [
          { required: true, message: "身份证正面不能为空", trigger: "blur" }
        ],
        idback: [
          { required: true, message: "身份证反面不能为空", trigger: "blur" }
        ],
        teacherStudent: [
          { required: true, message: "教师资格认证不能为空", trigger: "blur" }
        ],
      },
      teacherId: [],
      teacherPhone: [],
      sectionList: [
        {
          macroId: "",
          name: "全部"
        }
      ],
      sectionList2:[],
      educationIdList: [
        {
          macroId: 70,
          name: "专科"
        },
        {
          macroId: 69,
          name: "本科"
        },
        {
          macroId: 68,
          name: "硕士"
        },
        {
          macroId: 67,
          name: "博士"
        }
      ],
      previewImage:[],
      detailShow:false,
      testPapers:[],
      choiceList:[],
      sectionIdStr:"",
      choiceTotal:0,
      subjectParams:{
          title:"",
          qtpye:"",//题型
          gradeId:"",//年级
          subjectId:"",//学科
          pageNumber: 1,
          pageSize: 10,
      },
      choiceList:[],
      choiceTotal:0,
      saveDialog: {
        title: "添加讲师",
        type: "add"
      },
      subjectList: [
        {
          macroId: "",
          name: "全部"
        }
      ],
      subjectList2:[],
      saveRuleFormFollowUp: {
        studentId: "",
        saleId: "",
        followupTags: "",
        nextFollowupTime: "",
        followContent: ""
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date(
                dayjs()
                  .add(1, "day")
                  .format("YYYY-MM-DD") + " 00:00:00"
              );
              const start = new Date(
                dayjs().format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date(dayjs().format("YYYY-MM-DD") + " 00:00:00");
              const start = new Date(
                dayjs()
                  .subtract(1, "day")
                  .format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date(
                dayjs()
                  .add(1, "day")
                  .format("YYYY-MM-DD") + " 00:00:00"
              );
              const start = new Date(
                dayjs()
                  .subtract(7, "day")
                  .format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date(
                dayjs()
                  .add(1, "day")
                  .format("YYYY-MM-DD") + " 00:00:00"
              );
              const start = new Date(
                dayjs()
                  .subtract(30, "day")
                  .format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date(
                dayjs()
                  .add(1, "day")
                  .format("YYYY-MM-DD") + " 00:00:00"
              );
              const start = new Date(
                dayjs()
                  .subtract(90, "day")
                  .format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
      switchStatus: 1,
      dialogContImgUrl: "",
      fileList: [],
      getTeacherId: 0,
      faceType: 1,
      total2:0,
      activeName:'info1',
      expireTimeOption: {
        disabledDate(date) {
          return date.getTime() > Date.now();
        }
      },
     
      arr3:[],
      // 新增讲师年级列表和学科列表
      teacherGradeList: [],
      teacherSubjectList: [],
      teacherSubjectListCheck:[],
      teacherInfoAccount: '',
    };
  },
  methods: {
    closeFileView() {
      this.dialogFileViewPrivateInfo.show = false;
    },
    studentAchievementFn(num){
      if(num==268){
        return '提高型'
      }else if(num==269){
        return '中上等成绩'
      }else if(num==270){
        return '中等类待提高'
      }else if(num==271){
        return '基础型'
      }
      else{
        return ''
      }
    },
    closeDialog(){
       this.getteacherManageList();
      // this.teacherId=[];
    },
    disabledStores(id){
      console.log(id)
    },
    handleClick(tab, event){
       if(this.saveDialog.type=='edit'){
          if(tab.index==4){
            this.finishPaperList();
          }
        }
    },
    finishPaperList() {
      var data = {
        examStatus: 1,
        pageNumber: 1,
        pageSize: 1000,
        sortOrder: "asc",
        examUserId: this.getTeacherId
      };
      finishPaperList(data).then(res => {
        if (res.rows.length) {
          this.paperId=res.rows[0].id;
          this.paperTable = res.rows;
          if (res.rows[0].title) {
            this.getFinishExamTitle = res.rows[0].title;
          }
          this.findPaperQuestions(res.rows[0].id);
        }else{
          this.finishPaperId = ''
          this.getFinishExam = []
          this.getFinishExamTitle="";
        }
      });
    },
    seeFinishPaper(id){
       this.findPaperQuestions(id);
    },
    clearFn(){
      this.listParams.currentProvinceIds="";
      this.listParams.currentCityIds="";
      this.listParams.currentCountyIds="";
      this.getteacherManageList();
    },
    findPaperQuestions(examUserId) {
      findPaperQuestions(examUserId).then(res => {
        if(res.rows.length){
          this.finishPaperId=res.rows[0].finishPaperId;
        }
         var data = {
        finishPaperId: this.finishPaperId,
        teacherId: this.getTeacherId
      };
        getFinishExamDetail(data).then(res => {
        if (res.flag == "success") {
          if (res.msg.title) {
            this.getFinishExamTitle = res.msg.title;
          }
          if (res.msg.desc) {
            this.getFinishExamDesc = res.msg.desc;
          }
        }
      });
        for (var i = 0; i < res.rows.length; i++) {
          for (var j = 0; j < res.rows[i].finishPaperSmalls.length; j++) {
            res.rows[i].finishPaperSmalls[j].smallTitle = res.rows[i].title;
            res.rows[i].finishPaperSmalls[j].paperBigType =
              res.rows[i].paperBigType;
            if (res.rows[i].finishPaperSmalls[j].smallTitle == "单项选择题") {
              res.rows[i].finishPaperSmalls[j].radio =
                res.rows[i].finishPaperSmalls[j].answer;
            } else if (
              res.rows[i].finishPaperSmalls[j].smallTitle == "多项选择题"
            ) {
              res.rows[i].finishPaperSmalls[j].checkbox = res.rows[i]
                .finishPaperSmalls[j].answer
                ? res.rows[i].finishPaperSmalls[j].answer.split("|")
                : [];
            } else if (
              res.rows[i].finishPaperSmalls[j].smallTitle == "判断题"
            ) {
              res.rows[i].finishPaperSmalls[j].judge =
                res.rows[i].finishPaperSmalls[j].answer;
            } else if (
              res.rows[i].finishPaperSmalls[j].smallTitle == "填空题"
            ) {
              res.rows[i].finishPaperSmalls[j].blanks =
                res.rows[i].finishPaperSmalls[j].answer;
            } else if (
              res.rows[i].finishPaperSmalls[j].smallTitle == "主观题"
            ) {
              res.rows[i].finishPaperSmalls[j].subjective =
                res.rows[i].finishPaperSmalls[j].answer;
            }
          }
        }
        this.getFinishExam = res.rows;
      });
    },
    //成功案例时间选择
    caseDatePickerChange(){
      if (!this.casePickerValue) {
        this.caseRuleForm.classTimeBegin = null;
        this.caseRuleForm.classTimeEnd = null;
        return;
      }
      this.caseRuleForm.classTimeBegin = this.casePickerValue[0];
      this.caseRuleForm.classTimeEnd = this.casePickerValue[1];
    },
    openReason(row,num){
      if(num==1){//基本信息原因

      }else if(num==2){//上传资质原因
       
      }
    },
    showTag(){

    },
     caseHandlePicture(file) {
        this.caseImageUrl = file.url;
        this.caseImgDialog = true;
      },
      handleLimit(file,fileList){
         this.hideUpload = fileList.length >= 8
      },
       experienceImgLimit(file,fileList){
         this.experienceImgUpload = fileList.length >= 8
      },
      caseHandleAvatarSuccess(file,fileList){
        this.caseRuleForm.photos.push({url:file[0].url});
      },
      experienceImgSuccess(file,fileList){
        this.experienceForm.photos.push({url:file[0].url});
      },
      caseHandleRemove(file, fileList) {
          this.hideUpload = file.length >= 8;
          for (var i=0;i<this.caseRuleForm.photos.length;i++) {
            if (this.caseRuleForm.photos[i].url === file.url) {
                this.caseRuleForm.photos.splice(i,1);
            }
          }
      },
      experienceImgRemove(file, fileList) {
          this.experienceImgLimit = file.length >= 8;
          for (var i=0;i<this.experienceForm.photos.length;i++) {
            if (this.experienceForm.photos[i].url === file.url) {
                this.experienceForm.photos.splice(i,1);
            }
          }
      },
       beginDate() {
            const self = this
            return {
                disabledDate(time){
                    if (self.casePickerValue2) {
                        return new Date(self.casePickerValue2).getTime() < time.getTime()
                    }
                }
            }
        },
        processDate() {
            const  self = this
            return {
                disabledDate(time) {
                    if (self.casePickerValue1) {
                        return new Date(self.casePickerValue1).getTime() > time.getTime()
                    }
                }
            }
        }, 
         ebeginDate() {
            const self = this
            return {
                disabledDate(time){
                    if (self.ecasePickerValue2) {
                        return new Date(self.ecasePickerValue2).getTime() < time.getTime()
                    }
                }
            }
        },
        eprocessDate() {
            const  self = this
            return {
                disabledDate(time) {
                    if (self.ecasePickerValue1) {
                        return new Date(self.ecasePickerValue1).getTime() > time.getTime()
                    }
                }
            }
        }, 
        seeReason(val){
      this.seeReasonPoups=true;
      this.reasonVal=val;
    },
      casePickerChange1(){
         this.caseRuleForm.classTimeBegin = this.casePickerValue1;
      },
      casePickerChange2(){
         this.caseRuleForm.classTimeEnd = this.casePickerValue2;
      },

      ecasePickerChange1(){
         this.experienceForm.classTimeBegin = this.ecasePickerValue1;
      },
      ecasePickerChange2(){
         this.experienceForm.classTimeEnd = this.ecasePickerValue2;
      },
      caseEdit(item,index){
        this.casePopup=true;
        this.caseIndex=index;
        this.caseUpDialog.title='编辑案例';
        this.caseUpDialog.type="edit";
        this.caseRuleForm={
          studentName:item.studentName,
          detailContent:item.detailContent,//详情介绍
          studentSectionId:item.studentSectionId,//年级
          studentSubjectId:item.studentSubjectId,//科目
          studentAchievement:item.studentAchievement,
          photos:item.photoList.map( item => {
            return {url: item}
          }),
        }
       if(this.caseRuleForm.photos.length<8){
         this.hideUpload=false;
       }else{
         this.hideUpload=true;
       }
        this.casePickerValue1=item.classTimeBegin;
        this.casePickerValue2=item.classTimeEnd;
      },
      caseRemove(index){
        this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
            this.caseList.splice(index,1);
        })
        .catch(() => {});
        
      },
      
      experienceEdit(item,index){
        this.experienceShow=true;
        this.daikeIndex=index;
        this.experienceDialog.title='编辑代课经历';
        this.experienceDialog.type="edit";
        this.experienceForm={
          studentName:item.studentName,
          detailContent:item.detailContent,//详情介绍
          // studentSectionId:item.studentSectionId,//年级
          // studentSubjectId:item.studentSubjectId,//科目
          studentAchievement:item.studentAchievement,
          // photos:item.photoList.map( item => {
          //   return {url: item}
          // }),

        }
        this.daikePhotoList=item.photoList.map( item => {
          return {url: item}
        })
       if(this.experienceForm.photos.length<8){
         this.experienceImgUpload=false;
       }else{
         this.experienceImgUpload=true;
       }
        this.ecasePickerValue1=item.classTimeBegin;
        this.ecasePickerValue2=item.classTimeEnd;
      },
      experienceRemove(index){
        this.$confirm("确定要删除吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
              this.daikeList.splice(index,1);
          })
          .catch(() => {});
      },
      //代课经历预览
    daikeItemSeeClick(item,index){
      this.dialogFileViewPrivateInfo = {
          show: true,
          type: "img",
          src: item.fileUrl ? item.fileUrl : item.url,
          curIndex: index ? index : 0,
          imgList:
            index != undefined
              ? this.daikePhotoList.map((item) => {
                  return item.fileUrl ? item.fileUrl : item.url;
                })
              : [item.fileUrl ? item.fileUrl : item.url],
        };
    },
    //代课经历删除
    daikeItemDelClick(index){
      this.daikePhotoList.splice(index, 1);
    },
    daikeSelectClick(){
      this.$refs.diakeuploadImg.value = "";
      this.$refs.diakeuploadImg.click();
    },
    exhibitionPhotosDel(index){
      this.exhibitionPhotos.splice(index, 1);
    },
    exhibitionPhotosSee(item,index){
      console.log(item,'item..')
      this.dialogFileViewPrivateInfo = {
          show: true,
          type: "img",
          src: item ? item : item,
          curIndex: index ? index : 0,
          imgList:
            index != undefined
              ? this.exhibitionPhotos.map((item) => {
                  return item ? item : item;
                })
              : [item ? item : item],
        };
    },
    dealStage(row){
      if (row.readingStage == 302) {
        return "在读--专科";
      } else if (row.readingStage == 303) {
        return "在读--本科";
      } else if (row.readingStage == 304) {
        return "在读--研究生";
      } else if (row.readingStage == 305) {
        return "在读--博士";
      } else {
        return "";
      }
    },
    daikeSelectChange(){
      if(this.daikePhotoList.length==8){
        this.msgWarn("最多只能上传八张图片！");
        return;
      }
      if (
        !/\.(png|jpg|gif|jpeg|webp)$/.test(this.$refs.diakeuploadImg.files[0].name)
      ) {
        this.msgWarn("只能是图片格式的文件！");
        return;
      }
      let formData = new FormData();
      formData.append("file", this.$refs.diakeuploadImg.files[0]);
      fileUploads(formData).then((uploadRes) => {
        if (uploadRes[0].url) {
          this.daikePhotoList.push({
            url: uploadRes[0].url,
          });
        }
      });
    },
      //成功案例保存
      confirmCase(formName){
        this.caseRuleForm.photoList=this.caseRuleForm.photos.map( item => {
          return item.url
        });
        this.$refs[formName].validate(valid => {
        if (valid) {
            this.casePopup=false;
            if(this.caseUpDialog.type=='save'){
              this.caseList.push(this.caseRuleForm);
            } else{
              this.caseRuleForm.classTimeBegin = this.casePickerValue1;
              this.caseRuleForm.classTimeEnd = this.casePickerValue2;
              this.caseList.splice(this.caseIndex, 1, this.caseRuleForm);
            }
        }else {
          this.msgWarn("必填字段不能为空！");
          return false;
        }
      })
      },
      daikeSave(formName){
        this.experienceForm.photoList=this.daikePhotoList.map( item => {
          return item.url
        });
        this.$refs[formName].validate(valid => {
        if (valid) {
            this.experienceShow=false;
            if(this.experienceDialog.type=='save'){
              this.daikeList.push(this.experienceForm);
            } else{
              this.experienceForm.classTimeBegin = this.ecasePickerValue1;
              this.experienceForm.classTimeEnd = this.ecasePickerValue2;
              this.daikeList.splice(this.daikeIndex, 1, this.experienceForm);
            }
        }else {
          this.msgWarn("必填字段不能为空！");
          return false;
        }
      })
      },
      seeHandleClick(tab, event){
          if(tab.index==4){
            this.finishPaperList();
          }
      },
       getCityLists(){
        return systemAreaList({
          parentId: this.saveRuleForm.provinceId
        }).then(res => {
          if (res.code == 0) {
            this.cityList = res.rows;
          }
        });
      },
    
    async provinceChangeList(){
      await this.getCityLists();
      this.saveRuleForm.cityId = this.cityList[0].areaId;
      this.cityChangeList();
    },
    async  cityChangeList(){
      await this.getCountyList();
      this.saveRuleForm.countyId = this.countyList[0].areaId;
    },
    getCountyList() {
      return systemAreaList({
        parentId: this.saveRuleForm.cityId
      }).then(res => {
        if (res.code == 0) {
          this.countyList = res.rows;
        }
      });
    },
     currentCityLists(){
       return systemAreaList({
        parentId: this.dataRuleForm.currentProvinceIds
      }).then(res => {
        if (res.code == 0) {
          this.currentCityData = res.rows;
        }
      });
    },
    groupChange() {
      if (this.skilfulTeachArray.length > 2) {
        this.skilfulTeachArray.shift();
      }
    },
    skilfulArrayChange(){
      if (this.skilfulArray.length > 2) {
        this.skilfulArray.shift();
      }
    },
     listCityLists(){
       return systemAreaList({
        parentId: this.listParams.currentProvinceIds
      }).then(res => {
        if (res.code == 0) {
          this.listCityData = res.rows;
        }
      });
    },
    async  listProvinceChange(){
      await this.listCityLists();
      this.listParams.currentCityIds = this.listCityData[0].areaId;
      this.listCityChange();
    },
    async  listCityChange(){
      await this.listCountyList();
      this.listParams.currentCountyIds = this.listCountyData[0].areaId;
    },
    listCountyList() {
      return systemAreaList({
        parentId: this.listParams.currentCityIds
      }).then(res => {
        if (res.code == 0) {
          this.listCountyData = res.rows;
        }
      });
    },
    async currentProvinceChange(){
      await this.currentCityLists();
      this.dataRuleForm.currentCityIds = this.currentCityData[0].areaId;
      this.currentCityChange();
      this.dataRuleForm.currentCountyIds=[];
    },
    async  currentCityChange(){
      await this.currentCountyList();
      // this.saveRuleForm.currentCountyIds = this.currentCountyData;
    },
    currentCountyList() {
      return systemAreaList({
        parentId: this.dataRuleForm.currentCityIds
      }).then(res => {
        if (res.code == 0) {
          this.currentCountyData = res.rows;
        }
      });
    },
    bindChange(e){
      if(e.length>5){
        this.$message.warning("最多只能选择五个地区")
        this.dataRuleForm.currentCountyIds.splice(-1);
      }
    },
    changeLabel(val) {
      // 是否与上次的类型相同
      let changeFlag = false;
      let changeItem = null;
      if (this.shareScopeEnd.length == 0) {
        this.value = val;
      } else {
        // 与原数组比对
        this.value.forEach(item => {
          if (item[0] !== this.shareScopeEnd[0][0]) {
            // 一级标签不同
            changeFlag = true;
            changeItem = item;
          } else if (item[1] != this.shareScopeEnd[0][1]) {
            // 一级标签相同但是二级标签不同
            changeFlag = true;
            changeItem = item;
          } else if (
            (!item[2] && this.shareScopeEnd[0][2]) ||
            (item[2] && !this.shareScopeEnd[0][2])
          ) {
            changeFlag = true;
            changeItem = item;
          }
        });
      }
      if (changeFlag) {
        this.value = [];
        this.value.push(changeItem);
      }
      this.shareScopeEnd = this.value;
    },
    dealSex(row) {
      if (row.sexId == 1) {
        return "男";
      } else if (row.sexId == 2) {
        return "女";
      } else {
        return "";
      }
    },
    dealTeachTitleId(row) {
      if (row.teachTitleId == 59) {
        return "初级教师";
      } else if (row.teachTitleId == 60) {
        return "中级教师";
      } else if (row.teachTitleId == 61) {
        return "高级教师";
      } else if (row.teachTitleId == 62) {
        return "特级教师";
      } else {
        return "";
      }
    },
    dealIdentityType(row) {
      if (row.identityType==1) {
        return "学科类--老师";
      } else if (row.identityType==2) {
        return "学科类--大学生";
      } else if (row.identityType==3) {
        return "素质教育类--老师";
      } else if (row.identityType == 4) {
        return "素质教育类--大学生";
      } else {
        return "";
      }
    },
    dealTeachingAgeId(row) {
      if (row.teachingAgeId == 27) {
        return "5年以下";
      } else if (row.teachingAgeId == 28) {
        return "5-10";
      } else if (row.teachingAgeId == 29) {
        return "10-15";
      } else if (row.teachingAgeId == 227) {
        return "15-20以上";
      } else {
        return "";
      }
    },
    educationId(row){
      if(row==70){
        return '专科'
      }else if(row==69){
        return '本科'
      }else if(row==68){
        return '硕士'
      }else if(row==67){
        return '博士'
      }
    },
    dealSectionId(row) {
      var str = "";
      var arr2 = new Array();
      if (row.sectionId) {
        arr2 = row.sectionId.split(",");
      }
      for (var i = 0; i < arr2.length; i++) {
        if (arr2[i] == 98) {
          str += "高三,";
        } else if (arr2[i] == 97) {
          str += "高二,";
        } else if (arr2[i] == 96) {
          str += "高一,";
        } else if (arr2[i] == 20) {
          str += "初三,";
        } else if (arr2[i] == 19) {
          str += "初二,";
        } else if (arr2[i] == 18) {
          str += "初一,";
        } else if (arr2[i] == 110) {
          str += "艺术培训,";
        } else if (arr2[i] == 111) {
          str += "小语种,";
        }
        else if (arr2[i] == 95) {
          str += "六年级,";
        } else if (arr2[i] == 94) {
          str += "五年级,";
        } else if (arr2[i] == 93) {
          str += "四年级,";
        } else if (arr2[i] = 92) {
          str += "三年级,";
        } 
      }
      return str.substring(0, str.length - 1);
    },
     getRowKeys(row) {
      return row.id;
    },
    answerPeriodId(row) {
      var str = "";
      var arr2 = new Array();
      if (row.periodId == 107) {
        str = "高中";
      } else if (row.periodId == 106) {
        str = "初中";
      } else if (row.periodId == 105) {
        str = "小学";
      } else if (row.periodId == 108) {
        str = "艺术培训";
      } else if (row.periodId == 109) {
        str = "小语种";
      }else if (row.periodId == 235) {
        str = "全学段";
      }
      return str;
    },
    dealSubjectIdd(row) {
      let result = []
      let list=this.subjectList.slice(1);
      var subjectArray=row.subjectId?row.subjectId.split(','):[];
      for(let  i=0;i<list.length;i++){
        let tempArr1=list[i];
        for(let j=0;j<subjectArray.length;j++){
          let tempArr2=subjectArray[j]
          if(tempArr2==tempArr1.macroId){
             result.push(tempArr1.name)
             break;
          }
        }
      }
      return result.join();
    },
    studentSectionIdFn(row){
      let result = []
      var subjectArray=String(row).split();
      for(let  i=0;i<this.sectionList.length;i++){
        let tempArr1=this.sectionList[i];
        for(let j=0;j<subjectArray.length;j++){
          let tempArr2=subjectArray[j]
          if(tempArr2==tempArr1.macroId){
             result.push(tempArr1.name)
             break;
          }
        }
      }
      return result.join();
    },
    studentSubjectIdFn(row){
      var subjectlist = "";
      if (row == 185) {
        subjectlist = "全学科";
      } else if (row == 23) {
        subjectlist = "语文";
      } else if (row == 24) {
        subjectlist = "数学";
      } else if (row == 25) {
        subjectlist = "英语";
      } else if (row == 101) {
        subjectlist = "物理";
      } else if (row == 102) {
        subjectlist = "化学";
      } else if (row == 99) {
        subjectlist = "政治";
      } else if (row == 100) {
        subjectlist = "生物";
      } else if (row == 87) {
        subjectlist = "地理";
      } else if (row == 88) {
        subjectlist = "历史";
      } else if (row == 103) {
        subjectlist = "科学";
      } else if (row == 112) {
        subjectlist = "国学";
      } else if (row == 113) {
        subjectlist = "书法";
      } else if (row == 114) {
        subjectlist = "吉他";
      } else if (row == 115) {
        subjectlist = "绘画";
      } else if (row == 116) {
        subjectlist = "德语";
      } else if (row == 117) {
        subjectlist = "法语";
      } else if (row == 118) {
        subjectlist = "俄语";
      } else if (row == 119) {
        subjectlist = "西班牙语";
      } else if (row == 183) {
        subjectlist = "其他小语种";
      } else if (row == 184) {
        subjectlist = "其他艺术课";
      }
      return subjectlist;
    },
    getGradeSubjectList() {
     
      if(this.saveRuleForm.periodId.length > 0){
        getTeacherInfoByPeriodId({
        periodId: this.saveRuleForm.periodId.join(',')
      }).then( res => {
        this.teacherGradeList = res.rows[1]
        this.teacherSubjectList = res.rows[0]
        this.teacherSubjectListCheck =  res.rows[0]
      })
      }
    },
    changes() {
      this.teacherGradeList=[]
      if(this.saveRuleForm.periodId.length > 0){
        getTeacherInfoByPeriodId({
        periodId: this.saveRuleForm.periodId.join(',')
      }).then( res => {
        this.teacherGradeList = res.rows[1]
        this.teacherSubjectList = res.rows[0]
        this.teacherSubjectListCheck =  res.rows[0]
        this.saveRuleForm.sectionId = this.teacherGradeList.map( item => {
          return item.macroId
        })
        this.saveRuleForm.subjectId = []
      })
      }else{
        this.saveRuleForm.sectionId = []
        this.saveRuleForm.subjectId = []
        this.teacherGradeList = []
        this.teacherSubjectList = []
        this.teacherSubjectListCheck=[]
      }
    },
    //年级选择
    gradeChange(){
      if(this.saveRuleForm.sectionId.length > 0){
          parentCodes({
            parentCode: subjectTeachingSubject
          }).then(res => {
            if (res.code == 0) {
            this.teacherSubjectList=res.rows;
            }
          });
      }
    },
    edit_content_change(html) {
      this.saveRuleForm.detailContent = html;
    },
    datePickerChange() {
      if (!this.timePickerValue) {
        this.saveRuleForm.classTimeBegin = null;
        this.saveRuleForm.classTimeEnd = null;
        return;
      }
      this.saveRuleForm.classTimeBegin = this.timePickerValue[0];
      this.saveRuleForm.classTimeEnd = this.timePickerValue[1];
    },
    resumeSuccess(response, file, fileList){
       if (response[0].url) {
        this.dataRuleForm.resume = response[0].url;
      }
    },
    resumeError(err, file, fileList){
      this.msgError("附件简历上传失败！");
    },
    handleAvatarSuccess(res, file) {
      this.dataRuleForm.photo = res[0].url;
    },
    //身份证上传
    idCardUpload(res, file) {
      this.uploadImg.idcard = res[0].url;
    },
    //教师资格认证上传
    teaQualifications(res, file) {
      this.uploadImg.qualifications = res[0].url;
      if(this.teacherQualificationCertificate){
        this.teacherQualificationCertificate=false;
        this.qualDisaled=true;
      }
    },
    clearFilesQualifications(){

    },
    //学历 学生证上传
    xueliUpload(res, file) {
      this.uploadImg.xueli = res[0].url;
    },
    imgSelectClick(){
      this.$refs.uploadImg.value = "";
      this.$refs.uploadImg.click();
    },
    imgSelectChange(){
      if(this.testPapers.length==8){
        this.msgWarn("最多只能上传八张图片！");
        return;
      }
      if (
        !/\.(png|jpg|gif|jpeg|webp)$/.test(this.$refs.uploadImg.files[0].name)
      ) {
        this.msgWarn("只能是图片格式的文件！");
        return;
      }
      let formData = new FormData();
      formData.append("file", this.$refs.uploadImg.files[0]);
      fileUploads(formData).then((uploadRes) => {
        if (uploadRes[0].url) {
          this.testPapers.push({
            url: uploadRes[0].url,
            
          });
        }
      });
    },
    handleAvatarSuccess5(file, fileList) {
      this.testPapers.push({url:file[0].url});
    },
    handleAvatarRemove5(file, fileList){
      for (var i=0;i<this.testPapers.length;i++) {
        if (this.testPapers[i].url === file.url) {
            this.testPapers.splice(i,1);
        }
      }
    },
    showImg(e) {
        this.imgPreview.img = e.target.src;
        this.imgPreview.show = true;
    },
    video_change(url) {
      this.videoForm.videoUrl = url;
      this.videoformArray.push({
        fileType: 6,
        fileUrl: url
      });
    },
    
    beforeAvatarUpload(file) {
      let reg = /\.(png|jpg|gif|jpeg)$/;
      if (!reg.test(file.name)) {
        this.msgError(
          "支持上传的图片格式为png，jpg，gif，jpeg；请检查格式是否正确！"
        );
      }
      return reg.test(file.name);
    },
    dealAdd() {
      this.qualDisaled=false;
      this.teacherQualificationCertificate=false;
      this.exhibitionPhotos=[];
      this.daikeList=[]
      this.activeName='info1';
      this.info2Flag=true;
      this.info3Flag=true;
      this.info4Flag=true;
      this.info5Flag=true;
      this.info6Flag=true;
      this.saveDialogVisible = true;
      this.teacherInfo.realname = '';
      this.teacherInfo.idcard = ''
      this.saveDialog = {
        title: "新增讲师",
        type: "add"
      };
      this.uploadImg={
        idcard:'',
        xueli:'',
        qualifications:'',

      }
      this.skilfulArray=[];
      this.skilfulTeachArray = [];
      this.periodId = [];
      this.sectionId = [];
      this.fileList = [];
      this.takeAwayArray = [];
      this.videoForm.videoUrl = "";
      this.getFinishExam=[];
      this.teacherGradeList = []
      this.teacherSubjectList = []
      this.teacherSubjectListCheck=[]
      this.caseList=[];
      this.testPapers=[]
      this.getProvinceList();
      this.dataRuleForm={
          photo:"",
          name:"",
          ifAcceptAudition:"",
          classForm:"",
          currentProvinceIds:[],
          currentCityIds:"",
          currentCountyIds:"",
          xmtTeacherSuccessCaseEntityList:[],
          skilfulTeach: "", //擅教学生
          skilful:"",//讲课风格
          resume:"",
          hopeMinFee:"",
          hopeMaxFee:"",

      }
      this.saveRuleForm = {
        subjectIdCheck:[],
        photo: "", //图片
        name: "", //昵称
        contact: "", //联系方式
        sexId: "", //性别
        realname: "", //真实姓名
        idcard: "", //身份证号
        currentAddress: "", //籍贯
        birthday: "", //出生日期
        educationId: "", //学历
        graduationSchool: "", //毕业院校
        teachingAgeId: "", //教龄
        schoolName: "", //任教学校
        teachTitleId: "", //老师职称
        skilfulTeach: "", //擅教学生
        skilful:"",//讲课风格
        teachTrait: "", //教学特点
        periodId:[],
        sectionId: [], //教授年纪
        subjectId: "", //教授科目
        hopeMinFee: "", //课酬最小值
        hopeMaxFee: "" ,//课酬最大值
        resume:"",//辅导协议
        provinceId: "",
        cityId: "",
        countyId: "",
        currentProvinceIds:"",
        currentCityIds:"",
        currentCountyIds:[],
        teachingDirection:1,
        identityType:'1',
        authFiles:[]
      };
      this.authenRuleForm = {
        idjust: "",
        idback: "",
        teacherStudent: "", //资格认证、学生证
        education: "", //学历
        qualifications: [] //资质
      };
      this.faceRuleForm = {
        totalImage: 0,
        appearance: 0,
        connectCapacity: 0,
        lectureSpeed: 0,
        lectureStyle: 0,
        specialtySkil: 0,
        remark: "",
        peopleType: 1
      };
    },
  async  dealEdit() {
      this.uploadImg.idcard="";
      this.uploadImg.xueli="";
      this.uploadImg.qualifications="";

      this.qualDisaled=false;
      this.activeName='info1';
      this.info2Flag=false;
      this.info3Flag=false;
      this.info4Flag=false;
      this.info5Flag=false;
      this.info6Flag=false;
      this.testPapers = [];
      this.teacherQualificationCertificate=false;
      await this.getProvinceList()
      this.saveDialog = {
        title: "编辑教师",
        type: "edit"
      };
      if (this.teacherId.length != 1) {
        this.msgWarn("请选择一名教师！");
        return;
      }
      this.getTeacherId = this.teacherId[0]
      this.authenRuleForm.idjust = '';
      this.authenRuleForm.idback = '';
      this.authenRuleForm.teacherStudent = '';
      this.authenRuleForm.education = '';
      this.authenRuleForm.qualifications = '';
      this.teacherInfoAccount = ''
      this.saveRuleForm = {
        subjectIdCheck:[],
        teachingDirection:'',
        identityType:'',
        photo: "", //图片
        name: "", //昵称
        contact: "", //联系方式
        sexId: "", //性别
        realname: "", //真实姓名
        idcard: "", //身份证号
        currentAddress: "", //籍贯
        birthday: "", //出生日期
        educationId: "", //学历
        graduationSchool: "", //毕业院校
        teachingAgeId: "", //教龄
        schoolName: "", //任教学校
        teachTitleId: "", //老师职称
        skilfulTeach: "", //擅教学生
        skilful:"",//讲课风格
        teachTrait: "", //教学特点
        periodId:[],
        sectionId: [], //教授年纪
        subjectId: "", //教授科目
        hopeMinFee: "", //课酬最小值
        hopeMaxFee: "", //课酬最大值，
        classForm:"",//授课方式
        ifAcceptAudition:"",//是否接收试听
        provinceId: "",
        cityId: "",
        countyId: "",
        currentProvinceIds:"",
        currentCityIds:"",
        currentCountyIds:[],
        resume:"",
        readingStage:""
      };
      this.dataRuleForm={
          photo:"",
          name:"",
          ifAcceptAudition:"",
          classForm:"",
          currentProvinceIds:[],
          currentCityIds:"",
          currentCountyIds:"",
          xmtTeacherSuccessCaseEntityList:[],
          skilfulTeach: "", //擅教学生
          skilful:"",//讲课风格
          resume:"",
          hopeMinFee:"",
          hopeMaxFee:"",
      };
      this.faceRuleForm = {
        peopleType: 1,
        overallScore:'',
        imageTemperament:0,
        communicationExpression:0,
        substituteLessonsBeijing:0,
        teachingExperience:0,
        provinceIdOne:'',
        provinceIdTwo:'',
        otherPartnersOne:'',
        otherPartnersTwo:'',
        otherPartnersThree:'',
        // teacherType:0,

        teachingCharacteristics:0,
        jobHuntingAttitude:0,
        overallEvaluation:'',
      }
      this.switchStatus = 1;
      this.fileList = [];
      await this.getTeacherDetail();
      this.saveDialogVisible = true;
      this.getResult="";
      this.videoForm.videoUrl="";
    },
    experienceFormListFn(num){
        for(var i=0;i<this.experienceFormList.length;i++){
          if(this.experienceFormList[i].macroId==num){
            return this.experienceFormList[i].name;
          }
        }
      },
    directionChange(){
      this.teacherGradeList=[]
      this.teacherSubjectListCheck=[]
      this.saveRuleForm.sectionId=[];
      this.saveRuleForm.periodId=[];
      this.changes()
        if(this.saveRuleForm.teachingDirection==1){
          this.saveRuleForm.identityType='1'
          // this.saveRuleForm.subjectId=""
        }else{
          this.saveRuleForm.identityType='3'
          // this.subjectIdCheck=""
        }
        
      },
  async  dealRowSee(id,num){
    if(num==1){
      this.seeActiveName='see1';
    }else if(num==2){
      this.seeActiveName='see2';
    }else if(num==3){
      this.seeActiveName='see3';
    }else if(num==4){
      this.seeActiveName='see4';
    }else if(num==6){
      this.seeActiveName='see6';
    }
     this.teacherId =[];
      this.teacherId.push(id);
      
      
      await this.getProvinceList()
      this.saveRuleForm = {
        subjectIdCheck:[],
      photo: "", //图片
      name: "", //昵称
      contact: "", //联系方式
      sexId: "", //性别
      realname: "", //真实姓名
      idcard: "", //身份证号
      currentAddress: "", //籍贯
      birthday: "", //出生日期
      educationId: "", //学历
      graduationSchool: "", //毕业院校
      teachingAgeId: "", //教龄
      schoolName: "", //任教学校
      teachTitleId: "", //老师职称
      skilfulTeach: "", //擅教学生
      skilful:"",//讲课风格
      teachTrait: "", //教学特点
      periodId:[],
      sectionId: [], //教授年纪
      subjectId: "", //教授科目
      hopeMinFee: "", //课酬最小值
      hopeMaxFee: "", //课酬最大值，
      classForm:"",//授课方式
      ifAcceptAudition:"",//是否接收试听
      provinceId: "",
      cityId: "",
      countyId: "",
      currentProvinceIds:"",
      currentCityIds:"",
      currentCountyIds:[],

      };
      (this.faceRuleForm = {
      peopleType: 1,
      overallScore:'',
      imageTemperament:0,
      communicationExpression:0,
      substituteLessonsBeijing:0,
      teachingExperience:0,
      provinceIdOne:'',
      otherPartnersOne:'',
      otherPartnersTwo:'',
      otherPartnersThree:'',
      // teacherType:0,
      teachingCharacteristics:0,
      jobHuntingAttitude:0,
      overallEvaluation:'',
      }),
      (this.switchStatus = 1);
      this.fileList = [];
      await this.getTeacherDetail();
      this.seeDialogVisible = true;
      this.getResult="";
      this.videoForm.videoUrl="";
    },
     async  dealSee(row) {
      this.uploadImg.idcard="";
      this.uploadImg.xueli="";
      this.uploadImg.qualifications="";
       this.testPapers=[]
       this.getTeacherId = row.id;
      this.seeActiveName='see1';
      await this.getProvinceList()
      this.saveDialog = {
        title: "编辑教师",
        type: "edit"
      };
      // if (this.teacherId.length != 1) {
      //   this.msgWarn("请选择一名教师！");
      //   return;
      // }
      this.saveRuleForm = {
        subjectIdCheck:[],
        photo: "", //图片
        name: "", //昵称
        contact: "", //联系方式
        sexId: "", //性别
        realname: "", //真实姓名
        idcard: "", //身份证号
        currentAddress: "", //籍贯
        birthday: "", //出生日期
        educationId: "", //学历
        graduationSchool: "", //毕业院校
        teachingAgeId: "", //教龄
        schoolName: "", //任教学校
        teachTitleId: "", //老师职称
        skilfulTeach: "", //擅教学生
        skilful:"",//讲课风格
        teachTrait: "", //教学特点
        periodId:[],
        sectionId: [], //教授年纪
        subjectId: "", //教授科目
        hopeMinFee: "", //课酬最小值
        hopeMaxFee: "", //课酬最大值，
        classForm:"",//授课方式
        ifAcceptAudition:"",//是否接收试听
        provinceId: "",
        cityId: "",
        countyId: "",
        currentProvinceIds:"",
        currentCityIds:"",
        currentCountyIds:[],

      };
      (this.faceRuleForm = {
        peopleType: 1,
        overallScore:'',
        imageTemperament:0,
        communicationExpression:0,
        substituteLessonsBeijing:0,
        teachingExperience:0,
        provinceIdOne:'',
        provinceIdTwo:'',
        otherPartnersOne:'',
        otherPartnersTwo:'',
        otherPartnersThree:'',
        // teacherType:0,
        teachingCharacteristics:0,
        jobHuntingAttitude:0,
        overallEvaluation:'',
      }),
        (this.switchStatus = 1);
      this.fileList = [];
      await this.getTeacherDetail();
      this.seeDialogVisible = true;
      this.getResult="";
      this.videoForm.videoUrl="";
    },
    async  dealSeeTit() {
      this.testPapers=[]
      this.faceRuleFormAuditor={
        overallScore:"",
        imageTemperament:"",
        communicationExpression:"",
        substituteLessonsBeijing:"",
        teachingExperience:"",
        provinceIdOne:"",
        provinceIdTwo:"",
        otherPartnersOne:"",
        otherPartnersTwo:"",
        otherPartnersThree:"",
        teachingCharacteristics:"",
        jobHuntingAttitude:"",
        overallEvaluation:""
      }
      this.getFinishExamTitle ="";
      this.getFinishExamDesc = "";
      this.getFinishExam = [];
      this.seeActiveName='see1';
      this.paperTable=[];
      

      await this.getProvinceList()
      this.saveDialog = {
        title: "编辑教师",
        type: "edit"
      };
      if (this.teacherId.length != 1) {
        this.msgWarn("请选择一名教师！");
        return;
      }
      this.getTeacherId = this.teacherId[0]
      this.saveRuleForm = {
        photo: "", //图片
        name: "", //昵称
        contact: "", //联系方式
        sexId: "", //性别
        realname: "", //真实姓名
        idcard: "", //身份证号
        currentAddress: "", //籍贯
        birthday: "", //出生日期
        educationId: "", //学历
        graduationSchool: "", //毕业院校
        teachingAgeId: "", //教龄
        schoolName: "", //任教学校
        teachTitleId: "", //老师职称
        skilfulTeach: "", //擅教学生
        skilful:"",//讲课风格
        teachTrait: "", //教学特点
        periodId:[],
        sectionId: [], //教授年纪
        subjectId: "", //教授科目
        hopeMinFee: "", //课酬最小值
        hopeMaxFee: "", //课酬最大值，
        classForm:"",//授课方式
        ifAcceptAudition:"",//是否接收试听
        provinceId: "",
        cityId: "",
        countyId: "",
        currentProvinceIds:"",
        currentCityIds:"",
        currentCountyIds:[],
        readingStage:""

      };
      (this.faceRuleForm = {
        peopleType: 1,
        overallScore:'',
        imageTemperament:0,
        communicationExpression:0,
        substituteLessonsBeijing:0,
        teachingExperience:0,
        provinceIdOne:'',
        provinceIdTwo:'',
        otherPartnersOne:'',
        otherPartnersTwo:'',
        otherPartnersThree:'',
        // teacherType:0,
        teachingCharacteristics:0,
        jobHuntingAttitude:0,
        overallEvaluation:'',
      }),
        (this.switchStatus = 1);
      this.fileList = [];
      await this.getTeacherDetail();
      this.seeDialogVisible = true;
      this.getResult="";
      this.videoForm.videoUrl="";
    },
    //重置密码
    dealReset() {
      if (this.teacherId.length > 1) {
        this.msgWarn("请选择单个用户进行密码重置操作！");
        return;
      }
      if (this.teacherId.length == 0) {
        this.msgWarn("请选择一个用户进行密码重置操作！");
        return;
      }
      this.$confirm("用户的密码将重置为手机号的后六位, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          var XmtTeacherEntity = {
            contact: this.teacherPhone[0],
            id: this.teacherId[0]
          };
          teacherResetpwd(XmtTeacherEntity).then(res => {
            if (res.code == 0) {
              this.msgSuccess("密码修改成功！");
            }
          });
        })
        .catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });
        });
    },
    getProvinceList(){
       return systemAreaList({
        parentId: this.parentId
      }).then(res => {
        if (res.code == 0) {
          this.provinceList = res.rows;

          this.provinceLessonList=res.rows;
          this.currentProvinceData=res.rows;
          this.listProvinceData=res.rows;
        }
      });
    },
    searchAnswer(){
      this.teacherQuestionList();
    },
    dealVideo(id) {
      this.authenDialogVideo = true;
    },
    confirmSaveup(formName) {
      this.dataRuleForm.exhibitionPhotos=this.exhibitionPhotos;
      if(this.dataRuleForm.classForm=='0'||this.dataRuleForm.classForm=='2'){
        if(this.dataRuleForm.currentProvinceIds){
          if(this.dataRuleForm.currentCountyIds.length<1){
          this.msgWarn('请填写完整面授地点！');
          return false;
        }
        }
      }else{
        if(this.dataRuleForm.currentProvinceIds){
          if(this.dataRuleForm.currentCountyIds.length<1){
            this.dataRuleForm.currentProvinceIds="";
            this.dataRuleForm.currentCityIds="";
            this.dataRuleForm.currentCountyIds=[];
          }
        }
      }
     this.dataRuleForm.xmtTeacherSuccessCaseEntityList=new Array();
      if(this.caseList&&this.saveRuleForm.teachingDirection==1){
        this.caseList.forEach( item => {
          let { studentAchievement,classTimeBegin, classTimeEnd, studentSubjectId, studentSectionId, studentName, photoList, detailContent } = item
          this.dataRuleForm.xmtTeacherSuccessCaseEntityList.push({
            classTimeBegin,
            classTimeEnd,
            studentSubjectId,
            studentSectionId,
            studentName,
            photos: photoList,
            detailContent,
            studentAchievement
          })
        })
      }else if(this.daikeList&&this.saveRuleForm.teachingDirection==2){
      
      console.log(this.daikeList,'daikePhotoList..')
      this.daikeList.forEach( item => {
          let { studentAchievement,classTimeBegin, classTimeEnd, studentSubjectId, studentSectionId, studentName, photoList, detailContent } = item
          this.dataRuleForm.xmtTeacherSuccessCaseEntityList.push({
            classTimeBegin,
            classTimeEnd,
            studentSubjectId,
            studentSectionId,
            studentName,
            photos: photoList,
            detailContent,
            studentAchievement
          })
        })
      }
      this.dataRuleForm.skilfulTeach = this.skilfulTeachArray.join(); //擅教学生
      this.dataRuleForm.skilful = this.skilfulArray.join(); //讲课风格
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.dataRuleForm.id=this.getTeacherId
          if (this.saveDialog.type == "add") {
            
            teacherUpdate(this.dataRuleForm).then(res => {
              if (res.code == 0) {
                this.msgSuccess("操作成功！");
                this.activeName='info3';
                this.info3Flag=false;
                // this.switchStatus = 3;
                this.getteacherManageList();
              }
            });
          } else if (this.saveDialog.type == "edit") {
            teacherUpdate(this.dataRuleForm).then(res => {
              if (res.code == 0) {
                this.msgSuccess("操作成功！");
                this.activeName='info3';
              }
            });
          }
        } else {
          this.msgWarn("必填字段不能为空！");
          return false;
        }
      });
    },
    zuopinSelectClick(){
      this.$refs.zuopinuploadImg.value = "";
      this.$refs.zuopinuploadImg.click();
    },
    zuopinSelectChange(){
      if(this.exhibitionPhotos.length==8){
        this.msgWarn("最多只能上传八张图片！");
        return;
      }
      if (
        !/\.(png|jpg|gif|jpeg|webp)$/.test(this.$refs.zuopinuploadImg.files[0].name)
      ) {
        this.msgWarn("只能是图片格式的文件！");
        return;
      }
      let formData = new FormData();
      formData.append("file", this.$refs.zuopinuploadImg.files[0]);
      fileUploads(formData).then((uploadRes) => {
        if (uploadRes[0].url) {
          this.exhibitionPhotos.push(uploadRes[0].url)
        }
      });
    },
    handleRemove(file) {
      for (const i in this.videoformArray) {
        if (this.videoformArray[i].fileUrl === file.url) {
            this.videoformArray.splice(i,1);
        }
      }
    },
    clickChange(item) {
      this.tableRadio = item;
    },
    choiceSelectionChange(val){
      console.log(val)
       this.answerId=[];
        if(val.length>0){
            for(let i=0,len=val.length;i<len;i++){
              this.answerId.push(val[i].id)
            }
        }
    },
    choiceItemClick(row){
      this.$refs.productListTable.toggleRowSelection(row);
    },
    sureChoice(){
      this.choiceProblemPopup=false;
      this.testTitle2(this.tableRadio)
    },
    testTitle2(row){
              var regex = /(<([^>]+)>)/ig
              var result = "<div class='quesborder'>"+row.title+"";
                    if (row.optionA) {
                        result += "<p style='padding:5px 0;margin-top:10px;'>A." + row.optionA.replace(regex,'') + "</p>";
                    }
                    if (row.optionB) {
                        result += "<p style='padding:5px 0;'>B." + row.optionB.replace(regex,'') + "</p>";
                    }
                    if (row.optionC) {
                        result += "<p style='padding:5px 0;'>C." + row.optionC.replace(regex,'') + "</p>";
                    }
                    if (row.optionD) {
                        result += "<p style='padding:5px 0;'>D." + row.optionD.replace(regex,'') + "</p>";
                    }
                    if (row.optionE) {
                        result += "<p style='padding:5px 0;'>E." + row.optionE.replace(regex,'') + "</p>";
                    }
                    if (row.answer1) {
                      var tempAnswer1 = row.answer1;
                      if(tempAnswer1 && tempAnswer1.startsWith("<p style='padding:10px 0;'>") && tempAnswer1.endsWith("</p>")){
                                      row.answer1 = tempAnswer1.substring(3,tempAnswer1.length-4);
                      }
                    }
                    result += "</div>";
                    this.getResult=result;
                    return result;
            },
    checkboxHtml(row,type){
      var regex = /(<([^>]+)>)/ig
              var result = "<div>";
                    if (type==1) {
                        result += "<p>A." + row.optionA.replace(regex,'') + "</p>";
                    }
                    if (type==2) {
                        result += "<p>B." + row.optionB.replace(regex,'') + "</p>";
                    }
                    if (type==3) {
                        result += "<p>C." + row.optionC.replace(regex,'') + "</p>";
                    }
                    if (type==4) {
                        result += "<p>D." + row.optionD.replace(regex,'') + "</p>";
                    }
                    if (type==5) {
                        result += "<p>E." + row.optionE.replace(regex,'') + "</p>";
                    }
                    result += "</div>";
                    return result;
    } ,       
    testTitle(row){
              var regex = /(<([^>]+)>)/ig
              var result = "<div class='quesborder' style='margin-top:30px;'><p>"+row.title+"</p><br/>";
                    if (row.optionA) {
                        result += "<p>A." + row.optionA.replace(regex,'') + "</p>";
                    }
                    if (row.optionB) {
                        result += "<p>B." + row.optionB.replace(regex,'') + "</p>";
                    }
                    if (row.optionC) {
                        result += "<p>C." + row.optionC.replace(regex,'') + "</p>";
                    }
                    if (row.optionD) {
                        result += "<p>D." + row.optionD.replace(regex,'') + "</p>";
                    }
                    if (row.optionE) {
                        result += "<p>E." + row.optionE.replace(regex,'') + "</p>";
                    }
                    if (row.answer1) {
                      var tempAnswer1 = row.answer1;
                      if(tempAnswer1 && tempAnswer1.startsWith("<p>") && tempAnswer1.endsWith("</p>")){
                                      row.answer1 = tempAnswer1.substring(3,tempAnswer1.length-4);
                      }
                        result += "<p style='color: red;font-weight: bolder'>【答案】："+row.answer1.replace(eval("/\\$\\#\\#\\#\\$/g"),"&nbsp;&nbsp;")+"</p><br/>"
                    }
                    if (row.answer2) {
                        result += "<p>"+row.answer2+"</p><br/>"
                    }
                    if (row.parse) {
                        result += "<p>"+row.parse+"</p>"
                    }
                    result += "</div>";
                    return result;
            },
    choiceTeacherProblem(){
      this.choiceProblemPopup=true;
      this.questionsListFn();
    },
    questionsListFn(){
       questionsList(this.subjectParams).then((res) => {
          this.choiceList=res.rows;
          this.choiceTotal=res.total;
      });
    },
    choiceSizeChange(pageSize){
      this.subjectParams.pageSize = pageSize;
      this.questionsListFn();
    },
    choiceCurrentChange(currentPage){
      this.subjectParams.pageNumber = currentPage;
      this.questionsListFn();
    },
    
    
    updateEquipment(row){
      if(row.isEquipment==0){
         teacherUpdateEquipment({id:row.id,isEquipment:1}).then(res => {
        if (res.code == 0) {
          this.getteacherManageList();
        }
      });
      }else{
         teacherUpdateEquipment({id:row.id,isEquipment:0}).then(res => {
          if (res.code == 0) {
            this.getteacherManageList();
          }
        });
      }
     
    },
    handlePreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 10 个文件，本次选择了 ${
          files.length
        } 个文件，共选择了 ${files.length + fileList.length} 个文件`
      );
    },
    //获取详情
    getTeacherDetail() {
      //获取基本信息
      teacherDetail(this.getTeacherId).then(res => {
        if (res.code == 0) {
          var getRows = res.rows;
          this.teacherInfo.realname=getRows.realname;
          this.teacherInfo.idcard=getRows.idcard;
          //教授学生赋值
          if(getRows.skilfulTeach === null){
            getRows.skilfulTeach = ''
          }
          if(getRows.skilful === null){
            getRows.skilful = ''
          }
          this.skilfulTeachArray = getRows.skilfulTeach
            .split(",")
            .map(function(item, index, array) {
              return item - 0;
            });
              //讲课风格
          this.skilfulArray = getRows.skilful
            .split(",")
            .map(function(item, index, array) {
              return item - 0;
            });
             //教受学段赋值
             if(getRows.periodId!=null){
                this.periodId = getRows.periodId
                .split(",")
                .map(function(item, index, array) {
                  return item - 0;
                });
             }else{
               this.periodId=[]
             }
          //教授年纪赋值
          this.sectionId = getRows.sectionId?getRows.sectionId:''
            .split(",")
            .map(function(item, index, array) {
              return item - 0;
            });
            this.changes();
          this.teacherInfoAccount = getRows.account
          this.saveRuleForm = {
            readingStage:getRows.readingStage,
            photo: getRows.photo, //图片
            name: getRows.name, //昵称
            contact: getRows.contact, //联系方式
            sexId: getRows.sexId, //性别
            realname: getRows.realname, //真实姓名
            idcard: getRows.idcard, //身份证号
            currentAddress: getRows.currentAddress, //籍贯
            birthday: getRows.birthday, //出生日期
            educationId: getRows.educationId, //学历
            graduationSchool: getRows.graduationSchool, //毕业院校
            teachingAgeId: getRows.teachingAgeId, //教龄
            schoolName: getRows.schoolName, //任教学校
            teachTitleId: getRows.teachTitleId, //老师职称
            skilful: getRows.skilful, //讲课风格
            skilfulTeach:getRows.skilfulTeach,//擅教学生
            teachTrait: getRows.teachTrait, //教学特点
            periodId:[],
            sectionId:[],
            subjectIdCheck:[],
            // sectionId: getRows.sectionId, //教授年纪
            subjectId: Number(getRows.subjectId), //教授科目
            hopeMinFee: getRows.hopeMinFee, //课酬最小值
            hopeMaxFee: getRows.hopeMaxFee, //课酬最大值
            identityType: getRows.identityType,
            classForm:Number(getRows.classForm),//授课方式
            ifAcceptAudition:getRows.ifAcceptAudition,//是否接收试听
            provinceId: res.rows.provinceId,
            cityId: res.rows.cityId,
            countyId: res.rows.countyId,
            currentProvinceIds:res.rows.currentProvinceIds?Number(res.rows.currentProvinceIds):'',
            currentCityIds:res.rows.currentCityIds?Number(res.rows.currentCityIds):'',
            currentCountyIds:res.rows.currentCountyIdsList,
            provinceName:res.rows.provinceName,
            cityName:res.rows.cityName,
            countyName:res.rows.countyName,
            currentProvinceName:res.rows.currentProvinceName,
            currentCountyName:res.rows.currentCountyName,
            currentCityName:res.rows.currentCityName,
            resume:res.rows.resume,
            currentProvincesName:res.rows.currentProvincesName,
            currentCitysName:res.rows.currentCitysName,
            isBasicdata:res.rows.isBasicdata,
            capesAuthStatus:res.rows.capesAuthStatus,
            isInterview:res.rows.isInterview,
            videoAuthStatus:res.rows.videoAuthStatus,
            teachingDirection:res.rows.teachingDirection,
            identityType:String(res.rows.identityType)
          };
          this.dataRuleForm={
              resume:res.rows.resume,
              skilful: getRows.skilful, //讲课风格
              skilfulTeach:getRows.skilfulTeach,//擅教学生
              photo:getRows.photo,
              name:getRows.name,
              ifAcceptAudition:getRows.ifAcceptAudition,
              classForm:Number(getRows.classForm),
              currentCityIds:res.rows.currentCityIds?Number(res.rows.currentCityIds):'',
              currentProvinceIds:res.rows.currentProvinceIds?Number(res.rows.currentProvinceIds):'',
              // realname: getRows.realname, //真实姓名
              xmtTeacherSuccessCaseEntityList:[],
              hopeMinFee: getRows.hopeMinFee, //课酬最小值
              hopeMaxFee: getRows.hopeMaxFee, //课酬最大值
              teachTrait: getRows.teachTrait, //教学特点
              currentCountyIds:res.rows.currentCountyIdsList,
              currentProvincesName:res.rows.currentProvincesName,
              currentCitysName:res.rows.currentCitysName
          }
          if(getRows.teachingDirection==1&&getRows.identityType==1){
            }else{
              this.saveRuleForm.subjectIdCheck=getRows.subjectId.split(',').map((item) => item * 1)
          }
         this.exhibitionPhotos=res.rows.exhibitionPhotoList;
          this.saveRuleForm.imageArray = new Array();
          this.saveRuleForm.imageArray.push(res.rows.photo)
          var CountysName=""
          if(res.rows.currentCountysName.length){
            for(let i=0;i<res.rows.currentCountysName.length;i++){
              CountysName+=res.rows.currentCountysName[i].NAME+"，"
            }
          }
          this.getCurrentCountysName=CountysName.substring(0,CountysName.length-1);
          var periodIdArr=[];
          periodIdArr=res.rows.periodId?res.rows.periodId.split(","):[];
          this.saveRuleForm.periodId=periodIdArr.map(Number);
          var sectionIdArr=[];
          sectionIdArr = res.rows.sectionId?res.rows.sectionId.split(","):[];
          this.saveRuleForm.sectionId=sectionIdArr.map(Number);
          if (this.saveRuleForm.provinceId) {
            this.getCityLists();
          }
          if (this.saveRuleForm.cityId) {
            this.getCountyList();
          }
          if (this.saveRuleForm.currentProvinceIds) {
            this.currentCityLists();
          }
          if (this.saveRuleForm.currentCityIds) {
            this.currentCountyList();
          }
          if(this.saveRuleForm.teachingDirection==1){
            this.caseList=getRows.xmtTeacherSuccessCaseEntityList;
          }else{
            this.daikeList=getRows.xmtTeacherSuccessCaseEntityList;
          }
          this.aa=Number(getRows.subjectId);
          this.getGradeSubjectList()
        }
      });
      this.videoformArray=[];
      teacherFindAuthFile(this.getTeacherId).then(res => {
        this.srcList=[];
        this.srcList2=[];
        this.srcList3=[];
        var getData = res.rows.authFiles;
        this.authFilesEdit=res.rows.authFiles;
        if (res.code == 0) {
          for (var i = 0; i < getData.length; i++) {
            if (getData[i].fileType == 6) {
              //视频
              this.videoForm.videoUrl = getData[i].fileUrl;
              this.videoformArray.push(getData[i]);
            }
            if (getData[i].fileType == 5) {
              this.videoformArray.push(getData[i]);
              this.urlObj.url = getData[i].fileUrl;
              this.urlArr.push(getData[i].fileUrl);
              this.fileList.push(this.urlObj);
              this.getResult=getData[i].title;
              this.urlObj = {};
            }
            if(getData[i].fileType==1&&getData[i].fileUrl!=null){//身份证
                this.uploadImg.idcard=getData[i].fileUrl;
            }
            if(getData[i].fileType==2){//资格认证
              this.uploadImg.qualifications= getData[i].fileUrl;
              if(getData[i].teacherQualificationCertificate==1){
                this.teacherQualificationCertificate=true;
              }else{
                this.teacherQualificationCertificate=false;
                if(this.uploadImg.qualifications){
                  this.qualDisaled=true;
                }else{
                  this.qualDisaled=false;
                }
              }
            }
            if(getData[i].fileType==3){//学历认证
                this.uploadImg.xueli=getData[i].fileUrl;
            }
            if(getData[i].fileType==4){//专业资质
                this.authenRuleForm.qualifications = getData[i].fileUrl;
            }else{
              this.authenRuleForm.qualifications=""
            }
          }
          this.authenRuleJson = {
            idjust: {
              fileType: 1,
              fileUrl: this.authenRuleForm.idjust,
              teacherId: this.getTeacherId
            },
            idback: {
              fileType: 1,
              fileUrl: this.authenRuleForm.idback,
              teacherId: this.getTeacherId
            },
            teacherStudent: {
              fileType: 2,
              fileUrl: this.authenRuleForm.teacherStudent,
              teacherId: this.getTeacherId
            },
            education: {
              fileType: 3,
              fileUrl: this.authenRuleForm.education,
              teacherId: this.getTeacherId
            },
            qualifications: {
              fileType: 4,
              fileUrl: this.authenRuleForm.qualifications,
              teacherId: this.getTeacherId
            }
          };
          let getTestPapers=this.authenRuleForm.qualifications ? this.authenRuleForm.qualifications.split(',') : [];
          this.previewImage=this.authenRuleForm.qualifications ? this.authenRuleForm.qualifications.split(',') : [];
          if(this.authenRuleForm.qualifications!=''){
          
                 getTestPapers.forEach(item => {  //处理图片需要转格式
                  this.testPapers.push({
                    "url": item,
                  })
                })
               }else{
               
                 this.testPapers=[];
               }
        }
      });
      this.mackData = {
        teacherId: this.getTeacherId,
        peopleType: 1
      };
      this.mackData2 = {
        teacherId: this.getTeacherId,
        peopleType: 2
      };
      teacherFaceMarkDetail(this.mackData).then(res => {
        if (res.rows == "空数组"||res.rows == "无数据") {
          this.faceType = 0;
        } else {
          this.faceRuleForm.id=res.rows.id;
          this.faceType = 1;
          this.faceRuleForm.overallEvaluation=res.rows.overallEvaluation?res.rows.overallEvaluation:'';
          this.faceRuleForm.overallScore=String(res.rows.overallScore);
          this.faceRuleForm.imageTemperament=String(res.rows.imageTemperament);
          this.faceRuleForm.communicationExpression=String(res.rows.communicationExpression);
          this.faceRuleForm.substituteLessonsBeijing=String(res.rows.substituteLessonsBeijing);
          this.faceRuleForm.teachingExperience=String(res.rows.teachingExperience);
          this.faceRuleForm.provinceIdOne=res.rows.provinceIdOne?Number(res.rows.provinceIdOne):'';
          this.faceRuleForm.provinceIdTwo=res.rows.provinceIdTwo?Number(res.rows.provinceIdTwo):'';
          this.faceRuleForm.otherPartnersOne=res.rows.otherPartnersOne;
          this.faceRuleForm.otherPartnersTwo=res.rows.otherPartnersTwo;
          this.faceRuleForm.otherPartnersThree=res.rows.otherPartnersThree;
          this.faceRuleForm.jobHuntingAttitude=String(res.rows.jobHuntingAttitude);
          this.faceRuleForm.teachingCharacteristics=String(res.rows.teachingCharacteristics);
        }
      });
       teacherFaceMarkDetail(this.mackData2).then(res => {
        if (res.rows == "空数组"||res.rows =="无数据") {
          this.faceType = 0;
        } else {
          this.faceRuleFormAuditor.id=res.rows.id;
          this.faceType = 1;
         this.faceRuleFormAuditor.overallEvaluation=res.rows.overallEvaluation?res.rows.overallEvaluation:'';
          this.faceRuleFormAuditor.overallScore=String(res.rows.overallScore);
          this.faceRuleFormAuditor.imageTemperament=String(res.rows.imageTemperament);
          this.faceRuleFormAuditor.communicationExpression=String(res.rows.communicationExpression);
          this.faceRuleFormAuditor.substituteLessonsBeijing=String(res.rows.substituteLessonsBeijing);
          this.faceRuleFormAuditor.teachingExperience=String(res.rows.teachingExperience);
          this.faceRuleFormAuditor.provinceIdOne=res.rows.provinceIdOne?Number(res.rows.provinceIdOne):'';
          this.faceRuleFormAuditor.provinceIdTwo=res.rows.provinceIdTwo?Number(res.rows.provinceIdTwo):'';
          this.faceRuleFormAuditor.otherPartnersOne=res.rows.otherPartnersOne;
          this.faceRuleFormAuditor.otherPartnersTwo=res.rows.otherPartnersTwo;
          this.faceRuleFormAuditor.otherPartnersThree=res.rows.otherPartnersThree;
          this.faceRuleFormAuditor.jobHuntingAttitude=String(res.rows.jobHuntingAttitude);
          this.faceRuleFormAuditor.teachingCharacteristics=String(res.rows.teachingCharacteristics);
        }
      });
      this.answerobj = {
        teacherId: this.getTeacherId
      };
      teacherQuestionAnswer(this.getTeacherId).then(res => {
        if (res.code == 0) {
          this.takeAwayArray = res.rows;
          if(res.rows.length==0){
            this.takeFlag=false;
          }else{
            this.takeFlag=true;
          }
        }
      });
    },
    photoBeforeAvatarUpload(file){
      let types = ['image/jpeg', 'image/jpg', 'image/png'];
      const isImage = types.includes(file.type);
      if (!isImage) {
        this.$message.error('上传图片只能是 JPG、JPEG、PNG 格式!');
        return false;
      }
    },
    handVideoSuccess(res, file) {
      this.videoformArray.push({
        fileType: 5,
        fileUrl: res[0].url
      });
    },
    isDisabled(){
      if(this.saveDialog.type=='edit'){
        return false;
      }else{
         return true;
      }
    }, 
    //视频确定
    confirmSaveVideo() {          
      if(this.videoForm.videoUrl==''){
        this.msgWarn("视频不能为空");
        return;
      }
      if (this.saveDialog.type == "add") {
        for (var i = 0; i < this.videoformArray.length; i++) {
          this.videoformArray[i].teacherId = this.getTeacherId;
           this.videoformArray[i].title = this.getResult;
        }
        teacherUpdateAuthFilee(this.videoformArray).then(res => {
          if (res.code == 0) {
            this.msgSuccess("操作成功！");
            this.activeName='info6';
            this.info5Flag=false;
            this.getteacherManageList();
          }
        });
      } else if (this.saveDialog.type == "edit") {
        for (var i = 0; i < this.videoformArray.length; i++) {
          this.videoformArray[i].teacherId = this.getTeacherId;
          this.videoformArray[i].title = this.getResult;
        }
        teacherUpdateAuthFilee(this.videoformArray).then(res => {
          if (res.code == 0) {
            this.msgSuccess("操作成功！");
            // this.saveDialogVisible = false;
            this.fileList = [];
             this.activeName='info6';
            this.info5Flag=false;
            this.finishPaperList();
            this.getteacherManageList();
          }
        });
      }
    },
    switchTitle(val) {
      this.switchStatus = val;
      if (val == 4) {
        this.findPaperQuestionsByTeacherIds();
      }
    },
    getTeachTitle() {
      getSelectList({
        parentCode: teachTitle
      }).then(res => {
        if (res.code == 0) {
          this.teachTitleList = res.rows;
        }
      });
    },
    handleSelectionChange(val) {
      this.teacherId = [];
      this.teacherPhone = [];
      if (val.length > 0) {
        for (let i = 0, len = val.length; i < len; i++) {
          this.teacherId.push(val[i].id);
          this.teacherPhone.push(val[i].account);
        }
      }
    },
    getteacherManageList() {
      let data = {
        name: this.listParams.name,
        sectionId:
          this.listParams.sectionId == "" ? "" : this.listParams.sectionId, //年级
        subjectId:
          this.listParams.subjectId == "" ? "" : this.listParams.subjectId, //学科
        identityType:
          this.listParams.identityType == "0"
            ? null
            : this.listParams.identityType, //身份
        teachTitleId:
          this.listParams.teachTitleId == ""
            ? null
            : String(this.listParams.teachTitleId), //职称
        teachingAgeId:
          this.listParams.teachingAgeId == ""
            ? null
            : this.listParams.teachingAgeId, //教龄
        pageNumber: this.listParams.pageNumber,
        pageSize: this.listParams.pageSize,
        currentProvinceIds:this.listParams.currentProvinceIds == "" ? "" : this.listParams.currentProvinceIds, 
        currentCityIds:this.listParams.currentCityIds == "" ? "" : this.listParams.currentCityIds,
        currentCountyIds:this.listParams.currentCountyIds == "" ? "" : this.listParams.currentCountyIds
      };
      teacherManageList(data).then(res => {
        this.listArray = res.rows;
        this.total = res.total;
      });
    },
    handleSizeChange(pageSize) {
      this.listParams.pageSize = pageSize;
      this.getteacherManageList();
    },
    handleCurrentChange(currentPage) {
      this.listParams.pageNumber = currentPage;
      this.getteacherManageList();
    },
     handleSizeChange2(pageSize) {
      this.answerObject.pageSize = pageSize;
      this.teacherQuestionList();
    },
    handleCurrentChange2(currentPage) {
      this.answerObject.pageNumber = currentPage;
      this.teacherQuestionList();
    },
    confirmSave(formName) {
       this.isDisable = true;
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.saveRuleForm.authFiles=[]
          if(this.uploadImg.idcard){
              this.saveRuleForm.authFiles.push({
                fileType:1,
                fileUrl:this.uploadImg.idcard,
                teacherId:this.saveDialog.type == "add" ? '' : this.getTeacherId
              })
            }
              this.saveRuleForm.authFiles.push({
                fileType:2,
                fileUrl:this.uploadImg.qualifications,
                teacherQualificationCertificate:this.teacherQualificationCertificate==true?1:this.uploadImg.qualifications?2:0,
                teacherId:this.saveDialog.type == "add" ? '' : this.getTeacherId
              })
            if(this.uploadImg.xueli){
              this.saveRuleForm.authFiles.push({
                fileType:3,
                fileUrl:this.uploadImg.xueli,
                teacherId:this.saveDialog.type == "add" ? '' : this.getTeacherId
              })
            }
            if(this.testPapers.length){
              var url = this.testPapers.map(function(obj,index){
                  return obj.url;
              }).join(",");
              this.saveRuleForm.authFiles.push({
                fileType:4,
                fileUrl:url,
                teacherId:this.saveDialog.type == "add" ? '': this.getTeacherId
              })
            }
          if (this.saveDialog.type == "add") {
            this.saveRuleForm2=Object.assign({}, this.saveRuleForm)
            if(this.saveRuleForm.periodId.length){
                this.saveRuleForm2.periodId=this.saveRuleForm.periodId.join()
                this.saveRuleForm.periodId=this.saveRuleForm.periodId
            }else{
             this.saveRuleForm2.periodId=""
            }
            if(this.saveRuleForm.sectionId.length){
              this.saveRuleForm2.sectionId = this.saveRuleForm.sectionId.join()
              this.saveRuleForm.sectionId=this.saveRuleForm.sectionId;
            }else{
              this.saveRuleForm2.sectionId=""
            }
             if(this.saveRuleForm.teachingDirection==1&&this.saveRuleForm.identityType==1){
              
            }else{
              this.saveRuleForm2.subjectId=this.saveRuleForm.subjectIdCheck.join();
            }
            
            teacherSave(this.saveRuleForm2).then(res => {
              if (res.code == 0&&typeof(res.rows)=='number') {
                this.msgSuccess("操作成功！");
                this.getTeacherId = res.rows;
                this.activeName='info2'
                this.info2Flag=false;
                this.getteacherManageList();
                this.isDisable = false
              }else{
                this.msgWarn(res.rows);
                this.isDisable = false
              }
            });
          } else if (this.saveDialog.type == "edit") {
            
            this.saveRuleForm.id = this.getTeacherId;
            this.saveRuleForm2=Object.assign({}, this.saveRuleForm)
            if(this.saveRuleForm.periodId.length){
                this.saveRuleForm2.periodId=this.saveRuleForm.periodId.join()
                this.saveRuleForm.periodId=this.saveRuleForm.periodId
            }else{
              this.saveRuleForm2.periodId=""
            }
            if(this.saveRuleForm.sectionId.length){
              this.saveRuleForm2.sectionId = this.saveRuleForm.sectionId.join()
              this.saveRuleForm.sectionId=this.saveRuleForm.sectionId;
            }else{
              this.saveRuleForm2.sectionId=""
            }
            if(this.saveRuleForm.teachingDirection==1&&this.saveRuleForm.identityType==1){
              
            }else{
              this.saveRuleForm2.subjectId=this.saveRuleForm.subjectIdCheck.join();
            }
            teacherUpdate(this.saveRuleForm2).then(res => {
              if (res.code == 0) {
                this.msgSuccess("操作成功！");
                this.activeName='info2'
                this.isDisable = false
                // this.saveDialogVisible = false;
              }else{
                this.msgWarn(res.rows);
                this.isDisable = false
              }
            });
          }
        } else {
          this.isDisable = false
          this.msgWarn("必填字段不能为空！");
          return false;
        }
      });
    },
    refreshClick() {
      this.reload();
    },
    studentItemClick(row) {
      this.$refs.productListTable.toggleRowSelection(row);
    },
    getSubjectList() {
      return getSelectList({
        parentCode: subjectCode
      }).then(res => {
        if (res.code == 0) {
          this.subjectList = res.rows;
          this.subjectList.unshift({
            macroId: "",
            name: "全部"
          });
        }
      });
    },
    getSubjectList2() {
      return getSelectList({
        parentCode: subjectCode
      }).then(res => {
        if (res.code == 0) {
          this.subjectList2 = res.rows;
          
        }
      });
    },
    //新增跟进记录
    addFollowUpClick() {
      this.followUpDialog = {
        title: "添加案例",
        type: "save"
      };
      this.followUpDialogVisible = true;
    },
    getSectionList() {
      getSelectList({
        parentCode: sectionCode
      }).then(res => {
        if (res.code == 0) {
          this.sectionList = res.rows;
          this.sectionList_clean = JSON.parse(JSON.stringify(res.rows));
           this.gradeidList = res.rows;
          this.sectionList.unshift({
            macroId: "",
            name: "全部"
          });
        }
      });
    },
    parentCodesFn(){
      parentCodes({
        parentCode: readingStage
      }).then(res => {
        if (res.code == 0) {
         this.stageList=res.rows;
        }
      });
      //获取学生类型
      parentCodes({
        parentCode: qualityStudentsType
      }).then(res => {
        if (res.code == 0) {
         this.experienceFormList=res.rows;
        }
      });
    },
    //获取学段
    sectionChange(){
      parentCodes({
        parentCode: aisciplineProfessorSection
      }).then(res => {
        if (res.code == 0) {
         this.teacherSectionList=res.rows;
        }
      });
    },
    //风格偏好
    getTeachStyle(){
      getSelectList({
        parentCode: teachStyle
      }).then(res => {
        if (res.code == 0) {
          this.skilfulDate=res.rows;
        }
      });
      //擅教学生
      getSelectList({
        parentCode: achievement
      }).then(res => {
        if (res.code == 0) {
          this.skilfulTeachDate=res.rows;
        }
      });
    },
     getSectionList2() {
      getSelectList({
        parentCode: sectionCode
      }).then(res => {
        if (res.code == 0) {
          this.sectionList2 = res.rows;
        }
      });
    },
    getPhaseList() {
      getSelectList({
        parentCode: phaseCode
      }).then(res => {
        if (res.code == 0) {
          this.phaseList = res.rows;
          this.phaseList.unshift({
            macroId: null,
            name: "全部"
          });
        }
      });
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.status == 0) {
        return "warning-row";
      }
      return "";
    },
    //面试评分
    confirmFace(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.saveDialog.type == "add") {
            this.faceRuleForm.teacherId = this.getTeacherId;
            teacherFaceMarkSave(this.faceRuleForm).then(res => {
              if (res.code == 0) {
                this.msgSuccess("操作成功！");
                this.saveDialogVisible = false;
                this.getteacherManageList();
              }
            });
          } else if (this.saveDialog.type == "edit") {
            this.faceRuleForm.teacherId = this.getTeacherId;
            if (this.faceType == 1) {
              teacherFaceMarkUpdate(this.faceRuleForm).then(res => {
                if (res.code == 0) {
                  this.msgSuccess("操作成功！");
                  this.saveDialogVisible = false;
                  this.getteacherManageList();
                }
              });
            } else {
              teacherFaceMarkSave(this.faceRuleForm).then(res => {
                if (res.code == 0) {
                  this.msgSuccess("操作成功！");
                  this.saveDialogVisible = false;
                  this.getteacherManageList();
                }
              });
            }
          }
        } else {
          this.msgWarn("必填字段不能为空！");
          return false;
        }
      });
    },
    //选择问题
    choiceProblem() {
      this.answerObject.question='';
      this.answerObject.periodId='';
      this.problemPopup = true;
      this.teacherQuestionList();
    },
    teacherQuestionList() {
      teacherQuestionList(this.answerObject).then(res => {
        this.answerArray = res.rows;
        this.answerSync = false;
        this.total2=res.total;
      });
    },
    checkSelectable(row){
      let flag = false;
      for (let i = 0, len = this.takeAwayArray.length; i < len; i++) {
        if (this.takeAwayArray[i].questionId == row.id) {
          flag = true;
          break;
        }
      }
      if (!flag) {
        return true;
      }
      return false;
    },
    //添加成功案例
    addCase(){
      this.hideUpload=false;
      this.casePopup=true;
      this.caseUpDialog.title='添加案例';
      this.caseUpDialog.type="save";
      this.caseRuleForm={
        studentName:"",
        detailContent:"",//详情介绍
        studentSectionId:"",//年级
        studentSubjectId:this.aa,//科目
        classTimeEnd:null,
        classTimeBegin:null,
        photos: [],
      }
      this.casePickerValue1='';
      this.casePickerValue2='';
    },
    //添加代课经历
    addExperience(){
      this.experienceDialog={
        title:'添加代课经历',
        type:'save'
      }
      this.experienceForm={
        studentName:"",
        studentAchievement:"",
        classTimeBegin:"",
        classTimeEnd:"",
        detailContent:"",
        photos:[]
      }
      this.ecasePickerValue1=""
      this.ecasePickerValue2=""
      this.daikePhotoList=[];
      this.experienceImgUpload=false;
      this.experienceShow=true;
    },
    answerSelectionChange(val) {
      this.takeAwayArray2 = val;
      this.answerId = [];
      if (val.length > 0) {
        for (let i = 0, len = val.length; i < len; i++) {
          this.answerId.push(val[i].id);
        }
      }
    },
    answerRowClick(row) {
      this.$refs.answerListTable.toggleRowSelection(row);
    },
    //面试答题添加
    answerAdd() {
      this.answerRuleForm.question = "";
      this.answerRuleForm.periodId = "";
      this.answerSync = true;
      this.answerDialog = {
        title: "新增面试答题",
        type: "add"
      };
    },
    closeSaveDialog(){
      this.getteacherManageList();
    },
    //面试答题编辑
    answerEdit() {
      if (this.answerId.length > 1 || this.answerId.length == 0) {
        this.msgWarn("请选择一条数据进行编辑操作！");
        return;
      }
      this.answerSync = true;
      this.answerDialog = {
        title: "编辑面试答题",
        type: "edit"
      };
      this.answerRuleForm.question = "";
      this.answerRuleForm.periodId = "";
      teacherQuestionInfo(this.answerId[0]).then(res => {
        console.log(res);
        if (res.code == 0) {
          this.answerRuleForm.question = res.rows.question;
          this.answerRuleForm.periodId = res.rows.periodId;
        }
      });
    },
    //面试答题删除
    answerDelete() {
      if (this.answerId.length == 0) {
        this.msgWarn("请至少选择一条数据！");
        return;
      }
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          teacherQuestionRemove(this.answerId).then(res => {
            if (res.code == 0) {
              this.msgSuccess("操作成功！");
              this.teacherQuestionList();
            }
          });
        })
        .catch(() => {});
    },
    answerConfirmSave(formName) {
      this.$refs[formName].validate(valid => {
         if (valid) {
           if (this.answerDialog.type == "add") {
              teacherQuestionSave(this.answerRuleForm).then(res => {
                if (res.code == 0) {
                  this.msgSuccess("操作成功！");
                  this.answerSync = false;
                  this.teacherQuestionList();
                }
              });
            } else if (this.answerDialog.type == "edit") {
              this.answerRuleForm.id = this.answerId[0];
              teacherQuestionUpdate(this.answerRuleForm).then(res => {
                if (res.code == 0) {
                  this.msgSuccess("操作成功！");
                  this.answerSync = false;
                  this.teacherQuestionList();
                }
              });
            }
         }else{
           this.msgWarn("必填字段不能为空！");
           return false;
         }
      })
    },
    takeAway() {
      if (this.takeAwayArray2.length > 0) {
        this.problemPopup = false;
        this.takeAwayArray2.forEach(item => {
          this.takeAwayArray.push({
            questionId: item.id,
            questionTitle: item.question,
            answer: item.answer
          });
        });
        this.takeAwayArray.answer = "";
      }
      this.$refs.answerListTable.clearSelection()
    },
    removeProblem(val) {
      for (let i = 0, len = this.takeAwayArray.length; i < len; i++) {
        if (this.takeAwayArray[i].questionId == val) {
          this.takeAwayArray.splice(i, 1);
        }
      }
    },
    confirmAnswer() {
      this.newarray = [];
      if (this.saveDialog.type == "edit") {
        this.takeAwayArray.forEach(item => {
          this.newarray.push({
            questionId: item.questionId,
            questionTitle: item.questionTitle,
            answer: item.answer,
            teacherId: this.getTeacherId
          });
        });
        
        if(this.takeFlag==false){
           this.xmtTeacherInterviewAnswer();
        }else{
          removeByTeacher(this.getTeacherId).then(res => {
            this.xmtTeacherInterviewAnswer();
          });
        }
      } else {
        this.takeAwayArray.forEach(item => {
          this.newarray.push({
            questionId: item.questionId,
            questionTitle: item.questionTitle,
            answer: item.answer,
            teacherId: this.getTeacherId
          });
        });
        this.xmtTeacherInterviewAnswer();
      }
    },
    xmtTeacherInterviewAnswer() {
      saveXmtTeacherInterviewAnswer(this.newarray).then(res => {
        if (res.code == 0) {
          this.msgSuccess("操作成功！");
          this.activeName='info4';
          this.info4Flag=false;
          // this.switchStatus = 6;
        }
      });
    },
    //选择试卷
    selectPaper() {
      this.selectPaperPopup = true;
      this.getPaperList();
    },
    getPaperList() {
      paperList(this.paperParams).then(res => {
        this.paperList = res.rows;
        this.paperTotal = res.total;
      });
    },
    change(val, num) {
      this.array = [];
      for (var i = 0; i < val.length; i++) {
        this.array.push(val[i]);
      }
      var data = {
        finishPaperId: num.finishPaperId,
        finishPaperBigId: num.finishPaperBigId,
        qid: num.qid,
        questionId: num.questionId,
        rightAnswer: num.rightAnswer,
        answer: this.array.join("|"),
        paperBigType: num.paperBigType
      };
      answerQuestion(data).then(res => {
        if (res.flag == "success") {
          // this.msgSuccess("操作成功！");
        }
      });
    },
    chang1(val, num) {
      var data = {
        id:num.id,
        finishPaperId: num.finishPaperId,
        finishPaperBigId: num.finishPaperBigId,
        qid: num.qid,
        questionId: num.questionId,
        rightAnswer: num.rightAnswer,
        answer: val,
        paperBigType: num.paperBigType
      };
      answerQuestion(data).then(res => {
        if (res.flag == "success") {
          // this.msgSuccess("操作成功！");
        }
      });
    },
    //提交试卷
    submitPaper() {
      if(this.getFinishExam.length==0){
        this.msgWarn("该试卷未组卷，请重新选择试卷！");
        return;
      }
      var data = {
        finishPaperId: this.finishPaperId,
        finishStatus: 2
      };
      submitFinishPaper(data).then(res => {
        if (res.flag == "success") {
          this.msgSuccess("交卷成功!");
          // this.saveDialogVisible = false;
          this.info6Flag=false;
          this.activeName='info6'
        }
      });
    },
    submitPaperSave() {
      if(this.getFinishExam.length==0){
        this.msgWarn("该试卷未组卷，请重新选择试卷！");
        return;
      }
      var data = {
        finishPaperId: this.finishPaperId,
        finishStatus: 1
      };
      submitFinishPaper(data).then(res => {
        if (res.flag == "success") {
          this.msgSuccess("保存成功!");
          this.saveDialogVisible = false;
        }
      });
    },
    imgItemDelClick(index){
      this.testPapers.splice(index, 1);
    },
    imgItemSeeClick(item,index){
      this.dialogFileViewPrivateInfo = {
          show: true,
          type: "img",
          src: item.fileUrl ? item.fileUrl : item.url,
          curIndex: index ? index : 0,
          imgList:
            index != undefined
              ? this.testPapers.map((item) => {
                  return item.fileUrl ? item.fileUrl : item.url;
                })
              : [item.fileUrl ? item.fileUrl : item.url],
        };
    },
    handlePaperSelectionChange(val) {
      if (val.length > 1) {
        this.$refs.paperListTable.clearSelection(); //清空列表的选中
        this.$refs.paperListTable.toggleRowSelection(val[val.length - 1]); //只显示选中最后一个 这时val还是多选的列表(就是你选中的几个数据)
      } else if (val.length === 1) {
        this.multipleTable = val[val.length - 1];
      } else {
        this.multipleTable = []; //this.multipleTable是选中行的最后一条数据
      }
    },
    paperItemClick(row) {
      this.$refs.paperListTable.toggleRowSelection(row);
    },
    handlePaperSizeChange(pageSize) {
      this.paperParams.pageSize = pageSize;
      this.getPaperList();
    },
    handlePaperCurrentChange(currentPage) {
      this.paperParams.pageNumber = currentPage;
      this.getPaperList();
    },
    getsearch() {
      this.getPaperList();
    },
    //试卷选择保存
    confirmPaperSelect() {
      this.selectPaperPopup = false;
      this.paperId = this.multipleTable.id; //获取试卷id
      this.startExamFn();
    },
    startExamFn() {
      //开始考试
      if (this.teacherId.length) {
        //编辑
        var data = {
          paperId: this.paperId,
          teacherId: this.getTeacherId
        };
        startExam(data).then(res => {
          if (res.flag == "success") {
            this.finishPaperId = res.msg.finishPaperId;
            this.getfinishPaperId();
          }
        });
      } else {
        var data = {
          paperId: this.paperId,
          teacherId: this.getTeacherId
        };
        startExam(data).then(res => {
          if (res.flag == "success") {
            this.finishPaperId = res.msg.finishPaperId;
            this.getfinishPaperId();
          }
        });
      }
    },
    findPaperQuestionsByTeacherIds() {
      getFinishStatusByTeacherId(this.getTeacherId).then(res => {
        if (res.code == 0) {
          if (res.rows == 1) {
            //保存
            findPaperQuestionsByTeacherId(this.getTeacherId).then(res => {
              if (res.rows != []) {
                //可编辑并回显
                this.btnShow = 1;
                this.finishPaperId = res.rows[0].finishPaperId
                  ? res.rows[0].finishPaperId
                  : "";
                  
                for (var i = 0; i < res.rows.length; i++) {
                  for (
                    var j = 0;
                    j < res.rows[i].finishPaperSmalls.length;
                    j++
                  ) {
                    res.rows[i].finishPaperSmalls[j].smallTitle =
                      res.rows[i].title;
                    res.rows[i].finishPaperSmalls[j].paperBigType =
                      res.rows[i].paperBigType;
                    if (
                      res.rows[i].finishPaperSmalls[j].smallTitle ==
                      "单项选择题"
                    ) {
                      res.rows[i].finishPaperSmalls[j].radio =
                        res.rows[i].finishPaperSmalls[j].answer;
                    } else if (
                      res.rows[i].finishPaperSmalls[j].smallTitle ==
                      "多项选择题"
                    ) {
                      res.rows[i].finishPaperSmalls[j].checkbox = res.rows[
                        i
                      ].finishPaperSmalls[j].answer.split("|");
                    } else if (
                      res.rows[i].finishPaperSmalls[j].smallTitle == "判断题"
                    ) {
                      res.rows[i].finishPaperSmalls[j].judge =
                        res.rows[i].finishPaperSmalls[j].answer;
                    } else if (
                      res.rows[i].finishPaperSmalls[j].smallTitle == "填空题"
                    ) {
                      res.rows[i].finishPaperSmalls[j].blanks =
                        res.rows[i].finishPaperSmalls[j].answer;
                    } else if (
                      res.rows[i].finishPaperSmalls[j].smallTitle == "主观题"
                    ) {
                      res.rows[i].finishPaperSmalls[j].subjective =
                        res.rows[i].finishPaperSmalls[j].answer;
                    }
                  }
                }
                this.getFinishExam = res.rows;
              }
            });
          } else if (res.rows == 2) {
            //提交审核
            this.btnShow == 0;
            // this.getFinishExam = [];
            findPaperQuestionsByTeacherId(this.getTeacherId).then(res => {
              if (res.rows != []) {
                //可编辑并回显
                // this.btnShow = 1;
                this.finishPaperId = res.rows[0].finishPaperId
                  ? res.rows[0].finishPaperId
                  : "";
                for (var i = 0; i < res.rows.length; i++) {
                  for (
                    var j = 0;
                    j < res.rows[i].finishPaperSmalls.length;
                    j++
                  ) {
                    res.rows[i].finishPaperSmalls[j].smallTitle =
                      res.rows[i].title;
                    res.rows[i].finishPaperSmalls[j].paperBigType =
                      res.rows[i].paperBigType;
                    if (
                      res.rows[i].finishPaperSmalls[j].smallTitle ==
                      "单项选择题"
                    ) {
                      res.rows[i].finishPaperSmalls[j].radio =
                        res.rows[i].finishPaperSmalls[j].answer;
                    } else if (
                      res.rows[i].finishPaperSmalls[j].smallTitle ==
                      "多项选择题"
                    ) {
                      res.rows[i].finishPaperSmalls[j].checkbox = res.rows[
                        i
                      ].finishPaperSmalls[j].answer.split("|");
                    } else if (
                      res.rows[i].finishPaperSmalls[j].smallTitle == "判断题"
                    ) {
                      res.rows[i].finishPaperSmalls[j].judge =
                        res.rows[i].finishPaperSmalls[j].answer;
                    } else if (
                      res.rows[i].finishPaperSmalls[j].smallTitle == "填空题"
                    ) {
                      res.rows[i].finishPaperSmalls[j].blanks =
                        res.rows[i].finishPaperSmalls[j].answer;
                    } else if (
                      res.rows[i].finishPaperSmalls[j].smallTitle == "主观题"
                    ) {
                      res.rows[i].finishPaperSmalls[j].subjective =
                        res.rows[i].finishPaperSmalls[j].answer;
                    }
                  }
                }
                this.getFinishExam = res.rows;
              }
            });
          } else {
            //审核成功
            this.btnShow = true;
          }
        }
      });
    },
    getfinishPaperId() {
      var data = {
        finishPaperId: this.finishPaperId,
        teacherId: this.getTeacherId
      };
      getFinishExamDetail(data).then(res => {
        if (res.flag == "success") {
          for (var i = 0; i < res.msg.finishPaperBigs.length; i++) {
            for (
              var j = 0;
              j < res.msg.finishPaperBigs[i].finishPaperSmalls.length;
              j++
            ) {
              res.msg.finishPaperBigs[i].finishPaperSmalls[j].smallTitle =
                res.msg.finishPaperBigs[i].title;
              res.msg.finishPaperBigs[i].finishPaperSmalls[j].paperBigType =
                res.msg.finishPaperBigs[i].paperBigType;
              res.msg.finishPaperBigs[i].finishPaperSmalls[j].radio = "";
              res.msg.finishPaperBigs[i].finishPaperSmalls[
                j
              ].checkbox = new Array();
              res.msg.finishPaperBigs[i].finishPaperSmalls[j].judge = "";
            }
          }
          this.getFinishExam = res.msg.finishPaperBigs;
          if (res.msg.title) {
            this.getFinishExamTitle = res.msg.title;
          }
          if (res.msg.desc) {
            this.getFinishExamDesc = res.msg.desc;
          }
        }
      });
    }
  },
  watch: {
    saveDialogVisible: function (val,oldVla) {
      if (this.$refs['saveRuleForm'] !== undefined) {
        this.$refs["saveRuleForm"].resetFields();
      }
      if (this.$refs['faceRuleForm'] !== undefined) {
        this.$refs["faceRuleForm"].resetFields();
      }
       if (this.$refs['authenRuleForm'] !== undefined) {
        this.$refs["authenRuleForm"].resetFields();
      }
    },
    answerSync: function (val,oldVla) {
      if (this.$refs['answerRuleForm'] !== undefined) {
        this.$refs["answerRuleForm"].resetFields();
      }
    },
     followUpDialogVisible: function (val,oldVla) {
      if (this.$refs['saveRuleFormFollowUp'] !== undefined) {
        this.$refs["saveRuleFormFollowUp"].resetFields();
      }
    }
  },
  mounted() {
    this.getteacherManageList();
    this.getSectionList();
    this.getSectionList2();
    this.getPhaseList();
    this.getSubjectList();
    this.getSubjectList2();
    this.getTeachTitle();
    this.getTeachStyle();
    this.getProvinceList();
    this.parentCodesFn();
    this.sectionChange();
    this.startDatePicker = this.beginDate();
    this.endDatePicker = this.processDate();
    this.estartDatePicker = this.ebeginDate();
    this.eendDatePicker = this.eprocessDate();
  }
};
</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */
.demo-table-expand {
  font-size: 0;
}
/deep/.dialog-footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.switchClass {
  color: red;
}
// .demo-table-expand label {
//   width: 120px;
//   color: #99a9bf;
// }
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 16%;
}
.demo-table-expand span {
  cursor: pointer;
}
.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .followUp_area {
    width: 100%;
    display: flex;
    align-items: center;
    margin-left: 50px;
  }
  .followBox {
    margin-left: 50px;
  }
  .btn-area {
    height: 50px;
    display: flex;
    align-items: center;
    .right {
      flex: 1;
      display: flex;
      justify-content: flex-end;
    }
  }
  .select-condition {
    border-bottom: 1px solid #f1f1f1;
    .left {
      flex: 1;
      display: flex;
      .select-condition-item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        .item-lable {
          word-break: keep-all;
        }
      }
    }
  }
  .table-area {
    flex: 1;
    overflow: hidden;
  }
  .page-area {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .dialog-content {
    min-height: 260px;
    max-height: 60vh;
    overflow-y: auto;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 30px;
    .item_select {
      display: flex;
      align-items: center;
      span {
        width: 120px;
      }
    }
    .record {
      margin-top: 230px;
    }
  }
  .score-input {
    border: none;
    outline: none;
    border-bottom: 1px solid #000000;
    text-align: center;
    width: 80px;
  }
  .subject-item {
    height: 40px;
    line-height: 40px;
  }
  .self-radio-group {
    margin-left: 20px;
    .self-radio-group-item {
      margin-bottom: 8px;
    }
  }
  .center {
    text-align: center;
    min-height: 30px;
  }
  .line {
    height: 0;
    border-bottom: 1px solid #f1f1f1;
    margin-left: 30px;
    margin: 10px 0 10px 30px;
  }
  .contactTable >>> .el-table__header-wrapper .el-table__header .el-checkbox {
    display: none;
  }
  .caseDiv{
    display: flex;
    margin-top:20px;
    .caseLeft{
      width: 80%;
      span{
        margin-right:30px;
      }
    } 

    .caseRight{
      flex:1;
      text-align: center;
    }
  }
  
  .subjectActive{
    overflow: hidden;
  }
  .subjectId_ul{
    // background:yellow;
    // width:100%;
    // overflow:visible;
    // height: 15px;
    // line-height: 15px;

  }
   /deep/ .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
      background-color: #1890ff;
      border-color: #1890ff;
    }
    /deep/ .el-checkbox.is-disabled.is-checked .el-checkbox__label {
      color: #1890ff;
    }
   
}
 /deep/.el-table .warning-row {
      background: #eee;
      color:#ccc;
    }
    /deep/.el-upload-list__item.is-ready {
  display: none !important;
}
>>>.quesborder{
    margin-top:20px !important;
    >p:nth-child(1){
     font-size:18px;
    }
  }
  .video_area {
    width: 200px;
    height: 140px;
    video {
      width: 200px;
      height: 140px;
      object-fit: fill;
    }
    div {
      width: 200px;
      height: 140px;
      border: 1px solid #dcdfe6;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  >>>.hide .el-upload--picture-card {
  		display: none;
	}
  .imgDolg {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  #imgDolgClose {
    position: fixed;
    top: 35px;
    cursor: pointer;
    right: 7%;
    font-size: 50px;
    color: white;
  }
  img {
    width: 40%;
    display: block;
  }
}
.img_list {
        width: 100%;
        min-height: 120px;
        overflow: auto;
        .img_item {
          position: relative;
          margin: 10px 10px 10px 0px;
          float: left;
          cursor: pointer;
          height: 108px;
          width: 146px;
          border: 1px solid #dddddd;
          border-radius: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          .img_hover {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: none;
            z-index: 6;
            align-items: center;
            justify-content: center;
            background-color: rgba($color: #000000, $alpha: 0.7);
            .icon_del {
              margin-left: 10px;
              cursor: pointer;
              color: #ffffff;
            }
            .icon_preview {
              cursor: pointer;
              color: #ffffff;
              font-size: 14px;
            }
          }
          &:hover {
            .img_hover {
              display: flex;
            }
          }
          img {
            width: 100%;
            height: 100%;
          }
          .icon_new {
            font-size: 20px;
          }
        }
      }
      .imgDefault{
  width:180px;
  height:120px;
  background:#f5f7fa;
  color:#909399;
  display:flex;
  align-items: center;
  justify-content: center;
}
</style>
