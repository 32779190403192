<template>
  <div class="main-content sys-role-manage">
    <div class="btn-area select-condition">
      <div class="left">
        <div class="select-condition-item">
          <span class="item-lable">关键字：</span>
          <el-input
            v-model="listParams.name"
            placeholder="请输入姓名/联系方式/昵称"
            size="small"
            style="min-width:120px"
          ></el-input>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">年级：</span>
          <el-select
            v-model="listParams.sectionId"
            placeholder="请选择"
            size="small"
            style="width:120px"
          >
            <el-option
              v-for="item in sectionList"
              :key="item.macroId"
              :label="item.name"
              :value="item.macroId"
            ></el-option>
          </el-select>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">学科：</span>
          <el-select
            v-model="listParams.subjectId"
            placeholder="请选择"
            size="small"
            style="width:120px"
          >
            <el-option
              v-for="item in subjectList"
              :key="item.macroId"
              :label="item.name"
              :value="item.macroId"
            ></el-option>
          </el-select>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">可面授地点：</span>
           <el-select
                  v-model="listParams.currentProvinceIds"
                  placeholder="请选择"
                  size="small"
                  clearable
                  @clear="clearFn()"
                  @change="listProvinceChange"
                  style="width:150px"
                >
                  <el-option
                    v-for="item in listProvinceData"
                    :key="item.areaId"
                    :label="item.name"
                    :value="item.areaId"
                  ></el-option>
                </el-select>
                <el-select
                  v-model="listParams.currentCityIds"
                  placeholder="请选择"
                  size="small"
                  clearable
                  @clear="clearFn()"
                  @change="listCityChange"
                  style="width:150px;margin-left:10px;"
                >
                  <el-option
                    v-for="item in listCityData"
                    :key="item.areaId"
                    :label="item.name"
                    :value="item.areaId"
                  ></el-option>
                </el-select>
                <el-select
                  v-model="listParams.currentCountyIds"
                  placeholder="请选择"
                  size="small"
                  clearable
                  @clear="clearFn()"
                  style="width:150px;margin-left:10px;"
                >
                  <el-option
                    v-for="item in listCountyData"
                    :key="item.areaId"
                    :label="item.name"
                    :value="item.areaId"
                  ></el-option>
                </el-select>
        </div>  
        <div class="select-condition-item">
          <el-button
            type="primary"
            size="small"
            icon="el-icon-search"
            @click="()=>(listParams.pageNumber=1,getteacherManageList())"
            >搜索</el-button
          >
        </div>
      </div>
    </div>
    <div class="btn-area select-condition">
      <div class="left">
          <div class="select-condition-item">
          <span class="item-lable">讲师职称：</span>
          <el-select
            v-model="listParams.teachTitleId"
            placeholder="请选择"
            size="small"
            style="width:120px"
          >
            <el-option
              v-for="item in teachTitleIdListSearch"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">教龄：</span>
          <el-select
            v-model="listParams.teachingAgeId"
            placeholder="请选择"
            size="small"
            style="width:120px"
          >
            <el-option
              v-for="item in teachingAgeIdListSearch"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">讲师身份：</span>
          <el-select
            v-model="listParams.identityType"
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="item in identityTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div class="btn-area">
      <div class="right">
        <el-button-group>
          <el-button type="default" size="small" @click="dealAdd">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-xinzeng" />
            </svg>
            <span>添加讲师</span>
          </el-button>
          <el-button type="default" size="small" @click="dealEdit">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-edit" />
            </svg>
            <span>编辑讲师</span>
          </el-button>
          <el-button type="default" size="small" @click="dealSeeTit">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-edit" />
            </svg>
            <span>查看讲师</span>
          </el-button>
          <el-button type="default" size="small" @click="dealReset">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-mima2" />
            </svg>
            <span>重置密码</span>
          </el-button>
        </el-button-group>
      </div>
    </div>
    <div class="table-area tableScroll">
      <el-table
        size="small"
        :data="listArray"
        style="width: 100%; margin-bottom: 20px"
        row-key="id"
        border
        height="100%"
        tooltip-effect="dark"
        ref="productListTable"
        @selection-change="handleSelectionChange"
        @row-click="studentItemClick"
        :row-class-name="tableRowClassName"
      >
        <el-table-column
          type="selection"
          width="55"
          fixed
          align="center"
        ></el-table-column>
        <el-table-column
          prop="no"
          label="编号"
          align="center"
        ></el-table-column>
       
        <el-table-column
          prop="name"
          label="昵称"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="realname"
          label="姓名"
          align="center"
        >
          <template slot-scope="scope">
          <div style="text-decoration:underline;cursor:pointer;" @click.stop="dealSee(scope.row)">{{ scope.row.realname }}</div>
        </template>
        </el-table-column>
        <el-table-column
          prop="account"
          label="账号"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="sexId"
          :formatter="dealSex"
          label="性别"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="sectionId"
          label="年级"
          :formatter="dealSectionId"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="subjectId"
          label="科目"
          :formatter="dealSubjectIdd"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="identityType"
          :formatter="dealIdentityType"
          label="教师身份"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="teachingAgeId"
          label="教龄"
          :formatter="dealTeachingAgeId"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="teachTitleId"
          :formatter="dealTeachTitleId"
          label="教师职称"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="createUserName"
          label="创建人"
          align="center"
        ></el-table-column>
         <el-table-column
          prop="no"
          label="状态"
          align="center"
        >
          <template slot-scope="scope">
            <p style="color:red;" v-show="scope.row.isBasicdata==2">审核通过</p>
            <p style="color:red;" v-show="scope.row.isBasicdata==3">审核失败</p>
           <p style="color:red;" v-show="scope.row.isBasicdata!=3&&scope.row.isBasicdata!=2
           " >审核中</p>
          </template>
        </el-table-column>
        <el-table-column prop="" label="更多" align="center" type="expand">
          <template slot-scope="props">
            <el-form label-position="left" inline class="demo-table-expand">
              <el-form-item label="基本信息">
                <span style="color:red" v-show="props.row.isBasicdata==0"
                 @click.stop="dealRowSee(props.row.id,1)"
                  >(未认证)</span
                >
                <span style="color:red" v-show="props.row.isBasicdata==1"
                   @click.stop="dealRowSee(props.row.id,1)"
                  >(审核中)</span
                >
                 <span style="color:#2fbd4a" v-show="props.row.isBasicdata==2"
                   @click.stop="dealRowSee(props.row.id,1)"
                  >(认证成功)</span
                >
                <span style="color:red" v-show="props.row.isBasicdata==3"
                   @click.stop="dealRowSee(props.row.id,1)"
                  >(已驳回)<span  @click.stop="seeReason(props.row.basicdataRejectReason)">原因</span></span
                >
              </el-form-item>
              <el-form-item label="资质认证">
                <span
                  @click.stop="dealRowSee(props.row.id,2)"
                  style="color:red"
                  v-show="
                    props.row.idcardAuthStatus == 0 ||
                      props.row.capesAuthStatus == 0
                  "
                  >(未认证)</span
                >
                <span
                @click.stop="dealRowSee(props.row.id,2)"
                  style="color:red"
                  v-show="
                    props.row.idcardAuthStatus == 1 ||
                      props.row.capesAuthStatus == 1
                  "
                  >(审核中)</span
                >
                <span
                  @click.stop="dealRowSee(props.row.id,2)"
                  style="color:#2fbd4a"
                  v-show="
                    props.row.idcardAuthStatus == 2 ||
                      props.row.capesAuthStatus == 2
                  "
                  >(认证成功)</span
                >
                <span
                  @click.stop="dealRowSee(props.row.id,2)"
                  style="color:red"
                  v-show="
                    props.row.idcardAuthStatus == 3 ||
                      props.row.capesAuthStatus == 3
                  "
                  >(已驳回)<span  @click.stop="seeReason(props.row.rejectQualificationReason)">原因</span></span
                >
              </el-form-item>
               <el-form-item label="面试答题">
                <span style="color:red" v-show="props.row.isInterview == 0"
                  @click.stop="dealRowSee(props.row.id,3)"
                  >(未填写)</span
                >
                <span style="color:#2fbd4a" v-show="props.row.isInterview == 1"
                  @click.stop="dealRowSee(props.row.id,3)"
                  >(已填写)</span
                >
                
              </el-form-item>
              <el-form-item label="试讲视频">
                <span
                 @click="dealRowSee(props.row.id,4)"
                  style="color:red"
                  v-show="
                    props.row.videoAuthStatus == 0
                  "
                  >(未填写)</span
                >
                <span
                  @click="dealRowSee(props.row.id,4)"
                  style="color:red"
                  v-show="
                    props.row.videoAuthStatus == 1
                  "
                  >(已填写)</span
                >
              </el-form-item>
              <el-form-item label="面试评分">
                <span style="color:red" v-show="props.row.isFacemark == 0"
                  @click.stop="dealRowSee(props.row.id,6)"
                  >(未填写)</span
                >
                <span style="color:#2fbd4a" v-show="props.row.isFacemark == 1"
                   @click.stop="dealRowSee(props.row.id,6)"
                  >(已填写)</span
                >
              </el-form-item>
              <el-form-item label="">
                 <i class="el-icon-video-camera-solid" style="font-size:30px;" @click="updateEquipment(props.row)" :style="{'color': (props.row.isEquipment==1 ? '#1890ff':'#BBBBBB')}"></i>
              </el-form-item>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click.stop="examine(scope.row)"
            >
              <span>审核</span>
            </el-button>
            <el-button
              type="text"
              size="small"
              @click.stop="removeTeacher(scope.row)"
            >
              <span>删除</span>
            </el-button>
            <el-button
              type="text"
              size="small"
              @click.stop="isEnable(scope.row)"
            >
              <span v-show="scope.row.status == 0">启用</span>
              <span v-show="scope.row.status == 1">禁用</span>
            </el-button>
             <el-button
              type="text"
              size="small"
              @click.stop="editCellPhone(scope.row)"
            >
              <span>更改手机号</span>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page-area">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="listParams.pageNumber"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="listParams.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background
      ></el-pagination>
    </div>
    <el-dialog
      :title="saveDialog.title"
      :visible.sync="saveDialogVisible"
      width="1220px"
      center
      @close="closeSaveDialog"
      :close-on-click-modal="false"
      custom-class="product-dialog"
    >
     <el-tabs v-model="activeName"  @tab-click="classStatusClick">
      <el-tab-pane label="基本信息" name="info1" >
      <div>
        <div class="dialog-content">
          <el-form
            :model="saveRuleForm"
            status-icon
            :rules="saveRules"
            ref="saveRuleForm"
            label-width="120px"
            size="small"
          >
            <el-row :gutter="20">
              <el-col :span="20" >
                  <el-row :gutter="20">
                      <el-col :span="8">
                        <el-form-item label="真实姓名" prop="name">
                          <el-input v-model="saveRuleForm.realname" placeholder="输入内容"></el-input>
                        </el-form-item>
                      </el-col>
                       <el-col :span="8">
                        <el-form-item label="联系方式" prop="contact">
                          <el-input
                            v-model="saveRuleForm.contact"
                            oninput="if(value.length>11)value=value.slice(0,11)"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                     <el-col :span="8" v-show="saveDialog.type == 'edit'">
                        <el-form-item label="账号" prop="contact">
                          {{teacherInfoAccount}}
                        </el-form-item>
                      </el-col>
                  </el-row>
                  <el-row :gutter="20" >
                    <el-col :span="8">
                <el-form-item label="出生日期" prop="birthday">
                  <el-date-picker
                    v-model="saveRuleForm.birthday"
                    type="date"
                    :default-value="new Date('1986-01-01')"
                    :picker-options="expireTimeOption"
                    placeholder="选择日期"
                    value-format="yyyy-MM-dd"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="身份证号" prop="idcard">
                    <el-input v-model="saveRuleForm.idcard" placeholder="输入内容"></el-input>
                </el-form-item>
              </el-col>
               <el-col :span="8">
                  <el-form-item label="性别" prop="sexId">
                    <el-radio-group v-model="saveRuleForm.sexId">
                      <el-radio :label="1">男</el-radio>
                      <el-radio :label="2">女</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
             
                  </el-row>
              </el-col>
            </el-row>
            <el-row :gutter="20">
               <el-col :span="7">
                <el-form-item label="籍贯" prop="">
                  <el-select
                  v-model="saveRuleForm.provinceId"
                  placeholder="请选择"
                  size="small"
                  @change="provinceChangeList"
                >
                  <el-option
                    v-for="item in provinceLessonList"
                    :key="item.areaId"
                    :label="item.name"
                    :value="item.areaId"
                  ></el-option>
                </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6" class="mL20">
                  <el-select
                  v-model="saveRuleForm.cityId"
                  placeholder="请选择"
                  size="small"
                  @change="cityChangeList"
                >
                  <el-option
                    v-for="item in cityList"
                    :key="item.areaId"
                    :label="item.name"
                    :value="item.areaId"
                  ></el-option>
                </el-select>
              </el-col>
              <el-col :span="6" class="mL20">
                <el-select
                  v-model="saveRuleForm.countyId"
                  placeholder="请选择"
                  size="small"
                >
                  <el-option
                    v-for="item in countyList"
                    :key="item.areaId"
                    :label="item.name"
                    :value="item.areaId"
                  ></el-option>
                </el-select>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="10">
                <el-form-item label="教学方向" prop="teachingDirection">
                  <el-radio-group v-model="saveRuleForm.teachingDirection" @change="directionChange()">
                    <el-radio :label="1">学科类</el-radio>
                    <el-radio :label="2">素质教育类</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item label="选择身份" prop="identityType">
                  <el-radio-group v-model="saveRuleForm.identityType">
                    <el-radio :label="`${saveRuleForm.teachingDirection==1?'1':'3'}`">老师</el-radio>
                    <el-radio :label="`${saveRuleForm.teachingDirection==1?'2':'4'}`">大学生-在读</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20" v-show="saveRuleForm.identityType==2||saveRuleForm.identityType==4">
              <el-col :span="6">
                <el-form-item label="在读阶段" prop="readingStage">
                  <el-select
                    v-model="saveRuleForm.readingStage"
                    placeholder="请选择"
                    size="small"
                  >
                    <el-option
                      v-for="item in stageList"
                      :key="item.macroId"
                      :label="item.name"
                      :value="item.macroId"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="7">
                <el-form-item label="在读学校" prop="identity">
                  <el-input v-model="saveRuleForm.schoolName"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="20">
                <el-form-item label="教授学段" prop="periodId">
                  <el-checkbox-group v-model="saveRuleForm.periodId" @change="changes()">
                    <el-checkbox v-show="saveRuleForm.teachingDirection==1" :label="107">高中</el-checkbox>
                    <el-checkbox v-show="saveRuleForm.teachingDirection==1" :label="106">初中</el-checkbox>
                    <el-checkbox v-show="saveRuleForm.teachingDirection==1" :label="105">小学</el-checkbox>
                    <el-checkbox v-show="saveRuleForm.teachingDirection==2" :label="108">艺术培训</el-checkbox>
                    <el-checkbox v-show="saveRuleForm.teachingDirection==2" :label="109">小语种</el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20" v-show="teacherGradeList.length > 0">
              <el-col :span="20">
                <el-form-item label="教授年级" prop="sectionId">
                  <el-checkbox-group v-model="saveRuleForm.sectionId" >
                    <el-checkbox v-for="(item, index) in teacherGradeList"  :label="item.macroId" :key="index" >{{item.name}}</el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20" v-show="teacherSubjectList.length > 0&&saveRuleForm.teachingDirection==1&&saveRuleForm.identityType==1">
              <el-col :span="18">
                <el-form-item label="教授科目" prop="subjectId"  class="subjectId_ul">
                  <el-radio-group v-model="saveRuleForm.subjectId">
                    <el-radio v-for="(item, index) in teacherSubjectList" :label="item.macroId" :key="index">{{item.name}}</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20" v-show="teacherSubjectListCheck.length > 0&&(saveRuleForm.teachingDirection!=1||saveRuleForm.identityType!=1)">
              <el-col :span="18">
                <el-form-item label="教授科目" prop="subjectIdCheck"  class="subjectId_ul">
                  <el-checkbox-group v-model="saveRuleForm.subjectIdCheck" >
                    <el-checkbox v-for="(item, index) in teacherSubjectListCheck"  :label="item.macroId" :key="index" >{{item.name}}</el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20" v-show="saveRuleForm.identityType==1||saveRuleForm.identityType==3">
              <el-col :span="6">
                  <el-form-item label="教龄" prop="teachingAgeId">
                  <el-select
                    v-model="saveRuleForm.teachingAgeId"
                    placeholder="请选择"
                    size="small"
                  >
                    <el-option
                      v-for="item in teachingAgeIdList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="学历" prop="educationId">
                  <el-select
                    v-model="saveRuleForm.educationId"
                    placeholder="请选择"
                    size="small"
                  >
                    <el-option
                      v-for="item in educationIdList"
                      :key="item.macroId"
                      :label="item.name"
                      :value="item.macroId"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              
              <el-col :span="6">
                <el-form-item label="毕业院校" prop="graduationSchool">
                  <el-input v-model="saveRuleForm.graduationSchool"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20" v-show="saveRuleForm.identityType=='1'&&saveRuleForm.teachingDirection==1">
              <el-col :span="6">
                <el-form-item label="任职学校" prop="schoolName">
                  <el-input v-model="saveRuleForm.schoolName"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="教师职称" prop="teachTitleId">
                  <el-select
                    v-model="saveRuleForm.teachTitleId"
                    placeholder="请选择"
                    size="small"
                  >
                    <el-option
                      v-for="item in teachTitleIdList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="10">
                <el-form-item label="个人身份认证" prop="idjust">
                  <el-upload
                    class="avatar-uploader"
                    :action="fileUpload"
                    :show-file-list="false"
                    :on-success="idCardUpload"
                    :before-upload="beforeAvatarUpload"
                    accept="image/jpeg, image/png/, image/gif"
                    :disabled="detaildStatus ? true : false"
                  >
                    <img
                      v-if="uploadImg.idcard"
                      :src="uploadImg.idcard"
                      class="avatar"
                    />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item :label="saveRuleForm.identityType==1||saveRuleForm.identityType==3?'学历证明':'学生证'" prop="idjust">
                  <el-upload
                    class="avatar-uploader"
                    :action="fileUpload"
                    :show-file-list="false"
                    :on-success="xueliUpload"
                    :before-upload="beforeAvatarUpload"
                    accept="image/jpeg, image/png/, image/gif"
                    :disabled="detaildStatus ? true : false"
                  >
                    <img
                      v-if="uploadImg.xueli"
                      :src="uploadImg.xueli"
                      class="avatar"
                    />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="10">
                <el-form-item label="教师资格认证" prop="qualifications" v-show="saveRuleForm.teachingDirection==1">
                  <el-upload
                    class="avatar-uploader"
                    :action="fileUpload"
                    :show-file-list="false"
                    :on-success="teaQualifications"
                    :before-upload="beforeAvatarUpload"
                    accept="image/jpeg, image/png/, image/gif"
                    :disabled="detaildStatus ? true : false"
                  >
                    <img
                      v-if="uploadImg.qualifications"
                      :src="uploadImg.qualifications"
                      class="avatar"
                    />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                   <el-checkbox v-model="teacherQualificationCertificate" :disabled="qualDisaled">暂未取得教师资格证</el-checkbox>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item :label="saveRuleForm.teachingDirection==2&&saveRuleForm.identityType==3?'教师荣誉':'专业资质'" prop="qualifications">
                  <div class="img_list">
                <div
                  class="img_item img_item_true"
                  v-for="(item, index) in testPapers"
                  :key="index"
                >
                  <div class="img_hover">
                    <span
                      class="iconfont icon-yulan1 icon_preview"
                      title="预览"
                      @click="imgItemSeeClick(item, index)"
                    ></span>
                    <span
                      @click="imgItemDelClick(index)"
                      class="iconfont icon-shanchu4 icon_del"
                      title="删除"
                     
                    ></span>
                  </div>
                  <img :src="item.url" alt="" />
                </div>
                <div class="img_item" @click="imgSelectClick">
                  <span class="iconfont icon-danyehuaban icon_new"></span>
                  <input
                    type="file"
                    ref="uploadImg"
                    @change="imgSelectChange"
                    style="display: none"
                    accept="image/png, image/jpeg, image/gif, image/jpg"
                  />
                </div>
              </div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="saveDialogVisible = false" size="small"
            >取 消</el-button
          >
          <el-button
            type="primary"
            :disabled="isDisable"
            @click="confirmSave('saveRuleForm')"
            size="small"
            >确 定</el-button
          >
        </span>
      </div>
    </el-tab-pane>
      <el-tab-pane label="展示资料" name="info2" :disabled='info2Flag'>
            <div>
        <div class="dialog-content">
         <el-form
            :model="dataRuleForm"
            ref="dataRuleForm"
            status-icon
            label-width="120px"
            size="small"
          >
            <el-row :gutter="20">
              <el-col :span="5">
                <el-form-item label="本人头像" prop="photo">
                  <el-upload
                    class="avatar-uploader"
                    :action="fileUpload"
                    :show-file-list="false"
                    :on-success="handleAvatarSuccess"
                    :before-upload="beforeAvatarUpload"
                    accept="image/jpeg, image/png/"
                  >
                    <img
                      v-if="dataRuleForm.photo"
                      :src="dataRuleForm.photo"
                      class="avatar"
                    />
                    <i v-else   class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </el-form-item>
              </el-col>
              <el-col :span="17" >
                <el-row :gutter="20">
                  <el-col :span="8">
                    <el-form-item label="昵称" prop="name">
                      <el-input v-model="dataRuleForm.name" placeholder="某某科老师"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="15">
                  <el-form-item label="是否接收试听" prop="ifAcceptAudition">
                      <el-radio-group v-model="dataRuleForm.ifAcceptAudition">
                        <el-radio :label="1">可接受45分钟~1小时试听</el-radio>
                        <el-radio :label="0">不接受</el-radio>
                      </el-radio-group>
                    </el-form-item>
                </el-col>
                </el-row>
              </el-col>
              <el-col :span="17" >
                <el-row :gutter="20">
                  <el-col :span="20">
                    <el-form-item label="授课方式" prop="classForm">
                    <el-radio-group v-model="dataRuleForm.classForm">
                      <el-radio :label="0">均可</el-radio>
                      <el-radio :label="1">线上</el-radio>
                      <el-radio :label="2">线下</el-radio>
                    </el-radio-group>
                  </el-form-item>
                    
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
            <el-row :gutter="20" v-show="dataRuleForm.classForm=='0'||dataRuleForm.classForm=='2'">
               <el-col :span="7">
                <el-form-item label="可面授地点" prop="">
                  <el-select
                  v-model="dataRuleForm.currentProvinceIds"
                  placeholder="请选择"
                  size="small"
                  @change="currentProvinceChange"
                >
                  <el-option
                    v-for="item in currentProvinceData"
                    :key="item.areaId"
                    :label="item.name"
                    :value="item.areaId"
                  ></el-option>
                </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6" class="mL20">
                  <el-select
                  v-model="dataRuleForm.currentCityIds"
                  placeholder="请选择"
                  size="small"
                  @change="currentCityChange"
                >
                  <el-option
                    v-for="item in currentCityData"
                    :key="item.areaId"
                    :label="item.name"
                    :value="item.areaId"
                  ></el-option>
                </el-select>
              </el-col>
              <el-col :span="6" class="mL20">
                <el-select
                  v-model="dataRuleForm.currentCountyIds"
                  placeholder="请选择"
                  size="small"
                  multiple
                  @change="bindChange"
                >
                  <el-option
                    v-for="item in currentCountyData"
                    :key="item.areaId"
                    :label="item.name"
                    :value="item.areaId"
                  ></el-option>
                </el-select>
              </el-col>
            </el-row>
            <el-row :gutter="20" v-show="saveRuleForm.teachingDirection==1">
              <el-col :span="20">
                <el-form-item label="擅教学生">
                  <span style="color:#ccc;"
                    >一对一教学，讲究个性化教学，根据以往经历，请您选择您更擅长带哪类学生？</span
                  >
                  <el-checkbox-group v-model="skilfulTeachArray" @change="groupChange">
                      <el-checkbox v-for="(item,index) in skilfulTeachDate" :key="index" :label="item.macroId" :value="item.macroId"
                      >{{item.name}}</el-checkbox
                    >
                  </el-checkbox-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20" v-show="saveRuleForm.teachingDirection==1">
              <el-col :span="20">
                <el-form-item label="讲课风格">
                  <el-checkbox-group v-model="skilfulArray" @change="skilfulArrayChange">
                      <el-checkbox v-for="(item,index) in skilfulDate" :key="index" :label="item.macroId" :value="item.macroId"
                      >{{item.name}}</el-checkbox
                    >
                  </el-checkbox-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20" v-show="saveRuleForm.teachingDirection==1">
              <el-col :span="20">
                <el-form-item label="教学特点" prop="teachTrait">
                  <el-input
                    type="textarea"
                    :rows="2"
                    maxlength="500"
                    show-word-limit
                    v-model="dataRuleForm.teachTrait"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12"> </el-col>
            </el-row>
            <el-row :gutter="20" v-show="saveRuleForm.teachingDirection==1">
            <el-col :span="20">
              <el-form-item label="成功案例">
                <el-button type="primary" @click="addCase()">添加</el-button>
                  <div class="caseDiv" v-for="(item, index) in caseList" :key="index">
                    <div class="caseLeft">
                        <p>
                          <span>{{item.studentName}}</span>
                          <span>{{studentSectionIdFn(item.studentSectionId)}}</span>
                          <span>{{item.classTimeBegin}} 至 {{item.classTimeEnd}}</span>
                          <span>{{studentAchievementFn(item.studentAchievement)}}</span>
                        </p>
                        <p>{{item.detailContent}}</p>
                        <p>
                          <el-image   style="cursor:pointer;width:100px;height:100px;margin-right:10px;margin-top:15px;" v-for='(items,indexs) in item.photoList'  :preview-src-list="item.photoList" :src="items"  :key='indexs' alt=""></el-image>
                        </p>
                    </div>
                    <div class="caseRight">
                        <el-button type="primary" @click="caseEdit(item,index)">编辑</el-button>
                        <el-button type="primary" @click="caseRemove(index)">删除</el-button>
                    </div>
                  </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20" v-show="saveRuleForm.teachingDirection==2">
            <el-col :span="20">
              <el-form-item label="代课经历">
                <el-button type="primary" @click="addExperience()">添加</el-button>
                  <div class="caseDiv" v-for="(item, index) in daikeList" :key="index">
                    <div class="caseLeft">
                        <p>
                          <span>{{item.studentName}}</span>
                          <span>{{item.classTimeBegin}} 至 {{item.classTimeEnd}}</span>
                          <span>{{experienceFormListFn(item.studentAchievement)}}</span>
                        </p>
                        <p>{{item.detailContent}}</p>
                        <p>
                          <el-image   style="cursor:pointer;width:100px;height:100px;margin-right:10px;margin-top:15px;" v-for='(items,indexs) in item.photoList'  :preview-src-list="item.photoList" :src="items"  :key='indexs' alt=""></el-image>
                        </p>
                    </div>
                    <div class="caseRight">
                        <el-button type="primary" @click="experienceEdit(item,index)">编辑</el-button>
                        <el-button type="primary" @click="experienceRemove(index)">删除</el-button>
                    </div>
                  </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20" v-show="saveRuleForm.teachingDirection==2">
            <el-col :span="20">
              <el-form-item label="作品展示">
                <div class="img_list">
                <div
                  class="img_item img_item_true"
                  v-for="(item, index) in exhibitionPhotos"
                  :key="index"
                >
                  <div class="img_hover">
                    <span
                      class="iconfont icon-yulan1 icon_preview"
                      title="预览"
                      @click="exhibitionPhotosSee(item, index)"
                    ></span>
                    <span
                      class="iconfont icon-shanchu4 icon_del"
                      title="删除"
                      @click="exhibitionPhotosDel(index)"
                    ></span>
                  </div>
                  <img :src="item" alt="" />
                </div>
                <div class="img_item" @click="zuopinSelectClick">
                  <span class="iconfont icon-danyehuaban icon_new"></span>
                  <input
                    type="file"
                    ref="zuopinuploadImg"
                    @change="zuopinSelectChange"
                    style="display: none"
                    accept="image/png, image/jpeg, image/gif, image/jpg"
                  />
                </div>
              </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item
            label="附件简历"
            prop="resume"
          >  
            <el-row align="middle" type="flex">
              <el-col :span="12">
                <div class="coachAgreement_tip">
                   <a target="view_window" :href="dataRuleForm.resume">{{dataRuleForm.resume}}</a>
                  <span v-show="!dataRuleForm.resume"
                    >还未上传附件简历</span
                  >
                </div>
              </el-col>
              <el-col :span="6" class="mL20">
                <el-upload
                  accept="image/jpeg, image/png/, image/gif"
                  :action="fileUpload"
                  :on-success="resumeSuccess"
                  :on-error="resumeError"
                  :show-file-list="false"
                >
                  <el-button
                    size="small"
                    type="primary"
                    >点击上传</el-button
                  >
                </el-upload>
              </el-col>
            </el-row>
          </el-form-item>
          <el-row :gutter="20">
              <el-col :span="20">
                <el-form-item label="期望课酬" prop="hopeMinFee">
                  <el-input
                    style="width:70px !important;"
                    v-model="dataRuleForm.hopeMinFee"
                    :maxlength="5"
                  ></el-input>
                  <span style="padding:0 13px;">至</span>
                  <el-input
                    style="width:70px !important;"
                    v-model="dataRuleForm.hopeMaxFee"
                    :maxlength="5"
                  ></el-input>元/课时
                  <span style="color:#999;">（1课时=1小时）</span>
                </el-form-item>
              </el-col>
            </el-row>
        </el-form>
        </div>
        <span
          slot="footer"
          class="dialog-footer"
          v-show="detaildStatus != true"
        >
          <el-button @click="saveDialogVisible = false" size="small"
            >取 消</el-button
          >
          <el-button
            type="primary"
            @click="confirmSaveup('saveRuleForm')"
            size="small"
            >确 定</el-button
          >
        </span>
      </div>
        </el-tab-pane>
        <el-tab-pane label="面试答题" name="info3" :disabled='info2Flag'>
        <div>
        <div class="dialog-content">
          <p>面试部分主要是在教学中遇到的常见问题，请依据老师实例情况填写</p>
          <el-button
            type="primary"
            size="small"
            style="margin-top:20px;"
            @click="choiceProblem"
            >选择问题</el-button
          >
          <div style="margin-top:30px;">
            <div
              v-for="(item, index) in takeAwayArray"
              :key="index"
              style="margin-top:15px;"
            >
              <p>
                问题{{ index + 1 }}：{{ item.questionTitle
                }}<i
                  class="el-icon-delete"
                  style="margin-left:20px;"
                  size="60"
                  @click="removeProblem(item.questionId)"
                ></i>
              </p>
              <el-input
                type="textarea"
                :rows="2"
                placeholder="请输入内容"
                style="margin-top:10px;"
                v-model="item.answer"
              >
              </el-input>
            </div>
          </div>
        </div>
        <span
          slot="footer"
          class="dialog-footer"
          v-show="detaildStatus != true"
        >
          <el-button @click="saveDialogVisible = false" size="small"
            >取 消</el-button
          >
          <el-button type="primary" @click="confirmAnswer()" size="small"
            >确 定</el-button
          >
        </span>
      </div>
      </el-tab-pane>
      <el-tab-pane label="上传视频" name="info4" :disabled='info2Flag'>
        <div>
        <div class="dialog-content">
          <p style="color:#000;">
            试讲视频：<b style="color:#666;"
              >（视频大小不超过150M，视频格式为rm，rmvb，mpeg1-4 mov mtv dat wmv
              avi 3gp amv dmv flv）</b
            >
          </p>
          <el-button type="primary" size="small" style="margin-top:20px;" @click="choiceTeacherProblem()"
            >选择问题</el-button
          >
           <p v-html="getResult"></p>
          <el-form
            :model="videoForm"
            status-icon
            ref="videoForm"
            label-width="120px"
            size="small"
          >
            <el-row :gutter="20">
              <el-col>
                <el-form-item label="上传视频" prop="" style="margin-top:30px;">
                  <upload-video
                    class="avatar-uploader"
                    @video_change="video_change"
                    :default_src="videoForm.videoUrl"
                  >
                  </upload-video>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col>
                <el-form-item label="教师风采" prop="photo">
                  <el-upload
                    class="upload-demo"
                    list-type="picture-card"
                    :action="fileUpload"
                    :on-preview="handlePreview"
                    :before-upload="photoBeforeAvatarUpload"
                    :on-success="handVideoSuccess"
                    :on-remove="handleRemove"
                    accept="image/jpeg, image/png/, image/gif"
                    multiple
                    :limit="10"
                    :on-exceed="handleExceed"
                    :file-list="fileList"
                  >
                    <i class="el-icon-plus"></i>
                    <div slot="tip" class="el-upload__tip">
                      照片大小不超过10M，格式JPG，最多不超过10张
                    </div>
                  </el-upload>
                  <!-- <img width="100%" :src="dialogContImgUrl" alt=""> -->
                  <!-- <el-upload
                  class="avatar-uploader"
                  :action="fileUpload"
                  :show-file-list="false"
                  :on-success="demeanorSuccess"
                  :before-upload="beforeAvatarUpload"
                  :on-exceed="exceedTips"
                  :limit='3'
                  accept="image/jpeg, image/png/, image/gif"
                >
                  <img  
                    v-if="dialogContImgUrl"
                    :src="dialogContImgUrl"
                    class="avatar"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload> -->
                  <!-- <span>建议图片尺寸:650*480</span> -->
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <span
          slot="footer"
          class="dialog-footer"
          v-show="detaildStatus != true"
        >
          <el-button @click="saveDialogVisible = false" size="small"
            >取 消</el-button
          >
          <el-button
            type="primary"
            @click="confirmSaveVideo('videoForm')"
            size="small"
            >确 定</el-button
          >
        </span>
      </div>
      </el-tab-pane>
       <el-tab-pane label="面试评分" name="info6" :disabled='info2Flag'>
         <div>
        <div class="dialog-content">
          <el-form
            :model="faceRuleForm"
            status-icon
            :rules="faceRules"
            ref="faceRuleForm"
            label-width="120px"
            size="small"
          >
            <el-row :gutter="20">
              <el-col>
                <el-form-item label="整体评分" prop="overallScore">
                  <el-radio-group v-model="faceRuleForm.overallScore">
                    <el-radio label="1">A+</el-radio>
                    <el-radio label="2">A</el-radio>
                    <el-radio label="3">B+</el-radio>
                    <el-radio label="4">B</el-radio>
                    <el-radio label="5">C</el-radio>
                    <el-radio label="6">D</el-radio>
                  </el-radio-group>
                </el-form-item>
                 <el-form-item label="1.形象气质" prop="imageTemperament">
                  <el-radio-group v-model="faceRuleForm.imageTemperament">
                    <el-radio label="1">负分</el-radio>
                    <el-radio label="2">正常</el-radio>
                    <el-radio label="3">加分</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="2.沟通表达" prop="communicationExpression">
                  <el-radio-group v-model="faceRuleForm.communicationExpression">
                    <el-radio label="1">负分</el-radio>
                    <el-radio label="2">正常</el-radio>
                    <el-radio label="3">加分</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="3.在京代课经验" prop="substituteLessonsBeijing">
                  <el-radio-group v-model="faceRuleForm.substituteLessonsBeijing">
                    <el-radio label="1">5年以下</el-radio>
                    <el-radio label="2">5-10年</el-radio>
                    <el-radio label="3">10年以上</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="4.教学经验" prop="teachingExperience">
                  <el-radio-group v-model="faceRuleForm.teachingExperience">
                    <el-radio label="1">5年以下</el-radio>
                    <el-radio label="2">5-10年</el-radio>
                    <el-radio label="3">10-15年</el-radio>
                    <el-radio label="4">15-20以上</el-radio>
                  </el-radio-group>
                </el-form-item>
               <el-form-item label="5.主要代课区域" prop="provinceIdOne">
                  <el-row :gutter="20">
                    <el-col :span="6">
                      <el-select
                        v-model="faceRuleForm.provinceIdOne"
                        placeholder="请选择"
                        size="small"
                      >
                        <el-option
                          v-for="item in provinceList"
                          :key="item.areaId"
                          :label="item.name"
                          :value="item.areaId"
                        ></el-option>
                      </el-select>
                    </el-col>
                    <el-col :span="6">
                      <el-select
                        v-model="faceRuleForm.provinceIdTwo"
                        placeholder="请选择"
                        size="small"
                      >
                        <el-option
                          v-for="item in provinceList"
                          :key="item.areaId"
                          :label="item.name"
                          :value="item.areaId"
                        ></el-option>
                      </el-select>
                    </el-col>
                   
            </el-row>
                </el-form-item>
                <el-form-item label="6.其他合作机构" prop="remark">
                  <el-row :gutter="20">
                    <el-col :span="6">
                       <el-input
                    type="text"
                    placeholder="机构一"
                    v-model="faceRuleForm.otherPartnersOne"
                  >
                  </el-input>
                    </el-col>
                     <el-col :span="6">
                      <el-input
                    type="text"
                    placeholder="机构二"
                    v-model="faceRuleForm.otherPartnersTwo"
                  >
                  </el-input>
                    </el-col>
                  <el-col :span="6">
                    <el-input
                    type="text"
                    placeholder="机构三"
                    v-model="faceRuleForm.otherPartnersThree"
                  >
                  </el-input>
                  </el-col>
                  </el-row>
                </el-form-item>
               
                <el-form-item label="7.教学特色" prop="teachingCharacteristics">
                  <el-radio-group v-model="faceRuleForm.teachingCharacteristics">
                    <el-radio label="1">负分</el-radio>
                    <el-radio label="2">正常</el-radio>
                    <el-radio label="3">加分</el-radio>
                  </el-radio-group>
                </el-form-item>
                 <el-form-item label="8.合作求职态度" prop="jobHuntingAttitude">
                  <el-radio-group v-model="faceRuleForm.jobHuntingAttitude">
                    <el-radio label="1">负分</el-radio>
                    <el-radio label="2">正常</el-radio>
                    <el-radio label="3">加分</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="整体评价" prop="overallEvaluation">
                  <el-input
                    type="textarea"
                    :rows="2"
                    :minRows="2"
                    :maxRows="6"
                    placeholder="请输入内容"
                    v-model="faceRuleForm.overallEvaluation"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <span
          slot="footer"
          class="dialog-footer"
          v-show="detaildStatus != true"
        >
          <el-button @click="saveDialogVisible = false" size="small"
            >取 消</el-button
          >
          <el-button
            type="primary"
            @click="confirmFace('faceRuleForm')"
            size="small"
            >确 定</el-button
          >
        </span>
      </div>
       </el-tab-pane>
     </el-tabs>
    </el-dialog>
    <el-dialog
      width="700px"
      center
      :title="answerDialog.title"
      :visible.sync="answerSync"
    >
      <div class="dialog-content">
        <el-form
          :model="answerRuleForm"
          status-icon
          :rules="answerRules"
          ref="answerRuleForm"
          label-width="100px"
          size="small"
        >
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="问题" prop="question">
                <el-input
                  v-model="answerRuleForm.question"
                  placeholder="请输入问题"
                ></el-input>
              </el-form-item>
              <el-form-item label="学段" prop="periodId">
                <el-select
                  v-model="answerRuleForm.periodId"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="option in phaseLists"
                    :key="option.macroId"
                    :label="option.name"
                    :value="option.macroId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="answerSync = false" size="small">取 消</el-button>
        <el-button
          type="primary"
          @click="answerConfirmSave('answerRuleForm')"
          size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog width="900px" center :visible.sync="problemPopup">
      <div class="dialog-content">
          <div class="btn-area">
            <div class="right">
              <el-button-group>
                <el-button type="default" size="small" @click="answerAdd">
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#icon-xinzeng" />
                  </svg>
                  <span>添加</span>
                </el-button>
                <el-button type="default" size="small" @click="answerEdit">
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#icon-edit" />
                  </svg>
                  <span>编辑</span>
                </el-button>
                <el-button type="default" size="small" @click="answerDelete">
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#icon-trash" />
                  </svg>
                  <span>删除</span>
                </el-button>
              </el-button-group>
            </div>
          </div>
          <div class="table-area">
            <el-table
              size="small"
              :data="answerArray"
              style="width: 100%; margin-bottom: 20px"
              row-key="id"
              border
              height="400px"
              tooltip-effect="dark"
              ref="answerListTable"
              @selection-change="answerSelectionChange"
            >
              <el-table-column
                type="selection"
                width="55"
                align="center"
                 :reserve-selection="true"
                 :selectable="checkSelectable"
              ></el-table-column>
              <el-table-column
                prop="question"
                label="问题"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="periodId"
                label="学段"
                :formatter="answerPeriodId"
                align="center"
              ></el-table-column>
            </el-table>
          </div>
          <div class="page-area">
            <el-pagination
              @size-change="handleSizeChange2"
              @current-change="handleCurrentChange2"
              :current-page="answerObject.pageNumber"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="answerObject.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total2"
              background
            ></el-pagination>
          </div>
       
      </div>
       <span slot="footer" class="dialog-footer">
          <el-button size="small" @click="problemPopup = false">取 消</el-button>
          <el-button type="primary" @click="takeAway()" size="small"
            >确 定</el-button
          >
        </span>
    </el-dialog>
    <el-dialog
      :title="followUpDialog.title"
      :visible.sync="followUpDialogVisible"
      :close-on-click-modal="false"
      width="900px"
      center
    >
      <div class="dialog-content">
        <el-form
          :model="saveRuleFormFollowUp"
          status-icon
          ref="saveRuleFormFollowUp"
          label-width="100px"
          size="small"
        >
          <el-row :gutter="20">
            <el-col :span="6">
              <el-form-item label="学生姓名" prop="studentName">
                <el-input
                  v-model="saveRuleForm.infoTitle"
                  placeholder="请输入学生姓名"
                  size="small"
                  :maxlength="5"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="学生年级" prop="sectionId">
                <el-select
                  v-model="saveRuleForm.sectionId"
                  placeholder="请选择"
                  size="small"
                >
                  <el-option
                    v-for="item in sectionList"
                    :key="item.macroId"
                    :label="item.name"
                    :value="item.macroId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="上课时间" prop="">
                <el-date-picker
                  size="small"
                  v-model="timePickerValue"
                  type="daterange"
                  align="right"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :picker-options="pickerOptions"
                  @change="datePickerChange"
                  value-format="yyyy-MM-dd HH:mm:ss"
                 :default-time="['00:00:00', '23:59:59']"
                ></el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-form-item label="详情介绍" prop="followContent">
              <editor
                :content_height="content_height"
                :set_editor_content="set_editor_content"
                @edit_content_change="edit_content_change"
              ></editor>
            </el-form-item>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="followUpDialogVisible = false" size="small"
          >取 消</el-button
        >
        <el-button
          type="primary"
          @click="confirmSaveFollowUp('saveRuleFormFollowUp')"
          size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 审核弹窗 -->
    <el-dialog width="1200px" center :visible.sync="examineDialog" :close-on-click-modal="false">
        <el-tabs v-model="activeName2"  @tab-click="classStatusClick">
          <el-tab-pane label="基本信息" name="info1">
              <div>
                <p v-show="examnineDate.isBasicdata==2" style="color:red;font-size:16px;">此页面已审核</p>
        <div class="dialog-content">
                <el-form
                  :model="examnineDate"
                  status-icon
                  ref="examnineDate"
                  label-width="120px"
                  size="small"
                >
                <el-row :gutter="20">
              <el-col :span="20" >
                  <el-row :gutter="20">
                      <el-col :span="8">
                        <el-form-item label="真实姓名：" prop="name">
                          <span>{{examnineDate.realname}}</span>
                        </el-form-item>
                      </el-col>
                       <el-col :span="8">
                        <el-form-item label="联系方式：" prop="contact">
                          <span>{{examnineDate.contact}}</span>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-form-item label="出生日期：" prop="birthday">
                          <span>{{examnineDate.birthday?examnineDate.birthday.split(' ')[0]:""}}</span>
                        </el-form-item>
                      </el-col>
                  </el-row>
                  <el-row :gutter="20" >
                    <el-col :span="8">
                      <el-form-item label="身份证号：" prop="idcard">
                          <span>{{examnineDate.idcard}}</span>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                          <el-form-item label="性别：" prop="sexId">
                            <span>{{examnineDate.sexId==1?'男':'女'}}</span>
                          </el-form-item>
                        </el-col>
                        <el-col :span="8">
                        <el-form-item label="籍贯：" prop="sexId">
                            <span>{{examnineDate.provinceName}}{{examnineDate.cityName}}{{examnineDate.countyName}}</span>
                          </el-form-item>
                      </el-col>
                        </el-row>
                        <el-row :gutter="20">
                    <el-col :span="8">
                      <el-form-item label="教学方向：" prop="teachingDirection">
                        <span>{{examnineDate.teachingDirection==1?'学科类':examnineDate.teachingDirection==2?'素质教育类':''}}</span>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="选择身份：" prop="teachingDirection">
                        <span>{{examnineDate.identityType==1||examnineDate.identityType==3?'老师':examnineDate.identityType==2||examnineDate.identityType==4?'大学生-在读':''}}</span>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="账号：">
                          <span>{{teacherInfoAccount}}</span>
                        </el-form-item>
                      </el-col>
                  </el-row>
                  <el-row :gutter="20" v-show="saveRuleForm.identityType==2||saveRuleForm.identityType==4">
                    <el-col :span="8">
                      <el-form-item label="在读阶段：" prop="teachingAgeId">
                        <span>{{dealStage(examnineDate)}}</span>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="在读院校：" prop="teachingAgeId">
                        <span>{{examnineDate.schoolName}}</span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="20">
                      <el-form-item label="教授学段：" prop="periodId">
                        <el-checkbox-group v-model="examnineDate.periodId" @change="changes()">
                          <el-checkbox disabled  :label="107">高中</el-checkbox>
                          <el-checkbox disabled :label="106">初中</el-checkbox>
                          <el-checkbox disabled :label="105">小学</el-checkbox>
                          <el-checkbox disabled :label="108">艺术培训</el-checkbox>
                          <el-checkbox disabled :label="109">小语种</el-checkbox>
                        </el-checkbox-group>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="20">
                      <el-form-item label="教授年级：" prop="sectionId">
                        <el-checkbox-group v-model="examnineDate.sectionId" >
                          <el-checkbox  disabled :label="98" :key="98" >高三</el-checkbox>
                          <el-checkbox  disabled :label="97" :key="97" >高二</el-checkbox>
                          <el-checkbox  disabled :label="96" :key="96" >高一</el-checkbox>
                          <el-checkbox  disabled :label="20" :key="20" >初三</el-checkbox>
                          <el-checkbox  disabled :label="19" :key="19" >初二</el-checkbox>
                          <el-checkbox  disabled :label="18" :key="18" >初一</el-checkbox>
                          <el-checkbox  disabled :label="95" :key="95" >六年级</el-checkbox>
                          <el-checkbox  disabled :label="94" :key="94" >五年级</el-checkbox>
                          <el-checkbox  disabled :label="93" :key="93" >四年级</el-checkbox>
                          <el-checkbox  disabled :label="92" :key="92" >三年级</el-checkbox>
                          <el-checkbox  disabled :label="110" :key="110" >艺术培训</el-checkbox>
                          <el-checkbox  disabled :label="111" :key="111" >小语种</el-checkbox>
                        </el-checkbox-group>
                    
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20" v-show="examnineDate.teachingDirection==1&&examnineDate.identityType==1">
                    <el-col :span="18">
                      <el-form-item label="教授科目：" prop="subjectId"  class="subjectId_ul">
                        <el-radio-group v-model="examnineDate.subjectId">
                          <el-radio  v-for="(item, index) in teacherSubjectList"  :disabled="examnineDate.subjectId!=item.macroId" :label="item.macroId"  :key="index">{{item.name}}</el-radio>
                        </el-radio-group>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20" v-show="examnineDate.teachingDirection!=1||examnineDate.identityType!=1">
                    <el-col :span="18">
                      <el-form-item label="教授科目" prop="subjectIdCheck"  class="subjectId_ul">
                        <el-checkbox-group v-model="examnineDate.subjectIdCheck" >
                          <el-checkbox v-for="(item, index) in teacherSubjectListCheck"  :label="item.macroId" :disabled="true" :key="index" >{{item.name}}</el-checkbox>
                        </el-checkbox-group>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  
                  <el-row :gutter="20" v-show="examnineDate.identityType==1||examnineDate.identityType==3">
                    <el-col :span="8">
                      <el-form-item label="教龄：" prop="teachingAgeId">
                        <span>{{dealTeachingAgeId(examnineDate)}}</span>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="学历：" prop="educationId">
                        <span>{{educationId(examnineDate.educationId)}}</span>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item label="毕业院校：" prop="graduationSchool">
                        <span>{{examnineDate.graduationSchool}}</span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20" v-show="examnineDate.identityType=='1'&&examnineDate.teachingDirection==1">
                    <el-col :span="6">
                      <el-form-item label="任职学校：" prop="schoolName">
                        <span>{{examnineDate.schoolName}}</span>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item label="教师职称：" prop="teachTitleId">
                        <span>{{dealTeachTitleId(examnineDate)}}</span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="10" class="img-col">
                      <el-form-item label="个人身份认证" prop="idjust">
                        <el-image
                          v-if="examnineImages.photo1"
                          :src="examnineImages.photo1"
                          class="avatar"
                          style="width:146px;height:108px;border-radius:6px;"
                          :preview-src-list="examnineSrc1">
                      </el-image>
                      <div class="imgDefault" v-else>
                        <el-image>
                          <div slot="error" class="image-slot">
                            <i class="el-icon-picture-outline" style="font-size:25px;display:block;text-align:center;"></i>
                            <p>暂未上传</p>
                          </div>
                        </el-image>
                      </div>
                      </el-form-item>
                    </el-col>
                    <el-col :span="10" class="img-col">
                      <el-form-item :label="examnineDate.identityType==1||examnineDate.identityType==3?'学历证明':'学生证'" prop="idjust" >
                        <el-image
                          v-if="examnineImages.photo4"
                          :src="examnineImages.photo4"
                          class="avatar"
                          style="width:146px;height:108px;border-radius:6px;"
                          :preview-src-list="examnineSrc4">
                      </el-image>
                      <div class="imgDefault" v-else>
                        <el-image>
                          <div slot="error" class="image-slot">
                            <i class="el-icon-picture-outline" style="font-size:25px;display:block;text-align:center;"></i>
                            <p>暂未上传</p>
                          </div>
                        </el-image>
                        </div>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="10" v-show="examnineDate.teachingDirection==1" class="img-col">
                      <el-form-item label="教师资格认证" prop="qualifications" >
                        <el-image
                          v-if="examnineImages.photo3"
                          :src="examnineImages.photo3"
                          class="avatar"
                          style="width:146px;height:108px;border-radius:6px;"
                          :preview-src-list="examnineSrc3">
                      </el-image>
                      <div class="imgDefault" v-else>
                        <el-image>
                          <div slot="error" class="image-slot">
                            <i class="el-icon-picture-outline" style="font-size:25px;display:block;text-align:center;"></i>
                            <p>暂未上传</p>
                          </div>
                        </el-image>
                      </div>
                      <el-checkbox style="display:block;" v-model="teacherQualificationCertificate" :disabled="qualDisaled">暂未取得教师资格证</el-checkbox>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12" class="img-col">
                      <el-form-item :label="examnineDate.teachingDirection==2&&examnineDate.identityType==3?'教师荣誉':'专业资质'" prop="qualifications">
                        <el-image v-for="(ele,index) in examnineImages.photo5" :key="index"
                                style="margin-right:10px;width:146px;height:108px;border-radius:6px;"
                                :src="ele"
                                :preview-src-list="examnineImages.photo5"
                              >
                          </el-image>
                      </el-form-item>
                      
                    </el-col>
                  </el-row>
                    </el-col>
                  </el-row>
                  
                </el-form>
              </div>
              <p style="color:red;">注：基本信息审核通过后，教务将可以为老师排课</p>
        <span slot="footer" class="dialog-footer">
          <el-button size="small" type="primary"  v-show="!examnineDate.isBasicdata==2||examnineDate.isBasicdata==0||examnineDate.isBasicdata==1" @click="isBasicFn(2)">驳 回</el-button>
           <el-button size="small" type="primary"  v-show="examnineDate.isBasicdata==3" >已 驳 回</el-button>
          <el-button
            type="primary"
            @click="isBasicFn(1)"
            size="small"
             v-show="!examnineDate.isBasicdata==3||examnineDate.isBasicdata==0||examnineDate.isBasicdata==1"
            >通 过</el-button
          >
          <el-button
            type="primary"
            size="small"
             v-show="examnineDate.isBasicdata==2"
            >已 通 过</el-button
          >
          <el-button
            type="primary"
            @click="isBasicFn(3)"
             v-show="examnineDate.isBasicdata==2||examnineDate.isBasicdata==3"
            size="small"
            >撤 回</el-button
          >
        </span>
      </div>
          </el-tab-pane>
          <el-tab-pane label="展示资料" name="info2">
              <div>
                <div class="dialog-content dialog-imgs">
                    <el-form
                  :model="examnineDate"
                  status-icon
                  ref="examnineDate"
                  label-width="120px"
                  size="small"
                >
                <el-row :gutter="20">
                  <el-col :span="5">
                      <el-form-item label="本人头像：" prop="photo">
                        <img
                            v-if="examnineDate.photo"
                            :src="examnineDate.photo"
                            class="avatar"
                            style="width:100px;height:100px;"
                          />
                      </el-form-item>
                    </el-col>
                    <el-col :span="17" >
                <el-row :gutter="20">
                  <el-col :span="7">
                    <el-form-item label="昵称：" prop="name">
                      <span>{{examnineDate.name}}</span>
                    </el-form-item>
                  </el-col>
                  <el-col :span="16">
                  <el-form-item label="是否接收试听：" prop="ifAcceptAudition">
                      <span>{{examnineDate.ifAcceptAudition==1?'可接受45分钟~1小时试听':'不接受'}}</span>
                    </el-form-item>
                </el-col>
                </el-row>
                 <el-row :gutter="20">
                    <el-col :span="7">
                      <el-form-item label="授课方式：" prop="classForm">
                      <span v-show="examnineDate.classForm==0">均可</span>
                      <span v-show="examnineDate.classForm==1">线上</span>
                      <span v-show="examnineDate.classForm==2">线下</span>
                    </el-form-item>
                    </el-col>
                    <el-col :span="14" v-show="examnineDate.classForm==0||examnineDate.classForm==2">
                      <el-form-item label="可面授地点：" prop="classForm">
                        <span>{{examnineDate.currentProvincesName}}{{examnineDate.currentCitysName}}{{getCurrentCountysName}}</span>
                      </el-form-item>
                    </el-col>
                </el-row>
              </el-col>
                </el-row>
                <el-row :gutter="20" v-show="examnineDate.teachingDirection==1">
                    <el-col :span="20">
                      <el-form-item label="擅教学生：">
                        <span style="color:#ccc;"
                          >一对一教学，讲究个性化教学，根据以往经历，请您选择您更擅长带哪类学生？</span
                        >
                        <el-checkbox-group v-model="skilfulTeachArray">
                            <el-checkbox disabled v-for="(item,index) in skilfulTeachDate" :key="index" :label="item.macroId" :value="item.macroId"
                            >{{item.name}}</el-checkbox
                          >
                        </el-checkbox-group>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20" v-show="examnineDate.teachingDirection==1">
                    <el-col :span="20">
                      <el-form-item label="讲课风格:">
                        <el-checkbox-group v-model="skilfulArray">
                            <el-checkbox disabled v-for="(item,index) in skilfulDate" :key="index" :label="item.macroId" :value="item.macroId"
                            >{{item.name}}</el-checkbox
                          >
                        </el-checkbox-group>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20" v-show="examnineDate.teachingDirection==1">
                    <el-col :span="20">
                      <el-form-item label="教学特点:" prop="teachTrait">
                        <span>{{examnineDate.teachTrait}}</span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20" v-show="examnineDate.teachingDirection==1">
                  <el-col :span="20">
                    <el-form-item label="成功案例:">
                          <div class="caseDiv" v-for="(item, index) in caseList" :key="index">
                    <div class="caseLeft">
                        <p>
                          <span>{{item.studentName}}</span>
                          <span>{{studentSectionIdFn(item.studentSectionId)}}/{{studentSubjectIdFn(item.studentSubjectId)}}</span>
                          <span>{{item.classTimeBegin}} 至 {{item.classTimeEnd}}</span>
                          <span>{{studentAchievementFn(item.studentAchievement)}}</span>
                        </p>
                        <p>{{item.detailContent}}</p>
                        <p>
                          <el-image  style="cursor:pointer;width:100px;height:100px;margin-right:10px;margin-top:15px;" v-for='(items,indexs) in item.photoList'  :preview-src-list="item.photoList" :src="items"  :key='indexs' alt=""></el-image>
                        </p>
                    </div>
                  </div>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20" v-show="examnineDate.teachingDirection==2">
                  <el-col :span="20">
                    <el-form-item label="代课经历:">
                          <div class="caseDiv" v-for="(item, index) in caseList" :key="index">
                    <div class="caseLeft">
                        <p>
                          <span>{{item.studentName}}</span>
                          <span>{{item.classTimeBegin}} 至 {{item.classTimeEnd}}</span>
                          <span>{{experienceFormListFn(item.studentAchievement)}}</span>
                        </p>
                        <p>{{item.detailContent}}</p>
                        <p>
                          <el-image  style="cursor:pointer;width:100px;height:100px;margin-right:10px;margin-top:15px;" v-for='(items,indexs) in item.photoList'  :preview-src-list="item.photoList" :src="items"  :key='indexs' alt=""></el-image>
                        </p>
                    </div>
                  </div>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20" v-show="examnineDate.teachingDirection==2">
                  <el-col :span="20">
                    <el-form-item label="作品展示:">
                      <el-image  style="cursor:pointer;width:100px;height:100px;margin-right:10px;margin-top:15px;" v-for='(items,indexs) in exhibitionPhotos'   :src="items"  :key='indexs' alt=""></el-image>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="20">
                    <el-form-item label="附件简历" prop="hopeMinFee">
                      <div class="coachAgreement_tip">
                        <a target="view_window" :href="examnineDate.resume">{{examnineDate.resume}}</a>
                        <span v-show="!examnineDate.resume"
                          >还未上传附件简历</span
                        >
                      </div>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20" >
                  <el-col :span="20">
                    <el-form-item label="期望课酬" prop="hopeMinFee">
                        <span style="color:red;">{{examnineDate.hopeMinFee}}</span>
                        <span style="padding:0 13px;">至</span>
                        <span style="color:red;">{{examnineDate.hopeMaxFee}}元/课时</span>
                        <span style="color:#999;">（1课时=1小时）</span>
                      </el-form-item>
                  </el-col>
                </el-row>
                </el-form>
                </div>
              </div>
          </el-tab-pane>
          <el-tab-pane label="面试答题" name="info3">
            <div>
              <p v-show="examnineDate.isInterview==2" style="color:red;font-size:16px;padding-bottom:10px;">此页面已审核</p>
              <p><span style="color:red;padding-right:10px;margin-top:40px;">*</span>面试部分主要是在教学中遇到的问题，请依据老师的实际情况填写</p>
              <div class="dialog-content">
                <div v-for="(item, index) in answerDate" :key="index">
                  <p style="margin-top:20px;font-weight: 600;font-size:16px;">
                    问题{{ index + 1 }}：{{ item.questionTitle }}
                  </p>
                  <p style="margin-top:15px;">答：{{ item.answer }}</p>
                   <el-divider></el-divider>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="上传视频" name="info4">
            <div>
              <p v-show="examnineDate.videoAuthStatus==2" style="color:red;font-size:16px;">此页面已审核</p>
              <div class="dialog-content">
                 <p v-html="getResult"></p>
                <el-form
            :model="videoForm"
            status-icon
            ref="videoForm"
            label-width="120px"
            size="small"
          >
            <el-row :gutter="20">
              <el-col>
                <el-form-item label="上传视频" prop="" style="margin-top:30px;">
                 <div class="video_area">
                    <video :src="videoForm.videoUrl" controls v-show="videoForm.videoUrl"></video>
                    <div v-show="!videoForm.videoUrl">暂无</div>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col>
                <el-form-item label="教师风采" prop="photo">
                  <el-upload
                    accept="image/jpeg, image/png/, image/gif"
                    class="upload-demo upDisabled"
                    disabled
                    list-type="picture-card"
                    :action="fileUpload"
                    :before-upload="photoBeforeAvatarUpload"
                    :on-preview="handlePreview"
                    :on-success="handVideoSuccess"
                    :on-remove="handleRemove"
                     multiple
                    :limit="10"
                    :on-exceed="handleExceed"
                    :file-list="fileList"
                  >
                    <i class="el-icon-plus"></i>
                  </el-upload>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
                <!-- <el-row :gutter="20">
                  <el-col :span="12" class="img-col">
                    <span>个人身份认证（正面）:</span
                    ><img :src="examnineImages.photo1" alt="" style="width:170px;" />
                  </el-col>
                </el-row> -->
              </div>
             
            </div>
          </el-tab-pane>
          <el-tab-pane label="面试评分" name="info6">
              <div>
        <div class="dialog-content">
          <div style="margin-left:40px;" class="examine-div1">
            <h2>录入员评价</h2>
            <p>
              整体评分：<span>{{ overallScoreFn(FaceMarkList)}}</span>
            </p>
            <p>
              1.形象气质：<span>{{ imageTemperamentFn(FaceMarkList) }}</span>
            </p>
            <p>
              2.沟通表达：<span>{{ communicationExpressionFn(FaceMarkList) }}</span>
            </p>
            <p>
              3.在京代课经验：<span>{{ substituteLessonsBeijingFn(FaceMarkList) }}</span>
            </p>
            <p>
              4.教学经验：<span>{{ teachingExperienceFn(FaceMarkList) }}</span>
            </p>
            <p>
              5.主要代课区域：<span>{{ provinceIdFn(FaceMarkList.provinceIdOne) }}  {{ provinceIdFn(FaceMarkList.provinceIdTwo) }}</span>
            </p>
            <p>
              6.其他合作机构：<span>{{ FaceMarkList.otherPartnersOne }}  {{ FaceMarkList.otherPartnersTwo }} {{ FaceMarkList.otherPartnersThree }}</span>
            </p>
            <p>
              7.教学特色：<span>{{ teachingCharacteristicsFn(FaceMarkList) }}</span>
            </p>
            <p>
              8.合作求职态度：<span>{{ jobHuntingAttitudeFn(FaceMarkList)}}</span>
            </p>
            <p>
              整体评价：<span>{{ FaceMarkList.overallEvaluation }}</span>
            </p>
            <p style="display: flex;align-items: center;justify-content:center;">
              <el-button type="primary" size="small" @click="isAgree(1)">同意并使用</el-button>
            </p>
          </div>
          <el-form
            :model="examineRuleForm"
            status-icon
            ref="examineRuleForm"
            label-width="120px"
            size="small"
            style="margin-top:30px;"
          >
            <h2 style="margin-left:30px;">审核员评价</h2>
            <el-row :gutter="20" style="margin-top:30px;">
              <el-col>
               <el-form-item label="整体评分" prop="overallScore">
                  <el-radio-group v-model="examineRuleForm.overallScore">
                    <el-radio label="1">A+</el-radio>
                    <el-radio label="2">A</el-radio>
                    <el-radio label="3">B+</el-radio>
                    <el-radio label="4">B</el-radio>
                    <el-radio label="5">C</el-radio>
                    <el-radio label="6">D</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="1.形象气质" prop="imageTemperament">
                  <el-radio-group v-model="examineRuleForm.imageTemperament">
                    <el-radio label="1">负分</el-radio>
                    <el-radio label="2">正常</el-radio>
                    <el-radio label="3">加分</el-radio>
                  </el-radio-group>
                </el-form-item>
                 <el-form-item label="2.沟通表达" prop="communicationExpression">
                  <el-radio-group v-model="examineRuleForm.communicationExpression">
                    <el-radio label="1">负分</el-radio>
                    <el-radio label="2">正常</el-radio>
                    <el-radio label="3">加分</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="3.在京代课经验" prop="substituteLessonsBeijing">
                  <el-radio-group v-model="examineRuleForm.substituteLessonsBeijing">
                    <el-radio label="1">5年以下</el-radio>
                    <el-radio label="2">5-10年</el-radio>
                    <el-radio label="3">10年以上</el-radio>
                  </el-radio-group>
                </el-form-item>
                 <el-form-item label="4.教学经验" prop="teachingExperience">
                  <el-radio-group v-model="examineRuleForm.teachingExperience">
                    <el-radio label="1">5年以下</el-radio>
                    <el-radio label="2">5-10年</el-radio>
                    <el-radio label="3">10-15年</el-radio>
                    <el-radio label="4">15-20以上</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="5.主要代课区域" prop="provinceIdOne">
                  <el-row :gutter="20">
                    <el-col :span="6">
                      <el-select
                        v-model="examineRuleForm.provinceIdOne"
                        placeholder="请选择"
                        size="small"
                      >
                        <el-option
                          v-for="item in provinceList"
                          :key="item.areaId"
                          :label="item.name"
                          :value="item.areaId"
                        ></el-option>
                      </el-select>
                    </el-col>
                    <el-col :span="6">
                      <el-select
                        v-model="examineRuleForm.provinceIdTwo"
                        placeholder="请选择"
                        size="small"
                      >
                        <el-option
                          v-for="item in provinceList"
                          :key="item.areaId"
                          :label="item.name"
                          :value="item.areaId"
                        ></el-option>
                      </el-select>
                    </el-col>
            </el-row>
                </el-form-item>
                <el-form-item label="6.其他合作机构" prop="remark">
                  <el-row :gutter="20">
                    <el-col :span="6">
                       <el-input
                    type="text"
                    placeholder="机构一"
                    v-model="examineRuleForm.otherPartnersOne"
                  >
                  </el-input>
                    </el-col>
                     <el-col :span="6">
                      <el-input
                    type="text"
                    placeholder="机构二"
                    v-model="examineRuleForm.otherPartnersTwo"
                  >
                  </el-input>
                    </el-col>
                  <el-col :span="6">
                    <el-input
                    type="text"
                    placeholder="机构三"
                    v-model="examineRuleForm.otherPartnersThree"
                  >
                  </el-input>
                  </el-col>
                  </el-row>
                </el-form-item>
                <el-form-item label="7.教学特色" prop="teachingCharacteristics">
                  <el-radio-group v-model="examineRuleForm.teachingCharacteristics">
                    <el-radio label="1">负分</el-radio>
                    <el-radio label="2">正常</el-radio>
                    <el-radio label="3">加分</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="8.合作求职态度" prop="jobHuntingAttitude">
                  <el-radio-group v-model="examineRuleForm.jobHuntingAttitude">
                    <el-radio label="1">负分</el-radio>
                    <el-radio label="2">正常</el-radio>
                    <el-radio label="3">加分</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="整体评价" prop="overallEvaluation">
                  <el-input
                    type="textarea"
                    :rows="2"
                    :minRows="2"
                    :maxRows="6"
                    placeholder="请输入内容"
                    v-model="examineRuleForm.overallEvaluation"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button size="small" @click="examineDialog =false">取 消</el-button>
          <el-button
            type="primary"
            @click="confirmExamineFace('examineRuleForm')"
            size="small"
            >确 定</el-button
          >
        </span>
      </div>
          </el-tab-pane>
        </el-tabs>
    </el-dialog>
    <el-dialog
      :title="caseUpDialog.title"
      :visible.sync="casePopup"
      :close-on-click-modal="false"
      width="1000px"
      center
    >
      <div class="dialog-content">
         <el-form  
          :model="caseRuleForm"
          status-icon
          :rules="scaseResultRules"
          ref="caseRuleForm"
          label-width="110px"
          size="small"
        >
           <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="机构/个人" prop="studentName">
                <el-input
                  v-model="caseRuleForm.studentName"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="年级" prop="studentSectionId">
                <el-select
                  v-model="caseRuleForm.studentSectionId"
                  placeholder="请选择"
                  size="small"
                >
                  <el-option
                    v-for="item in sectionList2"
                    :key="item.macroId"
                    :label="item.name"
                    :value="item.macroId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
             <el-col :span="8">
              <el-form-item label="学生类型" prop="studentAchievement">
                <el-select
                  v-model="caseRuleForm.studentAchievement"
                  placeholder="请选择"
                  size="small"
                >
                  <el-option
                    v-for="item in studentAchievementList"
                    :key="item.macroId"
                    :label="item.name"
                    :value="item.macroId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="开始上课时间" prop="classTimeEnd" >
                <el-date-picker
                  v-model="casePickerValue1"
                  type="date"
                   @change="casePickerChange1()"
                  :picker-options="startDatePicker"
                  placeholder="选择日期"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="结束上课时间" prop="classTimeEnd">
                <el-date-picker
                  v-model="casePickerValue2"
                  type="date"
                   @change="casePickerChange2()"
                  :picker-options="endDatePicker"
                  placeholder="选择日期"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="科目" prop="studentSubjectId">
                <el-select
                  v-model="caseRuleForm.studentSubjectId"
                  placeholder="请选择"
                  size="small"
                >
                  <el-option
                    v-for="item in subjectList2"
                    :key="item.macroId"
                    :label="item.name"
                    :value="item.macroId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
           <el-col :span="20">
              <el-form-item label="详细介绍" prop="detailContent">
                <el-input
                  type="textarea"
                  v-model="caseRuleForm.detailContent"
                  :rows="2"
                  maxlength="500"
                    show-word-limit
                  placeholder="学生上课前的成绩、上课后的成绩、学生的性格等"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
           <el-col :span="20">
              <el-form-item label="上传图片" prop="photos">
                <el-upload  
                :action="fileUpload" 
                :file-list="caseRuleForm.photos" 
                list-type="picture-card" 
                :on-preview="caseHandlePicture" 
                multiple
                :on-remove="caseHandleRemove" 
                :limit="8" 
                :on-change="handleLimit"
                accept="image/jpeg, image/png/, image/gif"
                :on-success="caseHandleAvatarSuccess"
                :class="{hide:hideUpload}"
                ><i class="el-icon-plus"></i>
              </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
       <span slot="footer" class="dialog-footer">
        <el-button @click="casePopup = false" size="small"
          >取 消</el-button
        >
        <el-button
          type="primary"
          @click="confirmCase('caseRuleForm')"
          size="small"
          >添 加</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="驳回原因"
      :visible.sync="seeReasonPoups"
      width="420px"
      center
      @close="closeSaveDialog"
      :close-on-click-modal="false"
      custom-class="product-dialog"
    >
      <p style="text-align:center;font-size:16px;margin-top:30px;padding-bottom:50px;">{{reasonVal}}</p>
    </el-dialog>
    <el-dialog
      title="选择试卷"
      :visible.sync="selectPaperPopup"
      width="1200px"
      center
      custom-class="product-dialog"
    >
      <div class="dialog-content">
        <div class="btn-area select-condition">
          <div class="left">
            <div class="select-condition-item">
              <span class="item-lable">关键字：</span>
              <el-input
                v-model="paperParams.name"
                placeholder="请输入试卷名称"
                size="small"
              ></el-input>
            </div>
            <div class="select-condition-item">
              <span class="item-lable">年级：</span>
              <el-select
                v-model="paperParams.gradeId"
                placeholder="请选择"
                size="small"
              >
                <el-option
                  v-for="item in sectionList"
                  :key="item.macroId"
                  :label="item.name"
                  :value="item.macroId"
                ></el-option>
              </el-select>
            </div>
            <div class="select-condition-item">
              <span class="item-lable">学科：</span>
              <el-select
                v-model="paperParams.subjectId"
                placeholder="请选择"
                size="small"
              >
                <el-option
                  v-for="item in subjectList"
                  :key="item.macroId"
                  :label="item.name"
                  :value="item.macroId"
                ></el-option>
              </el-select>
            </div>
            <div class="select-condition-item">
              <el-button
                type="primary"
                size="small"
                icon="el-icon-search"
                @click="getsearch"
                >搜索</el-button
              >
            </div>
          </div>
        </div>
        <div class="table-area mT10">
          <el-table
            size="small"
            :data="paperList"
            style="width: 100%;margin-bottom: 20px;"
            border
            :row-key="
              row => {
                return row.id;
              }
            "
            height="400px"
            tooltip-effect="dark"
            ref="paperListTable"
            @selection-change="handlePaperSelectionChange"
            @row-click="paperItemClick"
          >
            <el-table-column
              type="selection"
              width="55"
              :reserve-selection="true"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="title"
              label="试卷名称"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="gradeName"
              label="年级"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="subjectName"
              label="科目"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="limitTime"
              label="考试时长"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="totalScore"
              label="总分"
              align="center"
            ></el-table-column>
          </el-table>
        </div>
        <div class="page-area">
          <el-pagination
            @size-change="handlePaperSizeChange"
            @current-change="handlePaperCurrentChange"
            :current-page="paperParams.pageNumber"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="paperParams.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="paperTotal"
            background
          ></el-pagination>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="selectPaperPopup = false" size="small"
          >取 消</el-button
        >
        <el-button type="primary" @click="confirmPaperSelect()" size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>
     <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
    <el-dialog
      title="选择问题"
      :visible.sync="choiceProblemPopup"
      :close-on-click-modal="false"
      width="1000px"
      center
    >
    <div class="btn-area select-condition">
      <div class="left">
        <div class="select-condition-item">
          <span class="item-lable">关键字：</span>
          <el-input
            v-model="subjectParams.title"
            placeholder="请输入查询关键字"
            size="small"
          ></el-input>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">请选择题型：</span>
          <el-select
            v-model="subjectParams.qtpye"
            placeholder="请选择"
            size="small"
          >
            <el-option
                v-for="item in qtpyeLists"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">请选择年级：</span>
          <el-select
            v-model="subjectParams.gradeid"
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="item in gradeidList"
              :key="item.macroId"
              :label="item.name"
              :value="item.macroId"
            ></el-option>
          </el-select>
        </div>
         <div class="select-condition-item">
          <span class="item-lable">请选择学科：</span>
          <el-select
            v-model="subjectParams.subjectid"
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="item in subjectList"
              :key="item.macroId"
              :label="item.name"
              :value="item.macroId"
            ></el-option>
          </el-select>
        </div>
        <div class="select-condition-item">
          <el-button
            type="primary"
            size="small"
            icon="el-icon-search"
            @click="questionsListFn()"
            >搜索</el-button
          >
        </div>
      </div>
    </div>
       <div class="dialog-content">
            <div class="table-area mT10">
            <el-table
        size="small"
        :data="choiceList"
        style="width: 100%; margin-bottom: 20px"
        row-key="id"
        border
        tooltip-effect="dark"
        ref="productListTable"
         @current-change="clickChange"
        @selection-change="choiceSelectionChange"
        @row-click="choiceItemClick"
      >
        <el-table-column label="选择" width="55">
          <template slot-scope="scope">
            <el-radio v-model="tableRadio" :label="scope.row"
              ><i></i
            ></el-radio>
          </template>
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="试题"
          align="center"
          width="355"
        >
          <template slot-scope="scope">
            <div v-html="testTitle(scope.row)"></div>
          </template>
        </el-table-column>
        <el-table-column
          prop="qtpye"
          label="试题题型"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="createTime"
          label="年级/学科"
          align="center"
          :formatter="dealSectionId"
        ></el-table-column>
         <el-table-column
          prop="knowledges"
          label="知识点"
          align="center"
        ></el-table-column>
         <el-table-column
          prop="diff"
          label="难度值(1~5)"
          align="center"
        ></el-table-column>
         <el-table-column
          prop="source"
          label="试题来源(试卷)"
          align="center"
        ></el-table-column>
      </el-table>
          </div>
           <div class="page-area">
            <el-pagination
              @size-change="choiceSizeChange"
              @current-change="choiceCurrentChange"
              :current-page="subjectParams.pageNumber"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="subjectParams.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="choiceTotal"
              background
            ></el-pagination>
          </div>
       </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="choiceProblemPopup = false" size="small"
            >取 消</el-button
          >
          <el-button type="primary" size="small"  @click="sureChoice()"
            >确 定</el-button
          >
        </span>
     </el-dialog>
      <el-dialog
      title="查看讲师"
      :visible.sync="seeDialogVisible"
      width="1200px"
      center
      @close="closeDialog"
      :close-on-click-modal="false"
      custom-class="product-dialog"
    > 
      <el-tabs v-model="seeActiveName" @tab-click="seeHandleClick">
          <el-tab-pane label="基本信息" name="see1" >
            <div>
              <p v-show="saveRuleForm.isBasicdata==2" style="color:red;font-size:16px;">此页面已审核</p>
              <div class="dialog-content">
                <el-form
                  :model="saveRuleForm"
                  status-icon
                  :rules="saveRules"
                  ref="saveRuleForm"
                  label-width="120px"
                  size="small"
                >
                  <el-row :gutter="20">
              <el-col :span="20" >
                  <el-row :gutter="20">
                      <el-col :span="8">
                        <el-form-item label="真实姓名：" prop="name">
                          <span>{{saveRuleForm.realname}}</span>
                        </el-form-item>
                      </el-col>
                       <el-col :span="8">
                        <el-form-item label="联系方式：" prop="contact">
                          <span>{{saveRuleForm.contact}}</span>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-form-item label="出生日期：" prop="birthday">
                          <span>{{saveRuleForm.birthday?saveRuleForm.birthday.split(' ')[0]:""}}</span>
                        </el-form-item>
                      </el-col>
                  </el-row>
                  <el-row :gutter="20" >
                    <el-col :span="8">
                      <el-form-item label="身份证号：" prop="idcard">
                          <span>{{saveRuleForm.idcard}}</span>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                          <el-form-item label="性别：" prop="sexId">
                            <span>{{saveRuleForm.sexId==1?'男':'女'}}</span>
                          </el-form-item>
                        </el-col>
                        <el-col :span="8">
                        <el-form-item label="籍贯：" prop="sexId">
                            <span>{{saveRuleForm.provinceName}}{{saveRuleForm.cityName}}{{saveRuleForm.countyName}}</span>
                          </el-form-item>
                      </el-col>
                        </el-row>
                        <el-row :gutter="20">
                    <el-col :span="8">
                      <el-form-item label="教学方向：" prop="teachingDirection">
                        <span>{{saveRuleForm.teachingDirection==1?'学科类':saveRuleForm.teachingDirection==2?'素质教育类':''}}</span>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="选择身份：" prop="teachingDirection">
                        <span>{{saveRuleForm.identityType==1||saveRuleForm.identityType==3?'老师':saveRuleForm.identityType==2||saveRuleForm.identityType==4?'大学生-在读':''}}</span>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                     <el-form-item label="账号：">
                          <span>{{teacherInfoAccount}}</span>
                        </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20" v-show="saveRuleForm.identityType==2||saveRuleForm.identityType==4">
                    <el-col :span="8">
                      <el-form-item label="在读阶段：" prop="teachingAgeId">
                        <span>{{dealStage(saveRuleForm)}}</span>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="在读院校：" prop="teachingAgeId">
                        <span>{{saveRuleForm.schoolName}}</span>
                      </el-form-item>
                    </el-col>
                     <el-col :span="8">
                    
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="20">
                      <el-form-item label="教授学段：" prop="periodId">
                        <el-checkbox-group v-model="saveRuleForm.periodId" @change="changes()">
                          <el-checkbox disabled  :label="107">高中</el-checkbox>
                          <el-checkbox disabled :label="106">初中</el-checkbox>
                          <el-checkbox disabled :label="105">小学</el-checkbox>
                          <el-checkbox disabled :label="108">艺术培训</el-checkbox>
                          <el-checkbox disabled :label="109">小语种</el-checkbox>
                        </el-checkbox-group>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="20">
                      <el-form-item label="教授年级：" prop="sectionId">
                        <el-checkbox-group v-model="saveRuleForm.sectionId" >
                          <el-checkbox  disabled :label="98" :key="98" >高三</el-checkbox>
                          <el-checkbox  disabled :label="97" :key="97" >高二</el-checkbox>
                          <el-checkbox  disabled :label="96" :key="96" >高一</el-checkbox>
                          <el-checkbox  disabled :label="20" :key="20" >初三</el-checkbox>
                          <el-checkbox  disabled :label="19" :key="19" >初二</el-checkbox>
                          <el-checkbox  disabled :label="18" :key="18" >初一</el-checkbox>
                          <el-checkbox  disabled :label="95" :key="95" >六年级</el-checkbox>
                          <el-checkbox  disabled :label="94" :key="94" >五年级</el-checkbox>
                          <el-checkbox  disabled :label="93" :key="93" >四年级</el-checkbox>
                          <el-checkbox  disabled :label="92" :key="92" >三年级</el-checkbox>
                          <el-checkbox  disabled :label="110" :key="110" >艺术培训</el-checkbox>
                          <el-checkbox  disabled :label="111" :key="111" >小语种</el-checkbox>
                        </el-checkbox-group>
                    
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="18">
                      <el-form-item label="教授科目：" prop="subjectId"  class="subjectId_ul" v-show="saveRuleForm.teachingDirection==1&&saveRuleForm.identityType==1">
                        <el-radio-group v-model="saveRuleForm.subjectId">
                          <el-radio  v-for="(item, index) in teacherSubjectList"  :disabled="saveRuleForm.subjectId!=item.macroId" :label="item.macroId"  :key="index">{{item.name}}</el-radio>
                        </el-radio-group>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20" v-show="saveRuleForm.teachingDirection!=1||saveRuleForm.identityType!=1">
                    <el-col :span="18">
                      <el-form-item label="教授科目" prop="subjectIdCheck"  class="subjectId_ul">
                        <el-checkbox-group v-model="saveRuleForm.subjectIdCheck" >
                          <el-checkbox v-for="(item, index) in teacherSubjectListCheck"  :label="item.macroId" :disabled="true" :key="index" >{{item.name}}</el-checkbox>
                        </el-checkbox-group>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20" v-show="saveRuleForm.identityType==1||saveRuleForm.identityType==3">
                    <el-col :span="8">
                      <el-form-item label="教龄：" prop="teachingAgeId">
                        <span>{{dealTeachingAgeId(saveRuleForm)}}</span>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="学历：" prop="educationId">
                        <span>{{educationId(saveRuleForm.educationId)}}</span>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="毕业院校：" prop="graduationSchool">
                        <span>{{saveRuleForm.graduationSchool}}</span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20" v-show="saveRuleForm.identityType=='1'&&saveRuleForm.teachingDirection==1">
                    <el-col :span="8">
                      <el-form-item label="任职学校：" prop="schoolName">
                        <span>{{saveRuleForm.schoolName}}</span>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="教师职称：" prop="teachTitleId">
                        <span>{{dealTeachTitleId(saveRuleForm)}}</span>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                     
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="10" class="img-col">
                      <el-form-item label="个人身份认证" prop="idjust">
                        <el-image
                          v-if="uploadImg.idcard"
                          :src="uploadImg.idcard"
                          class="avatar"
                          style="width:146px;height:108px;border-radius:6px;"
                          >
                      </el-image>
                      <div class="imgDefault" v-else>
                        <el-image>
                          <div slot="error" class="image-slot">
                            <i class="el-icon-picture-outline" style="font-size:25px;display:block;text-align:center;"></i>
                            <p>暂未上传</p>
                          </div>
                        </el-image>
                      </div>
                      </el-form-item>
                    </el-col>
                    <el-col :span="10" class="img-col">
                      <el-form-item :label="saveRuleForm.identityType==1||saveRuleForm.identityType==3?'学历证明':'学生证'" prop="idjust" >
                        <el-image
                          v-if="uploadImg.xueli"
                          :src="uploadImg.xueli"
                          class="avatar"
                          style="width:146px;height:108px;border-radius:6px;"
                         >
                      </el-image>
                      <div class="imgDefault" v-else>
                        <el-image>
                          <div slot="error" class="image-slot">
                            <i class="el-icon-picture-outline" style="font-size:25px;display:block;text-align:center;"></i>
                            <p>暂未上传</p>
                          </div>
                        </el-image>
                        </div>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="10" v-show="saveRuleForm.teachingDirection==1" class="img-col">
                      <el-form-item label="教师资格认证" prop="qualifications" >
                        <el-image
                          v-if="uploadImg.qualifications"
                          :src="uploadImg.qualifications"
                          class="avatar"
                          style="width:146px;height:108px;border-radius:6px;"
                         >
                      </el-image>
                      <div class="imgDefault" v-else>
                        <el-image>
                          <div slot="error" class="image-slot">
                            <i class="el-icon-picture-outline" style="font-size:25px;display:block;text-align:center;"></i>
                            <p>暂未上传</p>
                          </div>
                        </el-image>
                      </div>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12" class="img-col">
                      <el-form-item :label="saveRuleForm.teachingDirection==2&&saveRuleForm.identityType==3?'教师荣誉':'专业资质'" prop="qualifications">
                        <el-image v-for="(ele,index) in testPapers" :key="index"
                                style="margin-right:10px;width:146px;height:108px;border-radius:6px;"
                                :src="ele.url"
                                :preview-src-list="testPapers"
                              >
                          </el-image>
                      </el-form-item>
                    </el-col>
                  </el-row>
                    </el-col>
                  </el-row>
                  
                </el-form>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="展示资料" name="see2" >
            <p v-show="saveRuleForm.capesAuthStatus==2" style="color:red;font-size:16px;">此页面已审核</p>
            <div class="dialog-content">
          <el-form
            :model="authenRuleForm"
            status-icon
            :rules="authenRules"
            ref="authenRuleForm"
            label-width="170px"
            size="small"
          >
            <el-row :gutter="20">
                  <el-col :span="5">
                      <el-form-item label="本人头像：" prop="photo">
                        <img
                            v-if="dataRuleForm.photo"
                            :src="dataRuleForm.photo"
                            class="avatar"
                            style="width:100px;height:100px;"
                          />
                      </el-form-item>
                    </el-col>
                    <el-col :span="17" >
                <el-row :gutter="20">
                  <el-col :span="8">
                    <el-form-item label="昵称：" prop="name">
                      <span>{{dataRuleForm.name}}</span>
                    </el-form-item>
                  </el-col>
                  <el-col :span="16">
                  <el-form-item label="是否接收试听：" prop="ifAcceptAudition">
                      <span>{{dataRuleForm.ifAcceptAudition==1?'可接受45分钟~1小时试听':'不接受'}}</span>
                    </el-form-item>
                </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="7">
                      <el-form-item label="授课方式：" prop="classForm">
                      <span v-show="dataRuleForm.classForm==0">均可</span>
                      <span v-show="dataRuleForm.classForm==1">线上</span>
                      <span v-show="dataRuleForm.classForm==2">线下</span>
                    </el-form-item>
                    </el-col>
                    <el-col :span="14" v-show="dataRuleForm.classForm==0||dataRuleForm.classForm==2">
                      <el-form-item label="可面授地点：" prop="classForm">
                        <span>{{dataRuleForm.currentProvincesName}}{{dataRuleForm.currentCitysName}}{{getCurrentCountysName}}</span>
                      </el-form-item>
                    </el-col>
                </el-row>
              </el-col>
                </el-row>
                <el-row :gutter="20" v-show="dataRuleForm.teachingDirection==1">
                    <el-col :span="20">
                      <el-form-item label="擅教学生：">
                        <span style="color:#ccc;"
                          >一对一教学，讲究个性化教学，根据以往经历，请您选择您更擅长带哪类学生？</span
                        >
                        <el-checkbox-group v-model="skilfulTeachArray">
                            <el-checkbox disabled v-for="(item,index) in skilfulTeachDate" :key="index" :label="item.macroId" :value="item.macroId"
                            >{{item.name}}</el-checkbox
                          >
                        </el-checkbox-group>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20" v-show="dataRuleForm.teachingDirection==1">
                    <el-col :span="20">
                      <el-form-item label="讲课风格:">
                        <el-checkbox-group v-model="skilfulArray">
                            <el-checkbox disabled v-for="(item,index) in skilfulDate" :key="index" :label="item.macroId" :value="item.macroId"
                            >{{item.name}}</el-checkbox
                          >
                        </el-checkbox-group>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20" v-show="saveRuleForm.teachingDirection==1">
                    <el-col :span="20">
                      <el-form-item label="教学特点:" prop="teachTrait">
                        <span>{{dataRuleForm.teachTrait}}</span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20" v-show="saveRuleForm.teachingDirection==1">
                  <el-col :span="20">
                    <el-form-item label="成功案例:">
                          <div class="caseDiv" v-for="(item, index) in caseList" :key="index">
                    <div class="caseLeft">
                        <p>
                          <span>{{item.studentName}}</span>
                          <span>{{studentSectionIdFn(item.studentSectionId)}}/{{studentSubjectIdFn(item.studentSubjectId)}}</span>
                          <span>{{item.classTimeBegin}} 至 {{item.classTimeEnd}}</span>
                          <span>{{studentAchievementFn(item.studentAchievement)}}</span>
                        </p>
                        <p>{{item.detailContent}}</p>
                        <p>
                          <el-image  style="cursor:pointer;width:100px;height:100px;margin-right:10px;margin-top:15px;" v-for='(items,indexs) in item.photoList'  :preview-src-list="item.photoList" :src="items"  :key='indexs' alt=""></el-image>
                        </p>
                    </div>
                  </div>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20" v-show="saveRuleForm.teachingDirection==2">
                  <el-col :span="20">
                    <el-form-item label="代课经历:">
                          <div class="caseDiv" v-for="(item, index) in daikeList" :key="index">
                    <div class="caseLeft">
                        <p>
                          <span>{{item.studentName}}</span>
                          <span>{{item.classTimeBegin}} 至 {{item.classTimeEnd}}</span>
                          <span>{{experienceFormListFn(item.studentAchievement)}}</span>
                        </p>
                        <p>{{item.detailContent}}</p>
                        <p>
                          <el-image  style="cursor:pointer;width:100px;height:100px;margin-right:10px;margin-top:15px;" v-for='(items,indexs) in item.photoList'  :preview-src-list="item.photoList" :src="items"  :key='indexs' alt=""></el-image>
                        </p>
                    </div>
                  </div>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20" v-show="saveRuleForm.teachingDirection==2">
                  <el-form-item label="作品展示:">
                    <el-image  style="cursor:pointer;width:100px;height:100px;margin-right:10px;margin-top:15px;" v-for='(items,indexs) in exhibitionPhotos'   :src="items"  :key='indexs' alt=""></el-image>
                  </el-form-item>
                </el-row>
                <el-row :gutter="20" >
                  <el-col :span="20">
                    <el-form-item label="附件简历" prop="hopeMinFee">
                      <div class="coachAgreement_tip">
                        <a target="view_window" :href="dataRuleForm.resume">{{dataRuleForm.resume}}</a>
                        <span v-show="!dataRuleForm.resume"
                          >还未上传附件简历</span
                        >
                      </div>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20" >
                  <el-col :span="20">
                    <el-form-item label="期望课酬" prop="hopeMinFee">
                        <span style="color:red;">{{dataRuleForm.hopeMinFee}}</span>
                        <span style="padding:0 13px;">至</span>
                        <span style="color:red;">{{dataRuleForm.hopeMaxFee}}元/课时</span>
                        <span style="color:#999;">（1课时=1小时）</span>
                      </el-form-item>
                  </el-col>
                </el-row>
          </el-form>
        </div>
          </el-tab-pane>
          <el-tab-pane label="面试答题" name="see3">
            <p v-show="saveRuleForm.isInterview==2" style="color:red;font-size:16px;padding-bottom:10px;">此页面已审核</p>
            <div class="dialog-content">
          <p>面试部分主要是在教学中遇到的常见问题，请依据老师实例情况填写</p>
          <div style="margin-top:30px;">
            <div
              v-for="(item, index) in takeAwayArray"
              :key="index"
              style="margin-top:15px;"
            >
              <p>
                问题{{ index + 1 }}：{{ item.questionTitle
                }}
              </p>
              <p style="margin-top:10px;">{{item.answer}}</p>
            </div>
          </div>
        </div>
          </el-tab-pane>
          <el-tab-pane label="上传视频" name="see4">
            <p v-show="saveRuleForm.videoAuthStatus==2" style="color:red;font-size:16px;">此页面已审核</p>
            <div class="dialog-content">
          <p style="color:#000;">
            试讲视频：<b style="color:#666;"
              >（视频大小不超过150M，视频格式为rm，rmvb，mpeg1-4 mov mtv dat wmv
              avi 3gp amv dmv flv）</b
            >
          </p>
          <p v-html="getResult"></p>
          <el-form
            :model="videoForm"
            status-icon
            ref="videoForm"
            label-width="120px"
            size="small"
          >
            <el-row :gutter="20">
              <el-col>
                <el-form-item label="上传视频" prop="" style="margin-top:30px;">
                  <div class="video_area">
                    <video :src="videoForm.videoUrl" controls v-show="videoForm.videoUrl"></video>
                    <div v-show="!videoForm.videoUrl">暂无</div>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col>
                <el-form-item label="教师风采" prop="photo">
                  <el-upload
                    accept="image/jpeg, image/png/, image/gif"
                    class="upload-demo"
                    list-type="picture-card"
                    :action="fileUpload"
                    :before-upload="photoBeforeAvatarUpload"
                    :on-preview="handlePreview"
                    :on-success="handVideoSuccess"
                    :on-remove="handleRemove"
                     multiple
                    :limit="10"
                    :on-exceed="handleExceed"
                    :file-list="fileList"
                  >
                    <i class="el-icon-plus"></i>
                    <div slot="tip" class="el-upload__tip">
                      照片大小不超过10M，格式JPG，最多不超过10张
                    </div>
                  </el-upload>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
          </el-tab-pane>
          <!-- <el-tab-pane label="功底考试" name="see5">
            <div class="dialog-content">
               <el-table
        size="small"
        :data="paperTable"
        style="width: 100%; margin-bottom: 20px"
        row-key="id"
        border
        tooltip-effect="dark"
        ref="productListTable"
      >
        <el-table-column
          prop="title"
          label="试卷名称"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="totalScore"
          label="总分"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="finishScore"
          label="最终得分"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="examBeginTime"
          label="测试开始时间"
          align="center"
          width="200px"
        ></el-table-column>
        <el-table-column
          prop="examEndTime"
          label="测试结束时间"
          align="center"
          width="200px"
        ></el-table-column>
        <el-table-column
          prop="teacherManagerName"
          label="批阅人"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="correctTime"
          label="批改时间"
          align="center"
        ></el-table-column>
        <el-table-column label="操作" align="center" width="100px">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="seeFinishPaper(scope.row.id)"
            >
              查看
            </el-button>
          </template>
        </el-table-column>
      </el-table>
          <h1 style="text-align:center;">{{ getFinishExamTitle }}</h1>
          <h3 style="text-align:center;margin-top:15px;">
            {{ getFinishExamDesc }}
          </h3>
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
          >
            <div
              v-for="(item, index) in getFinishExam"
              :key="index"
              style="margin-top:30px;"
            >
              <p
                style="cursor: pointer;background:#ccc;height:40px;line-height:40px;padding-left:10px;font-size:16px;"
              >
                {{ item.qno }}、{{ item.title }}:本题共{{
                  item.finishPaperSmalls.length
                }}小题，每小题{{ item.score }}分，共{{
                  item.finishPaperSmalls.length * item.score
                }}分。{{ item.desc }}
              </p>
              <div
                v-for="(items, indexs) in item.finishPaperSmalls"
                :key="indexs"
                style="margin-top:25px;"
              >
                <p style="display:flex;align-items:center;">
                  <span>{{ items.qid }}</span
                  >.<span v-html="items.questions.title"></span>
                </p>
                <div></div>
                <div style="margin-top:10px;">
                  <template v-if="items.smallTitle == '单项选择题'">
                    <el-form-item label="" prop="radio">
                      <el-radio-group
                        v-model="items.radio"
                       
                      >
                        <el-radio label="A"
                          >A.<span v-html="items.questions.optionA"></span
                        ></el-radio>
                        <el-radio label="B"
                          >B.<span v-html="items.questions.optionB"></span
                        ></el-radio>
                        <el-radio label="C"
                          >C.<span v-html="items.questions.optionC"></span
                        ></el-radio>
                        <el-radio label="D"
                          >D.<span v-html="items.questions.optionD"></span
                        ></el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </template>
                  <template v-if="items.smallTitle == '多项选择题'">
                    <el-form-item label="" prop="checkbox">
                      <el-checkbox-group
                        v-model="items.checkbox"
                      >
                        <el-checkbox label="A"
                          >A.<span v-html="items.questions.optionA"></span
                        ></el-checkbox>
                        <el-checkbox label="B"
                          >B.<span v-html="items.questions.optionB"></span
                        ></el-checkbox>
                        <el-checkbox label="C"
                          >C.<span v-html="items.questions.optionC"></span
                        ></el-checkbox>
                        <el-checkbox label="D"
                          >D.<span v-html="items.questions.optionD"></span
                        ></el-checkbox>
                        <el-checkbox label="E"
                          >E.<span v-html="items.questions.optionE"></span
                        ></el-checkbox>
                      </el-checkbox-group>
                    </el-form-item>
                  </template>
                  <template v-if="items.smallTitle == '判断题'">
                    <el-form-item label="" prop="judge">
                      <el-radio-group
                        v-model="items.judge"
                      >
                        <el-radio label="1">对</el-radio>
                        <el-radio label="0">错</el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </template>
                  <template v-if="items.smallTitle == '主观题'">
                    <el-form-item label="" prop="subjective">
                      <el-form-item label="" prop="subjective">
                        <span>{{items.subjective}}</span>
                      </el-form-item>
                    </el-form-item>
                  </template>
                  <template v-if="items.smallTitle == '填空题'">
                    <el-form-item label="" prop="blanks">
                     <span>{{items.blanks}}</span>
                    </el-form-item>
                  </template>
                  <template v-if="items.smallTitle == '测评题'">
                    测评题
                  </template>
                </div>
              </div>
            </div>
          </el-form>
        </div>
          </el-tab-pane> -->
          <el-tab-pane label="面试评分" name="see6">
              <div class="dialog-content">
                <p style="font-size:18px;padding-bottom:30px;font-weight:600;">录入员评价</p>
          <el-form
            :model="faceRuleForm"
            status-icon
            ref="faceRuleForm"
            label-width="120px"
            size="small"
          >
            <el-row :gutter="20">
              <el-col>
                <el-form-item label="整体评分" prop="overallScore">
                  <el-radio-group v-model="faceRuleForm.overallScore">
                    <el-radio label="1">A+</el-radio>
                    <el-radio label="2">A</el-radio>
                    <el-radio label="3">B+</el-radio>
                    <el-radio label="4">B</el-radio>
                    <el-radio label="5">C</el-radio>
                    <el-radio label="6">D</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="1.形象气质" prop="imageTemperament">
                  <el-radio-group v-model="faceRuleForm.imageTemperament">
                    <el-radio label="1">负分</el-radio>
                    <el-radio label="2">正常</el-radio>
                    <el-radio label="3">加分</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="2.沟通表达" prop="communicationExpression">
                  <el-radio-group v-model="faceRuleForm.communicationExpression">
                    <el-radio label="1">负分</el-radio>
                    <el-radio label="2">正常</el-radio>
                    <el-radio label="3">加分</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="3.在京代课经验" prop="substituteLessonsBeijing">
                  <el-radio-group v-model="faceRuleForm.substituteLessonsBeijing">
                    <el-radio label="1">5年以下</el-radio>
                    <el-radio label="2">5-10年</el-radio>
                    <el-radio label="3">10年以上</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="4.教学经验" prop="teachingExperience">
                  <el-radio-group v-model="faceRuleForm.teachingExperience">
                    <el-radio label="1">5年以下</el-radio>
                    <el-radio label="2">5-10年</el-radio>
                    <el-radio label="3">10-15年</el-radio>
                    <el-radio label="4">15-20以上</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="5.主要代课区域" prop="provinceIdOne">
                  <el-row :gutter="20">
                    <el-col :span="6">
                      <el-select
                        v-model="faceRuleForm.provinceIdOne"
                        placeholder="请选择"
                        size="small"
                        disabled 
                      >
                        <el-option
                          v-for="item in provinceList"
                          :key="item.areaId"
                          :label="item.name"
                          :value="item.areaId"
                        ></el-option>
                      </el-select>
                    </el-col>
                    <el-col :span="6">
                      <el-select
                        v-model="faceRuleForm.provinceIdTwo"
                        placeholder="请选择"
                        size="small"
                        disabled 
                      >
                        <el-option
                          v-for="item in provinceList"
                          :key="item.areaId"
                          :label="item.name"
                          :value="item.areaId"
                        ></el-option>
                      </el-select>
                    </el-col>
            </el-row>
                </el-form-item>
                <el-form-item label="6.其他合作机构" prop="remark">
                  <el-row :gutter="20">
                    <el-col :span="6">
                      <span style="margin-right:15px;">{{faceRuleForm.otherPartnersOne}}</span>
                      <span style="margin-right:15px;">{{faceRuleForm.otherPartnersTwo}}</span>
                      <span >{{faceRuleForm.otherPartnersThree}}</span>
                    </el-col>
                  </el-row>
                </el-form-item>
                <el-form-item label="7.教学特色" prop="teachingCharacteristics">
                  <el-radio-group v-model="faceRuleForm.teachingCharacteristics">
                    <el-radio label="1">负分</el-radio>
                    <el-radio label="2">正常</el-radio>
                    <el-radio label="3">加分</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="8.合作求职态度" prop="jobHuntingAttitude">
                  <el-radio-group v-model="faceRuleForm.jobHuntingAttitude">
                    <el-radio label="1">负分</el-radio>
                    <el-radio label="2">正常</el-radio>
                    <el-radio label="3">加分</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="整体评价" prop="overallEvaluation">
                  <span>{{faceRuleForm.overallEvaluation}}</span>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
           <p style="font-size:18px;margin-top:50px;padding-bottom:30px;font-weight:600;">审核员评价</p>
          <el-form
            :model="faceRuleFormAuditor"
            status-icon
            ref="faceRuleFormAuditor"
            label-width="120px"
            size="small"
          >
            <el-row :gutter="20">
              <el-col>
                <el-form-item label="整体评分" prop="overallScore">
                  <el-radio-group v-model="faceRuleFormAuditor.overallScore">
                    <el-radio label="1">A+</el-radio>
                    <el-radio label="2">A</el-radio>
                    <el-radio label="3">B+</el-radio>
                    <el-radio label="4">B</el-radio>
                    <el-radio label="5">C</el-radio>
                    <el-radio label="6">D</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="1.形象气质" prop="imageTemperament">
                  <el-radio-group v-model="faceRuleFormAuditor.imageTemperament">
                    <el-radio label="1">负分</el-radio>
                    <el-radio label="2">正常</el-radio>
                    <el-radio label="3">加分</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="2.沟通表达" prop="communicationExpression">
                  <el-radio-group v-model="faceRuleFormAuditor.communicationExpression">
                    <el-radio label="1">负分</el-radio>
                    <el-radio label="2">正常</el-radio>
                    <el-radio label="3">加分</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="3.在京代课经验" prop="substituteLessonsBeijing">
                  <el-radio-group v-model="faceRuleFormAuditor.substituteLessonsBeijing">
                    <el-radio label="1">5年以下</el-radio>
                    <el-radio label="2">5-10年</el-radio>
                    <el-radio label="3">10年以上</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="4.教学经验" prop="teachingExperience">
                  <el-radio-group v-model="faceRuleFormAuditor.teachingExperience">
                    <el-radio label="1">5年以下</el-radio>
                    <el-radio label="2">5-10年</el-radio>
                    <el-radio label="3">10-15年</el-radio>
                    <el-radio label="4">15-20以上</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="5.主要代课区域" prop="provinceIdOne">
                  <el-row :gutter="20">
                    <el-col :span="6">
                      <el-select
                        v-model="faceRuleFormAuditor.provinceIdOne"
                        placeholder="请选择"
                        size="small"
                        disabled 
                      >
                        <el-option
                          v-for="item in provinceList"
                          :key="item.areaId"
                          :label="item.name"
                          :value="item.areaId"
                        ></el-option>
                      </el-select>
                    </el-col>
                    <el-col :span="6">
                      <el-select
                        v-model="faceRuleFormAuditor.provinceIdTwo"
                        placeholder="请选择"
                        size="small"
                        disabled 
                      >
                        <el-option
                          v-for="item in provinceList"
                          :key="item.areaId"
                          :label="item.name"
                          :value="item.areaId"
                        ></el-option>
                      </el-select>
                    </el-col>
            </el-row>
                </el-form-item>
                <el-form-item label="6.其他合作机构" prop="remark">
                  <el-row :gutter="20">
                    <el-col :span="6">
                      <span style="margin-right:15px;">{{faceRuleFormAuditor.otherPartnersOne}}</span>
                      <span style="margin-right:15px;">{{faceRuleFormAuditor.otherPartnersTwo}}</span>
                      <span >{{faceRuleFormAuditor.otherPartnersThree}}</span>
                    </el-col>
                  </el-row>
                </el-form-item>
                <el-form-item label="7.教学特色" prop="teachingCharacteristics">
                  <el-radio-group v-model="faceRuleFormAuditor.teachingCharacteristics">
                    <el-radio label="1">负分</el-radio>
                    <el-radio label="2">正常</el-radio>
                    <el-radio label="3">加分</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="8.合作求职态度" prop="jobHuntingAttitude">
                  <el-radio-group v-model="faceRuleFormAuditor.jobHuntingAttitude">
                    <el-radio label="1">负分</el-radio>
                    <el-radio label="2">正常</el-radio>
                    <el-radio label="3">加分</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="整体评价" prop="overallEvaluation">
                  <span>{{faceRuleFormAuditor.overallEvaluation}}</span>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
          </el-tab-pane>
      </el-tabs>
     </el-dialog>
     <el-dialog
      :title="'修改'+`${teachcell}`+'老师的手机号'"
      :visible.sync="editCellPhoneDialog"
      :close-on-click-modal="false"
      width="400px"
      center
    >
      <div class="save-dialog-content">
        <el-form
          :model="cellPhoneForm"
          status-icon
          :rules="cellPhoneRules"
          ref="cellPhoneForm"
          label-width="100px"
          size="small"
        >
           <el-row :gutter="20">
            <el-col :span="20">
              <el-form-item label="新手机号" prop="phone">
                <el-input
                  v-model="cellPhoneForm.phone"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
       <span slot="footer" class="dialog-footer">
        <el-button @click="editCellPhoneDialog = false" size="small"
          >取 消</el-button
        >
        <el-button
          type="primary"
          @click="cellPhoneSave('cellPhoneForm')"
          size="small"
          >确 定</el-button
        >
      </span>
      </el-dialog>
     <div class="imgDolg" v-show="imgPreview.show" @click.stop="imgPreview.show = false">
      <i class="el-icon-close" id="imgDolgClose" @click.stop="imgPreview.show = false"></i>
      <img @click.stop="imgPreview.show = true" :src="imgPreview.img" />
    </div>
    <el-dialog
      :title="experienceDialog.title"
      :visible.sync="experienceShow"
      :close-on-click-modal="false"
      width="1000px"
      center
    >
      <div class="dialog-content">
         <el-form
          :model="experienceForm"
          status-icon
          :rules="experienceRules"
          ref="experienceForm"
          label-width="120px"
          size="small"
        >
           <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="学生/机构名称" prop="studentName">
                <el-input
                  v-model="experienceForm.studentName"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="学生类型" prop="studentAchievement">
                <el-select
                  v-model="experienceForm.studentAchievement"
                  placeholder="请选择"
                  size="small"
                >
                  <el-option
                    v-for="item in experienceFormList"
                    :key="item.macroId"
                    :label="item.name"
                    :value="item.macroId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="开始上课时间" prop="classTimeEnd" >
                <el-date-picker
                  v-model="ecasePickerValue1"
                  type="date"
                   @change="ecasePickerChange1()"
                  :picker-options="estartDatePicker"
                  placeholder="选择日期"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="结束上课时间" prop="classTimeEnd">
                <el-date-picker
                  v-model="ecasePickerValue2"
                  type="date"
                   @change="ecasePickerChange2()"
                  :picker-options="eendDatePicker"
                  placeholder="选择日期"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
         
          <el-row :gutter="20">
           <el-col :span="20">
              <el-form-item label="详细介绍" prop="detailContent">
                <el-input
                  type="textarea"
                  v-model="experienceForm.detailContent"
                  :rows="2"
                  maxlength="500"
                  show-word-limit
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
           <el-col :span="20">
              <el-form-item label="案例展示" prop="photos">
                <div class="img_list">
                <div
                  class="img_item img_item_true"
                  v-for="(item, index) in daikePhotoList"
                  :key="index"
                >
                  <div class="img_hover">
                    <span
                      class="iconfont icon-yulan1 icon_preview"
                      title="预览"
                      @click="daikeItemSeeClick(item, index)"
                    ></span>
                    <span
                      class="iconfont icon-shanchu4 icon_del"
                      title="删除"
                      @click="daikeItemDelClick(index)"
                    ></span>
                  </div>
                  <img :src="item.url" alt="" />
                </div>
                <div class="img_item" @click="daikeSelectClick">
                  <span class="iconfont icon-danyehuaban icon_new"></span>
                  <input
                    type="file"
                    ref="diakeuploadImg"
                    @change="daikeSelectChange"
                    style="display: none"
                    accept="image/png, image/jpeg, image/gif, image/jpg"
                  />
                </div>
              </div>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
       <span slot="footer" class="dialog-footer">
        <el-button @click="experienceShow = false" size="small"
          >取 消</el-button
        >
        <el-button
          type="primary"
          @click="daikeSave('experienceForm')"
          size="small"
          >添 加</el-button
        >
      </span>
    </el-dialog>
    <dialog-file-view-private
      :dialogFileViewPrivateInfo="dialogFileViewPrivateInfo"
      @closeFileView="closeFileView"
    ></dialog-file-view-private>
  </div>
</template>

<script>
import {
  productAdd,
  productUpdate,
  productInfoDetail
} from "@/api/operates/productManage";
import { systemAreaList } from "@/api/system/systemManage";
import {
  //讲师接口
  teacherManageList,
  teacherSave,
  teacherDetail,
  teacherResetpwd,
  teacherUpdate,
  teacherRemove,
  teacherUpdateAuthFilee,
  teacherFindAuthFile,
  teacherFaceMarkSave,
  teacherFaceMarkUpdate,
  teacherFaceMarkDetail,
  teacherQuestionList,
  teacherQuestionSave,
  teacherQuestionRemove,
  teacherQuestionInfo,
  teacherQuestionUpdate,
  saveXmtTeacherInterviewAnswer,
  teacherQuestionAnswer,
  removeByTeacher,
  findPaperQuestions,
  finishPaperList,
  updatePaperQuestions,
  getFinishStatusByTeacherId,
  findPaperQuestionsByTeacherId,
  paperList,
  submitFinishPaper,
   startExam,
  getFinishExamDetail,
  answerQuestion,
  questionsList,
  teacherUpdateState,
  getTeacherInfoByPeriodId,
  updatePhoneByTea
} from "@/api/teacher/teacherManage";
import {
  getSelectList,
  sectionCode,
  subjectCode,
  teachTitle,
  fileUpload,
  phaseCode,
  achievement,//擅教学生
  teachStyle,//讲课风格
  qualityStudentsType,
  readingStage
} from "@/api/public";
import {
  teacherUpdateEquipment,
  parentCodes,
  fileUploads
} from "@/api/administration/expertTeachBusiness/teachBusiness.js";
import dayjs from "dayjs";
import dialogFileViewPrivate from "../../teachBusiness/expertTeachBusiness/chat/dialog-file-view-private.vue";
let id = 0;
export default {
  components: {
    dialogFileViewPrivate,
  },
  data() {
    let checkPhone = (rule, value, callback) => {
      // 当数据为空时，不进行校验
      if (!value) {
        return callback()
      }
     const phoneReg = /^1[3|4|5|6|7|8][0-9]{9}$/
       setTimeout(() => {
        if (phoneReg.test(value)) {
          callback()
        } else {
          callback(new Error('请输入正确的手机号码'))
        }
      }, 100)
    }
    let idcard = (rule, value, callback) => {
      // 当数据为空时，不进行校验
      if (!value) {
        return callback()
      }
     const phoneReg = /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/
       setTimeout(() => {
        if (phoneReg.test(value)) {
          callback()
        } else {
          callback(new Error('请输入正确的身份证号码'))
        }
      }, 100)
    }
    return {
      editCellPhoneDialog:false,
      cellPhoneForm:{
        phone:"",
      },
       cellPhoneRules: {
        phone : [
          {
            required: true,
            validator: (rule, value, callback) => {
              let mobile = /^1[3|4|5|6|7|8|9]\d{9}$/;
              let phone = /^0\d{2,3}-?\d{7,8}$/;
              if (value === "") {
                callback(new Error("手机号不能为空"));
              } else if (!mobile.test(value) && !phone.test(value)) {
                callback(new Error("请输入正确手机号"));
              } else {
                callback();
              }
            },
            trigger: "blur"
          }
        ],
       
      },
      estartDatePicker: null,
      eendDatePicker: null,
      ecasePickerValue1:"",
      ecasePickerValue2:"",
      experienceForm:{
        studentName:"",
        studentAchievement:"",
        classTimeBegin:"",
        classTimeEnd:"",
        detailContent:"",
        photos:[]
      },
      experienceRules:{
        studentName: [
          { required: true, message: "学生/机构名称不能为空", trigger: "blur" },
        ],
      },
      dialogFileViewPrivateInfo: {
        show: false,
        type: "img",
        src: "",
      },
      daikePhotoList:[],
      daikeIndex:0,
      stageList:[],
      experienceFormList:[],
      paperTable:[],
      seeReasonPoups:false,
      seeDialogVisible:false,
      seeActiveName:"see1",
      parentId:0,
      provinceList:[],
      skilfulTeachArray: [],//擅教学生
      skilfulTeachDate:[],
      srcList:[],
      teacherStudentList:[],
      educationList:[],
      skilfulArray:[],
      skilfulDate:[],
      suitableForStudents:[],
      adopt:"通 过",
      getResult:"",
      answerId:[],
      choiceTotal:0,
      choiceList:[],
      tableRadio:"",
      info2Flag:true,
      info3Flag:true,
      info4Flag:true,
      info5Flag:true,
      info6Flag:true,
      activeName:'info1',
      activeName2:'info1',
      subjectParams:{
          title:"",
          qtpye:"",//题型
          gradeid:"",//年级
          subjectid:"",//学科
          pageNumber: 1,
          pageSize: 10,
      },
       beginDate() {
            const self = this
            return {
                disabledDate(time){
                    if (self.casePickerValue2) {
                        return new Date(self.casePickerValue2).getTime() < time.getTime()
                    }
                }
            }
        },
        processDate() {
            const  self = this
            return {
                disabledDate(time) {
                    if (self.casePickerValue1) {
                        return new Date(self.casePickerValue1).getTime() > time.getTime()
                    }
                }
            }
        }, 
      gradeidList: [
        {
          macroId: "0",
          name: "全部",
        },
      ],
      saveRuleForm2:{
        periodId:"",
        sectionId:"",
      },
      qtpyeLists: [
        {
            value: "",
            label: "全部",
        },
        {
            value: "单选题",
            label: "单选题",
        },
        {
            value: "多选题",
            label: "多选题",
        },
        {
            value: "判断题",
            label: "判断题",
        },
        {
            value: "论述题",
            label: "论述题",
        },
        {
            value: "填空题",
            label: "填空题",
        },
        {
            value: "测评题",
            label: "测评题",
        }
    ],
    daikeList:[],
    exhibitionPhotos:[],
    dataRuleForm:{
          resume:"",
          photo:"",
          name:"",
          ifAcceptAudition:"",
          classForm:"",
          currentProvinceIds:"",
          currentCityIds:"",
          currentCountyIds:[],
          xmtTeacherSuccessCaseEntityList:[]
      },
    expireTimeOption: {
        disabledDate(date) {
          return date.getTime() > Date.now();
        }
      },
      teacherQualificationCertificate:false,
      qualDisaled:false,
      getCurrentCountysName:"",
       paperParams: {
        pageNumber: 1,
        pageSize: 10,
        sortOrder: "asc",
        gradeId: "",
        subjectId: ""
      },
      paperTotal: 0,
      paperList: [],
      selectPaperPopup: false,
      getFinishExam:[],
      finishPaperId:'',
      btnShow: 1,
      paperId:'',
      allScore: 0,
      obtainScore: 0,
      rules: {},
      ruleForm: {
        a: []
      },
      imgPreview: {
        img: "",
        show: false,
      },
      reasonVal:"",
       currentCountyData:[],
      currentCityData:[],
      currentProvinceData:[],
      getFinishExamDesc: "",
      getFinishExamTitle: "",
      getFinishExam: [],
      examineDialog: false,
      newarray: [],
      answerKey: "",
      takeAwayArray: [],
      takeAwayArray2: [],
      value: [],
      shareScopeEnd: [],
      total2:0,
       hideUpload: false,
       experienceImgUpload:false,
      examnineSrc1:[],
      examnineSrc3:[],
      examnineSrc4:[],
      examnineSrc5:[],
      phaseLists:[
        {
          macroId:107,
          name:"高中"
        },
        {
          macroId:106,
          name:"初中"
        },
        {
          macroId:105,
          name:"小学"
        },
        {
          macroId:108,
          name:"艺术培训"
        },
        {
          macroId:109,
          name:"小语种"
        },
        {
          macroId:235,
          name:"全学段"
        }
      ],
      srcList:[],
      srcList2:[],
      srcList3:[],
      choiceProblemPopup:false,
      answerId: [],
      teacherTypeStr:'',
      previewImage:[],
      testPapers:[],
      testScore:0,
      answerArray: [],
      answerObject: {
        question: "",
        periodId: "",
        pageNumber: 1,
        pageSize: 10
      },
      phaseList: [],
      answerSync: false,
      answerDialog: {
        title: "新增面试答题",
        type: "add"
      },
      answerRuleForm: {
        question: "",
        periodId: ""
      },
      problemPopup: false, //面试答题选择列表
      mackData: {
        peopleType: 1,
        teacherId: ""
      },
       mackData2: {
        peopleType: 2,
        teacherId: ""
      },
      answerobj: {
        teacherId: ""
      },
      faceRuleForm: {
        overallScore:'',
        imageTemperament:0,
        communicationExpression:0,
        substituteLessonsBeijing:0,
        teachingExperience:0,
        provinceIdOne:'',
        otherPartnersOne:'',
        otherPartnersTwo:'',
        otherPartnersThree:'',
        // teacherType:0,
        suitableForStudents:'',
        teachingCharacteristics:0,
        jobHuntingAttitude:0,
        overallEvaluation:'',
        peopleType: 1
      },
      faceRuleFormAuditor: {
        overallScore:'',
        imageTemperament:0,
        communicationExpression:0,
        substituteLessonsBeijing:0,
        teachingExperience:0,
        provinceIdOne:'',
        otherPartnersOne:'',
        otherPartnersTwo:'',
        otherPartnersThree:'',
        // teacherType:0,
        suitableForStudents:'',
        teachingCharacteristics:0,
        jobHuntingAttitude:0,
        overallEvaluation:'',
        peopleType: 1
      },
      isDisable:false,
      urlObj: {},
      urlArr: [],
      teacherId2:"",
      authenDialogVideo: false,
      authenDialogVideoTitle: {
        title: "上传视频",
        type: "add"
      },
      takeFlag:false,
      detaildStatus: false,
      authenDialogVisible: false, //资质弹窗是否显示
      authenDialogTitle: {
        title: "上传资质",
        type: "add"
      },
      videoForm: {
        videoUrl: ""
      },
      videoformArray: [],
      periodId: [],
      sectionId: [],
      set_editor_content: "",
      content_height: "160",
      timePickerValue: "", //上课时间
      followUpDialog: {
        title: "添加案例",
        type: "save"
      },
      followUpDialogVisible: false,
      fileUpload,
      listArray: [],
      listParams: {
        name: "", //关键字
        sectionId: "0",
        subjectId: "0",
        identityType: "0",
        teachingAgeId: "", //教龄
        teachTitleId: "", //职称
        pageNumber: 1,
        pageSize: 10,
        currentProvinceIds:"",
        currentCityIds:"",
        currentCountyIds:""
      },
      identityTypeList: [
        {
          value: "0",
          label: "全部"
        },
        {
          value: "1",
          label: "学科类-老师"
        },
        {
          value: "2",
          label: "学科类-大学生"
        },
        {
          value: "3",
          label: "素质教育类-老师"
        },
        {
          value: "4",
          label: "素质教育类-大学生"
        }
      ],
      teachingAgeIdListSearch: [
        {
          value: '',
          label: "全部"
        },
        {
          value: 27,
          label: "5年以下"
        },
        {
          value: 28,
          label: "5-10"
        },
        {
          value: 29,
          label: "10-15"
        },
        {
          value: 227,
          label: "15-20以上"
        }
      ],
      teachingAgeIdList: [
        {
          value: 27,
          label: "5年以下"
        },
        {
          value: 28,
          label: "5-10"
        },
        {
          value: 29,
          label: "10-15"
        },
        {
          value: 227,
          label: "15-20以上"
        }
      ],
      teachTitleIdListSearch: [
         {
          value: '',
          label: "全部"
        },
        {
          value: 59,
          label: "初级教师"
        },
        {
          value: 60,
          label: "中级教师"
        },
        {
          value: 61,
          label: "高级教师"
        },
        {
          value: 62,
          label: "特级教师"
        }
      ],
      teachTitleIdList: [
        {
          value: 59,
          label: "初级教师"
        },
        {
          value: 60,
          label: "中级教师"
        },
        {
          value: 61,
          label: "高级教师"
        },
        {
          value: 62,
          label: "特级教师"
        }
      ],
      sectionList2:[],
      subjectList2:[],
      total: 0,
      saveDialogVisible: false,
      skilfulArray: [],
      caseList:[],
      saveRuleForm: {
        subjectIdCheck:[],
        photo: "", //图片
        name: "", //昵称
        contact: "", //联系方式
        sexId: "", //性别
        realname: "", //真实姓名
        idcard: "", //身份证号
        currentAddress: "", //籍贯
        birthday: "", //出生日期
        educationId: "", //学历
        graduationSchool: "", //毕业院校
        teachingAgeId: "", //教龄
        schoolName: "", //任教学校
        teachTitleId: "", //老师职称
        skilful: "", //讲课风格
        skilfulTeach:"",//擅教学生
        teachTrait: "", //教学特点
        periodId: "", //教授学段
        sectionId: "", //教授年纪
        subjectId: "", //教授科目
        hopeMinFee: "", //课酬最小值                                    
        hopeMaxFee: "" //课酬最大值
      },
      getperiodId:"",
      teacherGradeList: [],
      teacherSubjectList: [],
      teacherSubjectListCheck:[],
      examineRuleForm: {
        peopleType: 2,
        overallScore:'',
        imageTemperament:0,
        communicationExpression:0,
        substituteLessonsBeijing:0,
        teachingExperience:0,
        provinceIdOne:'',
        otherPartnersOne:'',
        otherPartnersTwo:'',
        otherPartnersThree:'',
        suitableForStudents:'',
        teachingCharacteristics:0,
        jobHuntingAttitude:0,
        overallEvaluation:'',
        provinceIdTwo:"",
      },
      teachTitleList: [],
      listProvinceData:[],
      listCityData:[],
      listCountyData:[],
      startDatePicker: null,
      endDatePicker: null,
      scaseResultRules:{
        studentName: [
          { required: true, message: "学生姓名不能为空", trigger: "blur" },
        ],
        studentSectionId: [
          { required: true, message: "年级不能为空", trigger: "blur" },
        ],
        studentSubjectId: [
          { required: true, message: "学科不能为空", trigger: "blur" },
        ],
      },
      saveRules: {
        contact: [
          {
            validator: (rule, value, callback) => {
              let mobile = /^1[2|3|4|5|6|7|8|9]\d{9}$/;
              let phone = /^0\d{2,3}-?\d{7,8}$/;
              if (!value) {
                callback();
              } else if (!mobile.test(value) && !phone.test(value)) {
                callback(new Error("请输入正确手机号"));
              } else {
                callback();
              }
            },
            trigger: "blur"
          }
        ],
        periodId: [
          { required: true, message: "教授学段不能为空", trigger: "change" }
        ],
        // subjectId: [{ required: true, message: "教授科目不能为空", trigger: "change" }],
        sectionId: [{ required: true, message: "教授年级不能为空", trigger: "change" }],
         idcard : [
          {
             validator: idcard, message: '请输入正确的身份证号码'
          }
        ],
     },
      faceRules:{
         overallScore: [
          { required: true, message: "整体评分不能为空", trigger: "change" }
        ],
      },
      experienceShow:false,
      authenRuleForm: {
        idjust: "",
        idback: "",
        teacherStudent: "", //资格认证、学生证
        education: "", //学历
        qualifications: "" //资质
      },
      authenRuleJson: {
        idjust: {
          fileType: 1,
          fileUrl: null,
          teacherId: 0
        },
        idback: {
          fileType: 1,
          fileUrl: null,
          teacherId: 0
        },
        teacherStudent: {
          fileType: 2,
          fileUrl: null,
          teacherId: 0
        },
        education: {
          fileType: 3,
          fileUrl: null,
          teacherId: 0
        },
        qualifications: {
          fileType: 4,
          fileUrl: null,
          teacherId: 0
        }
      },
      authenRules: {
        idjust: [
          { required: true, message: "身份证正面不能为空", trigger: "blur" }
        ],
        idback: [
          { required: true, message: "身份证反面不能为空", trigger: "blur" }
        ],
        teacherStudent: [
          { required: true, message: "教师资格认证不能为空", trigger: "blur" }
        ]
      },
      cityList:[],
      countyList:[],
      provinceLessonList:[],
      teacherId: [],
      teacherPhone: [],
      sectionList: [
        // {
        //   macroId: "0",
        //   name: "全部"
        // }
      ],
      educationIdList: [
        {
          macroId: 70,
          name: "专科"
        },
        {
          macroId: 69,
          name: "本科"
        },
        {
          macroId: 68,
          name: "硕士"
        },
        {
          macroId: 67,
          name: "博士"
        }
      ],
      saveDialog: {
        title: "新增产品",
        type: "add"
      },
      subjectList: [
        // {
        //   macroId: "0",
        //   name: "全部"
        // }
      ],
      saveRuleFormFollowUp: {
        studentId: "",
        saleId: "",
        followupTags: "",
        nextFollowupTime: "",
        followContent: ""
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date(
                dayjs()
                  .add(1, "day")
                  .format("YYYY-MM-DD") + " 00:00:00"
              );
              const start = new Date(
                dayjs().format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date(dayjs().format("YYYY-MM-DD") + " 00:00:00");
              const start = new Date(
                dayjs()
                  .subtract(1, "day")
                  .format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date(
                dayjs()
                  .add(1, "day")
                  .format("YYYY-MM-DD") + " 00:00:00"
              );
              const start = new Date(
                dayjs()
                  .subtract(7, "day")
                  .format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date(
                dayjs()
                  .add(1, "day")
                  .format("YYYY-MM-DD") + " 00:00:00"
              );
              const start = new Date(
                dayjs()
                  .subtract(30, "day")
                  .format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date(
                dayjs()
                  .add(1, "day")
                  .format("YYYY-MM-DD") + " 00:00:00"
              );
              const start = new Date(
                dayjs()
                  .subtract(90, "day")
                  .format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
       answerRules:{
          question: [{ required: true, message: "问题不能为空", trigger: "blur" }],
          periodId: [{ required: true, message: "学段不能为空", trigger: "change" }]
      },
       caseUpDialog: {
        title: "添加案例",
        type: "save"
      },
      casePickerValue1:"",
      casePickerValue2:"",
      caseRuleForm:{
        studentName:"",
        detailContent:"",//详情介绍
        studentSectionId:"",//年级
        studentSubjectId:"",//科目
        classTimeEnd:null,
        classTimeBegin:null,
      },
      casePopup:false,
      caseIndex:0,
      switchStatus: 1,
      dialogContImgUrl: "",
      fileList: [],
      getTeacherId: 0,
      faceType: 1,
      faceType2: 1,
      examineStatus: 1,
      examnineId: 0, //点击审核获取的id
      examnineDate: {},
      examnineSkilful: "",
      skilfulStyle:"",
      examninSectionId: "",
      examnineImages: {},
      answerDate: [],
      FaceMarkData: {
        peopleType: 1,
        teacherId: ""
      },
      FaceMarkData2: {
        peopleType: 2,
        teacherId: ""
      },
      teacherInfo:{
        id:"",
        realname:"",
        idcard:"",
      },
      FaceMarkList: {},
      FaceMarkList2: "",
      sectionCheckbox:[
        {label:98,content:"高三",parentId:107,flag:true,ischeck:false},
        {label:97,content:"高二",parentId:107,flag:true,ischeck:false},
        {label:96,content:"高一",parentId:107,flag:true,ischeck:false},
        {label:20,content:"初三",parentId:106,flag:true,ischeck:false},
        {label:19,content:"初二",parentId:106,flag:true,ischeck:false},
        {label:18,content:"初一",parentId:106,flag:true,ischeck:false},
        {label:95,content:"六年级",parentId:105,flag:true,ischeck:false},
        {label:94,content:"五年级",parentId:105,flag:true,ischeck:false},
        {label:93,content:"四年级",parentId:105,flag:true,ischeck:false},
        {label:92,content:"三年级",parentId:105,flag:true,ischeck:false},
        {label:110,content:"艺术培训",parentId:108,flag:true,ischeck:false},
        {label:111,content:"小语种",parentId:109,flag:true,ischeck:false},
      ],
      studentAchievementList:[
        {
          macroId:268,
          name:'拔高型'
        },
        {
          macroId:269,
          name:'中上等成绩'
        },
        {
          macroId:270,
          name:'中等类待提高'
        },
        {
          macroId:271,
          name:'基础型'
        }
      ],
       uploadImg:{
        idcard:"",
        qualifications:"",
        xueli:"",

      },
      experienceDialog:{
        title:'添加代课经历',
        type:'save'
      },
      dialogImageUrl:'',
      dialogVisible:false,
      teachcell:'',
      teacherInfoAccount:'',
    };
  },
   watch: {
    saveDialogVisible: function (val,oldVla) {
      if (this.$refs['saveRuleForm'] !== undefined) {
        this.$refs["saveRuleForm"].resetFields();
      }
      if (this.$refs['dataRuleForm'] !== undefined) {
        this.$refs["dataRuleForm"].resetFields();
      }
      if (this.$refs['faceRuleForm'] !== undefined) {
        this.$refs["faceRuleForm"].resetFields();
      }
    },
    answerSync: function (val,oldVla) {
      if (this.$refs['answerRuleForm'] !== undefined) {
        this.$refs["answerRuleForm"].resetFields();
      }
    },
    editCellPhoneDialog: function (val,oldVla) {
      if (this.$refs['cellPhoneForm'] !== undefined) {
        this.$refs["cellPhoneForm"].resetFields();
      }
    }
  },
  methods: {
    imgItemDelClick(index){
      this.testPapers.splice(index, 1);
    },
    imgItemSeeClick(item,index){
      this.dialogFileViewPrivateInfo = {
          show: true,
          type: "img",
          src: item.fileUrl ? item.fileUrl : item.url,
          curIndex: index ? index : 0,
          imgList:
            index != undefined
              ? this.testPapers.map((item) => {
                  return item.fileUrl ? item.fileUrl : item.url;
                })
              : [item.fileUrl ? item.fileUrl : item.url],
        };
    },
    daikeSelectClick(){
      this.$refs.diakeuploadImg.value = "";
      this.$refs.diakeuploadImg.click();
    },
    zuopinSelectClick(){
      this.$refs.zuopinuploadImg.value = "";
      this.$refs.zuopinuploadImg.click();
    },
    zuopinSelectChange(){
      if(this.exhibitionPhotos.length==8){
        this.msgWarn("最多只能上传八张图片！");
        return;
      }
      if (
        !/\.(png|jpg|gif|jpeg|webp)$/.test(this.$refs.zuopinuploadImg.files[0].name)
      ) {
        this.msgWarn("只能是图片格式的文件！");
        return;
      }
      console.log(this.$refs.zuopinuploadImg.files[0],'123123..')
      let formData = new FormData();
      formData.append("file", this.$refs.zuopinuploadImg.files[0]);
      
      fileUploads(formData).then((uploadRes) => {
        if (uploadRes[0].url) {
          this.exhibitionPhotos.push(uploadRes[0].url)
        }
      });
    },
     daikeSave(formName){
        this.experienceForm.photoList=this.daikePhotoList.map( item => {
          return item.url
        });
        this.$refs[formName].validate(valid => {
        if (valid) {
            this.experienceShow=false;
            if(this.experienceDialog.type=='save'){
              this.daikeList.push(this.experienceForm);
            } else{
              this.experienceForm.classTimeBegin = this.ecasePickerValue1;
              this.experienceForm.classTimeEnd = this.ecasePickerValue2;
              this.daikeList.splice(this.daikeIndex, 1, this.experienceForm);
            }
        }else {
          this.msgWarn("必填字段不能为空！");
          return false;
        }
      })
      },
    closeDialog(){
      // this.teacherId=[];
       this.getteacherManageList();
    },
    //身份证上传
    idCardUpload(res, file) {
      this.uploadImg.idcard = res[0].url;
    },
    closeFileView() {
      this.dialogFileViewPrivateInfo.show = false;
    },
    //教师资格认证上传
    teaQualifications(res, file) {
      this.uploadImg.qualifications = res[0].url;
      if(this.teacherQualificationCertificate){
        this.teacherQualificationCertificate=false;
        this.qualDisaled=true;
      }
    },
    //学历 学生证上传
    xueliUpload(res, file) {
      this.uploadImg.xueli = res[0].url;
    },
    studentSectionIdFn(row){
      let result = []
      var subjectArray=String(row).split();
      for(let  i=0;i<this.sectionList.length;i++){
        let tempArr1=this.sectionList[i];
        for(let j=0;j<subjectArray.length;j++){
          let tempArr2=subjectArray[j]
          if(tempArr2==tempArr1.macroId){
             result.push(tempArr1.name)
             break;
          }
        }
      }
      return result.join();
    },
    cellPhoneSave(formName){
       this.$refs[formName].validate((valid) => {
          if (valid) {
            updatePhoneByTea({
              id: this.cellPhoneForm.id,
              contact: this.cellPhoneForm.phone,
            }).then(res => {
              if(res.code == 0){
                this.msgSuccess('操作成功！')
                this.editCellPhoneDialog=false;
                this.getteacherManageList();
              }
            })
          }else {
          // this.msgWarn("手机号不能为空！");
          return false;
        }
       })
    },
    getSubjectList2() {
      return getSelectList({
        parentCode: subjectCode
      }).then(res => {
        if (res.code == 0) {
          this.subjectList2 = res.rows;
          
        }
      });
    },
    studentSubjectIdFn(row){
      var subjectlist = "";
      if (row == 185) {
        subjectlist = "全学科";
      } else if (row == 23) {
        subjectlist = "语文";
      } else if (row == 24) {
        subjectlist = "数学";
      } else if (row == 25) {
        subjectlist = "英语";
      } else if (row == 101) {
        subjectlist = "物理";
      } else if (row == 102) {
        subjectlist = "化学";
      } else if (row == 99) {
        subjectlist = "政治";
      } else if (row == 100) {
        subjectlist = "生物";
      } else if (row == 87) {
        subjectlist = "地理";
      } else if (row == 88) {
        subjectlist = "历史";
      } else if (row == 103) {
        subjectlist = "科学";
      } else if (row == 112) {
        subjectlist = "国学";
      } else if (row == 113) {
        subjectlist = "书法";
      } else if (row == 114) {
        subjectlist = "吉他";
      } else if (row == 115) {
        subjectlist = "绘画";
      } else if (row == 116) {
        subjectlist = "德语";
      } else if (row == 117) {
        subjectlist = "法语";
      } else if (row == 118) {
        subjectlist = "俄语";
      } else if (row == 119) {
        subjectlist = "西班牙语";
      } else if (row == 183) {
        subjectlist = "其他小语种";
      } else if (row == 184) {
        subjectlist = "其他艺术课";
      }
      return subjectlist;
    },
     getSectionList2() {
      getSelectList({
        parentCode: sectionCode
      }).then(res => {
        if (res.code == 0) {
          this.sectionList2 = res.rows;
        }
      });
    },
    bindChange(e){
      if(e.length>5){
        this.$message.warning("最多只能选择五个地区")
        this.dataRuleForm.currentCountyIds.splice(-1);
      }
    },
    provinceIdFn(data){
      for(var i=0;i<this.provinceList.length;i++){
        if(this.provinceList[i].areaId==data){
          return this.provinceList[i].name;
        }
      }
    },
     clearFn(){
      this.listParams.currentProvinceIds="";
      this.listParams.currentCityIds="";
      this.listParams.currentCountyIds="";
      this.getteacherManageList();
    },
    caseEdit(item,index){
        this.casePopup=true;
        this.caseIndex=index;
        this.caseUpDialog.title='编辑案例';
        this.caseUpDialog.type="edit";
        this.caseRuleForm={
          studentName:item.studentName,
          detailContent:item.detailContent,//详情介绍
          studentSectionId:item.studentSectionId,//年级
          studentSubjectId:item.studentSubjectId,//科目
          studentAchievement:item.studentAchievement,
          photos:item.photoList.map( item => {
            return {url: item}
          }),
        }
        if(this.caseRuleForm.photos.length<8){
         this.hideUpload=false;
       }else{
         this.hideUpload=true;
       }
        this.casePickerValue1=item.classTimeBegin;
        this.casePickerValue2=item.classTimeEnd;
      },
      experienceFormListFn(num){
        for(var i=0;i<this.experienceFormList.length;i++){
          if(this.experienceFormList[i].macroId==num){
            return this.experienceFormList[i].name;
          }
        }
      },
      studentAchievementFn(num){
      if(num==268){
        return '提高型'
      }else if(num==269){
        return '中上等成绩'
      }else if(num==270){
        return '中等类待提高'
      }else if(num==271){
        return '基础型'
      }
      else{
        return ''
      }
    },
    exhibitionPhotosDel(index){
      this.exhibitionPhotos.splice(index, 1);
    },
    exhibitionPhotosSee(item,index){
      console.log(item,'item..')
      this.dialogFileViewPrivateInfo = {
          show: true,
          type: "img",
          src: item ? item : item,
          curIndex: index ? index : 0,
          imgList:
            index != undefined
              ? this.exhibitionPhotos.map((item) => {
                  return item ? item : item;
                })
              : [item ? item : item],
        };
    },
      directionChange(){
        this.teacherGradeList=[]
        this.teacherSubjectListCheck=[]
        this.saveRuleForm.sectionId=[];
        this.saveRuleForm.periodId=[];
        this.changes()
        if(this.saveRuleForm.teachingDirection==1){
          this.saveRuleForm.identityType='1'
        }else{
          this.saveRuleForm.identityType='3'
        }
      },
      scoreChange(score,titscore,index,indexs){
        console.log(score,titscore)
        if(score>titscore){
          this.getFinishExam[index].finishPaperSmalls[indexs].score=0;
          this.msgWarn("得分不能大于题目分值！");
          return;
        }
      },
      caseRemove(index){
        this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
            this.caseList.splice(index,1);
        })
        .catch(() => {});
      },
      casePickerChange1(){
        this.caseRuleForm.classTimeBegin = this.casePickerValue1;
      },
      casePickerChange2(){
        this.caseRuleForm.classTimeEnd = this.casePickerValue2;
      },
      caseHandlePicture(file) {
        this.caseImageUrl = file.url;
        this.caseImgDialog = true;
      },
      handleLimit(file,fileList){
        this.hideUpload = fileList.length >= 8
      },
      caseHandleRemove(file, fileList) {
        this.hideUpload = fileList.length >= 8
        for (var i=0;i<this.caseRuleForm.photos.length;i++) {
          if (this.caseRuleForm.photos[i].url === file.url) {
              this.caseRuleForm.photos.splice(i,1);
          }
        }
      },

      caseHandleAvatarSuccess(file,fileList){
        this.caseRuleForm.photos.push({url:file[0].url});
      },
      //成功案例保存
      confirmCase(formName){
        this.caseRuleForm.photoList=this.caseRuleForm.photos.map( item => {
          return item.url
        });
        this.$refs[formName].validate(valid => {
        if (valid) {
            this.casePopup=false;
            if(this.caseUpDialog.type=='save'){
              this.caseList.push(this.caseRuleForm);
            } else{
              this.caseRuleForm.classTimeBegin = this.casePickerValue1;
              this.caseRuleForm.classTimeEnd = this.casePickerValue2;
              this.caseList.splice(this.caseIndex, 1, this.caseRuleForm);
            }
        }else {
          this.msgWarn("必填字段不能为空！");
          return false;
        }
      })
      console.log(this.caseRuleForm,'caseRuleForm...')
      },
      daikeSelectChange(){
      if(this.daikePhotoList.length==8){
        this.msgWarn("最多只能上传八张图片！");
        return;
      }
      if (
        !/\.(png|jpg|gif|jpeg|webp)$/.test(this.$refs.diakeuploadImg.files[0].name)
      ) {
        this.msgWarn("只能是图片格式的文件！");
        return;
      }
      let formData = new FormData();
      formData.append("file", this.$refs.diakeuploadImg.files[0]);
      fileUploads(formData).then((uploadRes) => {
        if (uploadRes[0].url) {
          this.daikePhotoList.push({
            url: uploadRes[0].url,
          });
        }
      });
    },
    changeLabel(val) {
      // 是否与上次的类型相同
      let changeFlag = false;
      let changeItem = null;
      if (this.shareScopeEnd.length == 0) {
        this.value = val;
      } else {
        // 与原数组比对
        this.value.forEach(item => {
          if (item[0] !== this.shareScopeEnd[0][0]) {
            // 一级标签不同
            changeFlag = true;
            changeItem = item;
          } else if (item[1] != this.shareScopeEnd[0][1]) {
            // 一级标签相同但是二级标签不同
            changeFlag = true;
            changeItem = item;
          } else if (
            (!item[2] && this.shareScopeEnd[0][2]) ||
            (item[2] && !this.shareScopeEnd[0][2])
          ) {
            changeFlag = true;
            changeItem = item;
          }
        });
      }
      if (changeFlag) {
        this.value = [];
        this.value.push(changeItem);
      }
      this.shareScopeEnd = this.value;
    },
    dealSex(row) {
      if (row.sexId == 1) {
        return "男";
      } else if (row.sexId == 2) {
        return "女";
      } else {
        return "";
      }
    },
    dealTeachTitleId(row) {
      if (row.teachTitleId == 59) {
        return "初级教师";
      } else if (row.teachTitleId == 60) {
        return "中级教师";
      } else if (row.teachTitleId == 61) {
        return "高级教师";
      } else if (row.teachTitleId == 62) {
        return "特级教师";
      } else {
        return "";
      }
    },
    dealIdentityType(row) {
      if (row.identityType==1) {
        return "学科类--老师";
      } else if (row.identityType==2) {
        return "学科类--大学生";
      } else if (row.identityType==3) {
        return "素质教育类--老师";
      } else if (row.identityType == 4) {
        return "素质教育类--大学生";
      } else {
        return "";
      }
    },
    dealTeachingAgeId(row) {
      if (row.teachingAgeId == 27) {
        return "5年以下";
      } else if (row.teachingAgeId == 28) {
        return "5-10";
      } else if (row.teachingAgeId == 29) {
        return "10-15";
      } else if (row.teachingAgeId == 227) {
        return "15-20以上";
      } else {
        return "";
      }
    },
    dealStage(row){
      if (row.readingStage == 302) {
        return "在读--专科";
      } else if (row.readingStage == 303) {
        return "在读--本科";
      } else if (row.readingStage == 304) {
        return "在读--研究生";
      } else if (row.readingStage == 305) {
        return "在读--博士";
      } else {
        return "";
      }
    },
    getCityLists(){
       return systemAreaList({
        parentId: this.saveRuleForm.provinceId
      }).then(res => {
        if (res.code == 0) {
          this.cityList = res.rows;
        }
      });
    },
     async  cityChangeList(){
      await this.getCountyList();
      this.saveRuleForm.countyId = this.countyList[0].areaId;
    },
    getCountyList() {
      return systemAreaList({
        parentId: this.saveRuleForm.cityId
      }).then(res => {
        if (res.code == 0) {
          this.countyList = res.rows;
        }
      });
    },
    
     async provinceChangeList(){
        await this.getCityLists();
        this.saveRuleForm.cityId = this.cityList[0].areaId;
        this.cityChangeList();
    },
    clickChange(item) {
      this.tableRadio = item;
    },
    choiceItemClick(row){
      this.$refs.productListTable.toggleRowSelection(row);
    },
     choiceSelectionChange(val){
       this.answerId=[];
        if(val.length>0){
            for(let i=0,len=val.length;i<len;i++){
              this.answerId.push(val[i].id)
            }
        }
    },
     resumeSuccess(response, file, fileList){
       if (response[0].url) {
        this.dataRuleForm.resume = response[0].url;
       
      }
    },
    resumeError(err, file, fileList){
      this.msgError("附件简历上传失败！");
    },  
    sureChoice(){
      this.choiceProblemPopup=false;
      this.testTitle2(this.tableRadio)
    },
    testTitle2(row){
      var regex = /(<([^>]+)>)/ig
      var result = "<div class='quesborder'><p>"+row.title+"</p><br/>";
            if (row.optionA) {
                result += "<p style='padding:5px 0;margin-top:10px;'>A." + row.optionA.replace(regex,'') + "</p>";
            }
            if (row.optionB) {
                result += "<p style='padding:5px 0;'>B." + row.optionB.replace(regex,'') + "</p>";
            }
            if (row.optionC) {
                result += "<p style='padding:5px 0;'>C." + row.optionC.replace(regex,'') + "</p>";
            }
            if (row.optionD) {
                 result += "<p style='padding:5px 0;'>D." + row.optionD.replace(regex,'') + "</p>";
            }
            if (row.optionE) {
                result += "<p style='padding:5px 0;'>E." + row.optionE.replace(regex,'') + "</p>";
            }
            if (row.answer1) {
              var tempAnswer1 = row.answer1;
              if(tempAnswer1 && tempAnswer1.startsWith("<p>") && tempAnswer1.endsWith("</p>")){
                              row.answer1 = tempAnswer1.substring(3,tempAnswer1.length-4);
              }
            }
            result += "</div>";
            this.getResult=result;
            return result;
    },
    testTitle(row){
      var regex = /(<([^>]+)>)/ig
      var result = "<div class='quesborder'><p>"+row.title+"</p><br/>";
            if (row.optionA) {
                result += "<p>A." + row.optionA.replace(regex,'') + "</p>";
            }
            if (row.optionB) {
                result += "<p>B." + row.optionB.replace(regex,'') + "</p>";
            }
            if (row.optionC) {
                result += "<p>C." + row.optionC.replace(regex,'') + "</p>";
            }
            if (row.optionD) {
                result += "<p>D." + row.optionD.replace(regex,'') + "</p>";
            }
            if (row.optionE) {
                result += "<p>E." + row.optionE.replace(regex,'') + "</p>";
            }
            if (row.answer1) {
              var tempAnswer1 = row.answer1;
              if(tempAnswer1 && tempAnswer1.startsWith("<p>") && tempAnswer1.endsWith("</p>")){
                              row.answer1 = tempAnswer1.substring(3,tempAnswer1.length-4);
              }
                result += "<p style='color: red;font-weight: bolder'>【答案】："+row.answer1.replace(eval("/\\$\\#\\#\\#\\$/g"),"&nbsp;&nbsp;")+"</p><br/>"
            }
            if (row.answer2) {
                result += "<p>"+row.answer2+"</p><br/>"
            }
            if (row.parse) {
                result += "<p>"+row.parse+"</p>"
            }
            result += "</div>";
            return result;
    },
    isAgree(num){
      //同意
      this.examineRuleForm.teacherId = this.examnineId;
      this.examineRuleForm.overallScore=String(this.FaceMarkList.overallScore);
      this.examineRuleForm.imageTemperament=String(this.FaceMarkList.imageTemperament);
      this.examineRuleForm.communicationExpression=String(this.FaceMarkList.communicationExpression);
      this.examineRuleForm.substituteLessonsBeijing=String(this.FaceMarkList.substituteLessonsBeijing);
      this.examineRuleForm.teachingExperience=String(this.FaceMarkList.teachingExperience);
      this.examineRuleForm.provinceIdOne=this.FaceMarkList.provinceIdOne;
      this.examineRuleForm.provinceIdTwo=this.FaceMarkList.provinceIdTwo;
      this.examineRuleForm.otherPartnersOne=this.FaceMarkList.otherPartnersOne;
      this.examineRuleForm.otherPartnersTwo=this.FaceMarkList.otherPartnersTwo;
      this.examineRuleForm.otherPartnersThree=this.FaceMarkList.otherPartnersThree;
      this.examineRuleForm.teacherType=this.FaceMarkList.teacherType;
      this.examineRuleForm.teachingCharacteristics=String(this.FaceMarkList.teachingCharacteristics);
      this.examineRuleForm.jobHuntingAttitude=String(this.FaceMarkList.jobHuntingAttitude);
       this.examineRuleForm.overallEvaluation=this.FaceMarkList.overallEvaluation;
    },
    showImg(e) {
        this.imgPreview.img = e.target.src;
        this.imgPreview.show = true;
    },
    imgSelectClick(){
      this.$refs.uploadImg.value = "";
      this.$refs.uploadImg.click();
    },
    imgSelectChange(){
      if(this.testPapers.length==8){
        this.msgWarn("最多只能上传八张图片！");
        return;
      }
      if (
        !/\.(png|jpg|gif|jpeg|webp)$/.test(this.$refs.uploadImg.files[0].name)
      ) {
        this.msgWarn("只能是图片格式的文件！");
        return;
      }
      let formData = new FormData();
      formData.append("file", this.$refs.uploadImg.files[0]);
      fileUploads(formData).then((uploadRes) => {
        if (uploadRes[0].url) {
          this.testPapers.push({
            url: uploadRes[0].url,
            
          });
        }
      });
    },
    dealSectionId(row) {
      var str = "";
      var arr2 = new Array();
      if (row.sectionId) {
        arr2 = row.sectionId.split(",");
      }
      for (var i = 0; i < arr2.length; i++) {
        if (arr2[i] == 98) {
          str += "高三,";
        } else if (arr2[i] == 97) {
          str += "高二,";
        } else if (arr2[i] == 96) {
          str += "高一,";
        } else if (arr2[i] == 20) {
          str += "初三,";
        } else if (arr2[i] == 19) {
          str += "初二,";
        } else if (arr2[i] == 18) {
          str += "初一,";
        } else if (arr2[i] == 95) {
          str += "六年级,";
        } else if (arr2[i] == 94) {
          str += "五年级,";
        } else if (arr2[i] == 93) {
          str += "四年级,";
        } else if (arr2[i] == 92) {
          str += "三年级,";
        } else if (arr2[i] == 110) {
          str += "艺术培训,";
        } else if (arr2[i] == 111) {
          str += "小语种,";
        }
      }
      return str.substring(0, str.length - 1);
    },
    removeTeacher(row){
       this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
       .then(() => {
          teacherRemove([row.id]).then(res => {                                                                       
            if (res.code == 0&&res.msg=="操作成功！") {
              this.getteacherManageList();
            }else{
               this.msgWarn(res.msg);
              // return false;
            }
          });
        })
        .catch(() => {});
    },
    answerPeriodId(row) {
      var str = "";
      var arr2 = new Array();
      if (row.periodId == 107) {
        str = "高中";
      } else if (row.periodId == 106) {
        str = "初中";
      } else if (row.periodId == 105) {
        str = "小学";
      } else if (row.periodId == 108) {
        str = "艺术培训";
      } else if (row.periodId == 109) {
        str = "小语种";
      } else if (row.periodId == 235) {
        str = "全学段";
      }
      return str;
    },
    dealSubjectIdd(row) {
      let result = []
      let list=this.subjectList.slice(1);
      var subjectArray=row.subjectId?row.subjectId.split(','):[];
      for(let  i=0;i<list.length;i++){
        let tempArr1=list[i];
        for(let j=0;j<subjectArray.length;j++){
          let tempArr2=subjectArray[j]
          if(tempArr2==tempArr1.macroId){
             result.push(tempArr1.name)
             break;
          }
        }
      }
      return result.join();
    },
    async  dealRowSee(id,num){
    if(num==1){
      this.seeActiveName='see1';
    }else if(num==2){
      this.seeActiveName='see2';
    }else if(num==3){
      this.seeActiveName='see3';
    }else if(num==4){
      this.seeActiveName='see4';
    }else if(num==6){
      this.seeActiveName='see6';
    }
     this.teacherId =[];
      this.teacherId.push(id);
      
      
      await this.getProvinceList()
      this.saveRuleForm = {
        subjectIdCheck:[],
      photo: "", //图片
      name: "", //昵称
      contact: "", //联系方式
      sexId: "", //性别
      realname: "", //真实姓名
      idcard: "", //身份证号
      currentAddress: "", //籍贯
      birthday: "", //出生日期
      educationId: "", //学历
      graduationSchool: "", //毕业院校
      teachingAgeId: "", //教龄
      schoolName: "", //任教学校
      teachTitleId: "", //老师职称
      skilfulTeach: "", //擅教学生
      skilful:"",//讲课风格
      teachTrait: "", //教学特点
      periodId:[],
      sectionId: [], //教授年纪
      subjectId: "", //教授科目
      hopeMinFee: "", //课酬最小值
      hopeMaxFee: "", //课酬最大值，
      classForm:"",//授课方式
      ifAcceptAudition:"",//是否接收试听
      provinceId: "",
      cityId: "",
      countyId: "",
      currentProvinceIds:"",
      currentCityIds:"",
      currentCountyIds:[],
      };
      (this.faceRuleForm = {  
      peopleType: 1,
      overallScore:'',
      imageTemperament:0,
      communicationExpression:0,
      substituteLessonsBeijing:0,
      teachingExperience:0,

      provinceIdOne:'',
      otherPartnersOne:'',
      otherPartnersTwo:'',
      otherPartnersThree:'',
      // teacherType:0,
      teachingCharacteristics:0,
      jobHuntingAttitude:0,
      overallEvaluation:'',
      }),
      (this.switchStatus = 1);
      this.fileList = [];
      await this.getTeacherDetail();
      this.seeDialogVisible = true;
      this.getResult="";
      this.videoForm.videoUrl="";
    },
    changes() {
      this.periodId = JSON.parse(JSON.stringify(this.saveRuleForm.periodId));
       if(this.saveRuleForm.periodId.length > 0){
        getTeacherInfoByPeriodId({
        periodId: this.saveRuleForm.periodId.join(',')
      }).then( res => {
        this.teacherGradeList = res.rows[1]
        this.teacherSubjectList = res.rows[0]
         this.teacherSubjectListCheck =  res.rows[0]
        this.saveRuleForm.sectionId = this.teacherGradeList.map( item => {
          return item.macroId
        })
        this.saveRuleForm.subjectId = []
      })
      }else{
        this.saveRuleForm.sectionId = []
        this.saveRuleForm.subjectId = []
        this.teacherGradeList = []
        this.teacherSubjectList = []
        this.teacherSubjectListCheck=[]
      }
    },
     ecasePickerChange1(){
         this.experienceForm.classTimeBegin = this.ecasePickerValue1;
      },
      ecasePickerChange2(){
         this.experienceForm.classTimeEnd = this.ecasePickerValue2;
      },
        //代课经历预览
    daikeItemSeeClick(item,index){
      this.dialogFileViewPrivateInfo = {
          show: true,
          type: "img",
          src: item.fileUrl ? item.fileUrl : item.url,
          curIndex: index ? index : 0,
          imgList:
            index != undefined
              ? this.daikePhotoList.map((item) => {
                  return item.fileUrl ? item.fileUrl : item.url;
                })
              : [item.fileUrl ? item.fileUrl : item.url],
        };
    },
    //代课经历删除
    daikeItemDelClick(index){
      this.daikePhotoList.splice(index, 1);
    },
      experienceEdit(item,index){
        
        this.experienceShow=true;
        this.daikeIndex=index;
        this.experienceDialog.title='编辑代课经历';
        this.experienceDialog.type="edit";
        this.experienceForm={
          studentName:item.studentName,
          detailContent:item.detailContent,//详情介绍
          // studentSectionId:item.studentSectionId,//年级
          // studentSubjectId:item.studentSubjectId,//科目
          studentAchievement:item.studentAchievement,
          // photos:item.photoList.map( item => {
          //   return {url: item}
          // }),

        }
        this.daikePhotoList=item.photoList.map( item => {
          return {url: item}
        })
       if(this.daikePhotoList.length<8){
         this.experienceImgUpload=false;
       }else{
         this.experienceImgUpload=true;
       }
        this.ecasePickerValue1=item.classTimeBegin;
        this.ecasePickerValue2=item.classTimeEnd;
      },
      experienceRemove(index){
        this.$confirm("确定要删除吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
              this.daikeList.splice(index,1);
          })
          .catch(() => {});
      },
    choiceTeacherProblem(){
      this.choiceProblemPopup=true;
      this.questionsListFn();
    },
     questionsListFn(){
       questionsList(this.subjectParams).then((res) => {
          this.choiceList=res.rows;
          this.choiceTotal=res.total;
      });
    },
    choiceSizeChange(pageSize){
      this.subjectParams.pageSize = pageSize;
      this.questionsListFn();
    },
    choiceCurrentChange(currentPage){
      this.subjectParams.pageNumber = currentPage;
      this.questionsListFn();
    },
    edit_content_change(html) {
      this.saveRuleForm.detailContent = html;
    },
    datePickerChange() {
      if (!this.timePickerValue) {
        this.saveRuleForm.classTimeBegin = null;
        this.saveRuleForm.classTimeEnd = null;
        return;
      }
      this.saveRuleForm.classTimeBegin = this.timePickerValue[0];
      this.saveRuleForm.classTimeEnd = this.timePickerValue[1];
    },
    handleAvatarSuccess(res, file) {
      this.dataRuleForm.photo = res[0].url;
    },
    handleAvatarSuccess1(res, file) {
      this.authenRuleForm.idjust = res[0].url;
      this.authenRuleJson.idjust.fileUrl = res[0].url;
    },
    handleAvatarSuccess2(res, file) {
      this.authenRuleForm.idback = res[0].url;
      this.authenRuleJson.idback.fileUrl = res[0].url;
    },
    handleAvatarSuccess3(res, file) {
      this.authenRuleForm.teacherStudent = res[0].url;
      this.authenRuleJson.teacherStudent.fileUrl = res[0].url;
    },
    handleAvatarSuccess4(res, file) {
      this.authenRuleForm.education = res[0].url;
      this.authenRuleJson.education.fileUrl = res[0].url;
    },
    handleAvatarSuccess5(res, file) {
      this.authenRuleForm.qualifications = res[0].url;
      this.authenRuleJson.qualifications.fileUrl = res[0].url;
    },
    video_change(url) {
      this.videoForm.videoUrl = url;
      this.videoformArray.push({
        fileType: 6,
        fileUrl: url
      });
    },
    beforeAvatarUpload(file) {
      let reg = /\.(png|jpg|gif|jpeg)$/;
      if (!reg.test(file.name)) {
        this.msgError(
          "支持上传的图片格式为png，jpg，gif，jpeg；请检查格式是否正确！"
        );
      }
      return reg.test(file.name);
    },
    overallScoreFn(row){
      if(row.overallScore==1){
        return 'A+'
      }else if(row.overallScore==2){
        return 'A'
      }else if(row.overallScore==3){
        return 'B+'
      }else if(row.overallScore==4){
        return 'B'
      }else if(row.overallScore==5){
        return 'C'
      }else if(row.overallScore==6){
        return 'D'
      }
    },
    imageTemperamentFn(row){
      if(row.imageTemperament==1){
        return '负分'
      }else if(row.imageTemperament==2){
        return '正常'
      }else if(row.imageTemperament==3){
        return '加分'
      }
    },
    communicationExpressionFn(row){
      if(row.communicationExpression==1){
        return '负分'
      }else if(row.communicationExpression==2){
        return '正常'
      }else if(row.communicationExpression==3){
        return '加分'
      }
    },
    substituteLessonsBeijingFn(row){
      if(row.substituteLessonsBeijing==1){
        return '5年以下'
      }else if(row.substituteLessonsBeijing==2){
        return '5-10年'
      }else if(row.substituteLessonsBeijing==3){
        return '10年以上'
      }
    },
    teachingExperienceFn(row){
      if(row.teachingExperience==1){
        return '5年以下'
      }else if(row.teachingExperience==2){
        return '5-10年'
      }else if(row.teachingExperience==3){
        return '10-15年'
      }else if(row.teachingExperience==4){
        return '15-20以上'
      }
    },
    addCase(){
      this.hideUpload=false;
      this.casePopup=true;
      this.caseUpDialog.title='添加案例';
      this.caseUpDialog.type="save";
      this.caseRuleForm={
        studentName:"",
        detailContent:"",//详情介绍
        studentSectionId:"",//年级
        studentSubjectId:this.aa,//科目
        classTimeEnd:null,
        classTimeBegin:null,
        photos: [],
      }
      this.casePickerValue1='';
      this.casePickerValue2='';
    },
    teachingCharacteristicsFn(row){
      if(row.teachingCharacteristics==1){
        return '负分'
      }else if(row.teachingCharacteristics==2){
        return '正常'
      }else if(row.teachingCharacteristics==3){
        return '加分'
      }
    },
    jobHuntingAttitudeFn(row){
      if(row.jobHuntingAttitude==1){
        return '负分'
      }else if(row.jobHuntingAttitude==2){
        return '正常'
      }else if(row.jobHuntingAttitude==3){
        return '加分'
      }
    },
    groupChange() {
      if (this.skilfulTeachArray.length > 2) {
        this.skilfulTeachArray.shift();
      }
    },
    skilfulArrayChange(){
      if (this.skilfulArray.length > 2) {
        this.skilfulArray.shift();
      }
    },
    dealAdd() {
      this.qualDisaled=false;
      this.teacherQualificationCertificate=false;
      this.testPapers=[]
      this.exhibitionPhotos=[];
      this.daikeList=[]
      this.teacherGradeList = [];
      this.teacherSubjectList = [];
      this.teacherSubjectListCheck=[]
      this.suitableForStudents=[];
      this.teacherInfo.realname="";
      this.teacherInfo.idcard="";
      this.caseList=[];
      this.skilfulTeachArray=[];
      this.getFinishExam=[];
      this.getResult="";
      this.activeName='info1';
      this.info2Flag=true;
      this.info3Flag=true;
      this.info4Flag=true;
      this.info5Flag=true;
      this.info6Flag=true;
      this.saveDialogVisible = true;
      this.saveDialog = {
        title: "新增讲师",
        type: "add"
      };
      this.skilfulArray = [];
      this.periodId = [];
      this.sectionId = [];
      this.fileList = [];
      this.takeAwayArray = [];
      this.videoForm.videoUrl = "";
       this.getProvinceList();
      
       this.uploadImg={
         idcard:'',
          xueli:'',
          qualifications:'',
       }
       this.dataRuleForm={
          photo:"",
          name:"",
          ifAcceptAudition:"",
          classForm:"",
          currentProvinceIds:[],
          currentCityIds:"",
          currentCountyIds:"",
          xmtTeacherSuccessCaseEntityList:[],
          skilfulTeach: "", //擅教学生
          skilful:"",//讲课风格
          resume:"",
          hopeMinFee:"",
          hopeMaxFee:"",
       }
      this.saveRuleForm = {
        subjectIdCheck:[],
        photo: "", //图片
        name: "", //昵称
        contact: "", //联系方式
        sexId: "", //性别
        realname: "", //真实姓名
        idcard: "", //身份证号
        currentAddress: "", //籍贯
        birthday: "", //出生日期
        educationId: "", //学历
        graduationSchool: "", //毕业院校
        teachingAgeId: "", //教龄
        schoolName: "", //任教学校
        teachTitleId: "", //老师职称
        skilful: "", //讲课风格
        skilfulTeach:"",//擅教学生
        teachTrait: "", //教学特点
        periodId: [], //教授学段
        sectionId: [], //教授年纪
        subjectId: "", //教授科目
        hopeMinFee: "", //课酬最小值
        hopeMaxFee: "" ,//课酬最大值
        provinceId: "",
        cityId: "",
        countyId: "",
        currentProvinceIds:"",
        currentCityIds:"",
        currentCountyIds:[],
        teachingDirection:1,
        identityType:'1',
        authFiles:[]
      };
      this.authenRuleForm = {
        idjust: "",
        idback: "",
        teacherStudent: "", //资格认证、学生证
        education: "", //学历
        qualifications: "" //资质
      };
      this.faceRuleForm = {
        totalImage: 0,
        appearance: 0,
        connectCapacity: 0,
        lectureSpeed: 0,
        lectureStyle: 0,
        specialtySkil: 0,
        remark: "",
        peopleType: 1
      };
      this.sectionCheckbox=[
        {label:98,content:"高三",parentId:107,flag:true,ischeck:false},
        {label:97,content:"高二",parentId:107,flag:true,ischeck:false},
        {label:96,content:"高一",parentId:107,flag:true,ischeck:false},
        {label:20,content:"初三",parentId:106,flag:true,ischeck:false},
        {label:19,content:"初二",parentId:106,flag:true,ischeck:false},
        {label:18,content:"初一",parentId:106,flag:true,ischeck:false},
        {label:95,content:"六年级",parentId:105,flag:true,ischeck:false},
        {label:94,content:"五年级",parentId:105,flag:true,ischeck:false},
        {label:93,content:"四年级",parentId:105,flag:true,ischeck:false},
        {label:92,content:"三年级",parentId:105,flag:true,ischeck:false},
        {label:110,content:"艺术培训",parentId:108,flag:true,ischeck:false},
        {label:111,content:"小语种",parentId:109,flag:true,ischeck:false},
      ]
    },
    getProvinceList(){
       return systemAreaList({
        parentId: this.parentId
      }).then(res => {
        if (res.code == 0) {
          this.provinceList = res.rows;
          console.log(res.rows,'主要代课区域')
          this.provinceLessonList=res.rows;
          this.currentProvinceData=res.rows;
          this.listProvinceData=res.rows;
        }
      });
    },
    listCityLists(){
       return systemAreaList({
        parentId: this.listParams.currentProvinceIds
      }).then(res => {
        if (res.code == 0) {
          this.listCityData = res.rows;
        }
      });
    },
    async  listProvinceChange(){
      await this.listCityLists();
      this.listParams.currentCityIds = this.listCityData[0].areaId;
      this.listCityChange();
    },
    async  listCityChange(){
      await this.listCountyList();
      this.listParams.currentCountyIds = this.listCountyData[0].areaId;
    },
    listCountyList() {
      return systemAreaList({
        parentId: this.listParams.currentCityIds
      }).then(res => {
        if (res.code == 0) {
          this.listCountyData = res.rows;
        }
      });
    },
    adoptFn(type){
      teacherUpdate({id:this.examnineId,isBasicdata:type}).then(res => {
              if (res.code == 0) {
                this.msgSuccess("操作成功！");
                this.examineDialog = false;
                // this.getteacherManageList();
              }
            });
    },
  async  dealEdit() {
      this.qualDisaled=false;
      this.activeName='info1';
      this.uploadImg.idcard="";
      this.uploadImg.xueli="";
      this.uploadImg.qualifications="";
      this.info2Flag=false;
      this.info3Flag=false;
      this.info4Flag=false;
      this.info5Flag=false;
      this.info6Flag=false;
      this.testPapers = [];
      this.teacherQualificationCertificate=false;
      await this.getProvinceList()
      this.saveDialog = {
        title: "编辑教师",
        type: "edit"
      };
      if (this.teacherId.length != 1) {
        this.msgWarn("请选择一名教师！");
        return;
      }
      this.getTeacherId = this.teacherId[0]
      this.authenRuleForm.idjust = '';
      this.authenRuleForm.idback = '';
      this.authenRuleForm.teacherStudent = '';
      this.authenRuleForm.education = '';
      this.authenRuleForm.qualifications = '';
      this.teacherInfoAccount = ''
      this.saveRuleForm = {
        subjectIdCheck:[],
        teachingDirection:'',
        identityType:'',
        photo: "", //图片
        name: "", //昵称
        contact: "", //联系方式
        sexId: "", //性别
        realname: "", //真实姓名
        idcard: "", //身份证号
        currentAddress: "", //籍贯
        birthday: "", //出生日期
        educationId: "", //学历
        graduationSchool: "", //毕业院校
        teachingAgeId: "", //教龄
        schoolName: "", //任教学校
        teachTitleId: "", //老师职称
        skilful: "", //讲课风格
        skilfulTeach:"",//擅教学生
        teachTrait: "", //教学特点
        periodId: [], //教授学段
        sectionId: [], //教授年纪
        subjectId: "", //教授科目
        hopeMinFee: "", //课酬最小值
        hopeMaxFee: "", //课酬最大值
         provinceId: "",
        cityId: "",
        countyId: "",
        currentProvinceIds:"",
        currentCityIds:"",
        currentCountyIds:[],
        resume:""

      };
      (this.dataRuleForm={
          photo:"",
          name:"",
          ifAcceptAudition:"",
          classForm:"",
          currentProvinceIds:[],
          currentCityIds:"",
          currentCountyIds:[],
          xmtTeacherSuccessCaseEntityList:[],
          skilfulTeach: "", //擅教学生
          skilful:"",//讲课风格
          resume:"",
          hopeMinFee:"",
          hopeMaxFee:"",
      });
      (this.faceRuleForm = {
        overallScore:'',
        imageTemperament:0,
        communicationExpression:0,
        substituteLessonsBeijing:0,
        teachingExperience:0,
        provinceIdOne:'',
        provinceIdTwo:"",
        otherPartnersOne:'',
        otherPartnersTwo:'',
        otherPartnersThree:'',
        // teacherType:0,
        suitableForStudents:'',
        teachingCharacteristics:0,
        jobHuntingAttitude:0,
        overallEvaluation:'',
        peopleType: 1
      }),
        (this.switchStatus = 1);
      this.fileList = [];
      await this.getTeacherDetail();
      this.saveDialogVisible = true;
      this.getResult="";
      this.videoForm.videoUrl="";
    },
    //重置密码
    dealReset() {
      if (this.teacherId.length > 1) {
        this.msgWarn("请选择单个用户进行密码重置操作！");
        return;
      }
      if (this.teacherId.length == 0) {
        this.msgWarn("请选择一个用户进行密码重置操作！");
        return;
      }
      this.$confirm("用户的密码将重置为手机号的后六位, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          var XmtTeacherEntity = {
            contact: this.teacherPhone[0],
            id: this.teacherId[0]
          };
          teacherResetpwd(XmtTeacherEntity).then(res => {
            if (res.code == 0) {
              this.msgSuccess("密码修改成功！");
            }
          });
        })
        .catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });
        });
    },
    //资质认证
    // dealAuthen(id){
    //   this.zizhiId=id;
    //   this.authenDialogVisible=true;
    //   this.detaildStatus=false;
    //   this.authenRuleForm={
    //     idjust:"",
    //     idback:"",
    //     teacherStudent:"",//资格认证、学生证
    //     education:"",//学历
    //     qualifications:"",//资质
    //   },
    //   this.authenRuleJson={
    //       idjust:{
    //          fileType:1,
    //         fileUrl:null,
    //         teacherId:0
    //       },
    //       idback:{
    //            fileType:1,
    //         fileUrl:null,
    //          teacherId:0
    //       },
    //        teacherStudent:{
    //            fileType:2,
    //         fileUrl:null,
    //          teacherId:0
    //       },
    //        education:{
    //            fileType:3,
    //         fileUrl:null,
    //          teacherId:0
    //       },
    //        qualifications:{
    //            fileType:4,
    //         fileUrl:null,
    //          teacherId:0
    //       }
    //    }
    //   this.authenDialogTitle={
    //     title:"上传资质",
    //     type:"add"
    //   }

    // },
    dealVideo(id) {
      this.authenDialogVideo = true;
    },
    //资质认证查看详情
    // dealAuthenDetails(id){
    //   this.zizhiId=id;
    //   this.authenDialogVisible=true;
    //   this.detaildStatus=true;
    //   this.authenDialogTitle={
    //     title:"查看资质",
    //     type:"see"
    //   }
    //   teacherFindAuthFile(id).then((res) => {
    //     var getData=res.rows.authFiles;
    //     if(res.code==0){
    //       this.authenRuleForm.idjust=getData[0].fileUrl;
    //       this.authenRuleForm.idback=getData[1].fileUrl;
    //       this.authenRuleForm.teacherStudent=getData[2].fileUrl;
    //       this.authenRuleForm.education=getData[3].fileUrl;
    //       this.authenRuleForm.qualifications=getData[4].fileUrl;
    //     }
    //   });
    // },
    handleAvatarRemove5(file, fileList){
      for (var i=0;i<this.testPapers.length;i++) {
        if (this.testPapers[i].url === file.url) {
            this.testPapers.splice(i,1);
        }
      }
    },
    confirmSaveup(formName) {
      this.dataRuleForm.exhibitionPhotos=this.exhibitionPhotos;
      if(this.dataRuleForm.classForm=='0'||this.dataRuleForm.classForm=='2'){
        if(this.dataRuleForm.currentProvinceIds){
          if(this.dataRuleForm.currentCountyIds.length<1){
          this.msgWarn('请填写完整面授地点！');
          return false;
        }
        }
      }else{
        if(this.dataRuleForm.currentProvinceIds){
          if(this.dataRuleForm.currentCountyIds.length<1){
            this.dataRuleForm.currentProvinceIds="";
            this.dataRuleForm.currentCityIds="";
            this.dataRuleForm.currentCountyIds=[];
          }
        }
      }
      this.dataRuleForm.xmtTeacherSuccessCaseEntityList=new Array();
      if(this.caseList&&this.saveRuleForm.teachingDirection==1){
        this.caseList.forEach( item => {
          let { studentAchievement,classTimeBegin, classTimeEnd, studentSubjectId, studentSectionId, studentName, photoList, detailContent } = item
          this.dataRuleForm.xmtTeacherSuccessCaseEntityList.push({
            classTimeBegin,
            classTimeEnd,
            studentSubjectId,
            studentSectionId,
            studentName,
            photos: photoList,
            detailContent,
            studentAchievement
          })
        })
      }else if(this.daikeList&&this.saveRuleForm.teachingDirection==2){
      
      console.log(this.daikeList,'daikePhotoList..')
      this.daikeList.forEach( item => {
          let { studentAchievement,classTimeBegin, classTimeEnd, studentSubjectId, studentSectionId, studentName, photoList, detailContent } = item
          this.dataRuleForm.xmtTeacherSuccessCaseEntityList.push({
            classTimeBegin,
            classTimeEnd,
            studentSubjectId,
            studentSectionId,
            studentName,
            photos: photoList,
            detailContent,
            studentAchievement
          })
        })
      }
      this.dataRuleForm.skilfulTeach = this.skilfulTeachArray.join(); //擅教学生
      this.dataRuleForm.skilful = this.skilfulArray.join(); //讲课风格
      this.$refs[formName].validate(valid => {
        this.dataRuleForm.id=this.getTeacherId
        if (valid) {
          if (this.saveDialog.type == "add") {
            teacherUpdate(this.dataRuleForm).then(res => {
              if (res.code == 0) {
                this.msgSuccess("操作成功！");
                this.activeName='info3';
                this.info3Flag=false;
                // this.switchStatus = 3;
                this.getteacherManageList();
              }
            });
          } else if (this.saveDialog.type == "edit") {
            teacherUpdate(this.dataRuleForm).then(res => {
              if (res.code == 0) {
                 this.msgSuccess("操作成功！");
                this.activeName='info3';
              }
            });
          }
        } else {
          this.msgWarn("必填字段不能为空！");
          return false;
        }
      });
    },
    handleRemove(file, fileList) {
      for (const i in this.videoformArray) {
        if (this.videoformArray[i].fileUrl === file.url) {
            this.videoformArray.splice(i,1);
        }
      }
    },
    handlePreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 10 个文件，本次选择了 ${
          files.length
        } 个文件，共选择了 ${files.length + fileList.length} 个文件`
      );
    },
    checkSelectable(row){
      let flag = false;
      for (let i = 0, len = this.takeAwayArray.length; i < len; i++) {
        if (this.takeAwayArray[i].questionId == row.id) {
          flag = true;
          break;
        }
      }
      if (!flag) {
        return true;
      }
      return false;
    },
    //获取详情
    getTeacherDetail() {
    
      this.detailShow=true;
      //获取基本信息
      teacherDetail(this.getTeacherId).then(res => {
        if (res.code == 0) {
          var getRows = res.rows;
          this.teacherInfo.realname=getRows.realname;
          this.teacherInfo.idcard=getRows.idcard;
          //教授学生赋值
          if(getRows.skilfulTeach === null){
            getRows.skilfulTeach = ''
          }
          if(getRows.skilful === null){
            getRows.skilful = ''
          }
          this.skilfulTeachArray = getRows.skilfulTeach
            .split(",")
            .map(function(item, index, array) {
              return item - 0;
            });
          this.skilfulArray = getRows.skilful
            .split(",")
            .map(function(item, index, array) {
              return item - 0;
            });
          //教受学段赋值
           if(getRows.periodId!=null){
              this.periodId = getRows.periodId
              .split(",")
              .map(function(item, index, array) {
                return item - 0;
              });
            }else{
              this.periodId=[]
            }
          //教授年纪赋值
          this.sectionId = getRows.sectionId?getRows.sectionId:''
            .split(",")
            .map(function(item, index, array) {
              return item - 0;
            });
          
            this.changes();
          this.teacherInfoAccount = getRows.account
          this.saveRuleForm = {
            readingStage:getRows.readingStage,
            photo: getRows.photo, //图片
            name: getRows.name, //昵称
            contact: getRows.contact, //联系方式
            sexId: getRows.sexId, //性别
            realname: getRows.realname, //真实姓名
            idcard: getRows.idcard, //身份证号
            currentAddress: getRows.currentAddress, //籍贯
            birthday: getRows.birthday, //出生日期
            educationId: getRows.educationId, //学历
            graduationSchool: getRows.graduationSchool, //毕业院校
            teachingAgeId: getRows.teachingAgeId, //教龄
            schoolName: getRows.schoolName, //任教学校
            teachTitleId: getRows.teachTitleId, //老师职称
            skilful: getRows.skilful, //擅教学生
            skilfulTeach:getRows.skilfulTeach,
            teachTrait: getRows.teachTrait, //教学特点
            subjectId: Number(getRows.subjectId), //教授科目
            hopeMinFee: getRows.hopeMinFee, //课酬最小值
            hopeMaxFee: getRows.hopeMaxFee, //课酬最大值
            identityType: getRows.identityType,
            ifAcceptAudition:getRows.ifAcceptAudition,
            classForm:Number(getRows.classForm),
            provinceId: res.rows.provinceId,
            cityId: res.rows.cityId,
            countyId: res.rows.countyId,
            currentProvinceIds:res.rows.currentProvinceIds?Number(res.rows.currentProvinceIds):"",
            currentCityIds:res.rows.currentCityIds?Number(res.rows.currentCityIds):"",
            currentCountyIds:res.rows.currentCountyIdsList,
            periodId:[],
            sectionId:[],
            subjectIdCheck:[],
            provinceName:res.rows.provinceName,
            cityName:res.rows.cityName,
            countyName:res.rows.countyName,
            currentProvinceName:res.rows.currentProvinceName,
            currentCountyName:res.rows.currentCountyName,
            currentCityName:res.rows.currentCityName,
            resume:res.rows.resume,
            currentProvincesName:res.rows.currentProvincesName,
            currentCitysName:res.rows.currentCitysName,
            isBasicdata:res.rows.isBasicdata,
            capesAuthStatus:res.rows.capesAuthStatus,
            isInterview:res.rows.isInterview,
            videoAuthStatus:res.rows.videoAuthStatus,
            teachingDirection:res.rows.teachingDirection,
            identityType:String(res.rows.identityType)
          };
          this.dataRuleForm={
              resume:res.rows.resume,
              skilful: getRows.skilful, //讲课风格
              skilfulTeach:getRows.skilfulTeach,//擅教学生
              photo:getRows.photo,
              name:getRows.name,
              ifAcceptAudition:getRows.ifAcceptAudition,
              classForm:Number(getRows.classForm),
              currentCityIds:res.rows.currentCityIds?Number(res.rows.currentCityIds):'',
              currentProvinceIds:res.rows.currentProvinceIds?Number(res.rows.currentProvinceIds):'',
              // realname: getRows.realname, //真实姓名
              xmtTeacherSuccessCaseEntityList:[],
              hopeMinFee: getRows.hopeMinFee, //课酬最小值
              hopeMaxFee: getRows.hopeMaxFee, //课酬最大值
              teachTrait: getRows.teachTrait, //教学特点
              currentCountyIds:res.rows.currentCountyIdsList,
              currentProvincesName:res.rows.currentProvincesName,
              currentCitysName:res.rows.currentCitysName
          }
          if(getRows.teachingDirection==1&&getRows.identityType==1){

            }else{
              this.saveRuleForm.subjectIdCheck=getRows.subjectId.split(',').map((item) => item * 1)
          }
          this.exhibitionPhotos=res.rows.exhibitionPhotoList;
           this.saveRuleForm.imageArray = new Array();
           this.saveRuleForm.imageArray.push(res.rows.photo)
          var CountysName=""
          if(res.rows.currentCountysName.length){
            for(let i=0;i<res.rows.currentCountysName.length;i++){
              CountysName+=res.rows.currentCountysName[i].NAME+"，"
            }
          }
          this.getCurrentCountysName=CountysName.substring(0,CountysName.length-1);
          var periodIdArr=[];
          periodIdArr=res.rows.periodId?res.rows.periodId.split(","):[];
          this.saveRuleForm.periodId=periodIdArr.map(Number);
          var sectionIdArr=[];
          sectionIdArr = res.rows.sectionId?res.rows.sectionId.split(","):[];
          this.saveRuleForm.sectionId=sectionIdArr.map(Number);
           if(this.saveRuleForm.teachingDirection==1){
            this.caseList=getRows.xmtTeacherSuccessCaseEntityList;
          }else{
            this.daikeList=getRows.xmtTeacherSuccessCaseEntityList;
          }
          if (this.saveRuleForm.provinceId) {
            this.getCityLists();
          }
          if (this.saveRuleForm.cityId) {
            this.getCountyList();
          }
          if (this.saveRuleForm.currentProvinceIds) {
            this.currentCityLists();
          }
          if (this.saveRuleForm.currentCityIds) {
            this.currentCountyList();
          }
          this.getGradeSubjectList()
        }
      });
       this.videoformArray=[];
      teacherFindAuthFile(this.getTeacherId).then(res => {
        var getData = res.rows.authFiles;
        if (res.code == 0) {
          for (var i = 0; i < getData.length; i++) {
            if (getData[i].fileType == 6) {
              //视频
              this.videoForm.videoUrl = getData[i].fileUrl;
              this.videoformArray.push(getData[i]);
            }
            if (getData[i].fileType == 5) {
              this.videoformArray.push(getData[i]);
              this.urlObj.url = getData[i].fileUrl;
              this.urlArr.push(getData[i].fileUrl);
              this.fileList.push(this.urlObj);
              this.getResult=getData[i].title;
              this.urlObj = {};
            }
            if(getData[i].fileType==1&&getData[i].fileUrl!=null){//身份证
                this.uploadImg.idcard=getData[i].fileUrl;
            }
            if(getData[i].fileType==2){//资格认证
                this.uploadImg.qualifications= getData[i].fileUrl;
                if(getData[i].teacherQualificationCertificate==1){
                this.teacherQualificationCertificate=true;
              }else{
                this.teacherQualificationCertificate=false;
                if(this.uploadImg.qualifications){
                  this.qualDisaled=true;
                }else{
                  this.qualDisaled=false;
                }
              }
            }
            if(getData[i].fileType==3){//学历认证
                this.uploadImg.xueli=getData[i].fileUrl;
            }
            if(getData[i].fileType==4||getData[i].fileType==7){//专业资质 荣誉
              
                this.authenRuleForm.qualifications = getData[i].fileUrl;
            }else{
              this.authenRuleForm.qualifications=""
            }
          }
          this.authenRuleJson = {
            idjust: {
              fileType: 1,
              fileUrl: this.authenRuleForm.idjust,
              teacherId: this.getTeacherId
            },
            idback: {
              fileType: 1,
              fileUrl: this.authenRuleForm.idback,
              teacherId: this.getTeacherId
            },
            teacherStudent: {
              fileType: 2,
              fileUrl: this.authenRuleForm.teacherStudent,
              teacherId: this.getTeacherId
            },
            education: {
              fileType: 3,
              fileUrl: this.authenRuleForm.education,
              teacherId: this.getTeacherId
            },
            qualifications: {
              fileType: 4,
              fileUrl: this.authenRuleForm.qualifications,
              teacherId: this.getTeacherId
            }
          };
          let getTestPapers=this.authenRuleForm.qualifications?this.authenRuleForm.qualifications.split(','):[];
          this.previewImage=this.authenRuleForm.qualifications?this.authenRuleForm.qualifications.split(','):[];
          if(this.authenRuleForm.qualifications!=''){
                 getTestPapers.forEach(item => {  //处理图片需要转格式
                  this.testPapers.push({
                    "url": item,
                  })
                })
          }else{
            this.testPapers=[];
          }
        }
      });
      this.mackData = {
        teacherId: this.getTeacherId,
        peopleType: 1
      };
      this.mackData2 = {
        teacherId: this.getTeacherId,
        peopleType: 2
      };
      teacherFaceMarkDetail(this.mackData).then(res => {
        if (res.rows == "空数组"||res.rows =="无数据") {
          this.faceType = 0;
        } else {
          this.faceRuleForm.id=res.rows.id;
          this.faceType = 1;
         this.faceRuleForm.overallEvaluation=res.rows.overallEvaluation?res.rows.overallEvaluation:'';
          this.faceRuleForm.overallScore=String(res.rows.overallScore);
          this.faceRuleForm.imageTemperament=String(res.rows.imageTemperament);
          this.faceRuleForm.communicationExpression=String(res.rows.communicationExpression);
          this.faceRuleForm.substituteLessonsBeijing=String(res.rows.substituteLessonsBeijing);
          this.faceRuleForm.teachingExperience=String(res.rows.teachingExperience);
          this.faceRuleForm.provinceIdOne=res.rows.provinceIdOne?Number(res.rows.provinceIdOne):'';
          this.faceRuleForm.provinceIdTwo=res.rows.provinceIdTwo?Number(res.rows.provinceIdTwo):'';
          this.faceRuleForm.otherPartnersOne=res.rows.otherPartnersOne;
          this.faceRuleForm.otherPartnersTwo=res.rows.otherPartnersTwo;
          this.faceRuleForm.otherPartnersThree=res.rows.otherPartnersThree;
          this.faceRuleForm.jobHuntingAttitude=String(res.rows.jobHuntingAttitude);
          this.faceRuleForm.teachingCharacteristics=String(res.rows.teachingCharacteristics);
        }
      });
       teacherFaceMarkDetail(this.mackData2).then(res => {
        if (res.rows == "空数组"||res.rows =="无数据") {
          this.faceType = 0;
        } else {
          this.faceRuleFormAuditor.id=res.rows.id;
          this.faceType = 1;
         this.faceRuleFormAuditor.overallEvaluation=res.rows.overallEvaluation?res.rows.overallEvaluation:'';
          this.faceRuleFormAuditor.overallScore=String(res.rows.overallScore);
          this.faceRuleFormAuditor.imageTemperament=String(res.rows.imageTemperament);
          this.faceRuleFormAuditor.communicationExpression=String(res.rows.communicationExpression);
          this.faceRuleFormAuditor.substituteLessonsBeijing=String(res.rows.substituteLessonsBeijing);
          this.faceRuleFormAuditor.teachingExperience=String(res.rows.teachingExperience);
          this.faceRuleFormAuditor.provinceIdOne=res.rows.provinceIdOne?Number(res.rows.provinceIdOne):'';
          this.faceRuleFormAuditor.provinceIdTwo=res.rows.provinceIdTwo?Number(res.rows.provinceIdTwo):'';
          this.faceRuleFormAuditor.otherPartnersOne=res.rows.otherPartnersOne;
          this.faceRuleFormAuditor.otherPartnersTwo=res.rows.otherPartnersTwo;
          this.faceRuleFormAuditor.otherPartnersThree=res.rows.otherPartnersThree;
          this.faceRuleFormAuditor.jobHuntingAttitude=String(res.rows.jobHuntingAttitude);
          this.faceRuleFormAuditor.teachingCharacteristics=String(res.rows.teachingCharacteristics);
        }
      });
      this.answerobj = {
        teacherId: this.getTeacherId
      };
      teacherQuestionAnswer(this.getTeacherId).then(res => {
        if (res.code == 0) {
          this.takeAwayArray = res.rows;
          if(res.rows.length==0){
            this.takeFlag=false;
          }else{
            this.takeFlag=true;
          }
        }
      });
    },
     classStatusClick(tab){
        console.log(tab.index)
        if(this.saveDialog.type=='edit'||this.examineDialog==true){
          if(tab.index==4){
            this.finishPaperList();
          }
        }
       
      },
    handVideoSuccess(res, file) {
      this.videoformArray.push({
        fileType: 5,
        fileUrl: res[0].url
      });
    },
    //视频确定
    confirmSaveVideo() {
      if(this.videoForm.videoUrl==''){
        this.msgWarn("视频不能为空");
        return;
      }
      if (this.saveDialog.type == "add") {
        for (var i = 0; i < this.videoformArray.length; i++) {
          this.videoformArray[i].teacherId = this.getTeacherId;
          this.videoformArray[i].title = this.getResult;
        }
        teacherUpdateAuthFilee(this.videoformArray).then(res => {
          if (res.code == 0) {
            this.msgSuccess("操作成功！");
            this.switchStatus = 4;
            this.activeName='info6';
            this.info5Flag=false;
            // this.saveDialogVisible = false;
            this.getteacherManageList();
          }
        });
      } else if (this.saveDialog.type == "edit") {
        for (var i = 0; i < this.videoformArray.length; i++) {
          this.videoformArray[i].teacherId = this.getTeacherId;
          this.videoformArray[i].title = this.getResult;
        }
        teacherUpdateAuthFilee(this.videoformArray).then(res => {
          if (res.code == 0) {
            this.msgSuccess("操作成功！");
            // this.saveDialogVisible = false;
            this.fileList = [];
            this.activeName='info6';
            // this.getteacherManageList();
          }
        });
      }
    },
     currentCityLists(){
       return systemAreaList({
        parentId: this.dataRuleForm.currentProvinceIds
      }).then(res => {
        if (res.code == 0) {
          this.currentCityData = res.rows;
        }
      });
    },
    async currentProvinceChange(){
      await this.currentCityLists();
      this.dataRuleForm.currentCityIds = this.currentCityData[0].areaId;
      this.currentCityChange();
    },
    async  currentCityChange(){
      await this.currentCountyList();
      // this.saveRuleForm.currentCountyIds = this.currentCountyData[0].areaId;
    },
    currentCountyList() {
      return systemAreaList({
        parentId: this.dataRuleForm.currentCityIds
      }).then(res => {
        if (res.code == 0) {
          this.currentCountyData = res.rows;
        }
      });
    },
     getsearch() {
      this.getPaperList();
    },
    switchTitle(val) {
      this.switchStatus = val;
      if(val==4){
        this.findPaperQuestionsByTeacherIds();
      }
    },
     //试卷选择保存
    confirmPaperSelect() {
      this.selectPaperPopup = false;
      this.paperId = this.multipleTable.id; //获取试卷id
      this.startExamFn();
    },
    startExamFn() {
      //开始考试
      if (this.teacherId.length) {
        //编辑
        var data = {
          paperId: this.paperId,
          teacherId:this.getTeacherId
        };
        startExam(data).then(res => {
          if (res.flag == "success") {
            this.finishPaperId = res.msg.finishPaperId;
            this.getfinishPaperId();
          }
        });
      } else {
        var data = {
          paperId: this.paperId,
          teacherId: this.getTeacherId
        };
        startExam(data).then(res => {
          if (res.flag == "success") {
            this.finishPaperId = res.msg.finishPaperId;
            this.getfinishPaperId();
          }
        });
      }
    },
    getfinishPaperId() {
      var data = {
        finishPaperId: this.finishPaperId,
        teacherId: this.getTeacherId
      };
      getFinishExamDetail(data).then(res => {
        if (res.flag == "success") {
          for (var i = 0; i < res.msg.finishPaperBigs.length; i++) {
            for (
              var j = 0;
              j < res.msg.finishPaperBigs[i].finishPaperSmalls.length;
              j++
            ) {
              res.msg.finishPaperBigs[i].finishPaperSmalls[j].smallTitle =
                res.msg.finishPaperBigs[i].title;
              res.msg.finishPaperBigs[i].finishPaperSmalls[j].paperBigType =
                res.msg.finishPaperBigs[i].paperBigType;
              res.msg.finishPaperBigs[i].finishPaperSmalls[j].radio = "";
              res.msg.finishPaperBigs[i].finishPaperSmalls[
                j
              ].checkbox = new Array();
              res.msg.finishPaperBigs[i].finishPaperSmalls[j].judge = "";
            }
          }
          this.getFinishExam = res.msg.finishPaperBigs;
          if (res.msg.title) {
            this.getFinishExamTitle = res.msg.title;
          }
          if (res.msg.desc) {
            this.getFinishExamDesc = res.msg.desc;
          }
        }
      });
    },
    seeReason(val){
      this.seeReasonPoups=true;
      this.reasonVal=val;
    },
    handlePaperSizeChange(pageSize) {
      this.paperParams.pageSize = pageSize;
      this.getPaperList();
    },
    handlePaperCurrentChange(currentPage) {
      this.paperParams.pageNumber = currentPage;
      this.getPaperList();
    },
    handlePaperSelectionChange(val) {
      if (val.length > 1) {
        this.$refs.paperListTable.clearSelection(); //清空列表的选中
        this.$refs.paperListTable.toggleRowSelection(val[val.length - 1]); //只显示选中最后一个 这时val还是多选的列表(就是你选中的几个数据)
      } else if (val.length === 1) {
        this.multipleTable = val[val.length - 1];
      } else {
        this.multipleTable = []; //this.multipleTable是选中行的最后一条数据
      }
    },
    paperItemClick(row) {
      this.$refs.paperListTable.toggleRowSelection(row);
    },
     updateEquipment(row){
      if(row.isEquipment==0){
         teacherUpdateEquipment({id:row.id,isEquipment:1}).then(res => {
        if (res.code == 0) {
          this.getteacherManageList();
        }
      });
      }else{
         teacherUpdateEquipment({id:row.id,isEquipment:0}).then(res => {
          if (res.code == 0) {
            this.getteacherManageList();
          }
        });
      }
    },
    //添加代课经历
    addExperience(){
      this.experienceDialog={
        title:'添加代课经历',
        type:'save'
      }
      this.experienceForm={
        studentName:"",
        studentAchievement:"",
        classTimeBegin:"",
        classTimeEnd:"",
        detailContent:"",
        photos:[]
      }
      this.ecasePickerValue1=""
      this.ecasePickerValue2=""
      this.daikePhotoList=[];
      this.experienceImgUpload=false;
      this.experienceShow=true;
    },
    getGradeSubjectList() {

      if(this.saveRuleForm.periodId.length > 0){
        getTeacherInfoByPeriodId({
        periodId: this.saveRuleForm.periodId.join(',')
      }).then( res => {
        this.teacherGradeList = res.rows[1]
        this.teacherSubjectList = res.rows[0]
        this.teacherSubjectListCheck =  res.rows[0]
      })
      }
    },
    getGradeSubjectListEX() {
        console.log(this.examnineDate.periodId,'this.examnineDate.periodId')

      if(this.examnineDate.periodId.length > 0){
        getTeacherInfoByPeriodId({
        periodId: this.examnineDate.periodId.join(',')
      }).then( res => {
        this.teacherGradeList = res.rows[1]
        this.teacherSubjectList = res.rows[0]
        this.teacherSubjectListCheck =  res.rows[0]
      })
      }
    },
    findPaperQuestionsByTeacherIds() {
      getFinishStatusByTeacherId(this.getTeacherId).then(res => {
        if (res.code == 0) {
          if (res.rows == 1) {
            //保存
            findPaperQuestionsByTeacherId(this.getTeacherId).then(res => {
              if (res.rows != []) {
                //可编辑并回显
                this.btnShow = 1;
                this.finishPaperId = res.rows[0].finishPaperId
                  ? res.rows[0].finishPaperId
                  : "";
                for (var i = 0; i < res.rows.length; i++) {
                  for (
                    var j = 0;
                    j < res.rows[i].finishPaperSmalls.length;
                    j++
                  ) {
                    res.rows[i].finishPaperSmalls[j].smallTitle =
                      res.rows[i].title;
                    res.rows[i].finishPaperSmalls[j].paperBigType =
                      res.rows[i].paperBigType;
                    if (
                      res.rows[i].finishPaperSmalls[j].smallTitle ==
                      "单项选择题"
                    ) {
                      res.rows[i].finishPaperSmalls[j].radio =
                        res.rows[i].finishPaperSmalls[j].answer;
                    } else if (
                      res.rows[i].finishPaperSmalls[j].smallTitle ==
                      "多项选择题"
                    ) {
                      res.rows[i].finishPaperSmalls[j].checkbox = res.rows[
                        i
                      ].finishPaperSmalls[j].answer.split("|");
                    } else if (
                      res.rows[i].finishPaperSmalls[j].smallTitle == "判断题"
                    ) {
                      res.rows[i].finishPaperSmalls[j].judge =
                        res.rows[i].finishPaperSmalls[j].answer;
                    } else if (
                      res.rows[i].finishPaperSmalls[j].smallTitle == "填空题"
                    ) {
                      res.rows[i].finishPaperSmalls[j].blanks =
                        res.rows[i].finishPaperSmalls[j].answer;
                    } else if (
                      res.rows[i].finishPaperSmalls[j].smallTitle == "主观题"
                    ) {
                      res.rows[i].finishPaperSmalls[j].subjective =
                        res.rows[i].finishPaperSmalls[j].answer;
                    }
                  }
                }
                this.getFinishExam = res.rows;
              }
            });
          } else if (res.rows == 2) {
            //提交审核
            this.btnShow == 0;
            // this.getFinishExam = [];
            findPaperQuestionsByTeacherId(this.getTeacherId).then(res => {
              if (res.rows != []) {
                //可编辑并回显
                // this.btnShow = 1;
                this.finishPaperId = res.rows[0].finishPaperId
                  ? res.rows[0].finishPaperId
                  : "";
                for (var i = 0; i < res.rows.length; i++) {
                  for (
                    var j = 0;
                    j < res.rows[i].finishPaperSmalls.length;
                    j++
                  ) {
                    res.rows[i].finishPaperSmalls[j].smallTitle =
                      res.rows[i].title;
                    res.rows[i].finishPaperSmalls[j].paperBigType =
                      res.rows[i].paperBigType;
                    if (
                      res.rows[i].finishPaperSmalls[j].smallTitle ==
                      "单项选择题"
                    ) {
                      res.rows[i].finishPaperSmalls[j].radio =
                        res.rows[i].finishPaperSmalls[j].answer;
                    } else if (
                      res.rows[i].finishPaperSmalls[j].smallTitle ==
                      "多项选择题"
                    ) {
                      res.rows[i].finishPaperSmalls[j].checkbox = res.rows[
                        i
                      ].finishPaperSmalls[j].answer.split("|");
                    } else if (
                      res.rows[i].finishPaperSmalls[j].smallTitle == "判断题"
                    ) {
                      res.rows[i].finishPaperSmalls[j].judge =
                        res.rows[i].finishPaperSmalls[j].answer;
                    } else if (
                      res.rows[i].finishPaperSmalls[j].smallTitle == "填空题"
                    ) {
                      res.rows[i].finishPaperSmalls[j].blanks =
                        res.rows[i].finishPaperSmalls[j].answer;
                    } else if (
                      res.rows[i].finishPaperSmalls[j].smallTitle == "主观题"
                    ) {
                      res.rows[i].finishPaperSmalls[j].subjective =
                        res.rows[i].finishPaperSmalls[j].answer;
                    }
                  }
                }
                this.getFinishExam = res.rows;
              }
            });
          } else {
            //审核成功
            this.btnShow = true;
          }
        }
      });
    },
     //选择试卷
    selectPaper() {
      this.selectPaperPopup = true;
      this.getPaperList();
    },
    getPaperList() {
      paperList(this.paperParams).then(res => {
        this.paperList = res.rows;
        this.paperTotal = res.total;
      });
    },
    getTeachTitle() {
      getSelectList({
        parentCode: teachTitle
      }).then(res => {
        if (res.code == 0) {
          this.teachTitleList = res.rows;
        }
      });
    },
    handleSelectionChange(val) {
      this.teacherId = [];
      this.teacherPhone = [];
      if (val.length > 0) {
        for (let i = 0, len = val.length; i < len; i++) {
          this.teacherId.push(val[i].id);
          this.teacherPhone.push(val[i].account);
        }
      }
    },
    getteacherManageList() {
      let data = {
        name: this.listParams.name,
        sectionId:
          this.listParams.sectionId == "0" ? "" : this.listParams.sectionId, //年级
        subjectId:
          this.listParams.subjectId == "0" ? "" : this.listParams.subjectId, //学科
        identityType:
          this.listParams.identityType == "0"
            ? null
            : this.listParams.identityType, //身份
        teachTitleId:
          this.listParams.teachTitleId == ""
            ? null
            : String(this.listParams.teachTitleId), //职称
        teachingAgeId:
          this.listParams.teachingAgeId == ""
            ? null
            : this.listParams.teachingAgeId, //教龄
        pageNumber: this.listParams.pageNumber,
        pageSize: this.listParams.pageSize,
        currentProvinceIds:this.listParams.currentProvinceIds == "" ? "" : this.listParams.currentProvinceIds, 
        currentCityIds:this.listParams.currentCityIds == "" ? "" : this.listParams.currentCityIds,
        currentCountyIds:this.listParams.currentCountyIds == "" ? "" : this.listParams.currentCountyIds
      };
      teacherManageList(data).then(res => {
        this.listArray = res.rows;
        this.total = res.total;
      });
    },
    handleSizeChange(pageSize) {
      this.listParams.pageSize = pageSize;
      this.getteacherManageList();
    },
    handleCurrentChange(currentPage) {
      this.listParams.pageNumber = currentPage;
      this.getteacherManageList();
    },
     handleSizeChange2(pageSize) {
      this.answerObject.pageSize = pageSize;
      this.teacherQuestionList();
    },
    handleCurrentChange2(currentPage) {
      this.answerObject.pageNumber = currentPage;
      this.teacherQuestionList();
    },
     submitPaperSave() {
      if(this.getFinishExam.length==0){
        this.msgWarn("该试卷未组卷，请重新选择试卷！");
        return;
      }
      var data = {
        finishPaperId: this.finishPaperId,
        finishStatus: 1
      };
      submitFinishPaper(data).then(res => {
        if (res.flag == "success") {
          this.msgSuccess("保存成功!");
          this.saveDialogVisible = false;
        }
      });
    },
    getTeachStyle(){
      getSelectList({
        parentCode: teachStyle
      }).then(res => {
        if (res.code == 0) {
          this.skilfulDate=res.rows;
        }
      });
      //擅教学生
      getSelectList({
        parentCode: achievement
      }).then(res => {
        if (res.code == 0) {
          this.skilfulTeachDate=res.rows;
        }
      });
    },
     //提交试卷
    submitPaper() {
      if(this.getFinishExam.length==0){
        this.msgWarn("该试卷未组卷，请重新选择试卷！");
        return;
      }
      var data = {
        finishPaperId: this.finishPaperId,
        finishStatus: 2
      };
      submitFinishPaper(data).then(res => {
        if (res.flag == "success") {
          this.msgSuccess("交卷成功!");
          this.info6Flag=false;
          this.activeName='info6'
        }
      });
    },
    getIdentityType(type){
      if(type==1){
        return '自由兼职老师'
      }else if(type==2){
        return '一线经验老师（含离退）'
      }else if(type==3){
        return '大学生'
      }else if(type==4){
        return '素质类老师（含艺术 语言）'
      }
    },
    confirmSave(formName) {
       this.isDisable = true
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.saveRuleForm.authFiles=[]
          if(this.uploadImg.idcard){
              this.saveRuleForm.authFiles.push({
                fileType:1,
                fileUrl:this.uploadImg.idcard,
                teacherId:this.saveDialog.type == "add" ? '' : this.getTeacherId
              })
            }
              this.saveRuleForm.authFiles.push({
                fileType:2,
                fileUrl:this.uploadImg.qualifications,
                teacherQualificationCertificate:this.teacherQualificationCertificate==true?1:this.uploadImg.qualifications?2:0,
                teacherId:this.saveDialog.type == "add" ? '' : this.getTeacherId
              })
            if(this.uploadImg.xueli){
              this.saveRuleForm.authFiles.push({
                fileType:3,
                fileUrl:this.uploadImg.xueli,
                teacherId:this.saveDialog.type == "add" ? '' : this.getTeacherId
              })
            }
            if(this.testPapers.length){
              var url = this.testPapers.map(function(obj,index){
                  return obj.url;
              }).join(",");
              this.saveRuleForm.authFiles.push({
                fileType:this.saveRuleForm.teachingDirection==2&&this.saveRuleForm.identityType==3?7:4,
                fileUrl:url,
                teacherId:this.saveDialog.type == "add" ? '': this.getTeacherId
              })
            }
          if (this.saveDialog.type == "add") {
            this.saveRuleForm2=Object.assign({}, this.saveRuleForm)
            if(this.saveRuleForm.periodId.length){
                this.saveRuleForm2.periodId=this.saveRuleForm.periodId.join()
                this.saveRuleForm.periodId=this.saveRuleForm.periodId
            }else{
             this.saveRuleForm2.periodId=""
            }
            if(this.saveRuleForm.sectionId.length){
              this.saveRuleForm2.sectionId = this.saveRuleForm.sectionId.join()
              this.saveRuleForm.sectionId=this.saveRuleForm.sectionId;
            }else{
              this.saveRuleForm2.sectionId=""
            }
             if(this.saveRuleForm.teachingDirection==1&&this.saveRuleForm.identityType==1){
              
            }else{
              this.saveRuleForm2.subjectId=this.saveRuleForm.subjectIdCheck.join();
            }
            teacherSave(this.saveRuleForm2).then(res => {
              if (res.code == 0&&typeof(res.rows)=='number') {
                this.msgSuccess("操作成功！");
                this.getTeacherId = res.rows;
                this.info2Flag=false;
                this.activeName='info2'
                this.getteacherManageList();
                this.isDisable = false
              }else{
                this.msgWarn(res.rows);
                this.isDisable = false
              }
            });
          } else if (this.saveDialog.type == "edit") {
            this.saveRuleForm.id = this.getTeacherId;
            this.saveRuleForm2=Object.assign({}, this.saveRuleForm)
            if(this.saveRuleForm.periodId.length){
                this.saveRuleForm2.periodId=this.saveRuleForm.periodId.join()
                this.saveRuleForm.periodId=this.saveRuleForm.periodId
            }else{
              this.saveRuleForm2.periodId=""
            }
            if(this.saveRuleForm.sectionId.length){
              this.saveRuleForm2.sectionId = this.saveRuleForm.sectionId.join()
              this.saveRuleForm.sectionId=this.saveRuleForm.sectionId;
            }else{
              this.saveRuleForm2.sectionId=""
            }
             if(this.saveRuleForm.teachingDirection==1&&this.saveRuleForm.identityType==1){
              
            }else{
              this.saveRuleForm2.subjectId=this.saveRuleForm.subjectIdCheck.join();
            }
            teacherUpdate(this.saveRuleForm2).then(res => {
              if (res.code == 0) {
                this.msgSuccess("操作成功！");
                this.activeName='info2'
                this.isDisable = false
                // this.saveDialogVisible = false;
              }else{
                this.msgWarn(res.rows);
                this.isDisable = false
              }
            });
          }
        } else {
          this.isDisable = false
          this.msgWarn("必填字段不能为空！");
          return false;
        }
      });
    },
   
    studentItemClick(row) {
      this.$refs.productListTable.toggleRowSelection(row);
    },
    getSubjectList() {
      return getSelectList({
        parentCode: subjectCode
      }).then(res => {
        if (res.code == 0) {
          this.subjectList = res.rows;

          this.subjectList.unshift({
            macroId: "0",
            name: "全部"
          });
        }
      });
    },
  
    //新增跟进记录
    addFollowUpClick() {
      this.followUpDialog = {
        title: "添加案例",
        type: "save"
      };
      this.followUpDialogVisible = true;
    },
    getSectionList() {
      getSelectList({
        parentCode: sectionCode
      }).then(res => {
        if (res.code == 0) {
          this.sectionList = res.rows;
          this.sectionList_clean = JSON.parse(JSON.stringify(res.rows));
          this.sectionList.unshift({
            macroId: '0',
            name: "全部"
          });
          this.gradeidList = res.rows;
         
        }
      });
    },
    getPhaseList() {
      getSelectList({
        parentCode: phaseCode
      }).then(res => {
        if (res.code == 0) {
          this.phaseList = res.rows;
          console.log(res.rows);
        }
      });
    },
    //面试评分
    confirmFace(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.faceRuleForm.teacherId = this.getTeacherId;
          if (this.saveDialog.type == "add") {
            teacherFaceMarkSave(this.faceRuleForm).then(res => {
              if (res.code == 0) {
                this.msgSuccess("操作成功！");
                this.saveDialogVisible = false;
                this.getteacherManageList();
              }
            });
          } else if (this.saveDialog.type == "edit") {
            if (this.faceType == 1) {
              teacherFaceMarkUpdate(this.faceRuleForm).then(res => {
                if (res.code == 0) {
                  this.msgSuccess("操作成功！");
                  this.saveDialogVisible = false;
                  this.getteacherManageList();
                }
              });
            } else {
              teacherFaceMarkSave(this.faceRuleForm).then(res => {
                if (res.code == 0) {
                  this.msgSuccess("操作成功！");
                  this.saveDialogVisible = false;
                  this.getteacherManageList();
                }
              });
            }
          }
        } else {
          this.msgWarn("必填字段不能为空！");
          return false;
        }
      });
    },
    //选择问题
    choiceProblem() {
      this.problemPopup = true;
      this.teacherQuestionList();
    },
    teacherQuestionList() {
      teacherQuestionList(this.answerObject).then(res => {
        this.answerArray = res.rows;
        this.total2=res.total;
        this.answerSync = false;
      });
    },
    answerSelectionChange(val) {
      this.takeAwayArray2 = val;
      this.answerId = [];
      if (val.length > 0) {
        for (let i = 0, len = val.length; i < len; i++) {
          this.answerId.push(val[i].id);
        }
      }
    },
    answerRowClick(row) {
      this.$refs.answerListTable.toggleRowSelection(row);
    },
    //面试答题添加
    answerAdd() {
      this.answerRuleForm.question = "";
      this.answerRuleForm.periodId = "";
      this.answerSync = true;
      this.answerDialog = {
        title: "新增面试答题",
        type: "add"
      };
    },
    //面试答题编辑
    answerEdit() {
      if (this.answerId.length > 1 || this.answerId.length == 0) {
        this.msgWarn("请选择一条数据进行编辑操作！");
        return;
      }
      this.answerSync = true;
      this.answerDialog = {
        title: "编辑面试答题",
        type: "edit"
      };
      this.answerRuleForm.question = "";
      this.answerRuleForm.periodId = "";
      teacherQuestionInfo(this.answerId[0]).then(res => {
        console.log(res);
        if (res.code == 0) {
          this.answerRuleForm.question = res.rows.question;
          this.answerRuleForm.periodId = res.rows.periodId;
        }
      });
    },
    //面试答题删除
    answerDelete() {
      if (this.answerId.length == 0) {
        this.msgWarn("请至少选择一条数据！");
        return;
      }
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          teacherQuestionRemove(this.answerId).then(res => {
            if (res.code == 0) {
              this.msgSuccess("操作成功！");
              this.teacherQuestionList();
            }
          });
        })
        .catch(() => {});
    },
    closeSaveDialog(){
      this.getteacherManageList();
    },
    answerConfirmSave(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.answerDialog.type == "add") {
        teacherQuestionSave(this.answerRuleForm).then(res => {
          if (res.code == 0) {
            this.msgSuccess("操作成功！");
            this.answerSync = false;
            this.teacherQuestionList();
          }
        });
      } else if (this.answerDialog.type == "edit") {
        this.answerRuleForm.id = this.answerId[0];
        teacherQuestionUpdate(this.answerRuleForm).then(res => {
          if (res.code == 0) {
            this.msgSuccess("操作成功！");
            this.answerSync = false;
            this.teacherQuestionList();
          }
        });
      }
        }else{
           this.msgWarn("必填字段不能为空！");
           return false;
        }
      })
    },
    takeAway() {
      if (this.takeAwayArray2.length > 0) {
        this.problemPopup = false;
        this.takeAwayArray2.forEach(item => {
          this.takeAwayArray.push({
            questionId: item.id,
            questionTitle: item.question,
            answer: item.answer
          });
        });
        this.takeAwayArray.answer = "";
      }
      this.$refs.answerListTable.clearSelection()
    },
    removeProblem(val) {
      for (let i = 0, len = this.takeAwayArray.length; i < len; i++) {
        if (this.takeAwayArray[i].questionId == val) {
          this.takeAwayArray.splice(i, 1);
        }
      }
    },
    confirmAnswer() {
      this.newarray = [];
      if (this.saveDialog.type == "edit") {
        this.takeAwayArray.forEach(item => {
          this.newarray.push({
            questionId: item.questionId,
            questionTitle: item.questionTitle,
            answer: item.answer,
            teacherId: this.getTeacherId
          });
        });
        if(this.takeFlag==false){
           this.xmtTeacherInterviewAnswer();
        }else{
          removeByTeacher(this.getTeacherId).then(res => {
            this.xmtTeacherInterviewAnswer();
          });
        }
      } else {
        this.takeAwayArray.forEach(item => {
          this.newarray.push({
            questionId: item.questionId,
            questionTitle: item.questionTitle,
            answer: item.answer,
            teacherId: this.getTeacherId
          });
        });
        this.xmtTeacherInterviewAnswer();
      }
    },
    xmtTeacherInterviewAnswer() {
      saveXmtTeacherInterviewAnswer(this.newarray).then(res => {
        if (res.code == 0) {
          this.msgSuccess("操作成功！");
          // this.switchStatus = 6;
           this.activeName='info4';
          this.info4Flag=false;
        }
      });
    },
    chang1(val, num) {
      console.log(num)
      var data = {
        id:num.id,
        finishPaperId: num.finishPaperId,
        finishPaperBigId: num.finishPaperBigId,
        qid: num.qid,
        questionId: num.questionId,
        rightAnswer: num.rightAnswer,
        answer: val,
        paperBigType: num.paperBigType
      };
      answerQuestion(data).then(res => {
        if (res.flag == "success") {
          // this.msgSuccess("操作成功！");
        }
      });
    },
     change(val, num) {
      this.array = [];
      for (var i = 0; i < val.length; i++) {
        this.array.push(val[i]);
      }
      var data = {
        finishPaperId: num.finishPaperId,
        finishPaperBigId: num.finishPaperBigId,
        qid: num.qid,
        questionId: num.questionId,
        rightAnswer: num.rightAnswer,
        answer: this.array.join("|"),
        paperBigType: num.paperBigType
      };
      answerQuestion(data).then(res => {
        if (res.flag == "success") {
          // this.msgSuccess("操作成功！");
        }
      });
    },
    isEnable(row) {
      this.$confirm(`${row.status == 0 ? "是否启用该讲师？" : "是否禁用该讲师？"}`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          teacherUpdate({
            id: row.id,
            status: row.status == 1 ? 0 : 1
          }).then((res) => {
           if (res.code == 0) {
            this.msgSuccess("操作成功！");
            this.getteacherManageList();
          }
          });
        })
        .catch(() => {});
    },
    editCellPhone(row){
      this.cellPhoneForm.phone="";
      this.cellPhoneForm.id=row.id;
      this.teachcell=row.realname;
      this.editCellPhoneDialog=true;
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.status == 0) {
        return "warning-row";
      }
      return "";
    },
    //审核
  async  examine(row) {
     await  this.getProvinceList();
      this.exhibitionPhotos=[]
      this.examnineId = row.id;
      this.getTeacherId =row.id;
      this.examineDialog = true;
      this.examnineSkilful = "";
      this.skilfulStyle ="";
      this.examninSectionId = "";
      this.activeName2='info1';
      this.getResult="";
      this.videoForm.videoUrl="";
      this.fileList = [];
      this.testScore = 0;
      this.examnineImages.photo1=""
      this.examnineImages={
        photo1:"",
        photo4:"",
        photo3:"",
        
      }
      this.examineRuleForm={
        peopleType: 2,
        overallScore:'',
        imageTemperament:0,
        communicationExpression:0,
        substituteLessonsBeijing:0,
        teachingExperience:0,
        provinceIdOne:'',
        otherPartnersOne:'',
        otherPartnersTwo:'',
        otherPartnersThree:'',
        provinceIdTwo:'',

        suitableForStudents:'',
        teachingCharacteristics:0,
        jobHuntingAttitude:0,
        overallEvaluation:'',
      },
   
    await  this.examineDetailFn();
    },
    //获取点击审核的详情

    examineDetailFn() {
      this.fileList =[]
      this.FaceMarkData = {
        teacherId: this.examnineId,
        peopleType: 1
      };
      this.FaceMarkData2 = {
        teacherId: this.examnineId,
        peopleType: 2
      };
      //审核员获取详情
      teacherFaceMarkDetail(this.FaceMarkData2).then(res => {
        //  this.FaceMarkList2=res.rows;
        if (res.rows == "无数据") {
          this.faceType2 = 0;
        } else {
          this.faceType2 = 1;
          this.examineRuleForm.id=res.rows.id;
          this.examineRuleForm.overallEvaluation=res.rows.overallEvaluation?res.rows.overallEvaluation:'';
          this.examineRuleForm.overallScore=String(res.rows.overallScore);
          this.examineRuleForm.imageTemperament=String(res.rows.imageTemperament);
          this.examineRuleForm.communicationExpression=String(res.rows.communicationExpression);
          this.examineRuleForm.substituteLessonsBeijing=String(res.rows.substituteLessonsBeijing);
          this.examineRuleForm.teachingExperience=String(res.rows.teachingExperience);
          this.examineRuleForm.provinceIdOne=Number(res.rows.provinceIdOne);
          this.examineRuleForm.provinceIdTwo=Number(res.rows.provinceIdTwo);
          this.examineRuleForm.otherPartnersOne=res.rows.otherPartnersOne;
          this.examineRuleForm.otherPartnersTwo=res.rows.otherPartnersTwo;
          this.examineRuleForm.otherPartnersThree=res.rows.otherPartnersThree;
          this.examineRuleForm.jobHuntingAttitude=String(res.rows.jobHuntingAttitude);
          this.examineRuleForm.teachingCharacteristics=String(res.rows.teachingCharacteristics);
        }
      });
     
      teacherFaceMarkDetail(this.FaceMarkData).then(res => {
        if (res.code == 0) {
          this.FaceMarkList = res.rows;
          if (res.rows.appearance == 1) {
            this.FaceMarkList.appearance = "极佳";
          } else if (res.rows.appearance == 2) {
            this.FaceMarkList.appearance = "佳";
          } else if (res.rows.appearance == 3) {
            this.FaceMarkList.appearance = "平实";
          } else if (res.rows.appearance == 4) {
            this.FaceMarkList.appearance = "略差";
          } else if (res.rows.appearance == 5) {
            this.FaceMarkList.appearance = "极差";
          }
          //沟通能力
          if (res.rows.connectCapacity == 1) {
            this.FaceMarkList.connectCapacity = "极佳";
          } else if (res.rows.connectCapacity == 2) {
            this.FaceMarkList.connectCapacity = "佳";
          } else if (res.rows.connectCapacity == 3) {
            this.FaceMarkList.connectCapacity = "平实";
          } else if (res.rows.connectCapacity == 4) {
            this.FaceMarkList.connectCapacity = "略差";
          } else if (res.rows.connectCapacity == 5) {
            this.FaceMarkList.connectCapacity = "极差";
          }
          //讲课速度
          if (res.rows.lectureSpeed == 1) {
            this.FaceMarkList.lectureSpeed = "正常";
          } else if (res.rows.lectureSpeed == 2) {
            this.FaceMarkList.lectureSpeed = "快";
          } else if (res.rows.lectureSpeed == 3) {
            this.FaceMarkList.lectureSpeed = "慢";
          }
          //讲课风格
          if (res.rows.lectureStyle == 1) {
            this.FaceMarkList.lectureStyle = "条理清晰，结构严谨";
          } else if (res.rows.lectureStyle == 2) {
            this.FaceMarkList.lectureStyle = "情绪饱满，慷慨激昂";
          } else if (res.rows.lectureStyle == 3) {
            this.FaceMarkList.lectureStyle = "亲切自然，娓娓而谈";
          } else if (res.rows.lectureStyle == 4) {
            this.FaceMarkList.lectureStyle = "风趣幽默，生动形象";
          } else if (res.rows.lectureStyle == 5) {
            this.FaceMarkList.lectureStyle = "组织严密，有条不紊";
          }
          //专业技能
          if (res.rows.specialtySkill == 1) {
            this.FaceMarkList.specialtySkil = "极佳";
          } else if (res.rows.specialtySkill == 2) {
            this.FaceMarkList.specialtySkil = "优秀";
          } else if (res.rows.specialtySkill == 3) {
            this.FaceMarkList.specialtySkil = "普通";
          } else if (res.rows.specialtySkill == 4) {
            this.FaceMarkList.specialtySkil = "略差";
          } else if (res.rows.specialtySkill == 5) {
            this.FaceMarkList.specialtySkil = "极差";
          }
        }
      });
      teacherQuestionAnswer(this.examnineId).then(res => {
        if (res.code == 0) {
          this.answerDate = res.rows;
        }
      });
      teacherFindAuthFile(this.examnineId).then(res => {
        this.examnineSrc1=[];
        this.examnineSrc3=[];
        this.examnineSrc4=[];
        this.examnineSrc5=[];
        var getData = res.rows.authFiles;
        if (res.code == 0) {
          for (var i = 0; i < getData.length; i++) {
            if (getData[i].fileType == 6) {
              //视频
              this.videoForm.videoUrl = getData[i].fileUrl;
              this.videoformArray.push(getData[i]);
            }
            if (getData[i].fileType == 5) {
              this.videoformArray.push(getData[i]);
              this.urlObj.url = getData[i].fileUrl;
              this.urlArr.push(getData[i].fileUrl);
              this.fileList.push(this.urlObj);
              this.getResult=getData[i].title;
              this.urlObj = {};
            }
            if(getData[i].fileType == 1&&getData[i].fileUrl!=null){
              this.examnineImages.photo1 = getData[i].fileUrl?getData[i].fileUrl:"";
            }
            if(getData[i].fileType == 2){
              this.examnineImages.photo3 = getData[i].fileUrl?getData[i].fileUrl:"";
              if(getData[i].teacherQualificationCertificate==1){
                this.teacherQualificationCertificate=true;
              }else{
                this.teacherQualificationCertificate=false;
              }
              this.qualDisaled=true
            }
            if(getData[i].fileType == 3){
              this.examnineImages.photo4 = getData[i].fileUrl?getData[i].fileUrl:"";
            }
            if(getData[i].fileType == 4||getData[i].fileType == 7){
              this.examnineImages.photo5 = getData[i].fileUrl?getData[i].fileUrl.split(','):"";
            }
          }
          this.examnineSrc1.push(this.examnineImages.photo1);
          this.examnineSrc3.push(this.examnineImages.photo3);
          this.examnineSrc4.push(this.examnineImages.photo4);
          this.examnineSrc5.push(getData[4]?getData[4].fileUrl:'');
       }
      });
      teacherDetail(this.examnineId).then(res => {
        if (res.code == 0) {
          let getRows = res.rows;
          this.examnineDate = {
            isBasicdata:getRows.isBasicdata,
            capesAuthStatus:getRows.capesAuthStatus,
            isInterview:getRows.isInterview,
            videoAuthStatus:getRows.videoAuthStatus,
            readingStage:getRows.readingStage,
            photo: getRows.photo, //图片
            name: getRows.name, //昵称
            contact: getRows.contact, //联系方式
            sexId: getRows.sexId, //性别
            realname: getRows.realname, //真实姓名
            idcard: getRows.idcard, //身份证号
            currentAddress: getRows.currentAddress, //籍贯
            birthday: getRows.birthday, //出生日期
            educationId: getRows.educationId, //学历
            graduationSchool: getRows.graduationSchool, //毕业院校
            teachingAgeId: getRows.teachingAgeId, //教龄
            schoolName: getRows.schoolName, //任教学校
            teachTitleId: getRows.teachTitleId, //老师职称
            skilful: getRows.skilful, //讲课风格
            skilfulTeach:getRows.skilfulTeach,//擅教学生
            teachTrait: getRows.teachTrait, //教学特点
            periodId:[],
            sectionId:[],
            subjectIdCheck:[],
            // sectionId: getRows.sectionId, //教授年纪
            subjectId: Number(getRows.subjectId), //教授科目
            hopeMinFee: getRows.hopeMinFee, //课酬最小值
            hopeMaxFee: getRows.hopeMaxFee, //课酬最大值
            identityType: getRows.identityType,
            classForm:Number(getRows.classForm),//授课方式
            ifAcceptAudition:getRows.ifAcceptAudition,//是否接收试听
            provinceId: res.rows.provinceId,
            cityId: res.rows.cityId,
            countyId: res.rows.countyId,
            currentProvinceId:res.rows.currentProvinceId,
            currentCityId:res.rows.currentCityId,
            currentCountyId:res.rows.currentCountyId,
            provinceName:res.rows.provinceName,
            cityName:res.rows.cityName,
            countyName:res.rows.countyName,
            currentProvinceName:res.rows.currentProvinceName,
            currentCountyName:res.rows.currentCountyName,
            currentCityName:res.rows.currentCityName,
            resume:res.rows.resume,
            currentProvincesName:res.rows.currentProvincesName,
            currentCitysName:res.rows.currentCitysName,
            teachingDirection:res.rows.teachingDirection,
            identityType:res.rows.identityType,
          };
          if(res.rows.teachingDirection==1&&res.rows.identityType==1){
            }else{
              this.examnineDate.subjectIdCheck=res.rows.subjectId.split(',').map((item) => item * 1)
          }
          var CountysName=""

          if(res.rows.currentCountysName.length){
            for(let i=0;i<res.rows.currentCountysName.length;i++){
              CountysName+=res.rows.currentCountysName[i].NAME+"，"
            }
          }
          if(getRows.skilfulTeach === null){
            getRows.skilfulTeach = ''
          }
          if(getRows.skilful === null){
            getRows.skilful = ''
          }
          this.getCurrentCountysName=CountysName.substring(0,CountysName.length-1);
           this.skilfulTeachArray = getRows.skilfulTeach
            .split(",")
            .map(function(item, index, array) {
              return item - 0;
            });
          this.skilfulArray = getRows.skilful
            .split(",")
            .map(function(item, index, array) {
              return item - 0;
            });
           var periodIdArr=[];
          periodIdArr=res.rows.periodId.split(",");
         
          this.examnineDate.periodId=periodIdArr.map(Number);
         
          var sectionIdArr=[];
          sectionIdArr = res.rows.sectionId.split(",");
          this.examnineDate.sectionId=sectionIdArr.map(Number);
          this.caseList=getRows.xmtTeacherSuccessCaseEntityList;
          this.teacherInfo.realname=getRows.realname;
          this.teacherInfo.idcard=getRows.idcard;
          //教授年级
          var sectionIdArray = new Array();
          if (res.rows.sectionId) {
            sectionIdArray = res.rows.sectionId.split(",");
          }
          this.examninSectionId="";
          for (var i = 0; i < sectionIdArray.length; i++) {
            if (sectionIdArray[i] == 98) {
              this.examninSectionId += "高三，";
            } else if (sectionIdArray[i] == 97) {
              this.examninSectionId += "高二，";
            } else if (sectionIdArray[i] == 97) {
              this.examninSectionId += "高一，";
            } else if (sectionIdArray[i] == 20) {
              this.examninSectionId += "初三，";
            } else if (sectionIdArray[i] == 19) {
              this.examninSectionId += "初二，";
            } else if (sectionIdArray[i] == 18) {
              this.examninSectionId += "初一，";
            } else if (sectionIdArray[i] == 95) {
              this.examninSectionId += "六年级，";
            } else if (sectionIdArray[i] == 94) {
              this.examninSectionId += "五年级，";
            } else if (sectionIdArray[i] == 93) {
              this.examninSectionId += "四年级，";
            } else if (sectionIdArray[i] == 92) {
              this.examninSectionId += "三年级，";
            } else if (sectionIdArray[i] == 110) {
              this.examninSectionId += "艺术培训，";
            } else if (sectionIdArray[i] == 111) {
              this.examninSectionId += "小语种，";
            }
          }
          this.examninSectionId.substring(0, this.examninSectionId.length - 1);
          //年级
          var arr2 = new Array();
          var arr3 = new Array();
          if (res.rows.skilfulTeach) {
            arr2 = res.rows.skilfulTeach.split(",");
          }
          if (res.rows.skilful) {
            arr3 = res.rows.skilful.split(",");
          }
          for (var i = 0; i < this.skilfulTeachDate.length; i++) {
            for(var k=0;k<arr2.length;k++){
              if(this.skilfulTeachDate[i].macroId==arr2[k]){
                this.examnineSkilful+=this.skilfulTeachDate[i].name;
              }
            }
          }
          this.examnineSkilful.substring(0, this.examnineSkilful.length - 1);
          for (var i = 0; i < this.skilfulDate.length; i++) {
            for(var k=0;k<arr3.length;k++){
              if(this.skilfulDate[i].macroId==arr3[k]){
                this.skilfulStyle+=this.skilfulDate[i].name;
              }
            }
          }
          this.skilfulStyle.substring(0, this.skilfulStyle.length - 1);
          //教授科目
          this.exhibitionPhotos=res.rows.exhibitionPhotoList;
        }
        this.getGradeSubjectListEX()
      });
      
    },
    switchExamine(val) {
      this.examineStatus = val;
      if (val == 4) {
        //功底考试
        this.finishPaperList();
      }
    },
 
    async  dealSee(row) {
      this.uploadImg.idcard="";
      this.uploadImg.xueli="";
      this.uploadImg.qualifications="";
      this.testPapers=[]
      this.getTeacherId = row.id;
      this.seeActiveName='see1';
      await this.getProvinceList()
      this.saveDialog = {
        title: "编辑教师",
        type: "edit"
      };
      this.saveRuleForm = {
        subjectIdCheck:[],
        photo: "", //图片
        name: "", //昵称
        contact: "", //联系方式
        sexId: "", //性别
        realname: "", //真实姓名
        idcard: "", //身份证号
        currentAddress: "", //籍贯
        birthday: "", //出生日期
        educationId: "", //学历
        graduationSchool: "", //毕业院校
        teachingAgeId: "", //教龄
        schoolName: "", //任教学校
        teachTitleId: "", //老师职称
        skilfulTeach: "", //擅教学生
        skilful:"",//讲课风格
        teachTrait: "", //教学特点
        periodId:[],
        sectionId: [], //教授年纪
        subjectId: "", //教授科目
        hopeMinFee: "", //课酬最小值
        hopeMaxFee: "", //课酬最大值，
        classForm:"",//授课方式
        ifAcceptAudition:"",//是否接收试听
        provinceId: "",
        cityId: "",
        countyId: "",
        currentProvinceIds:"",
        currentCityIds:"",
        currentCountyIds:[],

      };
      (this.faceRuleForm = {
        peopleType: 1,
        overallScore:'',
        imageTemperament:0,
        communicationExpression:0,
        substituteLessonsBeijing:0,
        teachingExperience:0,
        provinceIdOne:'',
        otherPartnersOne:'',
        otherPartnersTwo:'',
        otherPartnersThree:'',
        // teacherType:0,
        teachingCharacteristics:0,
        jobHuntingAttitude:0,
        overallEvaluation:'',
      }),
        (this.switchStatus = 1);
      this.fileList = [];
      await this.getTeacherDetail();
      this.seeDialogVisible = true;
      this.getResult="";
      this.videoForm.videoUrl="";
    },
    async  dealSeeTit() {
      this.testPapers=[]
      this.faceRuleFormAuditor={
        overallScore:"",
        imageTemperament:"",
        communicationExpression:"",
        substituteLessonsBeijing:"",
        teachingExperience:"",
        provinceIdOne:"",
        provinceIdTwo:"",
        otherPartnersOne:"",
        otherPartnersTwo:"",
        otherPartnersThree:"",
        teachingCharacteristics:"",
        jobHuntingAttitude:"",
        overallEvaluation:""
      }
      this.getFinishExamTitle ="";
      this.getFinishExamDesc = "";
      this.getFinishExam = [];
      this.seeActiveName='see1';
      this.paperTable=[];
      
      await this.getProvinceList()
      this.saveDialog = {
        title: "编辑教师",
        type: "edit"
      };
      if (this.teacherId.length != 1) {
        this.msgWarn("请选择一名教师！");
        return;
      }
      this.getTeacherId = this.teacherId[0]
      this.saveRuleForm = {
        photo: "", //图片
        name: "", //昵称
        contact: "", //联系方式
        sexId: "", //性别
        realname: "", //真实姓名
        idcard: "", //身份证号
        currentAddress: "", //籍贯
        birthday: "", //出生日期
        educationId: "", //学历
        graduationSchool: "", //毕业院校
        teachingAgeId: "", //教龄
        schoolName: "", //任教学校
        teachTitleId: "", //老师职称
        skilfulTeach: "", //擅教学生
        skilful:"",//讲课风格
        teachTrait: "", //教学特点
        periodId:[],
        sectionId: [], //教授年纪
        subjectId: "", //教授科目
        hopeMinFee: "", //课酬最小值
        hopeMaxFee: "", //课酬最大值，
        classForm:"",//授课方式
        ifAcceptAudition:"",//是否接收试听
        provinceId: "",
        cityId: "",
        countyId: "",
        currentProvinceIds:"",
        currentCityIds:"",
        currentCountyIds:[],
        readingStage:""
      };
      (this.faceRuleForm = {
        peopleType: 1,
        overallScore:'',
        imageTemperament:0,
        communicationExpression:0,
        substituteLessonsBeijing:0,
        teachingExperience:0,
        provinceIdOne:'',
        otherPartnersOne:'',
        otherPartnersTwo:'',
        otherPartnersThree:'',
        // teacherType:0,
        teachingCharacteristics:0,
        jobHuntingAttitude:0,
        overallEvaluation:'',
      }),
        (this.switchStatus = 1);
      this.fileList = [];
      await this.getTeacherDetail();
      this.seeDialogVisible = true;
      this.getResult="";
      this.videoForm.videoUrl="";
    },
    seeHandleClick(tab, event){
          if(tab.index==4){
            this.finishPaperList();
          }
      },
    educationId(row){
      if(row==70){
        return '专科'
      }else if(row==69){
        return '本科'
      }else if(row==68){
        return '硕士'
      }else if(row==67){
        return '博士'
      }
    },
    photoBeforeAvatarUpload(file){
      let types = ['image/jpeg', 'image/jpg', 'image/png'];
      const isImage = types.includes(file.type);
      if (!isImage) {
        this.$message.error('上传图片只能是 JPG、JPEG、PNG 格式!');
        return false;
      }
    },
    isBasicFn(num){
      if(num==1){//通过
        let data={"id":this.examnineId,"isBasicdata":2,"idcardAuthStatus":2,"capesAuthStatus":2,"educationAuthStatus":2}
        teacherUpdateState(data).then(res => {
          if (res.code == 0) {
              this.examnineSkilful=''
              this.skilfulStyle=""
              this.examineDialog=false;
              this.getteacherManageList();
              this.msgSuccess("操作成功！");
              this.getProvinceList();
              this.examineDetailFn();
          }
        });
      }else if(num==2){//驳回
        this.$prompt('请输入驳回原因', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /\S/,
        inputErrorMessage: '请输入驳回原因不能为空！',
        closeOnClickModal:false
      }).then(({ value }) => {
        teacherUpdateState({
          id: this.examnineId,
          isBasicdata:3,
          idcardAuthStatus:3,
          capesAuthStatus:3,
          educationAuthStatus:3,
          basicdataRejectReason:value
        }).then(res => {
          if(res.code == 0){
            this.msgSuccess('操作成功！')
            this.examineDialog=false;
              this.getteacherManageList();
            this.getProvinceList();
            this.examineDetailFn();
          }
        })
      }).catch(() => {

      });
      }else if(num==3){//撤回
          let data={"id":this.examnineId,"isBasicdata":0,"idcardAuthStatus":0,"capesAuthStatus":0,"educationAuthStatus":0}
          teacherUpdateState(data).then(res => {
            if (res.code == 0) {
              this.examnineSkilful=''
              this.skilfulStyle=""
              this.msgSuccess("操作成功！");
              this.examineDialog=false;
              this.getteacherManageList();
              this.getProvinceList();
              this.examineDetailFn();
            }
          });
        }
    },
     seeFinishPaper(id){
       this.findPaperQuestions(id);
    },
    finishPaperList() {
     this.testScore=0;
      var data = {
        examStatus: 1,
        pageNumber: 1,
        pageSize: 1000,
        sortOrder: "asc",
        examUserId: this.getTeacherId ? this.getTeacherId : this.examnineId,
        examUser:"teacher"
      };
      finishPaperList(data).then(res => {
        if (res.rows.length) {
          this.paperId=res.rows[0].id;
          this.paperTable = res.rows;
          if (res.rows[0].title) {
            this.getFinishExamTitle = res.rows[0].title;
          }
          this.findPaperQuestions(res.rows[0].id);
        }else{
          this.finishPaperId = ''
          this.getFinishExam = []
          this.getFinishExamTitle=""
        }
      });
    },
    findPaperQuestions(examUserId) {
      findPaperQuestions(examUserId).then(res => {
        if(res.rows.length){
          this.finishPaperId=res.rows[0].finishPaperId;
        }
        var data = {
        finishPaperId: this.finishPaperId,
        teacherId: this.getTeacherId
      };
        getFinishExamDetail(data).then(res => {
        if (res.flag == "success") {
          if (res.msg.title) {
            this.getFinishExamTitle = res.msg.title;
          }
          if (res.msg.desc) {
            this.getFinishExamDesc = res.msg.desc;
          }
        }
      });
        for (var i = 0; i < res.rows.length; i++) {
          for (var j = 0; j < res.rows[i].finishPaperSmalls.length; j++) {
            this.testScore +=Number(res.rows[i].finishPaperSmalls[j].score);
            res.rows[i].finishPaperSmalls[j].smallTitle = res.rows[i].title;
            res.rows[i].finishPaperSmalls[j].paperBigType =
              res.rows[i].paperBigType;
            if (res.rows[i].finishPaperSmalls[j].smallTitle == "单项选择题") {
              res.rows[i].finishPaperSmalls[j].radio =
                res.rows[i].finishPaperSmalls[j].answer;
            } else if (
              res.rows[i].finishPaperSmalls[j].smallTitle == "多项选择题"
            ) {
              res.rows[i].finishPaperSmalls[j].checkbox = res.rows[i]
                .finishPaperSmalls[j].answer
                ? res.rows[i].finishPaperSmalls[j].answer.split("|")
                : [];
            } else if (
              res.rows[i].finishPaperSmalls[j].smallTitle == "判断题"
            ) {
              res.rows[i].finishPaperSmalls[j].judge =
                res.rows[i].finishPaperSmalls[j].answer;
            } else if (
              res.rows[i].finishPaperSmalls[j].smallTitle == "填空题"
            ) {
              res.rows[i].finishPaperSmalls[j].blanks =
                res.rows[i].finishPaperSmalls[j].answer;
            } else if (
              res.rows[i].finishPaperSmalls[j].smallTitle == "主观题"
            ) {
              res.rows[i].finishPaperSmalls[j].subjective =
                res.rows[i].finishPaperSmalls[j].answer;
            }
          }
        }
        this.getFinishExam = res.rows;
      });
    },
    //审核功底考试保存
    basicSkills() {
      if(this.getFinishExam.length==0){
        this.msgWarn("功底考试暂无内容!");
        return;
      }
      var data={
        finishPaperBigs:this.getFinishExam,
        id:this.paperId,
        finishStatus:3
      }
      updatePaperQuestions(data).then(res => {
        if (res.code == 0) {
          this.msgSuccess("保存成功！");
          // this.examineDialog = false;
        }
      });
    },
    parentCodesFn(){
      parentCodes({
        parentCode: readingStage
      }).then(res => {
        if (res.code == 0) {
         this.stageList=res.rows;
        }
      });
      //获取学生类型
      parentCodes({
        parentCode: qualityStudentsType
      }).then(res => {
        if (res.code == 0) {
         this.experienceFormList=res.rows;
        }
      });
    },
    confirmExamineFace(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          
           
          this.examineRuleForm.teacherId = this.examnineId;
          if (this.faceType2 == 0) {
            //添加
            teacherFaceMarkSave(this.examineRuleForm).then(res => {
              if (res.code == 0) {
                this.msgSuccess("操作成功！");
                this.examineDialog = false;
              }
            });
          } else {
            //编辑
            teacherFaceMarkUpdate(this.examineRuleForm).then(res => {
              if (res.code == 0) {
                this.msgSuccess("操作成功！");
                this.examineDialog = false;
              }
            });
          }
          console.log(this.examineRuleForm);
        } else {
          this.msgWarn("必填字段不能为空！");
          return false;
        }
      });
    }
  },
  mounted() {
    this.getteacherManageList();
    this.getSectionList();
    this.getPhaseList();
    this.getSubjectList();
    this.getTeachTitle();
    this.getTeachStyle();
    this.getProvinceList();
    this.getSectionList2();
    this.getSubjectList2();
    this.parentCodesFn();
    this.startDatePicker = this.beginDate();
    this.endDatePicker = this.processDate();
  }
};
</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */
.demo-table-expand {
  font-size: 0;
}
/deep/.dialog-footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.switchClass {
  color: red;
}
// .demo-table-expand label {
//   width: 120px;
//   color: #99a9bf;
// }
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 16%;
}
.demo-table-expand span {
  cursor: pointer;
}
.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .followUp_area {
    width: 100%;
    display: flex;
    align-items: center;
    margin-left: 50px;
  }
  .followBox {
    margin-left: 50px;
  }
  .btn-area {
    height: 50px;
    display: flex;
    align-items: center;
    .right {
      flex: 1;
      display: flex;
      justify-content: flex-end;
    }
  }
  .select-condition {
    border-bottom: 1px solid #f1f1f1;
    .left {
      flex: 1;
      display: flex;
      .select-condition-item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        .item-lable {
          word-break: keep-all;
        }
      }
    }
  }
  .table-area {
    flex: 1;
    overflow: hidden;
  }
  .page-area {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .dialog-content {
    min-height: 260px;
    max-height: 60vh;
    overflow-y: auto;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 30px;
    .item_select {
      display: flex;
      align-items: center;
      span {
        width: 120px;
      }
    }
    .record {
      margin-top: 230px;
    }
  }
  .score-input {
    border: none;
    outline: none;
    border-bottom: 1px solid #000000;
    text-align: center;
    width: 80px;
  }
  .subject-item {
    height: 40px;
    line-height: 40px;
  }
  .self-radio-group {
    margin-left: 20px;
    .self-radio-group-item {
      margin-bottom: 8px;
    }
  }
  .center {
    text-align: center;
    min-height: 30px;
  }
  .line {
    height: 0;
    border-bottom: 1px solid #f1f1f1;
    margin-left: 30px;
    margin: 10px 0 10px 30px;
  }
}
/deep/.el-table .warning-row {
  background: #eee;
  color:#ccc;
}
.dialog-imgs {
  // span {
  //   width: 150px;
  //   display: inline-block;
  // }
}
.img-col {
  display: flex;
  align-items: center;
  margin-top: 20px;
  // justify-content: space-between;
}
.examine-div1 {
  p {
    margin-top: 25px;
    
  }
}
.imgDefault{
  width:180px;
  height:120px;
  background:#f5f7fa;
  color:#909399;
  display:flex;
  align-items: center;
  justify-content: center;
}
.imgShow{
  width:180px;
  height:120px;
  object-fit:cover;
  object-position:top;
  border-radius: 2px;
}
.image-slot{
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align:center;
  p{
    margin-top:6px;
  }
}
 /deep/ .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
      background-color: #1890ff;
      border-color: #1890ff;
    }
    /deep/ .el-checkbox.is-disabled.is-checked .el-checkbox__label {
      color: #1890ff;
    }
     .video_area {
    width: 200px;
    height: 140px;
    video {
      width: 200px;
      height: 140px;
      object-fit: fill;
    }
    div {
      width: 200px;
      height: 140px;
      border: 1px solid #dcdfe6;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .caseDiv{
    display: flex;
    margin-top:20px;
    .caseLeft{
      width: 80%;
      span{
        margin-right:30px;
      }
    } 

    .caseRight{
      flex:1;
      text-align: center;
    }
  }
   >>>.hide .el-upload--picture-card {
  		display: none;
	}
  .upDisabled .el-upload–picture-card {
    display: none;
  }
  .aaa span{
    width:20%;
    display:inline-block;
  }
  >>>.quesborder{
    margin-top:20px !important;
    >p:nth-child(1){
     font-size:18px;
    }
  }
    .imgDolg {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  #imgDolgClose {
    position: fixed;
    top: 35px;
    cursor: pointer;
    right: 7%;
    font-size: 50px;
    color: white;
  }
  img {
    width: 40%;
    display: block;
  }
}
.img_list {
        width: 100%;
        min-height: 120px;
        overflow: auto;
        .img_item {
          position: relative;
          margin: 10px 10px 10px 0px;
          float: left;
          cursor: pointer;
          height: 108px;
          width: 146px;
          border: 1px solid #dddddd;
          border-radius: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          .img_hover {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: none;
            z-index: 6;
            align-items: center;
            justify-content: center;
            background-color: rgba($color: #000000, $alpha: 0.7);
            .icon_del {
              margin-left: 10px;
              cursor: pointer;
              color: #ffffff;
            }
            .icon_preview {
              cursor: pointer;
              color: #ffffff;
              font-size: 14px;
            }
          }
          &:hover {
            .img_hover {
              display: flex;
            }
          }
          img {
            width: 100%;
            height: 100%;
          }
          .icon_new {
            font-size: 20px;
          }
        }
      }
</style>
