<template>
  <div class="main-content sys-role-manage sys-menu-manage">
    <div class="btn-area">
      <div class="left"></div>
      <div class="right">
        <el-button-group>
          <el-button type="default" size="small" @click="importClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-shuaxin" />
            </svg>
            <span>导入</span>
          </el-button>
          <el-button type="default" size="small" @click="refreshClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-shuaxin" />
            </svg>
            <span>刷新</span>
          </el-button>
          <el-button type="default" size="small" @click="addClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-xinzeng" />
            </svg>
            <span>新增</span>
          </el-button>
          <el-button type="default" size="small" @click="editClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-edit" />
            </svg>
            <span>编辑</span>
          </el-button>
          <el-button type="default" size="small" @click="delClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-trash" />
            </svg>
            <span>删除</span>
          </el-button>
        </el-button-group>
      </div>
    </div>
    <div class="table-area org-tree">
      <el-tree
        node-key="id"
        :props="props"
        :load="loadNode"
        lazy
        :highlight-current="treeOptions.highlightCurrent"
        :expand-on-click-node="treeOptions.expandOnClickNode"
        :default-expand-all="treeOptions.defaultExpandAll"
        :default-expanded-keys="treeOptions.defaultExpandedKeys"
        @node-click="handleNodeClick"
        :key="treeKey"
        style="height: 400px"
      ></el-tree>
    </div>
    <el-dialog :title="saveDialog.title" :visible.sync="saveDialogVisible" width="500px" center>
      <div>
        <el-form
          :model="saveRuleForm"
          status-icon
          :rules="saveRules"
          ref="saveRuleForm"
          label-width="100px"
          size="small"
        >
          <el-form-item label="名称" prop="" v-show="dataTypeShow==true">
            <el-select
              v-model="saveRuleForm.dictId"
              placeholder="请选择"
              size="small"
              style="width: 100%"
            >
              <el-option
                v-for="item in selectList"
                :key="item.macroId"
                :label="item.name"
                :value="item.macroId"
              ></el-option>
            </el-select>
          </el-form-item>
            <el-form-item label="名称" prop="" v-show="dataTypeShow==false">
            <el-input v-model="saveRuleForm.name"></el-input>
          </el-form-item>
          <el-form-item label="排序" prop="">
            <el-input v-model="saveRuleForm.sort"></el-input>
          </el-form-item>
          <el-form-item label="权重" prop="weight">
            <el-input v-model="saveRuleForm.weight"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="saveDialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="confirmSave('saveRuleForm')" size="small">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="导入知识点"
      :visible.sync="importDialogVisible"
      width="700px"
      :close-on-click-modal="false"
      center
    >
      <div class="dialog-content">
        <div style="text-align: left">
          <el-button type="success" size="small" @click="selectFileClick"
            >选择文件</el-button
          >
          <el-button type="primary" size="small"  v-loading.fullscreen.lock="fullscreenLoading" @click="uploadSelectFileClick">
            开始上传  
          </el-button>
          <input
            type="file"
            multiple
            style="display: none"
            ref="batchImport"
            @change="selectFileChange"
          />
        </div>
        <el-table
          :data="selectFile"
          border
          height="400"
          style="width: 100%; margin-top: 20px"
          size="small"
          row-key="id"
        >
          <el-table-column prop="file.name" label="文件名" align="center">
          </el-table-column>
          <el-table-column prop="file.size" label="大小" align="center">
          </el-table-column>
          <!-- <el-table-column label="状态" align="center">
            <template slot-scope="scope">
              <span v-show="scope.row.status == 0">待上传</span>
              <span v-show="scope.row.status == 1">上传成功</span>
              <span v-show="scope.row.status == 2"
                >上传失败(原因：{{ scope.row.importMsg }})</span
              >
            </template>
          </el-table-column> -->
          <el-table-column prop="amount2" label="操作" align="center">
            <template slot-scope="scope">
              <el-button
                type="danger"
                v-show="scope.row.status == 0 || scope.row.status == 2"
                size="small"
                @click="selectFileDel(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="importDialogVisible = false"
          size="small"
          >关闭</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  systemCourseListSyncTree,
  systemCourseSave,
  systemCourseRemove,
  systemCourseUpdate,
  systemCourseInfo,
} from "@/api/system/systemManage";
import {
  knowledge,
  knowledgeCheckRemove,
  knowledgeRemove,
  knowledgeSave,
  findCommonMacros,
  knowledgeInfo,
  knowledgeUpdate,
  importFile
} from "@/api/teacher/teacherManage";
import { getSelectList, phaseCode, sectionCode, subjectCode } from "@/api/public";
import { formatTree } from "@/utils/common";
export default {
  inject: ['reload'],
  data() {
    return {
      fullscreenLoading:false,
  
      importDialogVisible:false,
      importFile,
      selectFile:[],
      dataTypeShow:true,
      saveDialogVisible: false,
      saveRuleForm: {
        businessMark:'public',
        id: 0,
        name: "",
        dictId: "",
        parentId: "",
        parentIds: "",
        dataType: 1,
        sort: "",
        weight:""
      },
      saveRules: {
        dictId: [
          { required: true, message: "名称不能为空", trigger: "blur" },
        ],
        name: [
          { required: true, message: "名称不能为空2", trigger: "blur" },
        ],
        sort: [{ required: true, message: "排序不能为空", trigger: "blur" }],
      },
      saveDialog: {
        title: "新增",
        type: "add",
      },
      defaultProps: {
        children: "children",
        label: "name",
      },
      treeData: [],
      treeOptions: {
        defaultExpandAll: false,
        expandOnClickNode: true,
        highlightCurrent: true,
        defaultExpandedKeys: [0],
        selectNode: {},
      },
      props: {
        label: "name",
      },
      parentId: 0,
      childList: [],
      selectList: [],
      treeKey: new Date().getTime(),
    };
  },
  methods: {
    loadNode(node, resolve) {
      if (node.level === 0) {
        return knowledge({
          parentId: this.parentId,
        }).then((res) => {
          if (res.code == 0) {
            this.treeData = formatTree(res.rows, {
              id: "id",
              parentId: "parentId",
              originId: -1,
            });
            console.log(this.treeData,'treeData....')
            if (this.treeData[0].children.length > 0) {
              this.childList = JSON.parse(
                JSON.stringify(this.treeData[0].children)
              );
            }
            resolve(this.treeData);
          }
        });
      }
      if (node.level === 1) {
        return resolve(this.childList);
      }
      if (node.level > 1) {
        this.parentId = node.data.id;
        return knowledge({
          parentId: this.parentId,
        }).then((res) => {
          if (res.code == 0) {
            resolve(res.rows);
          }
        });
      }
    },
    handleNodeClick(selectNode) {
      this.treeOptions.selectNode = selectNode;
    },
    selectFileDel(row){
       for (let i = 0, len = this.selectFile.length; i < len; i++) {
          if (row && row.id == this.selectFile[i].id) {
            this.selectFile.splice(i, 1);
            break;
          }
        }
    },
    selectFileChange(){
      if (this.$refs.batchImport.files.length > 0) {
        for (
          let i = 0, len = this.$refs.batchImport.files.length;
          i < len;
          i++
        ) {
          this.selectFile.push({
            status: 0,
            file: this.$refs.batchImport.files[i],
            importMsg: "",
            // id: ++id
          });
        }
      }
    },
    confirmSave(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          for (let i = 0, len = this.selectList.length; i < len; i++) {
            if (this.saveRuleForm.dictId == this.selectList[i].macroId) {
              this.saveRuleForm.name = this.selectList[i].name;
              break;
            }
          }
          let data = {
            businessMark:'public',
            id: this.saveRuleForm.id,
            name: this.saveRuleForm.name,
            dictId: this.saveRuleForm.dictId,
            parentId: this.saveRuleForm.parentId,
            parentIds: this.saveRuleForm.parentIds,
            dataType: this.saveRuleForm.dataType,
            sort: this.saveRuleForm.sort,
            weight:this.saveRuleForm.weight
          };
         
          if (this.saveDialog.type == "add") {
            knowledgeSave(data).then((res) => {
              if (res.code == 0) {
                this.msgSuccess("操作成功！");
                this.saveDialogVisible = false;
                this.parentId = 0;
                this.treeKey = new Date().getTime()
              }
            });
          } else if (this.saveDialog.type == "edit") {
            knowledgeUpdate(data).then((res) => {
              if (res.code == 0) {
                this.msgSuccess("操作成功！");
                this.saveDialogVisible = false;
                this.parentId = 0;
                this.treeKey = new Date().getTime()
              }
            });
          }
        } else {
          this.msgWarn("必填字段不能为空！");
          return false;
        }
      });
    },
    editClick() {
      if(this.treeOptions.selectNode.dataType>2){//输入名称
          this.dataTypeShow=false;
      }else{
        this.dataTypeShow=true;
      }
      // console.log(this.treeOptions.selectNode)
      if (this.treeOptions.selectNode.id === undefined) {
        this.msgWarn("请选择一个条目进行编辑！");
        return;
      }
      if(this.treeOptions.selectNode.dataType==0){//根目录
        this.msgWarn("根目录不可以修改！");
        return;
      }
      this.saveDialog.title = "编辑";
      this.saveDialog.type = "edit";
      this.saveRuleForm = {
        businessMark:'public',
        id: this.treeOptions.selectNode.id,
        name: "",
        dictId: "",
        parentId: "",
        parentIds: "",
        dataType: 1,
        sort: "",
        weight:""
      };
      this.saveDialogVisible = true;
      knowledgeInfo(this.saveRuleForm.id).then((res) => {
        if (res.code == 0) {
          this.saveRuleForm = {
            businessMark:'public',
            id: res.rows.id,
            name: res.rows.name,
            dictId: res.rows.dictId,
            parentId: res.rows.parentId,
            parentIds: res.rows.parentIds,
            dataType: res.rows.dataType,
            sort: res.rows.sort,
            weight:res.rows.weight
          };
          this.selectList = [
            {name: res.rows.name, macroId: res.rows.dictId}
          ]
          let parentCode;
          if (this.saveRuleForm.dataType == 1) {
            parentCode = phaseCode;
          } else if (this.saveRuleForm.dataType == 2) {
            parentCode = subjectCode;
          } else if (this.saveRuleForm.dataType == 3) {
            parentCode = subjectCode;
          }
          getSelectList({
            parentCode,
          }).then((res) => {
            if (res.code == 0) {
              this.selectList = res.rows;
            }
          });
        }
      });
    },
     importClick(){
      this.importDialogVisible=true;
    },
     selectFileClick() {
      this.$refs.batchImport.click();
      this.$refs.batchImport.value = "";
    },
    uploadSelectFileClick(){
         for (let i = 0, len = this.selectFile.length; i < len; i++) {
        if (!this.selectFile[i].status) {
          this.fullscreenLoading=true;
          this.dealFileUpload(this.selectFile[i]);
        }
      }
    },
     dealFileUpload(item) {
      let formData = new FormData();
      // formData.append("areaId", this.areaId);
      formData.append("file", item.file);
      importFile(formData).then(res => {
           this.fullscreenLoading = true;
        if (res.code == 0) {
             this.fullscreenLoading = false;
          if (res.errorList.length > 0) {
            item.status = 2;
            item.importMsg = res.errorList[0].importMsg;
          } else if (res.successList.length > 0) {
            item.status = 1;
            item.importMsg = "";
          }
          this.msgSuccess("导入成功！");
        }
      });
    },
    addClick() {
      if(this.treeOptions.selectNode.dataType>=2){//输入名称
          this.dataTypeShow=false;
      }else{
        this.dataTypeShow=true;
      }
      let parentCode;
      if (this.treeOptions.selectNode.id === undefined) {
        this.msgWarn("请选择一个目录添加下级！");
        return;
      }
      // if (this.treeOptions.selectNode.dataType == 3) {
      //   this.msgWarn("学科目录无法添加下级目录！");
      //   return;
      // }
      this.saveDialog.title = "新增";
      this.saveDialog.type = "add";
      this.saveRuleForm = {
        businessMark:'public',
        id: 0,
        // name: "",
        // dictId: "",
        parentId: this.treeOptions.selectNode.id,
        parentIds: "",
        dataType: Number(this.treeOptions.selectNode.dataType) + 1,
        sort: "",
        weight:""
      };
      if (this.treeOptions.selectNode.id == 0) {
        this.saveRuleForm.parentIds = "0";
      } else {
        this.saveRuleForm.parentIds =
          this.treeOptions.selectNode.parentIds +
          "," +
          this.treeOptions.selectNode.id;
      }
      if (this.saveRuleForm.dataType == 1) {
        parentCode = "sys.teach.phase";
      } else if (this.saveRuleForm.dataType == 2) {
        parentCode = "sys.teach.subject";
      } 
      // else if (this.saveRuleForm.dataType == 3) {
      //   parentCode = "sys.teach.subject";
      // }
      getSelectList({
        parentCode,
      }).then((res) => {
        if (res.code == 0) {
          this.selectList = res.rows;
        }
      });
      this.saveDialogVisible = true;
    },
    refreshClick() {
      this.reload()
    },
    delClick() {
      if (this.treeOptions.selectNode.id === undefined) {
        this.msgWarn("请选择一个条目进行操作！");
        return;
      }
         knowledgeCheckRemove(this.treeOptions.selectNode.id).then((res) => {
            if (res.rows=='OK') {
              this.$confirm("确定要删除吗?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              })
                .then(() => {
                  knowledgeRemove([this.treeOptions.selectNode.id]).then((res) => {
                    if (res.code == 0) {
                      this.msgSuccess("操作成功！");
                      this.parentId = 0;
                      this.treeKey = new Date().getTime()
                    }
                  });
                })
                .catch(() => {});
            }else{
              this.msgWarn("包含子级元素不能删除!");
            }
          });
    },
  },
  created() {},
  mounted() {},
};
</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */
.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .btn-area {
    height: 50px;
    display: flex;
    align-items: center;
    .left {
      width: 300px;
    }
    .right {
      flex: 1;
      display: flex;
      justify-content: flex-end;
    }
  }
  .table-area {
    flex: 1;
    padding: 20px 0;
    overflow: auto;
    border: 1px solid #f1f1f1;
  }
}
</style>