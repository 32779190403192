<template>
  <div class="main-content sys-role-manage">
    <div class="btn-area select-condition">
      <div class="left">
        <div class="select-condition-item">
          <span class="item-lable">关键字：</span>
          <el-input
            v-model="subjectParams.title"
            placeholder="请输入查询关键字"
            size="small"
            style="min-width:120px"
          ></el-input>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">请选择题型：</span>
          <el-select
            v-model="subjectParams.qtpye"
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="item in qtpyeLists"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">请选择年级：</span>
          <el-select
            v-model="subjectParams.gradeId"
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="item in gradeidList"
              :key="item.macroId"
              :label="item.name"
              :value="item.macroId"
            ></el-option>
          </el-select>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">请选择学科：</span>
          <el-select
            v-model="subjectParams.subjectId"
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="item in subjectidList"
              :key="item.macroId"
              :label="item.name"
              :value="item.macroId"
            ></el-option>
          </el-select>
        </div>
        <div class="select-condition-item">
          <el-button
            type="primary"
            size="small"
            icon="el-icon-search"
            @click="()=>(subjectParams.pageNumber=1,getQuestionsList())"
            >搜索</el-button
          >
        </div>
      </div>
    </div>
    <div class="btn-area">
      <div class="right">
        <el-button-group>
          <el-button type="default" size="small" @click="refreshFn">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-shuaxin" />
            </svg>
            <span>刷新</span>
          </el-button>
          <el-button type="default" size="small" @click="addFn">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-xinzeng" />
            </svg>
            <span>添加</span>
          </el-button>
          <el-button type="default" size="small" @click="editFn">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-edit" />
            </svg>
            <span>编辑</span>
          </el-button>
          <el-button type="default" size="small" @click="deleteFn">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-trash" />
            </svg>
            <span>删除</span>
          </el-button>
        </el-button-group>
      </div>
    </div>
    <div class="table-area">
      <el-table
        size="small"
        :data="productList"
        style="width: 100%; margin-bottom: 20px"
        row-key="id"
        border
        height="100%"
        tooltip-effect="dark"
        ref="productListTable"
        @selection-change="handleSelectionChange"
        @row-click="studentItemClick"
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="createTime"
          label="试题"
          align="center"
          width="355"
        >
          <template slot-scope="scope">
            <div v-html="testTitle(scope.row)"></div>
          </template>
        </el-table-column>
        <el-table-column
          prop="qtpye"
          label="试题题型"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="createTime"
          label="年级/学科"
          align="center"
          :formatter="dealSectionId"
        ></el-table-column>
        <el-table-column
          prop="knowledges"
          label="知识点"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="diff"
          label="难度值(1~5)"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="source"
          label="试题来源(试卷)"
          align="center"
        ></el-table-column>
      </el-table>
    </div>
    <div class="page-area">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="subjectParams.pageNumber"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="subjectParams.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background
      ></el-pagination>
    </div>
    <el-dialog
      :title="popupData.title"
      :visible.sync="popupVisible"
      width="900px"
      center
      :close-on-click-modal="false"
      custom-class="product-dialog"
    >
      <div class="dialog-content">
        <el-form
          :model="saveRuleForm"
          status-icon
          :rules="saveRules"
          ref="saveRuleForm"
          label-width="120px"
          size="small"
        >
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="试题题型" prop="qtpye">
                <el-select
                  v-model="saveRuleForm.qtpye"
                  placeholder="请选择"
                  @change="questionTypeChange"
                >
                  <el-option
                    v-for="option in qtpyeList"
                    :key="option.value"
                    :label="option.label"
                    :value="option.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="试题-题干" prop="title" ref="title">
            <editor
              :content_height="content_height"
              :set_editor_content="set_editor_title"
              @edit_content_change="edit_title_change"
            ></editor>
          </el-form-item>
          <el-form-item
            label="选项A"
            prop="optionA"
            v-show="
              saveRuleForm.qtpye == '1' ||
              saveRuleForm.qtpye == '2' ||
              saveRuleForm.qtpye == '6'
            "
          >
            <editor
              :content_height="content_height"
              :set_editor_content="set_editor_optionA"
              @edit_content_change="edit_optionA_change"
            ></editor>
          </el-form-item>
          <el-form-item
            label="选项B"
            prop="optionB"
            v-show="
              saveRuleForm.qtpye == '1' ||
              saveRuleForm.qtpye == '2' ||
              saveRuleForm.qtpye == '6'
            "
          >
            <editor
              :content_height="content_height"
              :set_editor_content="set_editor_optionB"
              @edit_content_change="edit_optionB_change"
            ></editor>
          </el-form-item>
          <el-form-item
            label="选项C"
            prop="optionC"
            v-show="
              saveRuleForm.qtpye == '1' ||
              saveRuleForm.qtpye == '2' ||
              saveRuleForm.qtpye == '6'
            "
          >
            <editor
              :content_height="content_height"
              :set_editor_content="set_editor_optionC"
              @edit_content_change="edit_optionC_change"
            ></editor>
          </el-form-item>
          <el-form-item
            label="选项D"
            prop="optionD"
            v-show="
              saveRuleForm.qtpye == '1' ||
              saveRuleForm.qtpye == '2' ||
              saveRuleForm.qtpye == '6'
            "
          >
            <editor
              :content_height="content_height"
              :set_editor_content="set_editor_optionD"
              @edit_content_change="edit_optionD_change"
            ></editor>
          </el-form-item>
          <el-form-item
            label="选项E"
            prop="optionE"
            v-show="
              saveRuleForm.qtpye == '1' ||
              saveRuleForm.qtpye == '2' ||
              saveRuleForm.qtpye == '6'
            "
          >
            <editor
              :content_height="content_height"
              :set_editor_content="set_editor_optionE"
              @edit_content_change="edit_optionE_change"
            ></editor>
          </el-form-item>
          <el-form-item
            label="正确答案"
            prop="answer1"
            v-show="saveRuleForm.qtpye == '1'"
          >
            <el-radio-group v-model="saveRuleForm.answer1">
              <el-radio label="A">A</el-radio>
              <el-radio label="B">B</el-radio>
              <el-radio label="C">C</el-radio>
              <el-radio label="D">D</el-radio>
              <el-radio label="E">E</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="正确答案"
            prop="answer1"
            v-show="saveRuleForm.qtpye == '2'"
          >
            <el-checkbox-group
              v-model="checkboxesArray"
              @change="checkAnswer1()"
            >
              <el-checkbox
                v-for="item in checkboxes"
                :label="item.name"
                :checked="item.checked"
                :key="item.id"
                >{{ item.name }}</el-checkbox
              >
            </el-checkbox-group>
          </el-form-item>
          <el-form-item
            label="正确答案"
            prop="answer1"
            v-show="saveRuleForm.qtpye == '3'"
          >
            <el-radio-group v-model="saveRuleForm.answer1">
              <el-radio label="1">对</el-radio>
              <el-radio label="0">错</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="正确答案"
            prop="answer1"
            v-show="
              saveRuleForm.qtpye == '4' ||
              saveRuleForm.qtpye == '5' ||
              saveRuleForm.qtpye == '6'
            "
          >
            <editor
              :content_height="content_height"
              :set_editor_content="set_editor_answer1"
              @edit_content_change="edit_answer1_change"
            ></editor>
          </el-form-item>
          <el-form-item label="答案说明" prop="answer2">
            <editor
              :content_height="content_height"
              :set_editor_content="set_editor_answer2"
              @edit_content_change="edit_answer2_change"
            ></editor>
          </el-form-item>
          <el-form-item label="试题解析" prop="parse">
            <editor
              :content_height="content_height"
              :set_editor_content="set_editor_parse"
              @edit_content_change="edit_parse_change"
            ></editor>
          </el-form-item>
          <el-form-item label="年级" prop="gradeid">
            <el-select v-model="saveRuleForm.gradeid" placeholder="请选择">
              <el-option
                v-for="option in gradeidList"
                :key="option.macroId"
                :label="option.name"
                :value="option.macroId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="学科" prop="subjectid">
            <el-select v-model="saveRuleForm.subjectid" placeholder="请选择">
              <el-option
                v-for="option in subjectidList"
                :key="option.macroId"
                :label="option.name"
                :value="option.macroId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="难度" prop="diff">
            <el-select v-model="saveRuleForm.diff" placeholder="请选择">
              <el-option
                v-for="item in diffList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="知识点" prop="knowledges">
            <el-input
              v-model="saveRuleForm.knowledges"
              :disabled="true"
              @click.native="setKnowledges"
            ></el-input>
          </el-form-item>
          <el-form-item label="试题区域" prop="area">
            <el-input
              v-model="saveRuleForm.area"
              placeholder="试题区域"
            ></el-input>
          </el-form-item>
          <el-form-item label="试题年份" prop="year">
            <el-date-picker
              v-model="saveRuleForm.year"
              type="year"
              format="yyyy"
              value-format="yyyy"
              :picker-options="pickerOptions"
              placeholder="选择年份"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="试题类型" prop="papertpye">
            <el-select v-model="saveRuleForm.papertpye" placeholder="请选择">
              <el-option
                v-for="item in papertpyeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="试题来源" prop="source">
            <el-input
              v-model="saveRuleForm.source"
              placeholder="试题来源（试卷）"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="popupVisible = false" size="small">取 消</el-button>
        <el-button
          type="primary"
          @click="confirmSave('saveRuleForm')"
          size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="选择知识点"
      :visible.sync="choiceDialogVisible"
      width="350px"
      center
    >
      <div class="dialog-content">
        <el-tree
          node-key="id"
          :props="props"
          :load="knowledgeloadNode"
          lazy
          :highlight-current="knowledgeOptions.highlightCurrent"
          :expand-on-click-node="knowledgeOptions.expandOnClickNode"
          :default-expand-all="knowledgeOptions.defaultExpandAll"
          :default-expanded-keys="knowledgeOptions.defaultExpandedKeys"
          @node-click="knowledgeNodeClick"
          :key="treeKey"
          style="height: 400px"
        ></el-tree>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="choiceDialogVisible = false" size="small"
          >取 消</el-button
        >
        <el-button type="primary" @click="choiceSave()" size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getSelectList,
  sectionCode,
  subjectCode,
  teachStyle,
  teachYear,
  teachSpeed,
  constellation,
} from "@/api/public";
import { formatTree } from "@/utils/common";
import {
  //讲师接口
  questionsList,
  questionsRemove,
  knowledge,
  questionsSave,
  questionsInfo,
  questionsUpdate,
} from "@/api/teacher/teacherManage";
export default {
  inject: ["reload"],
  data() {
    return {
      selectsubjectId:"",
      total: 0,
      subjectParams: {
        title: "",
        qtpye: "", //题型
        gradeid: "", //年级
        subjectid: "", //学科
        pageNumber: 1,
        pageSize: 10,
      },
      gradeidList: [], //年级数据
      qtpyeList: [
        {
          value: "1",
          label: "单选题",
        },
        {
          value: "2",
          label: "多选题",
        },
        {
          value: "3",
          label: "判断题",
        },
        {
          value: "4",
          label: "论述题",
        },
        {
          value: "5",
          label: "填空题",
        },
        {
          value: "6",
          label: "测评题",
        },
      ],
      qtpyeLists: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "单选题",
          label: "单选题",
        },
        {
          value: "多选题",
          label: "多选题",
        },
        {
          value: "判断题",
          label: "判断题",
        },
        {
          value: "论述题",
          label: "论述题",
        },
        {
          value: "填空题",
          label: "填空题",
        },
        {
          value: "测评题",
          label: "测评题",
        },
      ],
      productList: [],
      subjectidList: [
        {
          macroId: "0",
          name: "全部",
        },
      ],
      popupData: {
        title: "新增",
        type: "add",
      },
      popupVisible: false,
      saveRuleForm: {
        answer1: "", //正确答案
        answer2: "", //答案说明
        area: "", //区域
        diff: "", //难度
        gradeid: "", //年级
        id: 0,
        knowledgeId: "", //知识点id
        knowledges: "", //知识点名称
        optionA: "",
        optionB: "",
        optionC: "",
        optionD: "",
        optionE: "",
        papertpye: "", //试题类型
        parse: "", //解析
        qtpye: "0", //试题题型
        qtype: "", //
        source: "", //来源
        subjectid: "", //学科
        title: "", //题干
        year: "", //年份
      },
      gradeidList: [
        {
          macroId: "0",
          name: "全部",
        },
      ],
      diffList: [
        {
          value: "1",
          label: 1,
        },
        {
          value: "2",
          label: 2,
        },
        {
          value: "3",
          label: 3,
        },
        {
          value: "4",
          label: 4,
        },
        {
          value: "5",
          label: 5,
        },
      ],
      papertpyeList: [
        {
          value: "1",
          label: "月考",
        },
        {
          value: "2",
          label: "模拟考",
        },
        {
          value: "3",
          label: "中考",
        },
        {
          value: "4",
          label: "高考",
        },
        {
          value: "5",
          label: "学业考试",
        },
        {
          value: "6",
          label: "其他",
        },
      ],
      saveRules: {
        gradeid: [{ required: true, message: "年级不能为空", trigger: "blur" }],
        subjectid: [
          { required: true, message: "学科不能为空", trigger: "blur" },
        ],
        knowledges: [
          { required: true, message: "知识点不能为空", trigger: "blur" },
        ],
        year: [
          { required: true, message: "试题年份不能为空", trigger: "blur" },
        ],
        papertpye: [
          { required: true, message: "试题类型不能为空", trigger: "blur" },
        ],
        qtpye: [
          { required: true, message: "试题题型不能为空", trigger: "blur" },
        ],
      },
      qtypeStr: "",
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6;
        },
      },
      content_height: 150,
      set_editor_title: "",
      set_editor_optionA: "",
      set_editor_optionB: "",
      set_editor_optionC: "",
      set_editor_optionD: "",
      set_editor_optionE: "",
      set_editor_answer1: "",
      set_editor_answer2: "",
      set_editor_parse: "",
      checkboxes: [
        { id: "A", name: "A", checked: false },
        { id: "B", name: "B", checked: false },
        { id: "C", name: "C", checked: false },
        { id: "D", name: "D", checked: false },
        { id: "E", name: "E", checked: false },
      ],
      checkboxesArray: [],
      choiceDialogVisible: false,
      props: {
        label: "name",
      },
      parentId: 0,
      treeData: [],
      childList: [],
      treeKey: new Date().getTime(),
      knowledgeOptions: {
        defaultExpandAll: false,
        expandOnClickNode: true,
        highlightCurrent: true,
        defaultExpandedKeys: [0],
        selectNode: {},
      },
      knowledgeparentId: 0,
      rowlistId: [],
      answerId: [],
    };
  },
  created() {
    this.getQuestionsList();
  },
  methods: {
    getQuestionsList() {
      questionsList(this.subjectParams).then((res) => {
        this.productList = res.rows;
        this.total = res.total;
      });
    },
    //获取年纪数据
    getGradeidList() {
      getSelectList({
        parentCode: sectionCode,
      }).then((res) => {
        if (res.code == 0) {
          this.gradeidList = res.rows;
        }
      });
    },
    //获取学科数据
    getSubjectList() {
      return getSelectList({
        parentCode: subjectCode,
      }).then((res) => {
        if (res.code == 0) {
          this.subjectidList = res.rows;
          this.subjectidList.unshift({
            macroId: "0",
            name: "全部",
          });
        }
      });
    },
    //搜索
    searchFn() {
      this.getQuestionsList();
    },
    //刷新
    refreshFn() {
      this.reload();
    },
    //添加
    addFn() {
      this.popupData = {
        title: "新增",
        type: "add",
      };
      this.popupVisible = true;
      this.checkboxesArray = [];
      this.set_editor_title = "";
      this.set_editor_answer1 = "";
      this.set_editor_answer2 = "";
      this.set_editor_parse = "";
      this.set_editor_optionA = "";
      this.set_editor_optionB = "";
      this.set_editor_optionC = "";
      this.set_editor_optionD = "";
      this.set_editor_optionE = "";
      this.saveRuleForm = {
        answer1: "", //正确答案
        answer2: "", //答案说明
        area: "", //区域
        diff: "", //难度
        gradeid: "", //年级
        id: 0,
        knowledgeId: "", //知识点id
        knowledges: "", //知识点名称
        optionA: "",
        optionB: "",
        optionC: "",
        optionD: "",
        optionE: "",
        papertpye: "", //试题类型
        parse: "", //解析
        qtpye: "1", //试题题型
        qtype: "", //
        source: "", //来源
        subjectid: "", //学科
        title: "", //题干
        year: "", //年份
      };
    },
    getAnswer1(row) {
      if (row == 1) {
        return "对";
      } else if (row == 0) {
        return "错";
      }
    },
    //编辑
    editFn() {
      this.popupData = {
        title: "编辑",
        type: "edit",
      };
      if (this.answerId.length != 1) {
        this.msgWarn("您没有选中任何数据项！");
        return;
      }
      this.popupVisible = true;
      this.getQuestionsInfo();
    },
    //删除
    deleteFn() {
      if (this.answerId.length == 0) {
        this.msgWarn("请至少选择一条数据！");
        return;
      }
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          questionsRemove(this.answerId).then((res) => {
            if (res.code == 0) {
              this.msgSuccess("操作成功！");
              this.getQuestionsList();
            }
          });
        })
        .catch(() => {});
    },
    //获取详情
    getQuestionsInfo() {
      questionsInfo(this.answerId[0]).then((res) => {
        if (res.code == 0) {
          this.set_editor_title = res.rows.title;
          this.set_editor_optionA = res.rows.optionA;
          this.set_editor_optionB = res.rows.optionB;
          this.set_editor_optionC = res.rows.optionC;
          this.set_editor_optionD = res.rows.optionD;
          this.set_editor_optionE = res.rows.optionE;
          this.set_editor_answer2 = res.rows.answer2;
          this.set_editor_answer1 = res.rows.answer1;
          this.set_editor_parse = res.rows.parse;
          if (res.rows.qtpye == 2) {
            if (res.rows.answer1) {
              this.checkboxesArray = res.rows.answer1.split("|");
            }
          }
          this.saveRuleForm = {
            answer1: res.rows.answer1, //正确答案
            // answer2:res.rows.answer2,//答案说明
            area: res.rows.area, //区域
            diff: res.rows.diff, //难度
            gradeid: Number(res.rows.gradeid), //年级
            id: res.rows.id,
            knowledgeId: res.rows.knowledgeId, //知识点id
            knowledges: res.rows.knowledges, //知识点名称
            // optionA:"",
            // optionB:"",
            // optionC:"",
            // optionD:"",
            // optionE:"",
            papertpye: res.rows.papertpye, //试题类型
            // parse:"",//解析
            qtpye: res.rows.qtpye, //试题题型
            qtype: "", //
            source: res.rows.source, //来源
            subjectid: Number(res.rows.subjectid), //学科
            // title:"",//题干
            year: String(res.rows.year), //年份
          };
        }
      });
    },
    handleSelectionChange(val) {
      this.rowlistId = val;
      this.answerId = [];
      if (val.length > 0) {
        for (let i = 0, len = val.length; i < len; i++) {
          this.answerId.push(val[i].id);
        }
      }
    },
    studentItemClick(row) {
      this.$refs.productListTable.toggleRowSelection(row);
    },
    handleSizeChange(pageSize) {
      this.subjectParams.pageSize = pageSize;
      this.getQuestionsList();
    },
    handleCurrentChange(currentPage) {
      this.subjectParams.pageNumber = currentPage;
      this.getQuestionsList();
    },
    edit_title_change(html) {
      this.set_editor_title = html;
      this.saveRuleForm.title = html;
    },
    edit_optionA_change(html) {
      this.set_editor_optionA = html;
      this.saveRuleForm.optionA = html;
    },
    edit_optionB_change(html) {
      this.set_editor_optionB = html;
      this.saveRuleForm.optionB = html;
    },
    edit_optionC_change(html) {
      this.set_editor_optionC = html;
      this.saveRuleForm.optionC = html;
    },
    edit_optionD_change(html) {
      this.set_editor_optionD = html;
      this.saveRuleForm.optionD = html;
    },
    edit_optionE_change(html) {
      this.set_editor_optionE = html;
      this.saveRuleForm.optionE = html;
    },
    edit_answer1_change(html) {
      this.set_editor_answer1 = html;
      this.saveRuleForm.answer1 = html;
    },
    edit_answer2_change(html) {
      this.set_editor_answer2 = html;
      this.saveRuleForm.answer2 = html;
    },
    edit_parse_change(html) {
      this.set_editor_parse = html;
      this.saveRuleForm.parse = html;
    },
    checkAnswer1() {
      console.log(this.checkboxesArray);
    },
    questionTypeChange(val) {
      var qtype = Number(this.saveRuleForm.qtpye);
      switch (qtype) {
        case 1:
          this.saveRuleForm.qtype = "单选题";
          this.set_editor_title = "";
          this.saveRuleForm.title = "";
          this.set_editor_optionA = "";
          this.saveRuleForm.optionA = "";
          this.set_editor_optionB = "";
          this.set_editor_optionC = "";
          this.set_editor_optionD = "";
          this.set_editor_optionE = "";
          this.saveRuleForm.optionB = "";
          this.saveRuleForm.optionC = "";
          this.saveRuleForm.optionD = "";
          this.saveRuleForm.optionE = "";
          break;
        case 2:
          this.saveRuleForm.qtype = "多选题";
          this.set_editor_title = "";
          this.saveRuleForm.title = "";
          this.set_editor_optionA = "";
          this.saveRuleForm.optionA = "";
          this.set_editor_optionB = "";
          this.set_editor_optionC = "";
          this.set_editor_optionD = "";
          this.set_editor_optionE = "";
          this.saveRuleForm.optionB = "";
          this.saveRuleForm.optionC = "";
          this.saveRuleForm.optionD = "";
          this.saveRuleForm.optionE = "";
          break;
        case 3:
          this.saveRuleForm.qtype = "判断题";
          this.set_editor_title = "";
          this.saveRuleForm.title = "";
          this.set_editor_optionA = "";
          this.saveRuleForm.optionA = "";
          this.set_editor_optionB = "";
          this.set_editor_optionC = "";
          this.set_editor_optionD = "";
          this.set_editor_optionE = "";
          this.saveRuleForm.optionB = "";
          this.saveRuleForm.optionC = "";
          this.saveRuleForm.optionD = "";
          this.saveRuleForm.optionE = "";
          break;
        case 4:
          this.saveRuleForm.qtype = "论述题";
          this.set_editor_title = "";
          this.saveRuleForm.title = "";
          this.set_editor_optionA = "";
          this.saveRuleForm.optionA = "";
          this.set_editor_optionB = "";
          this.set_editor_optionC = "";
          this.set_editor_optionD = "";
          this.set_editor_optionE = "";
          this.saveRuleForm.optionB = "";
          this.saveRuleForm.optionC = "";
          this.saveRuleForm.optionD = "";
          this.saveRuleForm.optionE = "";
          break;
        case 5:
          this.set_editor_answer1 = "将答案用 $###$ 分隔";
          this.saveRuleForm.qtype = "填空题";
          this.set_editor_title = "";
          this.saveRuleForm.title = "";
          this.set_editor_optionA = "";
          this.saveRuleForm.optionA = "";
          this.set_editor_optionB = "";
          this.set_editor_optionC = "";
          this.set_editor_optionD = "";
          this.set_editor_optionE = "";
          this.saveRuleForm.optionB = "";
          this.saveRuleForm.optionC = "";
          this.saveRuleForm.optionD = "";
          this.saveRuleForm.optionE = "";
          break;
        case 6:
          this.set_editor_answer1 = "选项-分值,选项-分值, 例如：A-0,B-1,C-2,";
          this.saveRuleForm.qtype = "测评题";
          this.set_editor_title = "";
          this.saveRuleForm.title = "";
          this.set_editor_optionA = "";
          this.saveRuleForm.optionA = "";
          this.set_editor_optionB = "";
          this.set_editor_optionC = "";
          this.set_editor_optionD = "";
          this.set_editor_optionE = "";
          this.saveRuleForm.optionB = "";
          this.saveRuleForm.optionC = "";
          this.saveRuleForm.optionD = "";
          this.saveRuleForm.optionE = "";
          break;
        default:
      }
    },
    knowledgeloadNode(node, resolve) {
      if (node.level === 0) {
        return knowledge({
          parentId: this.knowledgeparentId,
        }).then((res) => {
          if (res.code == 0) {
            this.knowledgetreeData = formatTree(res.rows, {
              id: "id",
              parentId: "parentId",
              originId: -1,
            });
            if (this.knowledgetreeData[0].children.length > 0) {
              this.knowledgechildList = JSON.parse(
                JSON.stringify(this.knowledgetreeData[0].children)
              );
            }
            resolve(this.knowledgetreeData);
          }
        });
      }
      if (node.level === 1) {
        return resolve(this.knowledgechildList);
      }
      if (node.level > 1) {
        this.parentId = node.data.id;
        return knowledge({
          parentId: this.parentId,
        }).then((res) => {
          if (res.code == 0) {
            resolve(res.rows);
          }
        });
      }
    },
    knowledgeNodeClick(selectNode) {

      if(selectNode.dataType==2){//能获取到知识点的学科Id
      console.log(selectNode)
      this.selectsubjectId = selectNode.dictId
      }
      if (selectNode.dataType >= 3) {
        this.knowledgeOptions.selectNode = selectNode;
      } else {
        this.msgWarn("请选择一个知识点目录!");
        return;
      }
    },
    loadNode(node, resolve) {
      if (node.level === 0) {
        return knowledge({
          parentId: this.parentId,
        }).then((res) => {
          if (res.code == 0) {
            this.treeData = formatTree(res.rows, {
              id: "id",
              parentId: "parentId",
              originId: -1,
            });
            if (this.treeData[0].children.length > 0) {
              this.childList = JSON.parse(
                JSON.stringify(this.treeData[0].children)
              );
            }
            resolve(this.treeData);
          }
        });
      }
      if (node.level === 1) {
        return resolve(this.childList);
      }
      if (node.level > 1) {
        this.parentId = node.data.id;
        return knowledge({
          parentId: this.parentId,
        }).then((res) => {
          if (res.code == 0) {
            resolve(res.rows);
          }
        });
      }
    },
    setKnowledges() {
      this.choiceDialogVisible = true;
    },
    confirmSave(formName) {
      if(this.selectsubjectId!=this.saveRuleForm.subjectid){
          this.msgWarn("当前知识点与试题学科不符！");
          return false;
      }
      if (this.saveRuleForm.qtpye == "1") {
        this.saveRuleForm.qtype = "单选题";
      } else if (this.saveRuleForm.qtpye == "2") {
        this.saveRuleForm.qtype = "多选题";
        var answerVal = [];
        for (var i = 0; i < this.checkboxesArray.length; i++) {
          answerVal.push(this.checkboxesArray[i]);
        }
        answerVal.sort();
        this.saveRuleForm.answer1 = answerVal.join("|");
      } else if (this.saveRuleForm.qtpye == "3") {
        this.saveRuleForm.qtype = "判断题";
      } else if (this.saveRuleForm.qtpye == "4") {
        this.saveRuleForm.qtype = "论述题";
      } else if (this.saveRuleForm.qtpye == "5") {
        this.saveRuleForm.qtype = "填空题";
      } else if (this.saveRuleForm.qtpye == "6") {
        this.saveRuleForm.qtype = "测评题";
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.popupData.type == "add") {
            questionsSave(this.saveRuleForm).then((res) => {
              if (res.code == 0) {
                this.popupVisible = false;
                this.msgSuccess("操作成功！");
                this.getQuestionsList();
              }
            });
          } else if (this.popupData.type == "edit") {
            questionsUpdate(this.saveRuleForm).then((res) => {
              if (res.code == 0) {
                this.popupVisible = false;
                this.msgSuccess("操作成功！");
                this.getQuestionsList();
              }
            });
          }
        } else {
          this.msgWarn("必填字段不能为空！");
          return false;
        }
      });
    },
    isQtpyeFn(row) {
      if (row == 1) {
        return "对";
      } else if (row == 0) {
        return "错";
      }
    },
    choiceSave() {
      if (this.knowledgeOptions.selectNode.dataType != undefined) {
        this.saveRuleForm.knowledgeId = this.knowledgeOptions.selectNode.id;
        this.saveRuleForm.knowledges = this.knowledgeOptions.selectNode.name;
        this.choiceDialogVisible = false;
      }
    },
    dealSectionId(row) {
      return row.gradeName + "/" + row.subjectName;
    },
    testTitle(row) {
      var regex = /(<([^>]+)>)/ig
      var result = "<div ><p>" + row.title + "</p><br/>";
      if (row.qtpye == "单选题" || row.qtpye == "多选题") {
        if (row.optionA) {
          result += "<p>A." + row.optionA.replace(regex,'') + "</p>";
        }
        if (row.optionB) {
          result += "<p>B." + row.optionB.replace(regex,'') + "</p>";
        }
        if (row.optionC) {
          result += "<p>C." + row.optionC.replace(regex,'') + "</p>";
        }
        if (row.optionD) {
          result += "<p>D." + row.optionD.replace(regex,'') + "</p>";
        }
        if (row.optionE) {
          result += "<p>E." + row.optionE.replace(regex,'') + "</p>";
        }
      }
      if (row.answer1) {
        if (row.qtpye == "判断题") {
          var tempAnswer1 = row.answer1;
          if (
            tempAnswer1 &&
            tempAnswer1.startsWith("<p>") &&
            tempAnswer1.endsWith("</p>")
          ) {
            row.answer1 = tempAnswer1.substring(3, tempAnswer1.length - 4);
          }
          result +=
            "<p style='color: red;font-weight: bolder'>【答案】：" +
            this.getAnswer1(row.answer1) +
            "</p><br/>";
        } else {
          var tempAnswer1 = row.answer1;
          if (
            tempAnswer1 &&
            tempAnswer1.startsWith("<p>") &&
            tempAnswer1.endsWith("</p>")
          ) {
            row.answer1 = tempAnswer1.substring(3, tempAnswer1.length - 4);
          }
          result +=
            "<p style='color: red;font-weight: bolder'>【答案】：" +
            row.answer1.replace(eval("/\\$\\#\\#\\#\\$/g"), "&nbsp;&nbsp;") +
            "</p><br/>";
        }
      }
      if (row.answer2) {
        result += "<p>" + row.answer2 + "</p><br/>";
      }
      if (row.parse) {
        result += "<p>" + row.parse + "</p>";
      }
      result += "</div>";
      return result;
    },
  },
  mounted() {
    this.getGradeidList();
    this.getSubjectList();
  },
  watch: {
    popupVisible: function (val,oldVla) {
      if (this.$refs['saveRuleForm'] !== undefined) {
        this.$refs["saveRuleForm"].resetFields();
      }
    }
  },
};
</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */
.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .btn-area {
    height: 50px;
    display: flex;
    align-items: center;
    .right {
      flex: 1;
      display: flex;
      justify-content: flex-end;
    }
  }
  .select-condition {
    border-bottom: 1px solid #f1f1f1;
    .left {
      flex: 1;
      display: flex;
      .select-condition-item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        .item-lable {
          word-break: keep-all;
        }
      }
    }
  }
  .table-area {
    flex: 1;
    overflow: hidden;
  }
  .page-area {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .dialog-content {
    min-height: 260px;
    max-height: 60vh;
    overflow-y: auto;
    padding-left: 10px;
    padding-right: 10px;
    .item_select {
      display: flex;
      align-items: center;
      span {
        width: 120px;
      }
    }
    .record {
      margin-top: 230px;
    }
  }
  .score-input {
    border: none;
    outline: none;
    border-bottom: 1px solid #000000;
    text-align: center;
    width: 80px;
  }
  .subject-item {
    height: 40px;
    line-height: 40px;
  }
  .self-radio-group {
    margin-left: 20px;
    .self-radio-group-item {
      margin-bottom: 8px;
    }
  }
  .center {
    text-align: center;
    min-height: 30px;
  }
  .line {
    height: 0;
    border-bottom: 1px solid #f1f1f1;
    margin-left: 30px;
    margin: 10px 0 10px 30px;
  }

}

</style>