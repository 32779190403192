<template>
  <div class="main-content sys-role-manage">
    <div class="btn-area select-condition">
      <div class="left">
        <div class="select-condition-item">
          <span class="item-lable">请选择年级：</span>
          <el-select
            v-model="paperParams.gradeId"
            placeholder="请选择"
            size="small"
              @change="()=>(paperParams.pageNumber=1,getpaperList())"
          >
            <el-option
              v-for="item in gradeidListTitle"
              :key="item.macroId"
              :label="item.name"
              :value="item.macroId"
            
            ></el-option>
          </el-select>
        </div>
         <div class="select-condition-item">
          <span class="item-lable">请选择学科：</span>
          <el-select
            v-model="paperParams.subjectId"
            placeholder="请选择"
            size="small"
           @change="()=>(paperParams.pageNumber=1,getpaperList())"
          >
            <el-option
              v-for="item in subjectidList"
              :key="item.macroId"
              :label="item.name"
              :value="item.macroId"
            ></el-option>
          </el-select>
        </div>
        <div class="select-condition-item">
          <el-button
            type="primary"
            size="small"
            icon="el-icon-search"
            @click="()=>(paperParams.pageNumber=1,getpaperList())"
            >搜索</el-button
          >
        </div>
      </div>
    </div>
    <div class="btn-area">
      <div class="right">
        <el-button-group>
          <el-button type="default" size="small" @click="refreshFn">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-shuaxin" />
            </svg>
            <span>刷新</span>
          </el-button>
          <el-button type="default" size="small" @click="addFn">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-xinzeng" />
            </svg>
            <span>添加</span>
          </el-button>
          <el-button type="default" size="small" @click="editFn">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-edit" />
            </svg>
            <span>编辑</span>
          </el-button>
          <el-button type="default" size="small" @click="deleteFn">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-trash" />
            </svg>
            <span>删除</span>
          </el-button>
        </el-button-group>
      </div>
    </div>
    <div class="table-area">
      <el-table
        size="small"
        :data="paperList"
        style="width: 100%; margin-bottom: 20px"
        row-key="id"
        border
        height="100%"
        tooltip-effect="dark"
        ref="paperListTable"
        @selection-change="handleSelectionChange"
        @row-click="studentItemClick"
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="title"
          label="试卷名称"
          align="center"
        ></el-table-column>
        
        <el-table-column
          prop="createTime"
          label="年级"
          align="center"
          :formatter="dealSectionId"
        ></el-table-column>
        <el-table-column
          prop="subjectName"
          label="科目"
          align="center"
        ></el-table-column>
         <el-table-column
          prop="limitTime"
          label="考试时长"
          align="center"
        ></el-table-column>
         <el-table-column
          prop="totalScore"
          label="总分"
          align="center"
        ></el-table-column>
        <el-table-column label="操作" align="center" width="300px">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click.stop="testPaper(scope.row)"
              >组卷</el-button
            >
            <el-button
              type="text"
              size="small"
              @click.stop="testDetails(scope.row)"
            >
            预览
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page-area">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="paperParams.pageNumber"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="paperParams.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background
      ></el-pagination>
    </div>
    <el-dialog
      :title="popupData.title"
      :visible.sync="popupVisible"
      width="700px"
      center
      :close-on-click-modal="false"
      custom-class="product-dialog"
    >
      <div class="dialog-content">
        <el-form
          :model="saveRuleForm"
          status-icon
          :rules="saveRules"
          ref="saveRuleForm"
          label-width="100px"
          size="small"
        >
            <el-row :gutter="20">
                <el-form-item label="试卷名称" prop="title" >
                    <el-input v-model="saveRuleForm.title" placeholder="请输入试卷名称"></el-input>
                </el-form-item>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="年级" prop="gradeId" >
                        <el-select v-model="saveRuleForm.gradeId" placeholder="请选择">
                        <el-option
                            v-for="option in gradeidList"
                            :key="option.macroId"
                            :label="option.name"
                            :value="option.macroId"
                        ></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="学科" prop="subjectId" >
                        <el-select v-model="saveRuleForm.subjectId" placeholder="请选择">
                            <el-option
                            v-for="option in subjectidList2"
                            :key="option.macroId"
                            :label="option.name"
                            :value="option.macroId"
                            ></el-option>
                        </el-select>
                    </el-form-item> 
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="考试时长" prop="limitTime">
                        <el-input v-model="saveRuleForm.limitTime" placeholder="请输入考试时长"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="总分" prop="totalScore">
                        <el-input v-model="saveRuleForm.totalScore" placeholder="请输入总分"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="popupVisible = false" size="small"
          >取 消</el-button
        >
        <el-button
          type="primary"
          @click="confirmSave('saveRuleForm')"
          size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>
     <el-dialog
      title="预览"
      :visible.sync="previewPopup"
      width="1200px"
      center
      custom-class="product-dialog"
    >
      <div class="dialog-content">
         <h1 style="text-align:center;">{{examPaper.title}}</h1>
         <h3 style="text-align:center;margin-top:15px;">{{examPaper.desc}}</h3>
         <div v-for="(item,index) in paperDetailList" :key="index"  style="margin-top:30px;">
             <p style="cursor: pointer;background:#ccc;height:40px;line-height:40px;padding-left:10px;font-size:16px;">{{item.qno}}、{{item.title}}:本题共{{item.examPaperSmalls.length}}小题，每小题{{item.score}}分，共{{item.examPaperSmalls.length*item.score}}分。{{item.desc}}</p>
             <div v-for="(items,index) in item.examPaperSmalls" :key="index" style="margin-top:25px;">
                 <p>{{items.qid}}.<span  class="content" v-html="items.questions!=null?items.questions.title:''"></span></p>
                 <div style="margin-top:10px;">
                    <template v-if="items.questions!=null&&(item.paperBigType==1||item.paperBigType==2||item.paperBigType==6)" >
                        <span class="contTitle">A.</span><span  class="content" v-html="items.questions!=null?items.questions.optionA:''"></span>&nbsp;&nbsp;
                    </template>
                    <template v-if="items.questions!=null&&(item.paperBigType==1||item.paperBigType==2||item.paperBigType==6)" >
                        <span class="contTitle">B.</span><span class="content" v-html="items.questions!=null?items.questions.optionB:''"></span>&nbsp;&nbsp;
                    </template>
                    <template v-if="items.questions!=null&&(item.paperBigType==1||item.paperBigType==2||item.paperBigType==6)">
                        <span class="contTitle">C.</span><span class="content" v-html="items.questions!=null?items.questions.optionC:''"></span>&nbsp;&nbsp;
                    </template>
                    <template v-if="items.questions!=null&&(item.paperBigType==1||item.paperBigType==2||item.paperBigType==6)">
                        <span class="contTitle">D.</span><span class="content" v-html="items.questions!=null?items.questions.optionD:''"></span>&nbsp;&nbsp;
                    </template>
                    <template v-if="items.questions!=null&&(item.paperBigType==1||item.paperBigType==2||item.paperBigType==6)">
                        <span class="contTitle">E.</span><span class="content" v-html="items.questions!=null?items.questions.optionE:''"></span>&nbsp;&nbsp;
                    </template>
                    <template v-if="items.questions!=null&&(item.paperBigType==3)">
                        <span class="contTitle"></span><span class="content">对</span>&nbsp;&nbsp;<span class="content">错</span>
                    </template>
                 </div>
             </div> 
         </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="previewPopup = false" size="small" type="primary"
          >取 消</el-button
        >
      </span>
     </el-dialog>

      <el-dialog
        title="组卷"
        :visible.sync="testPaperPopup"
        width="1200px"
        center
        custom-class="product-dialog"
    >
      <div class="dialog-content">
          <el-form
          :model="testPaperForm"
          status-icon
          :rules="testPaperRules"
          ref="testPaperForm"
          label-width="100px"
          size="small"
        >
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="试卷类型" prop="paperBigType" >
                        <el-select v-model="testPaperForm.paperBigType" placeholder="请选择" @change="gradeIdFn()">
                        <el-option
                            v-for="option in paperType"
                            :key="option.value"
                            :label="option.label"
                            :value="option.value"
                        ></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="显示为" prop="title">
                        <el-input v-model="testPaperForm.title" placeholder="显示为"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="题目数量" prop="questionNum">
                        <el-input v-model="testPaperForm.questionNum" placeholder="请输入题目数量"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="每题分值" prop="score">
                        <el-input v-model="testPaperForm.score" placeholder="请输入分值"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="描述" prop="desc">
                        <el-input type="textarea" v-model="testPaperForm.desc"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
         <el-button type="success" size="small"  @click="addSubject" style="float:right">添加大题</el-button>
        <div v-for="(item,index) in examPaperBigList" :key="index" style="margin-top:55px;">
            <div style="display:flex;justify-content: space-between;background:#ccc;height:40px;line-height:40px;padding:0 10px;">
                <p>第{{index+1}}部分（<span>{{item.title}}</span>）:<span> {{item.desc}}</span></p>
                <p>
                    <i class="el-icon-upload2" @click="upItem(index)" style="font-size:25px;margin:0 5px;"></i>
                    <i class="el-icon-download" @click="downItem(index)" style="font-size:25px;margin:0 5px;"></i>
                    <i class="el-icon-delete" @click="delItem(index)" style="font-size:25px;margin:0 5px;"></i>
                </p>
            </div>
            <div style="display:flex;justify-content: space-between;height:50px;line-height:50px;">
                <p>
                    <el-button type="success" size="small" @click="addSmallSubject(index)">添加试题</el-button>
                    <span style="margin-left:15px;">已添加试题数目：{{item.examPaperSmalls.length}}
                （总分：{{item.examPaperSmalls.length * item.score}}）</span>
                </p>
                <p>
                    题目数量:<el-input v-model="item.questionNum" placeholder="请输入内容" style="width:60px;margin:0 10px;"></el-input>
                    每题分值:<el-input v-model="item.score" placeholder="请输入内容" style="width:60px;margin:0 10px;"></el-input>
                </p>
            </div>
            <div>
                <el-table
                    :data="examPaperBigList[index].examPaperSmalls"
                    style="width: 100%">
                    <el-table-column
                        type="index"
                        label="序号"
                        width="80">
                    </el-table-column>
                    <el-table-column
                        prop="title"
                        label="题目"
                       >
                      <template slot-scope="scope">
                        <div v-html="scope.row.questions.title"></div>
                      </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center">
                      <template slot-scope="scope">
                        <el-button
                          type="text"
                          size="small"
                          @click="moveUp(index,scope.$index)"
                          >上移</el-button
                        >
                        <el-button
                          type="text"
                          size="small"
                          @click="moveDown(index,scope.$index)"
                          >下移</el-button
                        >
                        <el-button
                          type="text"
                          size="small"
                          @click="moveRemove(index,scope.$index)"
                          >删除</el-button
                        >
                      </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
      </div>
       <span slot="footer" class="dialog-footer">
        <el-button @click="testPaperPopup = false" size="small" 
          >取 消</el-button
        >
        <el-button @click="savePaperQuestionsFn()" size="small" type="primary"
          >保 存</el-button
        >
      </span>
      </el-dialog>
      <el-dialog
        title="添加试题"
        :visible.sync="subjectPopup"
        width="1200px"
        center
        custom-class="product-dialog"
    >
      <div class="dialog-content">
        <div class="btn-area select-condition">
      <div class="left">
        <div class="select-condition-item">
          <span class="item-lable">请选择年级：</span>
          <el-select
            v-model="subjectParams.gradeid"
            placeholder="请选择"
            size="small"
              @change="searchPaperFn"
          >
            <el-option
              v-for="item in gradeidListTitle"
              :key="item.macroId"
              :label="item.name"
              :value="item.macroId"
            
            ></el-option>
          </el-select>
        </div>
         <div class="select-condition-item">
          <span class="item-lable">请选择学科：</span>
          <el-select
            v-model="subjectParams.subjectid"
            placeholder="请选择"
            size="small"
            @change="searchPaperFn"
          >
            <el-option
              v-for="item in subjectidList"
              :key="item.macroId"
              :label="item.name"
              :value="item.macroId"
            ></el-option>
          </el-select>
        </div>
        <div class="select-condition-item">
          <el-button
            type="primary"
            size="small"
            icon="el-icon-search"
            @click="searchPaperFn()"
            >搜索</el-button
          >
        </div>
      </div>
    </div>
          <div class="table-area mT10">
            <el-table
              size="small"
              :data="subjectPaperList"
              style="width: 100%;margin-bottom: 20px;"
              border
              row-key="getRowKeys"
              height="400px"
              tooltip-effect="dark"
              ref="tagsListTable"
              @selection-change="handleTagsSelectionChange"
            >
              <el-table-column
                type="selection"
                width="55"
                align="center"
                :selectable="checkSelectable"
              ></el-table-column>
              <el-table-column prop="polymerizationKeywords" label="试题" align="center" width="350">
                <template slot-scope="scope">
                  <div v-html="testTitle(scope.row)"></div>
                </template>
              </el-table-column>
              <el-table-column prop="qtpye" label="试题题型" align="center" width="70"></el-table-column>
              <el-table-column prop="gradeName" label="年级" align="center" width="70"></el-table-column>
               <el-table-column prop="subjectName" label="学科" align="center" width="70"></el-table-column>
              <el-table-column prop="knowledges" label="知识点" align="center"></el-table-column>
              <el-table-column prop="diff" label="难度值(1~5)" align="center" width="70"></el-table-column>
              <el-table-column prop="source" label="试题来源(试卷)" align="center"></el-table-column>
            </el-table>
          </div>
          <div class="page-area">
            <el-pagination
              @size-change="handleTagsSizeChange"
              @current-change="handleTagsCurrentChange"
              :current-page="paperParams.pageNumber"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="paperParams.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="subjectTotal"
              background
            ></el-pagination>
          </div>
      </div>
       <span slot="footer" class="dialog-footer">
          <el-button @click="tagsDialogVisible = false" size="small">取 消</el-button>
          <el-button type="primary" @click="confirmTagsSelect()" size="small">确 定</el-button>
        </span>
      </el-dialog>
  </div>
</template>

<script>    
    import {
        getSelectList,
        sectionCode,
        subjectCode,
        teachStyle,
        teachYear,
        teachSpeed,
        constellation
    } from "@/api/public";
    import { formatTree } from "@/utils/common";
    import {//讲师接口
        paperList,
        paperRemove,
        paperSave,
        paperUpdate,
        paperInfo,
        paperfindPaperQuestions,
        questionsList,
        savePaperQuestions
    } from "@/api/teacher/teacherManage";
    export default{
        data(){
            return{
                nowStudentId:[],
                total: 0,
                subjectTotal:0,
                paperParams:{
                    pageNumber: 1,
                    pageSize: 10,
                    sortOrder:'asc',
                    subjectId:'',
                    gradeId:''
                },
                subjectParams:{
                    pageNumber: 1,
                    pageSize: 10,
                    sortOrder:'asc',
                    keyword:null,
                    knowledgeParentId:"0",
                    qtpye:"",
                    subjectid:"",
                    gradeid:"",
                },
                gradeidList:[],//年级数据
                gradeidList2:[],
                gradeidListTitle:[],
                paperList:[],
                subjectidList: [
                    // {
                    // macroId: "0",
                    // name: "全部",
                    // },
                ],
                subjectidList2:[],
                popupData:{
                  title: "新增",
                  type: "add"
                },
                popupVisible:false,
                saveRuleForm:{ 
                    title:"",
                    gradeId:"",//年级
                    subjectId:"",//学科
                    limitTime:"",//考试时长
                    totalScore:"",//总分
                },
                gradeidList: [
                  
                ],
                 saveRules: {
                    title:[
                      { required: true, message: "试卷名称不能为空", trigger: "blur" },
                    ],
                    gradeId:[
                      { required: true, message: "年级不能为空", trigger: "change" },
                    ],
                    subjectId:[
                      { required: true, message: "学科不能为空", trigger: "change" },
                    ]
                  },
                  paperId:[],
                  previewPopup:false,
                  examPaper:{},
                  paperDetailList:{},
                  //组卷
                  testPaperPopup:false,
                  testPaperForm:{
                      paperBigType:"",//试题类型
                      title:"",//显示为
                      questionNum:"1",//题目数量
                      score:"1",//每题分值
                      desc:"",//描述
                      examPaperSmalls:[]
                  },
                  testPaperRules:{
                       paperBigType: [
                            { required: true, message: "试卷类型不能为空", trigger: "blur" },
                       ],
                       title: [
                            { required: true, message: "显示数据不能为空", trigger: "blur" },
                       ],
                       questionNum: [
                            { required: true, message: "题目数量不能为空", trigger: "blur" },
                       ],
                       score: [
                            { required: true, message: "分值不能为空", trigger: "blur" },
                       ],
                       desc: [
                            { required: true, message: "描述不能为空", trigger: "blur" },
                       ]
                  },
                  paperType:[
                      {
                        value: "0",
                        label: "全部",
                    },
                    {
                        value: "1",
                        label: "单选题",
                    },
                    {
                        value: "2",
                        label: "多选题",
                    },
                    {
                        value: "3",
                        label: "判断题",
                    },
                    {
                        value: "4",
                        label: "论述题",
                    },
                    {
                        value: "5",
                        label: "填空题",
                    },
                    {
                        value: "6",
                        label: "测评题",
                    }
                  ],
                  aaa:[],
                  examPaperBigList:[],
                  subjectPopup:false,
                  subjectPaperList:[],
                  tableData:[],
                  tagsSelection:[],
                  selectId:"",//试题table的id
                  paperRowId:"",
                  selectTagsList:[],

            }
        },
        created(){
            this.getpaperList()
        },
        methods:{
            gradeIdFn(){
                var paperBigType=Number(this.testPaperForm.paperBigType);
                switch (paperBigType){
                    case 1:
                        this.testPaperForm.title="单项选择题";
                        this.testPaperForm.desc="每题有且只有1个正确答案";
                        break;
                    case 2:
                        this.testPaperForm.title="多项选择题";
                        this.testPaperForm.desc="每题至少2个正确选项";
                        break;
                    case 3:
                        this.testPaperForm.title="判断题";
                        this.testPaperForm.desc="根据题干内容，判断对错";
                        break;
                    case 4:
                        this.testPaperForm.title="主观题";
                        this.testPaperForm.desc="阅读题干，回答问题";
                        break;
                    case 5:
                        this.testPaperForm.title="填空题";
                        this.testPaperForm.desc="看题填空";
                        break;
                    case 6:
                        this.testPaperForm.title="测评题";
                        this.testPaperForm.desc="根据自己的实际情况完成测评";
                        break;
                }
            },
            //添加大题
            addSubject(){
                if(this.testPaperForm.paperBigType){
                    this.examPaperBigList.push(this.testPaperForm);
                    this.testPaperForm={
                        paperBigType:"",//试题类型
                        title:"",//显示为
                        questionNum:"1",//题目数量
                        score:"1",//每题分值
                        desc:"",//描述
                        examPaperSmalls:[]
                    }
                }else{
                    this.msgWarn("请先选择试题类型！");
                    return;
                }
            },
            getRowKeys(row){
               return row.id;
            },
            //添加试题
            addSmallSubject(index){
                this.aaa=this.examPaperBigList[index].examPaperSmalls;
                this.selectId=index;
                this.subjectPopup=true;
                var paperBigType=Number(this.examPaperBigList[index].paperBigType);
                switch(paperBigType){
                    case 1:
                        this.subjectParams.qtpye='单选题';
                        break;
                    case 2:
                        this.subjectParams.qtpye='多选题';
                        break;
                    case 3:
                        this.subjectParams.qtpye='判断题';
                        break;
                    case 4:
                        this.subjectParams.qtpye='论述题';
                        break;
                    case 5:
                        this.subjectParams.qtpye='填空题';
                        break;
                    case 6:
                        this.subjectParams.qtpye='测评题';
                        break;
                    default:
                }
                this.getQuestionsList();
            },
           
            getQuestionsList(){
              questionsList(this.subjectParams).then((res) => {
                  this.subjectPaperList=res.rows;
                  this.subjectTotal=res.total;
                  // if(res.total!=0){
                  //     for(var i=0;i<this.subjectPaperList.length;i++){
                  //       this.nowStudentId.push(this.subjectPaperList[i].id)
                  //     }
                  //   }
              });
            },
            upItem(index,num){
                if(index==0){
                    this.msgWarn("已经是最顶部!");
                    return;
                }
                this.swapItems(this.examPaperBigList,index,index-1);
            },
            swapItems(array,index1,index2){
              array[index1]=array.splice(index2,1,array[index1])[0];
            },
            downItem(index){
                if(index==this.examPaperBigList.length-1){
                    this.msgWarn("已经是最底部!");
                    return;
                }
                 this.swapItems(this.examPaperBigList,index,index+1);
            },
            delItem(index){
                this.examPaperBigList.splice(index,1);
            },
            //上移
            moveUp(bigIndex,smallIndex){
                if(smallIndex==0){
                    this.msgWarn("已经是最顶部!");
                    return;
                }
                this.swapItems(this.examPaperBigList[bigIndex].examPaperSmalls,smallIndex,smallIndex-1);
            },
            //下移
            moveDown(bigIndex,smallIndex){
                if(smallIndex==this.examPaperBigList[bigIndex].examPaperSmalls.length-1){
                    this.msgWarn("已经是最底部!");
                    return;
                }
                this.swapItems(this.examPaperBigList[bigIndex].examPaperSmalls,smallIndex,smallIndex+1);
            },
            moveRemove(bigIndex,smallIndex){
                this.examPaperBigList[bigIndex].examPaperSmalls.splice(smallIndex,1);
            },
            handleTagsSelectionChange(val){
               this.tagsSelection=val;
            },
            tagsItemClick(row){
                // let flag = false;
                // for (let i = 0, len = this.selectTagsList.length; i < len; i++) {
                //   if (this.selectTagsList[i].id == row.id) {
                //     flag = true;
                //     break;
                //   }
                // }
                // if (!flag) {
                //   this.$refs.tagsListTable.toggleRowSelection(row);
                // }
                this.$refs.tagsListTable.toggleRowSelection(row);
            },
            handleTagsSizeChange(pageSize){
                this.subjectParams.pageSize = pageSize;
                this.getQuestionsList();
            },
            handleTagsCurrentChange(currentPage){
                this.subjectParams.pageNumber = currentPage;
                this.getQuestionsList();
            },
            testTitle(row){
                var result = "<div class='quesborder'><p>"+row.title+"</p><br/>";
                    if (row.optionA) {
                        result += "A.&nbsp;"+row.optionA+"&nbsp;&nbsp;";
                    }
                    if (row.optionB) {
                        result += "B.&nbsp;"+row.optionB+"&nbsp;&nbsp;";
                    }
                    if (row.optionC) {
                        result += "C.&nbsp;"+row.optionC+"&nbsp;&nbsp;";
                    }
                    if (row.optionD) {
                        result += "D.&nbsp;"+row.optionD+"&nbsp;&nbsp;";
                    }
                    if (row.optionE) {
                        result += "E.&nbsp;"+row.optionE+"&nbsp;&nbsp;";
                    }
                    if (row.answer1) {
                        result += "<p style='color: red;font-weight: bolder'>【答案】："+row.answer1.replace(eval("/\\$\\#\\#\\#\\$/g"),"&nbsp;&nbsp;")+"</p><br/>"
                    }
                    if (row.answer2) {
                        result += "<p>"+row.answer2+"</p><br/>"
                    }
                    if (row.parse) {
                        result += "<p>"+row.parse+"</p>"
                    }
                    result += "</div>";
                    return result;
            },
            confirmTagsSelect(){
                if(this.tagsSelection.length!=0){
                  var questionNum = Number(this.examPaperBigList[this.selectId].questionNum); //设计的试题数量
                  var finishQuestionNum =Number(this.examPaperBigList[this.selectId].examPaperSmalls.length); //已安排试题数量
                  var newQuestionNum=this.tagsSelection.length;//新安排试题的数量
                  if(questionNum<finishQuestionNum+newQuestionNum){
                    this.msgWarn("试题数量超限!");
                    return;
                  }
                  for(var i=0;i<this.tagsSelection.length;i++){
                    this.examPaperBigList[this.selectId].examPaperSmalls.push({
                      questionId:this.tagsSelection[i].id,
                      questions:{
                          title:this.tagsSelection[i].title,
                          answer1:this.tagsSelection[i].answer1,
                          answer2:this.tagsSelection[i].answer2,
                      },
                    })
                  }
                  this.subjectPopup=false;
                }else{
                   this.msgWarn("您没有选中任何数据项！");
                   return;
                }
                console.log( this.examPaperBigList,' this.examPaperBigList.....')
                return;
            },
            getpaperList(){
                paperList(this.paperParams).then((res) => {
                        this.paperList=res.rows;
                        this.total=res.total;
                });
            },
            searchPaperFn(){
              this.getQuestionsList();
            },

            //获取年纪数据
            getGradeidList(){
                getSelectList({
                    parentCode: sectionCode,
                }).then((res) => {
                    if (res.code == 0) {
                      this.gradeidListTitle = res.rows;
                     this.gradeidListTitle.unshift({
                        macroId: "",
                        name: "全部",
                    });
                    }
                });
            },
             //获取年纪数据
            getGradeidListAdd(){
                getSelectList({
                    parentCode: sectionCode,
                }).then((res) => {
                    if (res.code == 0) {
                    this.gradeidList = res.rows;
                    }
                });
            },
            //获取学科数据
             getSubjectList() {
                return getSelectList({
                    parentCode: subjectCode,
                }).then((res) => {
                    if (res.code == 0) {
                    this.subjectidList = res.rows;
                    this.subjectidList.unshift({
                        macroId: "",
                        name: "全部",
                    });
                    }
                });
                },
                checkSelectable(row,index) {
                  let flag = false;
                  for (let i = 0, len = this.aaa.length; i < len; i++) {
                    if (this.aaa[i].questionId == row.id) {
                      flag = true;
                      break;
                    }
                  }
                  if (!flag) {
                    return true;
                  }
                  return false;
                },
                //获取学科数据
             getSubjectListAdd() {
                return getSelectList({
                    parentCode: subjectCode,
                }).then((res) => {
                    if (res.code == 0) {
                    this.subjectidList2 = res.rows;
                    }
                });
                },
            //搜索
            searchFn(){
                this.getpaperList();
            },
             searchFn2(){
                this.getpaperList();
            },
            //刷新
            refreshFn(){
                this.reload();
            },
            //添加
            addFn(){
                this.popupData = {
                  title: "新增",
                  type: "add",
                }
                this.popupVisible=true;
                this.saveRuleForm={
                   
                }
            },
            //编辑
            editFn(){
                this.popupData = {
                  title: "编辑",
                  type: "edit",
                }
                if (this.paperId.length != 1) {
                  this.msgWarn("您没有选中任何数据项！");
                  return;
                }
                this.popupVisible=true;
                this.getPaperInfo();
            },
            //删除
            deleteFn(){
                if(this.paperId.length==0){
                    this.msgWarn("请至少选择一条数据！");
                    return;
                }
                this.$confirm("确定要删除吗?", "提示", {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  type: "warning",
                })
                  .then(() => {
                    paperRemove(this.paperId).then((res) => {
                      if (res.code == 0) {
                        this.msgSuccess("操作成功！");
                        this.getpaperList();
                      }
                    });
                  })
                  .catch(() => {})
            },
            //获取详情
            getPaperInfo(){
                 paperInfo(this.paperId[0]).then((res) => {
                    if (res.code == 0) {
                        this.saveRuleForm=res.rows;
                    }
                });
            },
            handleSelectionChange(val){
                this.rowlistId=val;
                this.paperId=[];
                if(val.length>0){
                    for(let i=0,len=val.length;i<len;i++){
                      this.paperId.push(val[i].id)
                    }
                }
            },
            studentItemClick(row){
                this.$refs.paperListTable.toggleRowSelection(row);
            },
            handleSizeChange(pageSize){
                this.paperParams.pageSize = pageSize;
                this.getpaperList();
            },
            handleCurrentChange(currentPage){
                this.paperParams.pageNumber = currentPage;
                this.getpaperList();
            },
            confirmSave(formName){
                this.$refs[formName].validate((valid) => {
                  if(valid){
                      if(this.popupData.type=='add'){
                          paperSave(this.saveRuleForm).then((res) => {
                            if (res.code == 0) {
                              this.popupVisible=false;
                              this.msgSuccess("操作成功！");
                              this.getpaperList();
                            }
                          });  
                      }else if(this.popupData.type=='edit'){
                          paperUpdate(this.saveRuleForm).then((res) => {
                            if (res.code == 0) {
                              this.popupVisible=false;
                              this.msgSuccess("操作成功！");
                              this.getpaperList();
                            }
                          });  
                      }
                  }else {
                    this.msgWarn("必填字段不能为空！");
                    return false;
                  }
                })
            },
            dealSectionId(row){
              return row.gradeName
            },
            //组卷
            testPaper(row){
              this.paperRowId=row.id;
              this.testPaperPopup=true;
              paperfindPaperQuestions(row.id).then((res) => {
                  if(res.code==0){
                    this.examPaperBigList=res.rows;
                  }
              });
            },
            //预览
            testDetails(row){
                this.previewPopup=true;
                paperfindPaperQuestions(row.id).then((res) => {
                    if(res.code==0){
                         this.paperDetailList=res.rows;
                    }
                });
                 paperInfo(row.id).then((res) => {
                    if(res.code==0){
                        this.examPaper=res.rows;
                    }
                });
            },
            //组卷保存
            savePaperQuestionsFn(){
              for(var i=0;i<this.examPaperBigList.length;i++){
                if(this.examPaperBigList[i].examPaperSmalls.length==0){
                    this.msgWarn("试题不能为空！");
                    return false;
                }
              }
                var data={
                    id:this.paperRowId,
                    examPaperBigs:this.examPaperBigList
                }
                savePaperQuestions(data).then((res) => {
                    if(res.code==0){
                        this.msgSuccess("操作成功！");
                        this.testPaperPopup = false;
                        this.getpaperList();
                    }
                });
            }
        },
        watch:{
          popupVisible: function (val,oldVla) {
            if (this.$refs['saveRuleForm'] !== undefined) {
              this.$refs["saveRuleForm"].resetFields();
            }
          }
        },
        mounted(){
            this.getGradeidList();
            this.getGradeidListAdd();
            this.getSubjectList();
            this.getSubjectListAdd();
        }
    }
</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */
.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .btn-area {
    height: 50px;
    display: flex;
    align-items: center;
    .right {
      flex: 1;
      display: flex;
      justify-content: flex-end;
    }
  }
  .select-condition {
    border-bottom: 1px solid #f1f1f1;
    .left {
      flex: 1;
      display: flex;
      .select-condition-item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        .item-lable {
          word-break: keep-all;
        }
      }
    }
  }
  .table-area {
    flex: 1;
    overflow: hidden;
  }
  .page-area {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .dialog-content {
    min-height: 260px;
    max-height: 60vh;
    overflow-y: auto;
    padding-left: 10px;
    padding-right: 10px;
    .item_select {
      display: flex;
      align-items: center;
      span {
        width: 120px;
      }
    }
    .record {
      margin-top: 230px;
    }
  }
  .score-input {
    border: none;
    outline: none;
    border-bottom: 1px solid #000000;
    text-align: center;
    width: 80px;
  }
  .subject-item {
    height: 40px;
    line-height: 40px;
  }
  .self-radio-group {
    margin-left: 20px;
    .self-radio-group-item {
      margin-bottom: 8px;
    }
  }
  .center {
    text-align: center;
    min-height: 30px;
  }
  .line {
    height: 0;
    border-bottom: 1px solid #f1f1f1;
    margin-left: 30px;
    margin: 10px 0 10px 30px;
  }
  
}
.content >>> p{
    display:inline !important;
  }
.contTitle{
  padding:8px 0 !important;
  display:inline-block !important;

}
</style>