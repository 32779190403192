<template>
  <div class="main-content sys-user-manage">
    <div class="btn-area">
      <div class="left">
        <div class="select-condition-item">
          <span class="item-lable">关键字：</span>
          <el-input
            v-model="listParams.name"
            placeholder="讲师姓名/联系方式"
            size="small"
            style="min-width: 120px"
          ></el-input>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">创建人：</span>
          <el-select
            v-model="listParams.createUserId"
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="item in createUserIdList"
              :key="item.user_id"
              :label="item.name"
              :value="item.user_id"
            ></el-option>
          </el-select>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">总课时：</span>
          <el-input
            v-model="listParams.beginHour"
            placeholder=""
            size="small"
          ></el-input
          >~
          <el-input
            v-model="listParams.endHour"
            placeholder=""
            size="small"
          ></el-input>
        </div>
        <el-button
          type="primary"
          size="small"
          icon="el-icon-search"
          @click="
            () => ((listParams.pageNumber = 1), teacherSchedulingListFn())
          "
          >搜索</el-button
        >
        <el-button
          type="primary"
          size="small"
          icon="el-icon-printer"
          @click="importExcel"
          >导出excel</el-button
        >
      </div>
    </div>
    <div class="table-area">
      <el-table
        size="small"
        :data="dataList"
        style="width: 100%; margin-bottom: 20px"
        row-key="userId"
        border
        height="100%"
        tooltip-effect="dark"
      >
        <el-table-column
          prop="realname"
          label="教师姓名"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="contact"
          label="联系方式"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="usedClassHour"
          label="总课时"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="createUserName"
          label="创建人"
          align="center"
        ></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="dealClassRecord(scope.row)"
              >上课记录</el-button
            >
            <el-button type="text" size="small" class="mL10" @click="dealEvaluate(scope.row)"
              >评价</el-button
            >
            <el-button
              type="text"
              size="small"
              class="mL10"
              v-show="scope.row.suggest == 0"
              @click="dealRecommend(scope.row)"
              >推荐</el-button
            >
            <el-button
              type="text"
              size="small"
              class="mL10"
              v-show="scope.row.suggest == 1"
              @click="dealCancelRecommend(scope.row)"
              >取消推荐</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page-area">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="listParams.pageNumber"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="listParams.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background
      ></el-pagination>
    </div>
    <el-dialog
      title="课程详情"
      :visible.sync="courseDetailDialog"
      :close-on-click-modal="false"
      width="1000px"
      center
    >
      <div class="dialog-content">
        <el-table
          size="small"
          :data="courseList"
          style="width: 100%; margin-bottom: 20px"
          row-key="userId"
          border
          tooltip-effect="dark"
        >
          <el-table-column
            prop="name"
            label="校区"
            align="center"
          ></el-table-column>
          <el-table-column prop="account" label="总课时数" align="center">
            <span slot-scope="scope">
              <span>{{ scope.row.map.totalClassHours }}</span>
            </span>
          </el-table-column>
          <el-table-column
            prop="usedClassHour"
            label="一对一课时数"
            align="center"
          >
            <span slot-scope="scope">
              <span>{{ scope.row.map.oneToOneClassHours }}</span>
            </span>
          </el-table-column>
          <el-table-column
            prop="createUserName"
            label="一对一学生数"
            align="center"
          >
            <span slot-scope="scope">
              <span>{{ scope.row.map.oneToOneStudents }}</span>
            </span>
          </el-table-column>
          <el-table-column
            prop="createUserName"
            label="试听课时数"
            align="center"
          >
            <span slot-scope="scope">
              <span>{{ scope.row.map.appointmentHours }}</span>
            </span>
          </el-table-column>
          <el-table-column
            prop="createUserName"
            label="试听课学生数"
            align="center"
          >
            <span slot-scope="scope">
              <span>{{ scope.row.map.appointmentStudents }}</span>
            </span>
          </el-table-column>
          <el-table-column
            prop="createUserName"
            label="一对多课时数"
            align="center"
          >
            <span slot-scope="scope">
              <span>{{ scope.row.map.bigClassHours }}</span>
            </span>
          </el-table-column>
          <el-table-column
            prop="createUserName"
            label="公开课课时数"
            align="center"
          >
            <span slot-scope="scope">
              <span>{{ scope.row.map.smallClassHours }}</span>
            </span>
          </el-table-column>
          <el-table-column
            prop="createUserName"
            label="班级数量"
            align="center"
          >
            <span slot-scope="scope">
              <span>{{ scope.row.map.classNum }}</span>
            </span>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
    <el-dialog
      title="评价详情"
      :visible.sync="evaluateDialog"
      :close-on-click-modal="false"
      width="800px"
      center
    >
      <div class="dialog-content">
        <el-tabs
          v-model="evaluateName"
          @tab-click="evaluateClick"
          type="card"
          style="margin-top: 20px"
        >
          <el-tab-pane label="学生评价" name="first">
            <div class="evaluateDiv">
              <p>老师的优点：</p>
              <div>
                <el-tag
                  style="margin-top: 10px"
                  size="small"
                  type="warning"
                  v-for="(value, key) in goodMapData"
                  :key="key"
                  >{{ key }}（{{ value }}）</el-tag
                >
              </div>
            </div>
            <div class="evaluateDiv">
              <p>希望老师改进的地方：</p>
              <div>
                <el-tag
                  style="margin-top: 10px"
                  size="small"
                  type="warning"
                  v-for="(value, key) in badMapData"
                  :key="key"
                  >{{ key }}（{{ value }}）</el-tag
                >
              </div>
            </div>
            <div class="studentContent">
              <dl v-for="(item, index) in studentEvaluateList" :key="index">
                <dt><img :src="item.studentPhoto ? item.studentPhoto : 'https://rcmtoss.oss-cn-beijing.aliyuncs.com/default/studentDefault.png'" alt="" /></dt>
                <dd>
                  <div class="contT">
                    <p>
                      <span>{{ item.studentName }}</span
                      ><img :src="xueshengPng" alt="" />
                    </p>
                    <p>评价于{{ item.createTime }}</p>
                  </div>
                  <div class="contTag">
                    <el-tag
                      style="margin-right: 10px; margin-bottom:10px"
                      type="warning"
                      v-for="(items, indexs) in item.evaluationTags"
                      :key="indexs"
                      >{{ items }}</el-tag
                    >
                  </div>
                  <p>{{ item.evaluationContent }}</p>
                </dd>
              </dl>
            </div>
          </el-tab-pane>
          <el-tab-pane label="教务评价" name="two">
            <div>
              <el-tag
                @click="labelClick(items.labelId)"
                style="margin-right: 10px; margin-top: 8px; cursor: pointer"
                type="warning"
                v-for="(items, indexs) in labelData"
                :key="indexs"
                >{{ items.name }}（{{ items.num }}）</el-tag
              >
            </div>
            <div class="teachContent">
              <dl
                v-for="(item, index) in teacherEvaluateList"
                :key="index"
                style="padding: 26px 0; border-bottom: 1px solid #dcdfe6"
              >
                <dt><img :src="item.teachManagerPhoto ? item.teachManagerPhoto : 'https://rcmtoss.oss-cn-beijing.aliyuncs.com/default/teacherManagerDefault.png'" alt="" /></dt>
                <dd>
                  <div class="contT">
                    <p>
                      <span>{{ item.teachManagerName }}</span
                      ><img :src="jiaowuPng" alt="" />
                    </p>
                    <p>评价于{{ item.createTime }}</p>
                  </div>
                  <div class="myEvaluateDiv">
                    <p>{{ item.evaluationContent }}</p>
                    <ul>
                      <li v-show="item.evaluationImage">
                        <span>{{ item.evaluationImage.parentType }}：</span>
                        <span
                          style="color: #ffba00"
                          v-for="(item, index) in item.evaluationImage
                            .selectgoodData"
                          :key="index"
                          >{{ item.name + "#" }}</span
                        >
                        <span>{{ item.evaluationImage.content }}</span>
                      </li>
                      <li v-show="item.evaluationTeaching">
                        <span>{{ item.evaluationTeaching.parentType }}：</span>
                        <span
                          style="color: #ffba00"
                          v-for="(item, index) in item.evaluationTeaching
                            .selectgoodData"
                          :key="index"
                          >{{ item.name + "#" }}</span
                        >
                        <span>{{ item.evaluationTeaching.content }}</span>
                      </li>
                      <li v-show="item.evaluationTeachingStyle">
                        <span
                          >{{ item.evaluationTeachingStyle.parentType }}：</span
                        >
                        <span
                          style="color: #ffba00"
                          v-for="(item, index) in item.evaluationTeachingStyle
                            .selectgoodData"
                          :key="index"
                          >{{ item.name + "#" }}</span
                        >
                        <span>{{ item.evaluationTeachingStyle.content }}</span>
                      </li>
                      <li v-show="item.evaluationOther">
                        <span>{{ item.evaluationOther.parentType }}：</span>
                        <span
                          style="color: #ffba00"
                          v-for="(item, index) in item.evaluationOther
                            .selectgoodData"
                          :key="index"
                          >{{ item.name + "#" }}</span
                        >
                        <span>{{ item.evaluationOther.content }}</span>
                      </li>
                    </ul>
                  </div>
                </dd>
              </dl>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-dialog>
  </div>
</template>
<script >
import dayjs from "dayjs";
import {
  getSelectList,
  sectionCode,
  subjectCode,
  teachStyle,
  teachYear,
  teachSpeed,
  constellation,
  followTags,
  fileUpload,
  reg_Num,
} from "@/api/public";
import xueshengPng from "@/assets/image/xuesheng.png";
import jiaowuPng from "@/assets/image/jiaowu.png";
import {
  teacherSchedulingList, //排课量列表接口
  suggestTeacher,
  cancelSuggestTeacher,
  teacherSchedulingDetail,
  addTeacherCreatePeople,
  teacherEvaluatedContent,
  teacherEvaluatedRemove,
  getListByTeacherManagerlist,
  getListEvaluatedCount, //统计
} from "@/api/administration/expertTeachBusiness/teachBusiness.js";
export default {
  data() {
    return {
      xueshengPng,
      jiaowuPng,
      goodMapData: [],
      badMapData: [],
      dataList: [],
      courseList: [],
      total: 0,
      courseDetailDialog: false,
      evaluateDialog: false,
      listParams: {
        name: "",
        pageNumber: 1,
        pageSize: 10,
      },
      createUserIdList: [],
      evaluateName: "first",
      evaluateStuParams: {
        pageNumber: 1,
        pageSize: 1000,
        teacherId: "",
        evaluationSource: 1, //1学生 0教务
      },
      studentEvaluateList: [],
      evaluateTeaParams: {
        pageNumber: 1,
        pageSize: 1000,
        teacherId: "",
        evaluationSource: 0, //1学生 0教务
        labelId: "",
      },
      teacherEvaluateList: [],
      labelData: [],
    };
  },
  methods: {
    getListEvaluatedCountFn() {
      //统计
      getListEvaluatedCount({
        teacherId: this.evaluateTeaParams.teacherId,
      }).then((res) => {
        if (res.code == 0) {
          this.labelData = res.rows;
        }
      });
    },
    labelClick(id) {
      this.evaluateTeaParams.labelId = id;
      this.getListByTeacherManagerlistFn();
    },
    evaluateClick(tab, event) {
      if (tab.index == 0) {
        this.teacherEvaluatedContentFn();
      } else if (tab.index == 1) {
        this.evaluateTeaParams.labelId = "";
        this.getListByTeacherManagerlistFn();
        this.getListEvaluatedCountFn();
      }
    },
    getListByTeacherManagerlistFn() {
      getListByTeacherManagerlist(this.evaluateTeaParams).then((res) => {
        this.teacherEvaluateList = res.rows;
        for (var i = 0; i < this.teacherEvaluateList.length; i++) {
          this.teacherEvaluateList[i].evaluationImage = this
            .teacherEvaluateList[i].evaluationImage
            ? JSON.parse(this.teacherEvaluateList[i].evaluationImage)
            : "";
          this.teacherEvaluateList[i].evaluationOther = this
            .teacherEvaluateList[i].evaluationOther
            ? eval("(" + this.teacherEvaluateList[i].evaluationOther + ")")
            : "";
          this.teacherEvaluateList[i].evaluationTeaching = this
            .teacherEvaluateList[i].evaluationTeaching
            ? eval("(" + this.teacherEvaluateList[i].evaluationTeaching + ")")
            : "";
          this.teacherEvaluateList[i].evaluationTeachingStyle = this
            .teacherEvaluateList[i].evaluationTeachingStyle
            ? eval(
                "(" + this.teacherEvaluateList[i].evaluationTeachingStyle + ")"
              )
            : "";
        }
      });
    },
    teacherEvaluatedContentFn() {
      var data = {
        pageNumber: 1,
        pageSize: 1000,
        teacherId: this.evaluateTeaParams.teacherId,
        evaluationSource: 1,
      };
      teacherEvaluatedContent(data).then((res) => {
        for (var i = 0; i < res.page.rows.length; i++) {
          res.page.rows[i].evaluationTags =
            res.page.rows[i].evaluationTag.split(",");
        }
        this.studentEvaluateList = res.page.rows;
        this.goodMapData = res.goodMap;
        this.badMapData = res.badMap;
      });
    },
    //列表数据
    teacherSchedulingListFn() {
      teacherSchedulingList(this.listParams)
        .then((res) => {
          this.dataList = res.rows;
          this.total = res.total;
        })
        .catch(() => {});
    },
    //获取创建人
    addTeacherCreatePeopleFn() {
      addTeacherCreatePeople()
        .then((res) => {
          console.log(res, "创建人");
          this.createUserIdList = res;
          this.createUserIdList.unshift({
            user_id: "",
            name: "全部",
          });
        })
        .catch(() => {});
    },
    //上课记录
    dealClassRecord(row) {
      this.courseDetailDialog = true;
      teacherSchedulingDetail({ teacherId: row.id })
        .then((res) => {
          this.courseList = res.rows;
        })
        .catch(() => {});
      // teacherSchedulingDetail(row.id))
      // .then(res => {

      // })
      // .catch(() => {});
    },
    removeAnonymous(row) {
      teacherEvaluatedRemove([row.id]).then((res) => {
        if (res.code == 0) {
          this.msgSuccess("操作成功！");
          this.teacherEvaluatedContent();
        }
      });
    },
    //评价
    dealEvaluate(row) {
      this.evaluateDialog = true;
      this.evaluateStuParams.teacherId = row.id;
      this.evaluateTeaParams.teacherId = row.id;
      this.evaluateName = 'first'
      this.teacherEvaluatedContentFn();
    },
    //推荐
    dealRecommend(row) {
      this.$confirm(
        `${row.suggest == "0" ? "确定要推荐吗?" : "确定要取消推荐吗?"}`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        suggestTeacher({
          id: row.id,
        })
          .then((res) => {
            if (res.code == 0) {
              this.msgSuccess(
                `${row.suggest == "0" ? "推荐成功！" : "取消推荐成功！"}`
              );
              this.teacherSchedulingListFn();
            }
          })
          .catch(() => {});
      });
    },
    //取消推荐
    dealCancelRecommend(row) {
      this.$confirm(
        `${row.suggest == "0" ? "确定要推荐吗?" : "确定要取消推荐吗?"}`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        cancelSuggestTeacher({
          id: row.id,
        })
          .then((res) => {
            if (res.code == 0) {
              this.msgSuccess(
                `${row.suggest == "0" ? "推荐成功！" : "取消推荐成功！"}`
              );
              this.teacherSchedulingListFn();
            }
          })
          .catch(() => {});
      });
    },
    //导出
    importExcel() {
      let jsonData = [],
        str = `教师姓名,联系方式,总课时,创建人\n`;
      for (let i = 0, len = this.dataList.length; i < len; i++) {
        jsonData.push({
          name: this.dataList[i].name,
          contact: this.dataList[i].contact,
          usedClassHour: this.dataList[i].usedClassHour,
          createUserName: this.dataList[i].createUserName,
        });
      }
      // 增加\t为了不让表格显示科学计数法或者其他格式
      for (let i = 0; i < jsonData.length; i++) {
        for (const key in jsonData[i]) {
          str += `${jsonData[i][key] + "\t"},`;
        }
        str += "\n";
      }
      console.log(str);
      // encodeURIComponent解决中文乱码
      const uri =
        "data:text/xls;charset=utf-8,\ufeff" + encodeURIComponent(str);
      // 通过创建a标签实现
      const link = document.createElement("a");
      link.href = uri;
      // 对下载的文件命名
      link.download = "订单审核列表.xls";
      link.click();
    },
    handleSizeChange(pageSize) {
      this.listParams.pageSize = pageSize;
      this.teacherSchedulingListFn();
    },
    handleCurrentChange(currentPage) {
      this.listParams.pageNumber = currentPage;
      this.teacherSchedulingListFn();
    },
  },
  mounted() {
    this.teacherSchedulingListFn();
    this.addTeacherCreatePeopleFn();
  },
};
</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */
.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .btn-area {
    height: 50px;
    display: flex;
    align-items: center;
    .left {
      flex: 1;
      display: flex;
      .select-condition-item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        .item-lable {
          word-break: keep-all;
        }
      }
    }
  }
  .dialog-content {
    .detail_type {
      font-size: 18px;
      line-height: 30px;
      font-weight: 900;
    }
    .row_detail {
      padding: 6px 0;
    }
    .certificate {
      width: 80px;
    }
  }
  .table-area {
    flex: 1;
    overflow: hidden;
  }
  .page-area {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .p1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .evaluateDiv {
    margin-top: 15px;
    p {
      color: #333333;
      font-weight: bold;
    }
  }
  .studentContent,
  .teachContent {
    margin-top: 50px;
    dl {
      display: flex;
      padding: 26px 0;
      border-bottom: 1px solid #dcdfe6;
      dt {
        width: 60px;
        height: 60px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
      dd {
        margin-left: 20px;
        flex: 1;
        .contT {
          display: flex;
          align-items: center;
          justify-content: space-between;
          p:nth-child(1) {
            display: flex;
            align-items: center;
            span {
              color: #909399;
            }
            img {
              width: 34px;
              height: 16px;
              margin-left: 7px;
            }
          }
        }
        .contTag {
          margin-top: 15px;
        }
        > p {
          color: #333333;
          margin-top: 20px;
          line-height: 24px;
        }
      }
    }
  }
  .myEvaluateDiv {
  margin-top: 20px;
  ul {
    li {
      margin: 6px 0;
      line-height: 1.5;
    }
  }
  div {
    display: flex;
    flex-direction: column;
    .btn {
      margin: 4px 0;
    }
  }
}
}
</style>
